import React, { memo, useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Scheduler, Resource, Scrolling } from "devextreme-react/scheduler";
import zipcode from "zipcodes";
import "./CustomCalendar.css";
import {
  getPropertiesOfPM,
  getPropertyManagerScheduler,
  getService,
} from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { addJob, editJob } from "../../../api/api_calls/addData";
import { toast } from "react-toastify";
import { deleteJob } from "../../../api/api_calls/deleteData";
import PhoneNumberInput from "../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
import { GenericModal } from "../../../components";
import { AddJob } from "./AddJob/AddJob";
import moment from "moment";
import { convertToUTC } from "../../../utils/HelperFunctions";

const view = [
  {
    type: "timelineDay",
    name: "Day",
    groupOrientation: "vertical",
    cellDuration: 60,
    maxAppointmentsPerCell: 4,
  },
  {
    type: "month",
    name: "Month",
    groupOrientation: "vertical",
    cellDuration: 120,
  },
  {
    type: "timelineWeek",
    name: "Week",
    groupOrientation: "vertical",
    colSpan: 2,
    cellDuration: 1440,
    maxAppointmentsPerCell: 4,

    // intervalCount: 1,
  },
];

const resourceColors = [
  "rgb(240, 87, 151)",
  "rgb(86, 202, 133)",
  "rgb(255, 151, 71)",
  "rgb(203, 107, 178)",
  "rgb(225, 142, 146)",
];

const state = {
  allowAdding: true,
  allowDeleting: true,
  allowUpdating: true,
  allowResizing: false,
};
const CustomCalendar = ({
  resources,
  market,
  groupDetails,
  currentView,
  setCurrentView,
  dataSource,
  handleGetAllGroupJobs,
  handleClearingAllJobs,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    date: new Date(),
    startTime: "",
    endTime: "",
    serviceCategory: {},
    service: {},
    requestedBy: "",
    property: "",
    groupName: {},
    staffMember: {},
    address: "",
    city: "",
    state: "",
    zipCode: "",
    addTask: "",
    userDetails: {},
  });
  const [open, setOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [addJobModalType, setAddJobModalType] = useState("");
  const [appointmentData, setAppointmentData] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  // ------------to hide the time in timeline week view---------------
  useEffect(() => {
    if (currentView === "Week") {
      const timeComponents = document.querySelectorAll("[title='12:00 AM']");

      timeComponents.forEach((component) => {
        component.style.display = "none";
      });
    }
  });
  // ------------------------------------------------------------------

  const handleModal = (event) => {
    // if (open) setAddJobModalType("");
    setAppointmentData(event?.appointmentData);
    if (open) setAddJobModalType("");
    setOpen(!open);
    setUpdateModalOpen(false);
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setOpen(false);
  };

  const makeResource = (resource) => {
    return resource.map((staff, ind) => {
      return {
        id: staff?._id,
        text: staff?.fullName,
        color: resourceColors?.[ind] || "red",
      };
    });
  };

  const fetchPropertyManager = async () => {
    const res = await getPropertyManagerScheduler();
    setFormData((currentState) => ({
      ...currentState,
      requestedBy: res?.data?.data?.allPropertiesManager,
    }));
  };

  const serviceValues = (data) => {
    const serviceValuesArray = [];
    data?.forEach((item) =>
      serviceValuesArray.push({
        _id: item?._id,
        name: `${item?.name} ($${item?.price})`,
        price: item?.price,
      })
    );

    return serviceValuesArray;
  };
  const fetchServices = async (id) => {
    const res = await getService(id);
    setFormData((currentState) => ({
      ...currentState,
      service: res?.data?.data?.services,
    }));
  };

  const onCurrentViewChange = (value) => {
    setCurrentView(value);
    handleClearingAllJobs([]);
  };

  const handleDateChange = (value) => {
    setCurrentDate(value);
  };

  const handleUpdateJob = (e) => {
    const serviceToSearch =
      e?.appointmentData?.service?._id || e?.appointmentData?.service;
    e.cancel = true;
    // const amount = serviceValues(formData?.service).filter(
    //   (item) => item?._id === e?.appointmentData?.service?._id
    // )[0]?.price;
    const amount = serviceValues(formData?.service).filter(
      (item) => item?._id === serviceToSearch
    )[0]?.price;

    //---------------- allotting the correct time -----------------
    const dateMoment = moment(e?.appointmentData?.startDate);
    const startTimeMoment = moment(
      new Date(e?.appointmentData?.jobTiming?.startTime).toLocaleString(
        "en-us",
        { timeZone: e?.appointmentData?.timezone }
      )
    );
    const startTimeForJob = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: startTimeMoment.second(),
      millisecond: startTimeMoment.millisecond(),
    });

    const endTimeMoment = moment(
      new Date(e?.appointmentData?.jobTiming?.endTime).toLocaleString("en-us", {
        timeZone: e?.appointmentData?.timezone,
      })
    );
    const endTimeForJob = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond(),
    });
    // ---------------------------------------------

    // ------------ for timezone ----------------
    let offset = new Date().getTimezoneOffset() / 60;

    const timeZone = {
      CST: 6,
      PST: 8,
      EST: 5,
    };

    let jobDate = new Date(e?.appointmentData?.startDate);
    jobDate.setTime(
      jobDate.getTime() -
        (offset - timeZone?.[e?.appointmentData?.timezone]) * 60 * 60 * 1000
    );

    let startTime = new Date(
      currentView === "Week" ? startTimeForJob : e?.appointmentData?.startDate
    );
    startTime.setTime(
      startTime.getTime() -
        (offset - timeZone?.[e?.appointmentData?.timezone]) * 60 * 60 * 1000
    );

    let endTime = new Date(
      currentView === "Week" ? endTimeForJob : e?.appointmentData?.endDate
    );
    endTime.setTime(
      endTime.getTime() -
        (offset - timeZone?.[e?.appointmentData?.timezone]) * 60 * 60 * 1000
    );

    // ------------------------------------------

    const appointmentData = {
      timezone: e?.appointmentData?.timezone,
      jobId: e?.appointmentData?.jobId,
      // localJobDate: moment(startTimeForJob).format("L"),
      // localStartTime: moment(startTimeForJob).format("LT"),
      requestedBy: e?.appointmentData?.requestedBy,
      propertyId:
        typeof e?.appointmentData?.property === "string"
          ? e?.appointmentData?.property
          : e?.appointmentData?.property?._id,
      // jobDate: e?.appointmentData?.startDate,
      jobDate: startTime,
      jobTiming: {
        // startTime: convertToUTC(startTimeForJob.toISOString()),
        // endTime: convertToUTC(endTimeForJob.toISOString()),
        startTime,
        endTime,
      },
      categoryId: e?.appointmentData?.serviceCategory,
      serviceId: serviceToSearch,
      address: e?.appointmentData?.address,
      city: e?.appointmentData?.city,
      country: e?.appointmentData?.country,
      state: e?.appointmentData?.state,
      postalCode: e?.appointmentData?.postalCode,
      groupTagID: e?.appointmentData?.groupName,
      assignedStaffMembers: [e?.appointmentData?.assignedStaff],
      isTemplate: e?.appointmentData?.isTemplate,
      task: e?.appointmentData?.task,
      amount,
    };
    editJob(appointmentData)
      .then((res) => {
        handleGetAllGroupJobs(groupDetails?._id);
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "3007",
        });
      });
    e.cancel = true;
  };
  const Appointment = ({ data }) => {
    return (
      <div
        className="flex-col"
        style={{ borderRadius: "8px !important", fontSize: "1rem !important" }}
      >
        <h3
          className={
            data?.appointmentData?.isStrikethroughed ? "strikethrough" : ""
          }
        >
          {`${data?.appointmentData?.service?.name} (${data?.appointmentData?.requestedByDetail?.fullName})`}
        </h3>
      </div>
    );
  };

  const handleJobDelete = (e) => {
    deleteJob(e?.appointmentData?.jobId)
      .then((res) => handleGetAllGroupJobs(groupDetails?._id))
      .catch((error) =>
        toast.error(error?.message, { toastId: "error in deleting job" })
      );
  };
  useEffect(() => {
    if (groupDetails?.categoryData?._id) {
      fetchServices(groupDetails?.categoryData?._id);
    }
  }, [groupDetails?.categoryData?._id]);
  useEffect(() => {
    fetchPropertyManager();
  }, []);

  return (
    <>
      <Scheduler
        dataSource={dataSource}
        currentDate={currentDate}
        currentView={currentView}
        onCurrentDateChange={handleDateChange}
        views={view}
        onCurrentViewChange={onCurrentViewChange}
        editing={state}
        groups={["assignedStaff"]}
        firstDayOfWeek={1}
        crossScrollingEnabled={true}
        showAllDayPanel={false}
        appointmentComponent={Appointment}
        onAppointmentAdding={(e) => {
          e.cancel = true;
          const amount = serviceValues(formData?.service).filter(
            (item) => item?._id === e?.appointmentData?.service
          )[0]?.price;
          const appointmentData = {
            marketId: market,
            requestedBy: e?.appointmentData?.requestedBy,
            propertyId: e?.appointmentData?.property,
            jobDate: e?.appointmentData?.startDate,
            jobTiming: {
              startTime: e?.appointmentData?.startDate,
              endTime: e?.appointmentData?.endDate,
            },
            categoryId: e?.appointmentData?.serviceCategory,
            serviceId: e?.appointmentData?.service,
            address: e?.appointmentData?.address,
            city: e?.appointmentData?.city,
            state: e?.appointmentData?.state,
            country: e?.appointmentData?.country,
            postalCode: e?.appointmentData?.postalCode,
            groupTagID: e?.appointmentData?.groupName,
            assignedStaffMembers: [e?.appointmentData?.assignedStaff],
            isTemplate: e?.appointmentData?.isTemplate || false,
            task: e?.appointmentData?.task,
            adminRole: 4,
            amount: amount,
          };
          dispatch(loaderActions.toggleLoading(true));
          addJob(appointmentData)
            .then((res) => {
              handleGetAllGroupJobs(groupDetails?._id);
            })
            .catch((error) => {
              toast.error(`${error}`, {
                toastId: "3500",
              });
            })
            .finally((res) => dispatch(loaderActions.toggleLoading(false)));
          e.cancel = true;
        }}
        onAppointmentUpdated={(e) => {
          handleUpdateJob(e);
        }}
        onAppointmentFormOpening={(e) => {
          e.cancel = true;
          if (e?.appointmentData?.text) setAddJobModalType("edit");
          handleModal(e);
        }}
        onAppointmentDeleted={handleJobDelete}
      >
        <Resource
          fieldExpr="assignedStaff"
          dataSource={makeResource(resources)}
          allowMultiple={false}
        />
      </Scheduler>
      <GenericModal
        open={open}
        onClose={handleModal}
        title={addJobModalType === "edit" ? "Edit Job" : "Add Job"}
        caption=""
        content={
          <AddJob
            modalType={addJobModalType}
            tempData={appointmentData}
            onCancel={handleModal}
            marketId={market}
            groupDetails={groupDetails}
            handleGetAllGroupJobs={handleGetAllGroupJobs}
          />
        }
      />
      <GenericModal
        open={updateModalOpen}
        onClose={handleUpdateModal}
        title="Add Job"
        caption=""
        content={
          <AddJob
            onCancel={handleUpdateModal}
            marketId={market}
            groupDetails={groupDetails}
          />
        }
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) =>
  JSON.stringify(prevProps) === JSON.stringify(nextProps);
export default memo(CustomCalendar, areEqual);
