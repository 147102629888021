import React from "react";
import Button from "@mui/material/Button";
import classes from "./Card.module.css";

const CustomButton = ({
  bgColor,
  borderColor,
  textColor,
  fontWeight,
  text,
  icon,
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      className={classes.customButton}
      startIcon={icon}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color: textColor,
        fontWeight: fontWeight,
        textTransform: "none",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
