import { createSlice } from "@reduxjs/toolkit";

const initialState ={
   bookingData: "",
};

const bookingSlice = createSlice({
   name: "bookingData",
   initialState,
   reducers:{
      storeBookingData(state, action){
         state = action.payload;

         return state;
      },
      // reset : () => initialState
   }
});

export const bookingDataActions = bookingSlice.actions;

export default bookingSlice.reducer;