import React, { useEffect, useState } from "react";
import { CustomSeparator } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import Activity from "../../../components/common/Activity/Activity";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import {
  getAdminDashboardActivityData,
  getPmDashboardActivity,
} from "../../../api/api_calls/data";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { ThemeProvider } from "@emotion/react";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";

const AllActivities = () => {
  const [activityArray, setActivityArray] = useState({
    data: [],
    totalCount: "",
    loading: false,
  });
  const roleArr = [4, 5, 6, 7, 8];
  const breadcrumbs = [
    { label: "Home", url: "/home/dashboard" },
    { label: "Activity" },
  ];
  const role = useSelector((state) => state.user.role);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);
  const fetchAllActivities = async () => {
    dispatch(loaderActions.toggleLoading(true));
    try {
      const res = await getAdminDashboardActivityData(10, (page - 1) * 10);
      if (res?.status === 200) {
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / 10));
        setActivityArray((v) => ({
          ...v,
          data: res?.data?.data?.activity,
          totalCount: res?.data?.data?.countData,
          loading: false,
        }));
        dispatch(loaderActions.toggleLoading(false));
      }
    } catch (error) {
      dispatch(loaderActions.toggleLoading(false));
      toast.error(error?.message);
    }
  };

  const fetchPmActivity = async () => {
    dispatch(loaderActions.toggleLoading(true));
    try {
      const res = await getPmDashboardActivity(10, (page - 1) * 10);
      if (res?.status === 200) {
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / 10));
        setActivityArray((v) => ({
          ...v,
          data: res?.data?.data?.notifications,
          totalCount: res?.data?.data?.countData,
          loading: false,
        }));
        dispatch(loaderActions.toggleLoading(false));
      }
    } catch (error) {
      dispatch(loaderActions.toggleLoading(false));
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (role === 1) {
      fetchPmActivity();
    } else if (roleArr.includes(role)) {
      fetchAllActivities();
    }
  }, [role, page]);

  return (
    <div className="flex-col ht-100 gap-28 side-pd-70" style={{ width: "90%" }}>
      <TableHeader
        title="Activity"
        caption={<CustomSeparator items={breadcrumbs} />}
        backIcon={true}
      />
      <div className="flex-col gap-32 pd-24 ht-100 overflow-auto  bg-white just-sp-bwn">
        <Activity activity={activityArray?.data || []} />
        <div className="flex-row justify-right mt-40">
          <ThemeProvider theme={paginationTheme}>
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default AllActivities;
