import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import Error from "../../../../components/common/commonComponents/Error/Error";
import Select from "react-select";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import classes from "./BookService.module.css";
import {
  getCategory,
  getProperty,
  getService,
} from "../../../../api/api_calls/data";
import { loaderActions } from "../../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { serviceformValidation } from "../../../../validations/addServiceValidation";
import zipcode from "zipcodes";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";
import moment from "moment";
import { toast } from "react-toastify";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro";
import { convertToUTC } from "../../../../utils/HelperFunctions";

const BookService = ({ onCancel, data }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const [errors, setErrors] = useState("");
  const [property, setProperty] = useState([]);
  const [category, setCategory] = useState([]);
  const [service, setService] = useState([]);

  let offset = new Date().getTimezoneOffset() / 60;

  const timeZone = {
    CST: 6,
    PST: 8,
    EST: 5,
  };

  const requestedBy = useSelector((state) => state?.user?.id);

  const timeZoneOptions = [
    { label: "EST", value: "EST" },
    { label: "CST", value: "CST" },
    { label: "PST", value: "PST" },
  ];

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    timezone: "EST",
    propertyName: data?.propertyName ? data.propertyName : "",
    categoryName: data?.categoryName ? data.categoryName : "",
    serviceName: data?.serviceName ? data.serviceName : "",
    address: data?.address ? data.address : "",
    city: data?.city ? data.city : "",
    state: data?.state ? data.state : "",
    postalCode: data?.postalCode ? data.postalCode : "",
    country: data?.country ? data.country : "",
    jobDate: data?.jobDate ? data?.jobDate : new Date(),
    startTime: data?.jobTiming?.startTime ? data?.jobTiming?.startTime : "",
    endTime: data?.jobTiming?.endTime ? data?.jobTiming?.endTime : "",
    amount: "",
  });

  const fetchData = () => {
    getProperty()
      .then((res) => {
        setProperty(res?.data?.data?.properties);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
    getCategory()
      .then((res) => {
        setCategory(res?.data?.data?.categories);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
  };

  const handleCategoryChange = (e) => {
    if (e?._id) {
      dispatch(loaderActions.toggleLoading(true));
      getService(e?._id)
        .then((res) => {
          if (res?.data?.data?.services.length > 0) {
            setService(res?.data?.data?.services);
          } else {
            setService([]);
          }
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    }
  };

  const autoFill = (val) => {
    if (val.target.value !== "") {
      const z = zipcode.lookup(val.target.value);
      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: z?.city || "",
        state: z?.state || "",
        country: z?.country || "",
      }));
    } else {
      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: "",
        state: "",
        country: "",
      }));
    }
  };

  const handleFormChange = (name, value, e) => {
    if (name === "jobDate") {
      value = moment(value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFormData((currentState) => ({
        ...currentState,
        [name]: value,
      }));
    } else if (name === "postalCode") {
      return autoFill(e);
    } else {
      setFormData((currentState) => ({
        ...currentState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const discountAmount =
      userData?.discounts.filter(
        (discount) => discount?.serviceId === formData?.serviceName?._id
      )[0]?.discount || 0;

    const dateMoment = moment(formData?.jobDate);
    const timeMoment = moment(formData?.startTime);
    const combinedDateTime = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: timeMoment.hour(),
      minute: timeMoment.minute(),
      second: timeMoment.second(),
      millisecond: timeMoment.millisecond(),
    });
    const endTimeMoment = moment(formData?.endTime);
    const endTimeForJob = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond(),
    });

    // ----------manipulating the time for different timezones----------------
    let jobDate = new Date(formData?.jobDate);
    jobDate.setTime(
      jobDate.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    let startTime = new Date(combinedDateTime);
    startTime.setTime(
      startTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    let endTime = new Date(endTimeForJob);
    endTime.setTime(
      endTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );
    // -----------------------------------------------------------------------

    setFormData((currentState) => ({
      ...currentState,
      jobDate: dateMoment.toISOString(),
      startTime: combinedDateTime.toISOString(),
      endTime: endTimeForJob.toISOString(),
    }));
    const postForm = {
      timezone: formData?.timezone,
      propertyId: formData?.propertyName?._id,
      requestedBy: requestedBy,
      categoryId: formData?.categoryName?._id,
      serviceId: formData?.serviceName?._id,
      localJobDate: moment(combinedDateTime).format("L"),
      localStartTime: moment(combinedDateTime).format("LT"),
      // jobDate: convertToUTC(dateMoment.toISOString()),
      jobDate: startTime,

      jobTiming: {
        // startTime: convertToUTC(combinedDateTime.toISOString()),
        // endTime: convertToUTC(endTimeForJob.toISOString()),
        startTime,
        endTime,
      },
      address: formData?.address,
      city: formData?.city,
      state: formData?.state,
      postalCode: formData?.postalCode,
      country: formData?.country,
      // transactionId: "",
      amount: formData?.amount - discountAmount,
    };

    const formValidation = serviceformValidation.validate(formData);
    if (formValidation?.error?.message) {
      setErrors(formValidation?.error?.message);
      return;
    }

    setTimeout(() => {
      navigate(`/home/payment`, { state: { formData, postForm } });
    }, 100);
  };

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);

  return (
    <form
      onSubmit={handleSubmit}
      className="gap-40 flex-col"
      style={{ width: "94%" }}
    >
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Timezone</label>
            <Select
              name="timezone"
              styles={customStylesReactSelect}
              options={timeZoneOptions}
              value={timeZoneOptions.filter(
                (timeZone) => timeZone?.value === formData?.timezone
              )}
              onChange={(e) =>
                setFormData((currState) => {
                  return { ...currState, timezone: e?.value };
                })
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Choose Property</label>
            <Select
              name="propertyName"
              placeholder="Select Property"
              options={property}
              getOptionLabel={(item) => item?.propertyName}
              getOptionValue={(item) => item?._id}
              styles={customStylesReactSelect}
              onChange={(selectedOption) =>
                handleFormChange("propertyName", selectedOption)
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service Category</label>
            <Select
              name="categoryName"
              placeholder="Select Category"
              options={category}
              getOptionLabel={(item) => item?.categoryName}
              getOptionValue={(item) => item?._id}
              onChange={(selectedOption) => {
                handleCategoryChange(selectedOption);
                handleFormChange("categoryName", selectedOption);
              }}
              styles={customStylesReactSelect}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service</label>
            <Select
              name="serviceName"
              placeholder="Select Service"
              options={service.length > 0 ? service : []}
              getOptionLabel={(item) => item?.name}
              getOptionValue={(item) => item?._id}
              styles={customStylesReactSelect}
              onChange={(selectedOption) => {
                handleFormChange("serviceName", selectedOption);

                setFormData((currentState) => ({
                  ...currentState,
                  amount: selectedOption?.price,
                }));
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Property Address</label>
            <input
              name="address"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              placeholder="Enter Address"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Postal Code</label>
            <input
              name="postalCode"
              onChange={(e) =>
                handleFormChange(e.target.name, e.target.value, e)
              }
              value={formData.postalCode}
              placeholder="Enter Postal Code"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>City</label>
            <input
              name="city"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              value={formData.city}
              placeholder="Enter City"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>State</label>
            <input
              name="state"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              value={formData.state}
              placeholder="Enter State"
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Country</label>
            <input
              name="country"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              value={formData.country}
              placeholder="Enter Country"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Select Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="jobDate"
                value={dayjs(formData?.jobDate)}
                onChange={(selectedDate) => {
                  handleFormChange("jobDate", selectedDate?.$d);
                }}
                inputFormat="DD/MM/YYYY"
                disablePast
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Select Time</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SingleInputTimeRangeField
                onChange={(e) => {
                  setFormData((currState) => {
                    return {
                      ...currState,
                      startTime: moment(e[0]?.["$d"])
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                      endTime: moment(e[1]?.["$d"])
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    };
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>
      {errors && <Error title={errors} />}

      <Box
        className="flex-row justify-center medium-gap"
        sx={{ textAlign: "center" }}
      >
        <Button
          type="button"
          title="Cancel"
          handleFunction={onCancel}
          theme="grey"
        />
        <Button title={"Pay Now"} type={"submit"} />
      </Box>
    </form>
  );
};

export default BookService;
