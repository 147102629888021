import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import { toast } from "react-toastify";
import LoginRightScreen from "../LoginRightScreen/LoginRightScreen";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import Input from "../commonComponents/Input/Input";
import Button from "../commonComponents/Button/Button";
import { MenuItem, Select } from "@mui/material";

import schema from "../../../validations/loginValidation";
import Error from "../commonComponents/Error/Error";
import API from "../../../api/api_setting/apiConstants";
import { login, propertyManagerLogin } from "../../../api/api_calls/auth";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/reducers/auth";
import { userActions } from "../../../redux/reducers/user";
import { loaderActions } from "../../../redux/reducers/loader";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState(4);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isPropertyManager, setIsPropertyManager] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
    if (e.target.value == 1) {
      setIsPropertyManager(true);
    }
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    const formData = {
      password,
      email,
      role,
    };
    const validData = schema?.validate(formData);
    if (validData?.error?.message) {
      setErrors(validData?.error?.message);
      return;
    }
    dispatch(loaderActions.toggleLoading(true));
    if (role === 1) {
      propertyManagerLogin(formData)
        .then((res) => {
          if (res?.status === 200) {
            localStorage.setItem(API.AUTH_TOKEN, res?.data?.data?.token);
            dispatch(authActions.login(res?.data?.data));
            dispatch(userActions.storeUser(res?.data?.data));
            navigate("/home");
          }
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "81",
          });
        })
        .finally((res) => {
          dispatch(loaderActions.toggleLoading(false));
        });
    } else {
      login(formData)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(API.AUTH_TOKEN, res.data?.data?.token);
            dispatch(authActions.login(res?.data?.data));
            dispatch(userActions.storeUser(res?.data?.data));
            navigate("/home");
          }
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "1",
          });
        })
        .finally((res) => {
          dispatch(loaderActions.toggleLoading(false));
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <Grid item container xs={12} className={classes.root}>
      <Grid item xs={12} md={6}>
        <div className={`${classes.login_content} flex-col`}>
          <h3 className="font-24">Welcome Back</h3>
          <p className="font-16">
            Enter below details to sign in to your account
          </p>

          <form className="flex-col" onSubmit={handleSubmit}>
            <div className={`${classes.login_inputs} flex-col`}>
              <Input
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
              />

              <div className={`${classes.login_password} flex-col`}>
                <Input
                  name="password"
                  placeholder="Password"
                  value={password}
                  maxLength={30}
                  type={showPassword ? "text" : "password"}
                  padding="10px"
                  onChange={handlePasswordChange}
                />
                <div className={classes.password} onClick={passwordVisibility}>
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                  )}
                </div>
              </div>

              {errors && <Error title={errors} />}
              <Select
                value={role}
                onChange={handleRoleChange}
                sx={{
                  height: "38px",
                  background: "#F8F8F8",
                  fontFamily: "Roboto Slab",
                  fontSize: "14px",
                }}>
                <MenuItem value={4} sx={{ fontFamily: "Roboto Slab" }}>
                  Super Admin
                </MenuItem>
                <MenuItem value={5} sx={{ fontFamily: "Roboto Slab" }}>
                  Admin
                </MenuItem>
                <MenuItem value={1} sx={{ fontFamily: "Roboto Slab" }}>
                  Property Manager
                </MenuItem>
                <MenuItem value={6} sx={{ fontFamily: "Roboto Slab" }}>
                  Sub-Admin
                </MenuItem>
                <MenuItem value={7} sx={{ fontFamily: "Roboto Slab" }}>
                  Manager
                </MenuItem>
                <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                  Q A
                </MenuItem>
              </Select>
            </div>
            {role === 4 ? (
              ""
            ) : (
              <div
                className="wd-100 flex-row"
                style={{ justifyContent: "flex-end" }}>
                <p
                  className="font-16 pointer"
                  onClick={() =>
                    navigate("/forgotPassword", {
                      state: {
                        email,
                        role,
                        isPropertyManager,
                      },
                    })
                  }>
                  Forgot Password
                </p>
              </div>
            )}
            <Button title="Sign In" />
          </form>
        </div>
      </Grid>

      <LoginRightScreen />
      {/* {toastError && <ToastWarn message={toastError} />} */}
    </Grid>
  );
};

export default Login;
