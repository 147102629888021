import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// very important line
import "chart.js/auto";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ dataset }) => {
  let yearsAndMonths = [];
  let graphData = [];

  if (dataset !== undefined && dataset.length > 0) {
    dataset.forEach((el, i) => {
      return (
        yearsAndMonths.push(
          `${el?._id?.day}/${el?._id?.month}/${el?._id?.year}`
        ) && graphData.push(el?.count || el?.totalRevenue)
      );
    });
  }

  let max = graphData?.length > 0 ? Math.max(...graphData) + 5 : 100;

  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        steps: 10,
        stepValue: 5,
        max: max,
      },
    },
  };

  const data = {
    labels: yearsAndMonths,
    datasets: [
      {
        label: "Service Requests",
        data: graphData,
        backgroundColor: "#5BC6D6",
      },
    ],
  };

  return (
    <Bar
      className="pm-service-request-bar-chart"
      options={options}
      data={data}
    />
  );
};

export default BarChart;
