import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateIcon from "@mui/icons-material/Create";
import { GenericModal } from "../../../components";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import { getSingleUser, getUserList } from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import AddUser from "./AddUser/AddUser";
import classes from "../StaffManagement/StaffManagement.module.css";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { toast } from "react-toastify";
const headings = [
  { heading: "Name", key: "fullName" },
  { heading: "Email Address", key: "email" },
  { heading: "Country Code", key: "countryCode" },
  { heading: "Mobile Number", key: "mobileNumber" },
  { heading: "Permissions", key: "permissions", type: "permission" },
  { heading: "Status", key: "isInviteAccepted", type: "status" },
  { heading: "Action", key: "icon" },
];

const Users = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [viewData, setViewData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [userStatus, setUserStatus] = useState("active");

  useEffect(() => {
    fetchUsers(rows, page);
  }, [rows, page]);

  useEffect(() => {
    fetchUsers(rows, 1);
    setPage(1);
  }, [userStatus]);

  const fetchUsers = (rows, page) => {
    dispatch(loaderActions.toggleLoading(true));
    getUserList(rows, (page - 1) * rows, "", userStatus)
      .then((res) => {
        setUserData(res?.data?.data?.adminUsersList);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
  };

  const handleModal = () => {
    setOpen(!open);
    setViewData(null);
    setModalType("");
    setUpdateModalOpen(false);
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setModalType("update");
    setViewData(data);
    setOpen(false);
  };
  const fetchSinglePropertyDetails = async (_id) => {
    try {
      const res = await getSingleUser(_id);
      if (res?.status === 200) {
        handleUpdateModal(res?.data?.data);
      }
    } catch (error) {}
  };
  const addIconsToUserData = (data) => {
    return data?.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}
        >
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={(e) => fetchSinglePropertyDetails(item?._id)}
          >
            <CreateIcon />
          </div>
        </div>
      ),
    }));
  };
  return (
    <div
      className="flex-col gap-32 ht-100 overflow-auto just-sp-bwn  pd-24  bg-white"
      style={{ width: "96%" }}
    >
      <div className="flex-col gap-32">
        <div className="wd-100 flex-row  justify-center align-center">
          <div
            className={`pd-16 ${
              userStatus === "active" ? classes.active : "bg-light"
            } bord-right-gray flex-row pointer justify-center`}
            style={{ width: "300px" }}
            onClick={() => setUserStatus("active")}
          >
            Active
          </div>
          <div
            className={`pd-16 ${
              userStatus === "inActive" ? classes.active : "bg-light"
            } flex-row pointer justify-center`}
            style={{ width: "300px" }}
            onClick={() => setUserStatus("inActive")}
          >
            Inactive
          </div>
        </div>
        <TableHeader
          title="Users & Access"
          dropdown
          firsticon={<PersonAddIcon />}
          firstbuttonTitle="Add Users"
          handleFirstButton={handleModal}
        />
        <CustomTable headers={headings} data={addIconsToUserData(userData)} />
      </div>
      {userData.length !== 0 && (
        <div className="flex-row align-center just-sp-bwn">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="table-row-selection">Rows</InputLabel>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                label="Rows"
                value={rows}
                onChange={(e) => setRows(e.target.value)}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <ThemeProvider theme={paginationTheme}>
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      )}
      {/* Add Property Manager modal */}
      <GenericModal
        open={open}
        onClose={handleModal}
        title="Add Users & Permissions"
        caption="Please fill below details to add a new User."
        content={
          <AddUser
            onCancel={handleModal}
            handleFetchUsers={(rows, page) => fetchUsers(rows, page)}
          />
        }
      />

      {/* action update icon modal */}

      <GenericModal
        open={updateModalOpen}
        onClose={handleUpdateModal}
        title="Edit Users & Permissions"
        content={
          <AddUser
            type={modalType}
            onCancel={handleUpdateModal}
            data={viewData}
            handleFetchUsers={(rows, page) => fetchUsers(rows, page)}
          />
        }
      />
    </div>
  );
};

export default Users;
