import { wrappedGet } from "../api_setting/urlFuncs";
import API from "../api_setting/apiConstants";

export const deleteService = (serviceId) => {
  return wrappedGet(`${API.GET_ALL_SERVICE}/${serviceId}`, "delete");
};

export const deleteQuestion = (questionId) => {
  return wrappedGet(`${API.GET_QUESTIONS}/${questionId}`, "delete");
};

export const deleteQuestionnaire = (questionnaireId) => {
  return wrappedGet(
    `${API.GET_ALL_QUESTIONNAIRES}/${questionnaireId}`,
    "delete"
  );
};

export const deleteCategory = (categoryId) => {
  return wrappedGet(`${API.GET_CATEGORY}/${categoryId}`, "delete");
};

export const deleteProperty = (propertyId) => {
  return wrappedGet(`${API.GET_PROPERTY}/${propertyId}`, "delete");
};

export const deleteGroup = (marketId, groupId) => {
  return wrappedGet(
    `${API.DELETE_GROUP}/${marketId}/group/${groupId}`,
    "delete"
  );
};

export const deleteMarket = (marketId) => {
  return wrappedGet(`${API.DELETE_MARKET}/${marketId}`, "delete");
};

export const deleteJob = (jobId) => {
  return wrappedGet(`${API.DELETE_JOB}/${jobId}`, "delete");
};

export const deleteStaff = (staffId) => {
  return wrappedGet(`${API.STAFF_LIST}/${staffId}`, "delete");
};
