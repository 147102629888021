import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import ClassIcon from "@mui/icons-material/Class";
import {
  categoryIcons,
  categorySVGs,
} from "../pages/SuperAdmin/CategoryManagement/indes";

// export const getCategoryIcon = (category) => {
//   switch (category) {
//     case "Cleaning":
//       return <CleaningServicesIcon />;

//     case "Plumbing":
//       return <PlumbingIcon />;

//     default:
//       return <ClassIcon />;
//   }
// };

export const getCategoryIcon = (category) => {
  if (!category) return <ClassIcon />;
  const icon = categorySVGs.filter(
    (categoryIcon) => categoryIcon?.name === category
  )[0]?.icon;
  return icon;
};
