import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import classes from "./GroupTags.module.css";
import { getCategoryIcon } from "../../utils/icons";
// import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
// import { Draggable, Droppable } from "react-drag-and-drop";

const GroupTags = ({
  handleModal,
  categoryList,
  setCategoryList,
  handleServices,
  active,
  addCategory = true,
}) => {
  const changeCategoryOrder = (item) => {};

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const [draggedItem, setDraggedItem] = useState(null);
  const [dragTarget, setDragTarget] = useState(null);
  //   const [{ isDragging }, drag] = useDrag((substance) => ({
  //     type: "category",
  //     // item: {id: id},
  //     item: { id: category?._id, index: ind },
  //     collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  //   }));

  const handleDragStart = (e, index) => {
    // e.preventDefault();
    e.stopPropagation();
    dragItem.current = index;
    // setDraggedItem(index);
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    dragOverItem.current = index;
    // setDragTarget(index);
  };

  const handleSort = (e, ind) => {
    let _categories = [...categoryList];

    const draggedItemContent = _categories.splice(dragItem.current, 1)[0];

    _categories.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setCategoryList([..._categories]);
  };

  return (
    <div className="flex-row large-gap">
      {categoryList.map((category, ind) => (
        <div
          draggable="true"
          onDragStart={(e) => handleDragStart(e, ind)}
          onDrop={(e) => handleSort(e, ind)}
          onDragOver={(e) => handleDragEnter(e, ind)}
          key={`group_tag_${ind}`}
          className={`flex-col pointer align-center small-gap bord-rd-8 justify-center dashed-border ${
            classes.service_tag
          } ${active === category.categoryName ? classes.active : ""}`}
          onClick={() => handleServices(category.categoryName)}
          style={{
            height: "90px",
            minWidth: "90px",
            width: "min-content",
            padding: "5px",
          }}
        >
          <div
            className={`bg-base flex-row justify-center align-center tiny-square color-tertiary-grey circular-div ${classes.icons}`}
          >
            <div
              className="flex-row justify-center align-center"
              style={{ width: "22px", height: "22px" }}
            >
              {getCategoryIcon(category.categoryIcon)}
            </div>
          </div>
          <h3 className="subtle-font text-center">{category.categoryName}</h3>
        </div>
      ))}
      {addCategory && (
        <div
          className="flex-col align-center pointer small-gap bord-rd-8 justify-center small-square dashed-border"
          onClick={handleModal}
        >
          <div className="bg-base flex-row justify-center align-center tiny-square color-tertiary-grey circular-div">
            <AddIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupTags;
