import API from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const getUserDetails = () => {
  return wrappedGet(API.PROFILE_DETAIL, "get");
};

export const editUserProfile = (data) => {
  return wrappedFetch(API.EDIT_PROFILE, "post", data);
};

export const getPropertyManagerDetails = () => {
  return wrappedGet(API.PROPERTY_MANAGER_DETAIL, "get");
};

export const editPropertyManagerDetails = (data) => {
  return wrappedFetch(API.EDIT_PROPERTY_MANAGER_DETAILS, "post", data);
};

export const sendNotification = (data) => {
  return wrappedFetch(API.SEND_NOTIFICATION, "post", data);
}


