import React, { useState, useEffect } from "react";
import classes from "./ResetPassword.module.css";
import LoginRightScreen from "../LoginRightScreen/LoginRightScreen";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Input from "../commonComponents/Input/Input";
import Button from "../commonComponents/Button/Button";
import { useSearchParams } from "react-router-dom";
import Error from "../commonComponents/Error/Error";
import { resetPassword } from "../../../api/api_calls/auth";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import createPasswordValidation from "../../../validations/createPasswordValidation";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const { id, value } = useParams();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const state = { token: token };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createdPass, setCreatedPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errors, setErrors] = useState("");
  const [passSame, setPassSame] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setCreatedPass(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPass(e.target.value);
  };
  const passwordNew = () => {
    setShowNewPassword(!showNewPassword);
  };
  const passwordCnfrm = () => {
    setShowCnfrmPassword(!showCnfrmPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPassSame(false);

    const validDate = { createdPass, confirmPass };
    const validatePassword = createPasswordValidation.validate(validDate);
    if (validatePassword?.error?.message) {
      setErrors(validatePassword?.error?.message);
      return;
    } else {
      setErrors(false);
      if (createdPass === confirmPass) {
        const formData = {
          id,
          otp: state?.token,
          isPropertyManager: value === "1" ? true : false,
          newPassword: createdPass,
          confirmPassword: confirmPass,
        };
        dispatch(loaderActions.toggleLoading(true));

        resetPassword(formData)
          .then((res) => {
            if (res.status === 200) {
              navigate("/login");
            }
          })
          .catch((error) => {
            toast.error(`${error}`, {
              toastId: "custd",
            });
          })
          .finally((res) => {
            dispatch(loaderActions.toggleLoading(false));
          });
      } else {
        setPassSame(true);
        return;
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <Grid container xs={12} className={classes.root}>
      <Grid xs={12} md={6}>
        <div className={`${classes.login_content} flex-col`}>
          <h3 className="font-24">Reset password</h3>
          <p className="font-16">Enter below details to reset your password</p>

          <form className="flex-col" onSubmit={handleSubmit}>
            <div className={`${classes.login_inputs} flex-col`}>
              <div className={`${classes.login_password} flex-col`}>
                <Input
                  name="newPassword"
                  placeholder="New Password"
                  value={createdPass}
                  type={showNewPassword ? "text" : "password"}
                  padding="10px"
                  onChange={handlePasswordChange}
                />
                <div className={classes.password} onClick={passwordNew}>
                  {showNewPassword ? (
                    <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                  )}
                </div>
              </div>
              <div className={`${classes.login_password} flex-col`}>
                <Input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPass}
                  type={showCnfrmPassword ? "text" : "password"}
                  padding="10px"
                  onChange={handleConfirmPasswordChange}
                />
                <div className={classes.password} onClick={passwordCnfrm}>
                  {showCnfrmPassword ? (
                    <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                  )}
                </div>
              </div>

              {errors && (
                <Error title="Password must contain atleast one lowercase letter, one uppercase letter, one number, one special character and must be 8 digits long." />
              )}
              {passSame && (
                <Error
                  title={"New password and Confirm password must be same"}
                />
              )}
            </div>
            <Button title="Submit" />
          </form>
        </div>
      </Grid>

      <LoginRightScreen />
    </Grid>
  );
};

export default ResetPassword;
