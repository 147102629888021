import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

const CustomSeparator = ({ items }) => {
  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="-" aria-label="breadcrumb">
        {items.map((item, index) => {
          const { label, url, data } = item;
          if (url) {
            return (
              <Link key={index} to={url} state={data} style={{ color: "gray" }}>
                {label}
              </Link>
            );
          } else {
            return (
              <Typography key={index} color="text.primary">
                {label}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator;
