import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Switch, FormGroup, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormField from "../../../../components/common/commonComponents/FormField/FormField";
import Button from "../../../../components/common/commonComponents/Button/Button";
import {
  addPropertyManager,
  editPropertyManager,
} from "../../../../api/api_calls/addData";
import { PMformValidationSchema } from "../../../../validations/PMformValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { toast } from "react-toastify";
import PhoneNumberInput from "../../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
import {
  capitalize,
  customStylesReactSelect,
} from "../../../../utils/HelperFuncitons";
import classes from "../../../../components/common/commonComponents/FormField/FormField.module.css";
import zipcode from "zipcodes";
import Select from "react-select";
import Input from "../../../../components/common/commonComponents/Input/Input";
import { Add, Remove } from "@mui/icons-material";
import { getAllServices } from "../../../../api/api_calls/data";
// const zips = require("zips");

const AddPropertyManager = ({
  title,
  caption,
  onCancel,
  data,
  type,
  handleModalType,
}) => {
  const [formData, setFormData] = useState({
    fullName: data?.fullName ? data.fullName : "",
    email: data?.email ? data.email : "",
    countryCode: data?.countryCode ? data.countryCode : "",
    mobileNumber: data?.mobileNumber ? data.mobileNumber : "",
    address: data?.address ? data.address : "",
    city: data?.city ? data.city : "",
    state: data?.state ? data.state : "",
    postalCode: data?.postalCode ? data.postalCode : "",
    country: data?.country ? data.country : "",
    description: data?.description ? data.description : "",
  });
  const [editable, setEditable] = useState(
    data?.isEditable === false ? data?.isEditable : true
  );
  const [errors, setErrors] = useState("");
  const [pincodeData, setPincodeData] = useState("");
  const [services, setServices] = useState([]);
  const [additionalDiscount, setAdditionalDiscount] = useState(data?.discounts || []);

  const formFields = [
    {
      label: "Property Manager Name",
      required: true,
      name: "fullName",
      editable: type === "view" ? false : true,
      value: formData.fullName,
      placeholder: "Enter your name",
    },
    {
      label: "Email Address",
      required: true,
      name: "email",
      editable: type === "view"  ? false : true,
      value: formData.email,
      placeholder: "Enter your Email Address",
    },
    // {
    //   label: "Country Code",
    //   required: false,
    //   name: "countryCode",
    //   editable: type === "view" ? false : true,
    //   value: formData.countryCode,
    //   placeholder: "Enter country Code",
    // },
    // {
    //   label: "Mobile Number",
    //   required: false,
    //   name: "mobileNumber",
    //   editable: type === "view" ? false : true,
    //   value: formData.mobileNumber,
    //   placeholder: "Enter mobile number",
    // },
    {
      label: "Address",
      required: false,
      name: "address",
      editable: type === "view" ? false : true,
      value: formData.address,
      placeholder: "Enter Address",
    },
    {
      label: "Postal Code",
      required: false,
      name: "postalCode",
      editable: type === "view" ? false : true,
      value: formData.postalCode,
      placeholder: "Enter Zip Code",
    },
    {
      label: "City",
      required: false,
      name: "city",
      editable: type === "view" ? false : true,
      value: formData.city,
      placeholder: "Enter City",
    },
    {
      label: "State",
      required: false,
      name: "state",
      editable: type === "view" ? false : true,
      value: formData.state,
      placeholder: "Enter State",
    },
    {
      label: "Country",
      required: false,
      name: "country",
      editable: type === "view" ? false : true,
      value: formData.country,
      placeholder: "Enter Country",
    },
    {
      label: "Description",
      required: false,
      name: "description",
      editable: type === "view" ? false : true,
      value: formData.description,
      placeholder: "Enter Description",
    },
  ];

  useEffect(() => {
    getAllServices()
      .then((res) => {
        const _services = [];
        res.data.data.services.forEach((service) => {
          _services.push({ label: service?.name, value: service?._id });
        });

        setServices(_services);
      })
      .catch((error) =>
        toast.error(error?.message, {
          toastId: "error in getting all services",
        })
      );
  }, []);

  const autoFill = (val) => {
    if (val.target.value !== "") {
      const z = zipcode.lookup(val.target.value);

      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: z?.city || "",
        state: z?.state || "",
        country: z?.country || "",
      }));
    } else {
      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: "",
        state: "",
        country: "",
      }));
    }
  };

  const handleFormChange = (e) => {
    if (e.target.name === "postalCode") {
      return autoFill(e);
    } else
      setFormData((currentState) => {
        return { ...currentState, [e.target.name]: e.target.value };
      });
  };

  const getButtonTitle = (type) => {
    switch (type) {
      case "view":
        return "Edit Details";
      case "update":
        return;
      default:
        return "Send Invite";
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#40a59e",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleServiceChange = (selectedOption, ind) => {
    const correctArray = additionalDiscount.slice();
    const tempCustomDiscount = correctArray[ind];

    tempCustomDiscount.serviceId = selectedOption?.value;

    correctArray.splice(ind, 1, tempCustomDiscount);

    setAdditionalDiscount(correctArray);
  };

  const handleDiscountChange = (e, ind) => {
    const correctArray = additionalDiscount.slice();
    const tempCustomDiscount = correctArray[ind];

    tempCustomDiscount.discount = e.target.value;

    correctArray.splice(ind, 1, tempCustomDiscount);

    setAdditionalDiscount(correctArray);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postForm = {
      ...formData,
      isEditable: editable,
      fullName: capitalize(formData?.fullName),
      isDiscountGiven: additionalDiscount.length !== 0,
      discounts: additionalDiscount,
    };

    const formValidation = PMformValidationSchema.validate(postForm);

    if (formValidation?.error?.message) {
      setErrors(formValidation.error.message);
      return;
    }

    if (type === "view") {
      handleModalType("update");
      return;
    } else if (type === "update") {
      postForm.pmId = data._id;
      editPropertyManager(postForm)
        .then((res) => window.location.reload())
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "6",
          });
        });
    } else {
      addPropertyManager(postForm)
        .then((res) => window.location.reload())
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "8",
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);

  return (
    <form
      onSubmit={handleSubmit}
      className="gap-40 flex-col"
      style={{ width: "94%" }}
    >
      <Grid container rowSpacing={5} columnSpacing={3}>
        {formFields.map((field) => (
          <Grid item xs={6} key={field.label}>
            <FormField
              fieldLabel={field.label}
              name={field.name}
              placeholder={field.placeholder}
              value={type === "view" ? data[field.name] : field.value}
              handleInputChange={handleFormChange}
              readOnly={!field?.editable}
            />
          </Grid>
        ))}
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Mobile Number</label>
            <PhoneNumberInput
              disabled={type === "view" ? true : false}
              props={{ setFormData }}
              value={formData?.countryCode + formData?.mobileNumber}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <div className="flex-row just-sp-bwn align-center">
              <label>Additional Discount</label>
              {type !== "view" && <div className="flex-row small-gap">
                <Add
                  className="pointer"
                  sx={{ color: "#40a59e" }}
                  onClick={() => {
                    setAdditionalDiscount((curr) => {
                      return [...curr, { serviceId: "", discount: "" }];
                    });
                  }}
                />
                <Remove
                  className="pointer"
                  sx={{ color: "#40a59e" }}
                  onClick={() => {
                    const _additionalDiscount = additionalDiscount.slice();
                    _additionalDiscount.pop();
                    setAdditionalDiscount(_additionalDiscount);
                  }}
                />
              </div>}
            </div>
            <div className="flex-col gap-24">
              {additionalDiscount.map((discount, ind) => (
                <div className="flex-row gap-24 align-center" key={ind}>
                  <Grid item xs={6}>
                    <Select
                      name="serviceName"
                      placeholder="Select Service"
                      //  className="basic-single"
                      isDisabled={type === "view"}
                      styles={customStylesReactSelect}
                      options={services}
                      value={services.filter((service) => service?.value === discount?.serviceId)[0]}
                      // onChange={(selectedOption) => {
                      //   handleCategorySelect(selectedOption);
                      //   handleFormChange("categoryName", selectedOption);
                      //   setAddedStaff([]);
                      //   setStaffMembers([]);
                      // }}
                      onChange={(selectedOption) =>
                        handleServiceChange(selectedOption, ind)
                      }
                    />
                  </Grid>

                  <Input
                    type="number"
                    min="1"
                    placeholder="Enter discount amount"
                    readOnly={type==="view"}
                    style={{ width: "50%" }}
                    value={discount?.discount}
                    onChange={(e) => handleDiscountChange(e, ind)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Grid>
        {/* --------------------------------: For demo :--------------------------------------
        <Grid item xs={6}>
          <FormField
            fieldLabel='zipcode'
            name='zipcode'
            placeholder='zipcode'
            // value={objZip}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <PinCode invalidError='Please check pincode' lengthError='check length' getData={(data) => setPincodeData(data)} />
        </Grid> */}
      </Grid>

      <FormGroup>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "631px",
            }}
          >
            <Typography variant="body1" fontWeight="bold" fontFamily="Inter">
              {title}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              fontFamily="Inter"
              sx={{ color: "grey", margin: "0" }}
            >
              {caption}
            </Typography>
          </Box>
          {type === "view" ? (
            <IOSSwitch checked={editable} disabled sx={{ m: 1 }} />
          ) : type === "update" ? (
            <IOSSwitch
              sx={{ m: 1 }}
              checked={editable}
              onChange={(e) => {
                {
                  setEditable(e.target.checked);
                }
              }}
            />
          ) : (
            <IOSSwitch
              sx={{ m: 1 }}
              checked={editable}
              onChange={(e) => {
                {
                  setEditable(e.target.checked);
                }
              }}
            />
          )}
        </Stack>
      </FormGroup>
      {errors && <Error title={errors} />}

      <Box
        className="flex-row justify-center medium-gap"
        sx={{ textAlign: "center" }}
      >
        <Button
          title={
            type === "view"
              ? "Edit Details"
              : type === "update"
              ? "Update"
              : "Send Invite"
          }
          type={type === "" || type === "update" ? "submit" : ""}
        />
      </Box>
    </form>
  );
};

export default AddPropertyManager;
