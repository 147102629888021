import React, { useState } from "react";
import classes from "./Card.module.css";
import { Typography, Menu, MenuItem, Avatar, AvatarGroup } from "@mui/material";
import CustomTag from "../common/commonComponents/CustomTag/CustomTag";
import { GenericModal } from "../GenericModal";
import AddGroup from "../../pages/SuperAdmin/Scheduler/Groups/AddGroup/AddGroup";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteGroup from "../../pages/SuperAdmin/Scheduler/Groups/DeleteGroup/DeleteGroup";

const CustomGroupCard = ({ groupNo, groupDetails, groupAvatar, marketId }) => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const handleModal = () => {
    setMenuAnchorEl(null);
    setOpen(!open);
    setModalType("update");
  };
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleDeleteModal = () => {
    setMenuAnchorEl(null);
    setDeleteGroupModal(!deleteGroupModal);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      <div className={`flex-row ${classes.cardDiv}`}>
        <div className={`flex-row gap-32 pd-38 bg-white ${classes.styleddiv}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <h2 className="secondary-heading">{groupDetails?.groupName}</h2>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: "grey" }}
              >
                Created on {moment(groupDetails?.createdAt).format("ll")}
              </Typography>
            </div>
            <div>
              <CustomTag
                title={groupDetails?.groupTagName}
                background={"#FCF1F7"}
                color={"#E64593"}
              />
            </div>
          </div>
          <div className="flex-row align-center just-sp-bwn wd-100">
            <AvatarGroup>
              {groupAvatar &&
                groupAvatar.map((item) => (
                  <Avatar key={item._id} src={item.profilePicture} />
                ))}
            </AvatarGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MoreVertIcon className="pointer" onClick={handleMenuOpen} />
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleModal}>Edit Group</MenuItem>
                <MenuItem
                  onClick={handleDeleteModal}
                  disabled={groupNo == 1 ? true : false}
                >
                  Delete Group
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <GenericModal
          open={open}
          onClose={handleModal}
          title="Edit Group"
          content={
            <AddGroup
              type={modalType}
              onCancel={handleModal}
              data={groupDetails}
            />
          }
        />
        <GenericModal
          open={deleteGroupModal}
          onClose={handleDeleteModal}
          title="Delete Group"
          content={
            <DeleteGroup
              groupId={groupDetails?._id}
              marketId={marketId}
              handleCancelFunction={handleDeleteModal}
            />
          }
        />
      </div>
    </>
  );
};

export default CustomGroupCard;
