import { Avatar } from "@mui/material";
import React from "react";
import classes from "./ChatBox.module.css";
import Button from "../../../../components/common/commonComponents/Button/Button";
import ForumIcon from "@mui/icons-material/Forum";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { useEffect } from "react";
import { queryMessages } from "./firebaseQueries";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useState } from "react";
import moment from "moment";
import { db } from "../firebase";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sendNotification } from "../../../../api/api_calls/user";
import { capitalize } from "../../../../utils/HelperFuncitons";
import { loaderActions } from "../../../../redux/reducers/loader";
import { uploadMedia } from "../../../../api/api_calls/addData";

const ChatBox = ({ room }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [otherUser, setOtherUser] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [chatMedia, setChatMedia] = useState("");
  const [mediaPreview, setMediaPreview] = useState("");
  const [mediaType, setMediaType] = useState("");

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  let currentUser = user?.role === 4 ? "support" : user?.id;

  const messagesEndRef = useRef(null);

  const messagesReference = collection(db, `rooms/${room?.uniqueId}/messages`);

  useEffect(() => {
    setMediaPreview("");
    setMediaType("");
    setChatMedia("");
    setInputMessage("");
    // for finding the other person in direct chat
    if (room?.type === "direct" || room?.type === "direct-client") {
      const usersId = Object.keys(room?.userDetails);

      const userId = usersId.filter((id) => id !== "support")[0];

      setOtherUser(room?.userDetails?.[userId]);
    }

    let messagesRef = queryMessages(room?.uniqueId);

    const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
      let messages = [];

      snapshot.forEach((doc) => {
        messages.push({ ...doc.data() });
      });

      setChatMessages(messages);
    });

    return () => unsubscribe();
  }, [room]);

  const markMessageRead = async () => {
    if (
      room &&
      !room?.lastMessage?.read &&
      room?.lastMessage?.sentBy !== currentUser
    ) {
      try {
        let chatRoomRef = doc(db, "rooms", room?.uniqueId);

        await updateDoc(chatRoomRef, {
          lastMessage: {
            sentBy: room?.lastMessage?.sentBy,
            message: room?.lastMessage?.message,
            createdAt: room?.lastMessage?.createdAt,
            read: true,
          },
        });
      } catch (error) {
        toast.error("Could not mark the last message as read.");
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
    markMessageRead();
  }, [chatMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getUserDetails = (userId) => {
    return room?.userDetails?.[userId];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const _inputMessage = inputMessage;
    setInputMessage("");
    const _mediaPreview = mediaPreview;
    setMediaPreview("");

    let chatRoomRef = doc(db, "rooms", room?.uniqueId);

    if (!inputMessage.trim() && !chatMedia) return;

    try {
      await addDoc(messagesReference, {
        message: _inputMessage,
        media: {
          name: _mediaPreview,
          type: mediaType.includes("image")
            ? "image"
            : mediaType.includes("video")
            ? "video"
            : "",
          url: chatMedia?.url || "",
          thumbnail: chatMedia?.thumbnail || "",
        },
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
          ? "video"
          : "text",
        sentBy: user?.role === 4 ? "support" : user?.id,
        sentTo: otherUser?.id,
        createdAt: serverTimestamp(),
      });

      await updateDoc(chatRoomRef, {
        lastMessage: {
          sentBy: currentUser,
          createdAt: serverTimestamp(),
          message: _inputMessage
            ? _inputMessage
            : `${
                currentUser === "support" ? "Support Center" : user?.name
              } uploaded a new ${mediaType}`,
          read: false,
        },
      });

      const notificationData = {
        title: capitalize(currentUser),
        body: `${_inputMessage}`,
        _id: [otherUser?.id],
        roomId: room?.uniqueId,
      };

      sendNotification(notificationData)
        .then((res) => {
          return;
        })
        .catch((error) =>
          toast.error(error?.message, {
            toastId: "error in sending notification",
          })
        );

      setMediaType("");
      setChatMedia("");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div className={`flex-col ht-100 just-sp-bwn ${classes.right_container}`}>
      <div className={classes.top_half}>
        {room && (
          <div
            className="flex-row align-center wd-100 just-sp-bwn"
            style={{ paddingBottom: "10px", borderBottom: "1px solid #EFEFEF" }}
          >
            <div
              className={`flex-row normal-gap align-center ${classes.chat_heading}`}
            >
              <Avatar src={otherUser?.image} alt={otherUser?.fullName} />
              <div className="flex-col">
                <h3>{otherUser?.fullName}</h3>
                <p>
                  Booking Id {room?.uniqueId.toUpperCase()} | {room?.roomName}
                </p>
              </div>
            </div>
            <div className={classes.job_due_date}>
              {moment.unix(room?.createdAt?.seconds).format("ll")}
            </div>
          </div>
        )}
        {chatMessages?.length !== 0 ? (
          <div
            className={`${classes.chat_component} overflow-auto hidden-scroll`}
          >
            {chatMessages.map((message) => {
              const userInfo = getUserDetails(message?.sentBy);

              if (message?.media?.url) {
                if (message?.media?.type === "image") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`flex-col normal-gap ${classes.chat_message} ${
                        message?.sentBy === currentUser ? "align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "flex-reverse-row"
                            : "flex-row"
                        } normal-gap align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`medium-gap flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                      >
                        <div
                          className={`flex-col medium-gap ${
                            message?.sentBy === currentUser
                              ? "flex-reverse-row"
                              : "flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <img src={message?.media?.url} width={200} />
                          {message?.message}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {moment
                            .unix(message?.createdAt?.seconds)
                            .format("lll")}
                        </p>
                      </div>
                    </div>
                  );
                } else if (message?.media?.type === "video") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`flex-col normal-gap ${classes.chat_message} ${
                        message?.sentBy === currentUser ? "align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "flex-reverse-row"
                            : "flex-row"
                        } normal-gap align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`medium-gap flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                      >
                        <div
                          className={`flex-col medium-gap ${
                            message?.sentBy === currentUser
                              ? "flex-reverse-row"
                              : "flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <video width="320" height="240" controls>
                            <source
                              src={message?.media?.url}
                              type="video/mp4"
                            />
                          </video>
                          {message?.message}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {moment
                            .unix(message?.createdAt?.seconds)
                            .format("lll")}
                        </p>
                      </div>
                    </div>
                  );
                }
              }

              return (
                <div
                  key={message?.createdAt + message?.sentBy}
                  ref={messagesEndRef}
                  className={`flex-col normal-gap ${classes.chat_message} ${
                    message?.sentBy === "support" ? "align-right" : ""
                  }`}
                >
                  <div
                    className={`${
                      message?.sentBy === currentUser
                        ? "flex-reverse-row"
                        : "flex-row"
                    } normal-gap align-center`}
                  >
                    <Avatar
                      src={userInfo?.image}
                      alt={userInfo?.fullName}
                      sx={{ height: "30px", width: "30px" }}
                    />
                    <h3>{userInfo?.fullName}</h3>
                    {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                  </div>
                  <div
                    className={`${
                      message?.sentBy === currentUser
                        ? classes.sending_message
                        : classes.incoming_message
                    }`}
                  >
                    <div
                      className={`${
                        message?.sentBy === currentUser
                          ? "flex-reverse-row"
                          : "flex-row"
                      }`}
                      style={{
                        textAlign:
                          message?.sentBy === currentUser ? "right" : "left",
                      }}
                    >
                      {message?.message}
                    </div>
                    <p
                      style={{
                        textAlign:
                          message?.sentBy === currentUser ? "right" : "left",
                      }}
                    >
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="flex-row align-center justify-center ht-100"
            style={{ fontSize: "150px", color: "#d0f2f0" }}
          >
            <ForumIcon fontSize="inherit" />
          </div>
        )}
      </div>
      {room && (
        <>
          {mediaPreview && (
            <div
              style={{
                position: "relative",
                width: "min-content",
              }}
            >
              <p className={`subtle-label ${classes.mediaPreview}`}>
                {mediaPreview}
              </p>
              <div
                className={classes.media_cross}
                onClick={() => {
                  setMediaPreview("");
                  setChatMedia("");
                  setMediaType("");
                }}
              >
                X
              </div>
            </div>
          )}
          <form
            className={`wd-100 flex-row align-center ${classes.input_form}`}
            onSubmit={handleSubmit}
          >
            <div className="flex-row align-center wd-100 normal-gap">
              <div
                style={{ color: "#a1a0a3" }}
                className="pointer"
                onClick={() => {
                  document.getElementById("chat_media").click();
                }}
              >
                <PermMediaIcon />
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                id="chat_media"
                onChange={(e) => {
                  setMediaPreview(e.target.files["0"]?.name);
                  setMediaType(e.target.files["0"].type);
                  dispatch(loaderActions.toggleLoading(true));
                  const formData = new FormData();
                  formData.append("media", e.target.files["0"]);
                  uploadMedia(formData)
                    .then((res) => setChatMedia(res?.data?.data))
                    .catch((error) => {
                      toast.error(error?.message, {
                        toastId: "chat message media upload",
                      });
                      setChatMedia("");
                      setMediaPreview("");
                      setMediaType("");
                    })
                    .finally(() =>
                      dispatch(loaderActions.toggleLoading(false))
                    );
                  e.target.value = "";
                }}
              />
              <input
                placeholder="Type something..."
                className={classes.chat_input}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              <Button title="Send" type="submit" />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ChatBox;
