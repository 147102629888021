import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import loaderReducer from "./reducers/loader";
import formDataReducer from "./reducers/formData";
import chatReducer from "./reducers/chat"

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    loader: loaderReducer,
    bookingData: formDataReducer,
    chat: chatReducer
  },
});

export default store;
