import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import FileSaver from "file-saver";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  maxHeight: "80vh",
  overflow: "auto",
  scrollbarWidth: "none", // for firefox
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  "&-ms-overflow-style:": {
    display: "none", // Hide the scrollbar for IE
  },
  bgcolor: "#424242",
  p: 4,
};

const downloadStyle = {
  position: "absolute",
  zIndex: "3",
  color: "white",
  left: "93.5%",
  top: "2%",
};

export default function QuestionImage({ image, open, onClose }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const downloadImage = async () => {
    let blob = await fetch(image).then((r) => r.blob());
    FileSaver.saveAs(blob, "questionImage.jpg");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box sx={style}>
        <div className="pointer" style={downloadStyle} onClick={downloadImage}>
          <DownloadIcon />
        </div>
        <img src={image} style={{ objectFit: "contain", width: "400px" }} />
      </Box>
    </Modal>
  );
}
