import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  getAllGroupsMarket,
  getAllMarkets,
} from "../../../../api/api_calls/data";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";
import { toast } from "react-toastify";
import Button from "../../../../components/common/commonComponents/Button/Button";

const SendUpdate = ({ onCancel, handleSelectedGroups }) => {
  const [markets, setMarkets] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const fetchMarkets = async () => {
    getAllMarkets()
      .then((res) => setMarkets(res?.data?.data?.market))
      .catch((error) =>
        toast.error(error?.message, { toastId: "fetching market error" })
      );
  };

  const fetchMarketGroups = (marketId) => {
    getAllGroupsMarket(marketId)
      .then((res) => setGroups(res?.data?.data?.groupsData))
      .catch((error) =>
        toast.error(error?.message, { toastId: "fetching group error" })
      );
  };

  const handleMarketChange = (market) => {
    fetchMarketGroups(market?._id);
  };

  const handleGroupSelection = (groupArray) => {
    const tempGroups = [];
    groupArray.forEach((group) => {
      tempGroups.push(group?._id);
    });
    setSelectedGroups(tempGroups);
  };

  useEffect(() => {
    fetchMarkets();
  }, []);

  return (
    <div className="flex-col wd-100 gap-32">
      <div className="flex-row medium-gap wd-100">
        <div className="flex-col wd-50 gap-6">
          <label>Select Market</label>
          <Select
            name="marketId"
            placeholder="Select Market"
            styles={customStylesReactSelect}
            options={markets}
            sx={{
              height: "38px",
              background: "#F8F8F8",
              fontFamily: "Roboto Slab",
              fontSize: "14px",
            }}
            getOptionLabel={(option) => option.marketName}
            getOptionValue={(option) => option._id}
            onChange={handleMarketChange}
          />
        </div>
        <div className="flex-col wd-50 gap-6">
          <label>Select Groups</label>
          <Select
            styles={customStylesReactSelect}
            options={groups}
            getOptionLabel={(option) => option?.groupName}
            getOptionValue={(option) => option?._id}
            isSearchable
            isMulti
            name="groups"
            sx={{
              height: "38px",
              background: "#F8F8F8",
              fontFamily: "Roboto Slab",
              fontSize: "14px",
            }}
            onChange={handleGroupSelection}
          />
        </div>
      </div>
      <div className="flex-row wd-100 justify-center medium-gap">
        <Button
          type="button"
          title="Cancel"
          theme="gray"
          handleFunction={onCancel}
        />
        <Button
          title="Save"
          type="button"
          handleFunction={() => {
            handleSelectedGroups(selectedGroups);
            onCancel();
          }}
        />
      </div>
    </div>
  );
};

export default SendUpdate;
