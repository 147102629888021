import { createSlice } from "@reduxjs/toolkit";
import { isAuth } from "../../utils/HelperFunctions";

const initialState = {
  name: "",
  email: "",
  role: "",
  profileImg: "",
  id: "",
  permissions: isAuth() ? ["All"] : "",
  isEditable: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeUser(state, action) {
      state.name = action.payload?.fullName;
      state.email = action.payload?.email;
      state.role = action.payload?.role;
      state.profileImg =
        action.payload?.profileImage || action.payload?.profilePicture;
      state.id = action.payload?._id || action.payload?.id;
      state.permissions = action.payload?.permissions;
      state.isEditable = action.payload?.isEditable;
      state.discounts = action.payload?.discounts;
      return state;
    },

    removeUser(state, action) {
      state = initialState;
      return state;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
