import React, { useEffect, useState } from "react";
import classes from "./AssignStaff.module.css";
import StaffAvatar from "../../../../components/common/StaffAvatar/StaffAvatar";
import Button from "../../../../components/common/commonComponents/Button/Button";
import {
  getAllGroupsMarket,
  getAllMarkets,
} from "../../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { acceptJob } from "../../../../api/api_calls/schedule";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";
import Select from "react-select";
import { toast } from "react-toastify";
import { assignStaffValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";

export const AssignStaff = ({
  serviceData,
  jobData,
  onCancel,
  handleFetchServiceJobRequests,
}) => {
  const dispatch = useDispatch();

  const [assignedStaff, setAssignedStaff] = useState(
    jobData?.staffData?._id || ""
  );
  const [markets, setMarkets] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupStaffs, setGroupStaffs] = useState([]);
  const [formData, setFormData] = useState({
    marketId: "",
    groupId: "",
    task: "",
  });
  const [error, setError] = useState("")

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getAllMarkets()
      .then((res) => setMarkets(res?.data?.data?.market))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  const fetchGroups = (marketId) => {
    setFormData((currState) => {
      return { ...currState, marketId, groupId: "" };
    });
    setAssignedStaff("");
    dispatch(loaderActions.toggleLoading(true));
    getAllGroupsMarket(marketId)
      .then((res) => setGroups(res?.data?.data?.groupsData))
      .catch((error) => toast?.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleAssignStaff = (e) => {
    const postData = {
      marketId: formData?.marketId,
      groupId: formData?.groupId,
      staffId: assignedStaff,
      jobId: jobData?._id,
      task: formData?.task,
    };

    const validateData = assignStaffValidation.validate(postData);

    if (validateData?.error?.message) {
      setError(validateData.error.message);
      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    acceptJob(postData)
      .then((res) => {
        onCancel();
        handleFetchServiceJobRequests(serviceData?._id);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  return (
    <div className={`flex-col gap-40 `}>
      <div className="flex-col align-center small-gap">
        <h2>Assign Staff</h2>
        <p className={`color-tertiary-grey`}>
          Please choose and designate a staff member to this particular Job
          request.
        </p>
      </div>
      <div className="flex-col gap-32">
        <div className={`flex-col wd-100 medium-gap ${classes.formField}`}>
          <p style={{ fontSize: "16px", fontWeight: "400", color: "#5a5d60" }}>
            Add Task
          </p>
          <textarea
            className={classes.formFieldTwo}
            placeholder="Add Task"
            // value={formData?.task}
            maxLength={300}
            onChange={(e) => {
              setFormData((currState) => {
                return { ...currState, task: e?.target?.value };
              });
            }}
          />
        </div>
        <div className="flex-row wd-100 align-center large-gap">
          <div className={`flex-col wd-50 medium-gap`}>
            <p
              style={{ fontSize: "16px", fontWeight: "400", color: "#5a5d60" }}>
              Select Market
            </p>
            <Select
              name="market"
              placeholder="Select Market"
              options={markets}
              getOptionLabel={(item) => item?.marketName}
              getOptionValue={(item) => item?._id}
              onChange={(selectedOption) => {
                setGroups([]);
                setGroupStaffs([]);
                fetchGroups(selectedOption?._id);
              }}
              styles={customStylesReactSelect}
            />
          </div>
          <div className={`flex-col wd-50 medium-gap`}>
            <p
              style={{ fontSize: "16px", fontWeight: "400", color: "#5a5d60" }}>
              Select Group
            </p>
            <Select
              name="group"
              key={groups}
              placeholder="Select Group"
              options={groups}
              getOptionLabel={(item) => item?.groupName}
              getOptionValue={(item) => item?._id}
              onChange={(selectedOption) => {
                setAssignedStaff("");
                setGroupStaffs(selectedOption?.groupMemberData);
                setFormData((currState) => {
                  return { ...currState, groupId: selectedOption?._id };
                });
              }}
              styles={customStylesReactSelect}
            />
          </div>
        </div>
        {/* <SearchInput placeholder="Search Staff" /> */}
        {groupStaffs?.length !== 0 && (
          <div>
            <p className="subtle-label">Select Staff</p>
            <div className={`flex-col mt-20 large-gap ${classes.staff_list}`}>
              {groupStaffs.map((staff) => (
                <div className="flex-row just-sp-bwn align-center">
                  <StaffAvatar
                    img={staff.profilePicture}
                    name={staff.fullName}
                    email={staff.email}
                  />
                  <Button
                    title={assignedStaff === staff._id ? "Unassign" : "Assign"}
                    background="white"
                    color={assignedStaff === staff._id ? "#CBCBCB" : "#1da94a"}
                    border={assignedStaff === staff._id ? "#CBCBCB" : "#1da94a"}
                    value={staff._id}
                    handleFunction={(e) => {
                      setAssignedStaff(e.target.value);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {error && <Error title={error} />}
        <div className="flex-row wd-100 mt-40 medium-gap justify-center align-center">
          <Button
            type="button"
            theme="grey"
            title="Cancel"
            handleFunction={onCancel}
          />
          <Button title="Done" handleFunction={handleAssignStaff} />
        </div>
      </div>
    </div>
  );
};
