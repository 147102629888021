import { Avatar } from "@mui/material";
import React from "react";

const StaffAvatar = ({ img, name, email }) => {
  return (
    <div className="flex-row align-center gap-6">
      <Avatar src={img} />
      <div className="flex-col small-gap">
        <h3>{name}</h3>
        <p>{email}</p>
      </div>
    </div>
  );
};

export default StaffAvatar;
