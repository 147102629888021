import React, { useEffect, useState } from "react";
import classes from "./CategoryManagement.module.css";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import GroupTags from "../../../components/GroupTags/GroupTags";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { GenericModal } from "../../../components";
import AddService from "./AddService/AddService";
import AddCategory from "./AddCategory/AddCategory";
import { useNavigate } from "react-router-dom";
import { getCategory, getService } from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { deleteService } from "../../../api/api_calls/deleteData";
import EditCategory from "./EditCategory/EditCategory";
import { toast } from "react-toastify";
import { reorderCategories } from "../../../api/api_calls/addData";
const headings = [
  { heading: "Image", key: "image" },
  { heading: "Name", key: "name" },
  { heading: "Description", key: "description" },
  { heading: "Default Price", key: "price", type: "price" },
  { heading: "Requested Service", key: "serviceRequested" },
  { heading: "Action", key: "icon" },
];

const CategoryManagement = () => {
  const navigate = useNavigate();
  const [showAddService, setShowAddService] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [addCategoryModalType, setAddCategoryModalType] = useState("");
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [active, setActive] = useState("");
  const [categories, setCategories] = useState([]);
  const [serviceModalType, setServiceModalType] = useState("");
  const [viewServiceData, setViewServiceData] = useState(null);
  const [categoryModalData, setCategoryModalData] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const dispatch = useDispatch();

  const fetchServices = (categoryId) => {
    dispatch(loaderActions.toggleLoading(true));

    getService(categoryId)
      .then((res) => {
        setServiceData(res?.data?.data?.services);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getCategory()
      .then((res) => {
        setCategories(res?.data?.data?.categories);
        setActive(res?.data?.data?.categories[0].categoryName);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  useEffect(() => {
    // to reorder category list
    const _categories = [];
    categories.forEach((category, ind) => {
      _categories.push({ _id: category?._id, order: ind });
    });

    if (_categories.length !== 0)
      reorderCategories(_categories)
        .then((res) => {
          return;
        })
        .catch((error) =>
          toast.error(error?.message, {
            toastId: "error in ordering categories",
          })
        );
  }, [categories]);

  // To show the first service data
  useEffect(() => {
    if (categories.length !== 0) {
      if (!active) {
        setActive(categories[0].categoryName);
        setSelectedCategoryId(categories[0]._id);
      } else {
        const categoryId = categories.filter(
          (category) => category.categoryName === active
        )[0]._id;

        setActive(active);
        setSelectedCategoryId(categoryId);

        if (categoryId) {
          fetchServices(categoryId);
        }
      }
    }
  }, [active]);

  const handleAddServiceModal = (data, type = "") => {
    setShowAddService(!showAddService);
    setViewServiceData(data);
    setServiceModalType(type);
  };

  const handleDeleteService = (id) => {
    dispatch(loaderActions.toggleLoading(true));
    deleteService(id)
      .then((res) => {
        const categoryId = categories.filter(
          (category) => category.categoryName === active
        )[0]._id;

        fetchServices(categoryId);
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "  13",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleAddCategoryModal = () => {
    setShowAddCategory(!showAddCategory);
    if (categoryModalData) {
      setAddCategoryModalType("");
      setCategoryModalData(null);
    }
  };

  const handleEditCategoryModal = () => {
    setEditCategoryModal(!editCategoryModal);
  };

  const handleCategoryModalType = (category) => {
    setAddCategoryModalType("edit");
    setEditCategoryModal(!editCategoryModal);
    setShowAddCategory(!showAddCategory);
    setCategoryModalData(category);
  };

  const handleServiceTable = (service) => {
    setActive(service);
  };

  const addIconsToUserData = (data) => {
    return data.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}
        >
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() =>
              navigate("/home/categoryManagement/serviceRequests", {
                state: { ...item },
              })
            }
          >
            Requests
          </div>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => handleDeleteService(item._id)}
          >
            <DeleteForeverIcon />
          </div>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={(e) => handleAddServiceModal(item, "edit")}
          >
            <EditIcon />
          </div>
        </div>
      ),
    }));
  };

  return (
    <div
      className="flex-col just-sp-bwn pd-24 bg-white ht-100 overflow-auto"
      style={{ width: "95%" }}
    >
      <div className="flex-col gap-32">
        <TableHeader
          title="Category Management"
          firsticon={<EditIcon />}
          handleFirstButton={handleEditCategoryModal}
        />
        <GroupTags
          active={active}
          categoryList={categories}
          handleServices={handleServiceTable}
          handleModal={handleAddCategoryModal}
          setCategoryList={setCategories}
        />
        <TableHeader
          title="List of Services"
          firstbuttonTitle="Add Service"
          handleFirstButton={handleAddServiceModal}
          firsticon={<Brightness7Icon />}
        />
        <CustomTable
          headers={headings}
          data={addIconsToUserData(serviceData)}
        />
        <GenericModal
          open={showAddService}
          onClose={handleAddServiceModal}
          content={
            <AddService
              handleCancelFunction={handleAddServiceModal}
              data={viewServiceData}
              categoryId={selectedCategoryId}
              type={serviceModalType}
              handleFetchServices={fetchServices}
            />
          }
        />
        <GenericModal
          open={showAddCategory}
          onClose={handleAddCategoryModal}
          content={
            <AddCategory
              type={addCategoryModalType}
              data={categoryModalData}
              handleCancelFunction={handleAddCategoryModal}
            />
          }
        />
        <GenericModal
          title="Edit Category"
          caption="Below are the added categories"
          open={editCategoryModal}
          onClose={handleEditCategoryModal}
          content={
            <EditCategory
              categories={categories}
              handleCancelFunction={handleEditCategoryModal}
              handleCategoryModalType={handleCategoryModalType}
            />
          }
        />
      </div>
    </div>
  );
};

export default CategoryManagement;
