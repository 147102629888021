import React from "react";
import classes from "./Sidebar.module.css";
import logo from "../../../assets/Next_Generation_Logo.png";
import WindowIcon from "@mui/icons-material/Window";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CategoryIcon from "@mui/icons-material/Category";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import GradeIcon from "@mui/icons-material/Grade";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { decodeJwt, getToken } from "../../../utils/HelperFunctions";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const PmMenuItems = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <WindowIcon fontSize="small" />,
  },

  {
    name: "Booking Management",
    path: "/bookingManagement",
    icon: <EventNoteIcon fontSize="small" />,
  },
  {
    name: "Property Management",
    path: "/propertyManagement",
    icon: <HomeWorkIcon fontSize="small" />,
  },
];

const Sidebar = () => {
  const permissions = useSelector((state) => state.user?.permissions);
  const chat = useSelector((state) => state.chat);
  const navigate = useNavigate();

  const menuItems = [
    permissions &&
      (permissions[0] === "All" || permissions.includes("Dashboard")) && {
        name: "Dashboard",
        path: "/dashboard",
        icon: <WindowIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("Scheduler")) && {
        name: "Scheduler",
        path: "/scheduler",
        icon: <EventNoteIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("Chat")) && {
        name: "Chat",
        path: "/chat",
        icon: <ChatBubbleIcon fontSize="small" />,
        chatCounter: true,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("Updates")) && {
        name: "Updates",
        path: "/updates",
        icon: <TextSnippetIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" ||
        permissions.includes("Property Manager")) && {
        name: "Property Manager",
        path: "/propertyManager",
        icon: <PeopleAltIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" ||
        permissions.includes("Category Management")) && {
        name: "Category Management",
        path: "/categoryManagement",
        icon: <CategoryIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" ||
        permissions.includes("Staff Management")) && {
        name: "Staff Management",
        path: "/staff",
        icon: <SupervisorAccountIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("Questionnaire")) && {
        name: "Questionnaire",
        path: "/questionnaire",
        icon: <QuestionMarkIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("User and Access")) && {
        name: "Users and Access",
        path: "/users",
        icon: <PersonIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" || permissions.includes("Ratings")) && {
        name: "Ratings",
        path: "/ratings",
        icon: <GradeIcon fontSize="small" />,
      },
    permissions &&
      (permissions[0] === "All" ||
        permissions.includes("Client Management")) && {
        name: "Client Management",
        path: "/client",
        icon: <SupportAgentIcon fontSize="small" />,
      },
  ];
  const permittedMenu = menuItems.filter((item) => Boolean(item));

  const tk = decodeJwt(getToken());

  return (
    <div className={`flex-col overflow-auto hidden-scroll ${classes.sidebar}`}>
      <div className={`flex-row ${classes.company_logo}`}>
        <img
          src={logo}
          className="pointer"
          onClick={() => navigate("/home/dashboard")}
          alt="Company Logo"
        />
      </div>
      <div>
        <ul className="flex-col">
          {(tk?.role === 1 ? PmMenuItems : permittedMenu).map((item) => (
            <NavLink
              key={item?.name}
              to={`/home${item.path}`}
              className={({ isActive }) => (isActive ? classes.active : "")}
            >
              <div className={`${classes.menu_item}`}>
                <li className="flex-row just-sp-bwn align-center">
                  <div className="flex-row align-center large-gap" style={{fontSize: "14px"}}>
                    {item.icon}
                    {item.name}
                  </div>
                  {item?.chatCounter &&
                    !!(
                      chat?.unreadStaffChat +
                      chat?.unreadClientChat +
                      chat?.unreadGroupChat
                    ) && (
                      <div className={classes.chatCounter}>
                        {chat?.unreadStaffChat +
                          chat?.unreadClientChat +
                          chat?.unreadGroupChat}
                      </div>
                    )}
                </li>
              </div>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
