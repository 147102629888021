import React, { useEffect, useState } from "react";
import Input from "../../../../components/common/commonComponents/Input/Input";
import classes from "./AddStaff.module.css";
import PhoneNumberInput from "../../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { addStaff } from "../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import { addStaffValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";

const AddStaff = ({ onClose }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: "",
    countryCode: "",
    mobileNumber: "",
  });
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = addStaffValidation.validate(formData);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    addStaff(formData)
      .then(() => {
        toast.success("Sent invite to the staff", {
          toastId: "staff added successfully",
        });
        onClose();
      })
      .catch((error) =>
        toast.error(error?.message, { toastId: "error in adding staff" })
      )
      .finally(() => dispatch(loaderActions.toggleLoading(false)));
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return (
    <form className="flex-col gap-32 wd-100" onSubmit={handleSubmit}>
      <div className="flex-row align-center medium-gap wd-100">
        <div className={`flex-col wd-50 small-gap ${classes.formField}`}>
          <label>Full Name *</label>
          <Input
            placeholder="Enter full name"
            value={formData?.fullName}
            onChange={(e) =>
              setFormData((curr) => {
                return { ...formData, fullName: e.target.value };
              })
            }
          />
        </div>
        <div className={`flex-col wd-50 small-gap ${classes.formField}`}>
          <label>Mobile Number</label>
          <PhoneNumberInput
            props={{ setFormData }}
            value={formData?.countryCode + formData?.mobileNumber}
          />
        </div>
      </div>
      {error && <Error title={error} />}
      <div className="flex-row bt-margin align-center medium-gap justify-center">
        <Button
          theme="gray"
          title="Cancel"
          type="button"
          handleFunction={onClose}
        />
        <Button title="Add" type="submit" />
      </div>
    </form>
  );
};

export default AddStaff;
