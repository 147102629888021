import React, { useState, useEffect } from "react";
import classes from "./ForgotPassword.module.css";

import LoginRightScreen from "../LoginRightScreen/LoginRightScreen";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { forgotPassword } from "../../../api/api_calls/auth";
import { loaderActions } from "../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import Error from "../commonComponents/Error/Error";
import { emailValidation } from "../../../validations/addUserValidation";
import { toast } from "react-toastify";
const ForgotPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.role == null) {
      navigate("/login");
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validEmail = (e) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
      role: state?.role,
      isPropertyManager: state?.role,
    };
    const validData = validEmail(formData?.email);

    const emailValidate = emailValidation.validate({ email: formData?.email });

    if (emailValidate?.error?.message) {
      setError(emailValidate?.error?.message);
      return;
    }

    if (!validData) {
      setError(!validData);
      return;
    } else {
      setError(false);
      forgotPassword(formData)
        .then((res) => {
          if (res.status === 200) {
            setLinkSent(true);
          }
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "poi",
          });
        })
        .finally((res) => {
          dispatch(loaderActions.toggleLoading(false));
        });
    }
  };
  useEffect(() => {
    if (linkSent) {
      setTimeout(() => {
        setLinkSent(false);
      }, 10000);
    }
    clearTimeout();
  }, [linkSent]);
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);

    clearTimeout();
  }, [error]);
  return (
    <Grid container xs={12} className={classes.root}>
      <Grid xs={12} md={6}>
        <div className={`${classes.login_content} flex-col`}>
          <h3 className="font-24">Forgot Password</h3>
          <p className="font-16">Enter you email to reset your password</p>

          <form className="flex-col" onSubmit={handleSubmit}>
            <div className={`${classes.login_inputs} flex-col`}>
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                onChange={handleEmailChange}></input>
            </div>
            {error && <Error title={error} />}

            <button type="submit">Send Link</button>
          </form>
          {linkSent && (
            <p className="font-16">
              Reset link has been sent to you.Kindly check your email.
            </p>
          )}
        </div>
      </Grid>

      <LoginRightScreen />
    </Grid>
  );
};

export default ForgotPassword;
