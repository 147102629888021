import Joi from "joi";

export const AddJobValidation = Joi.object({
  // jobName: Joi.string().required().label("Job Name").error(new Error("Please fill Job name")),
  timezone: Joi.string().required(),
  forRegisteredUser: Joi.boolean().required(),
  date: Joi.date()
    .required()
    .label("Select Date")
    .error(new Error("Please select a Date")),
  startTime: Joi.alternatives()
    .try(
      Joi.number().label("Select Start Time"),
      Joi.string().label("Select Start Time"),
      Joi.date()
    )
    .required()
    .error(new Error("Please select Start Time")),
  endTime: Joi.alternatives()
    .try(
      Joi.number().label("Select End Time"),
      Joi.string().label("Select End Time"),
      Joi.date()
    )
    .required()
    .error(new Error("Please select End Time")),
  serviceCategory: Joi.object(),
  service: Joi.alternatives()
    .try(Joi.object().label("Job Name"), Joi.string().label("Job Name"))
    .required()
    .error(new Error("Please select a Job")),
  groupName: Joi.object(),
  staffMember: Joi.alternatives()
    .try(
      Joi.object().label("Staff Member"),
      Joi.string().label("Property Manager")
    )
    .required()
    .error(new Error("Please select a Staff Member")),
  // propertyManager: Joi.alternatives()
  //   .try(
  //     Joi.object().label("Property Manager"),
  //     Joi.string().label("Property Manager")
  //   )
  //   .when("fullName", {
  //     is: Joi.exist(),
  //     then: Joi.allow(null),
  //   })
  //   .required()
  //   .error(new Error("Please select a Property Manager")),
  // property: Joi.alternatives()
  //   .try(Joi.object().label("Property"), Joi.string().label("Property"))
  //   .allow(null)
  //   .optional()
  //   .error(new Error("Please select a Property")),
  address: Joi.string()
    .required()
    .label("Address")
    .error(new Error("Please fill Address")),
  zipCode: Joi.number().integer().required().max(9999999999).label("Zip Code"),
  city: Joi.string()
    // .pattern(/^[A-Za-z]+$/)
    .required()
    .label("City")
    .error(new Error("Please fill valid city")),
  state: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("State")
    .error(new Error("Please fill valid state")),
  country: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("State")
    .error(new Error("Please fill valid country")),

  addTask: Joi.string().min(0).max(100).optional().label("Add Task"),
  isTemplate: Joi.boolean().optional(),
  amount: Joi.number().optional(),
  // fullName: Joi.optional(),
  // phoneNumber: Joi.string().optional(),
  // countryCode: Joi.string().optional(),
  phoneNumber: Joi.optional(),
  fullName: Joi.optional(),
  countryCode: Joi.optional(),
  propertyManager: Joi.optional(),
  property: Joi.optional()

});
