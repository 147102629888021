import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadStaffChat: 0,
  unreadClientChat: 0,
  unreadGroupChat: 0,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    storeClientChat(state, action) {
      state.unreadClientChat = action.payload;
      return state;
    },
    storeStaffChat(state, action) {
      state.unreadStaffChat = action.payload;
      return state;
    },
    storeGroupChat(state, action) {
      state.unreadGroupChat = action.payload;
      return state;
    },
  },
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
