import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WorkIcon from "@mui/icons-material/Work";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import { GenericModal } from "../../../components";

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import { getPropertyManager } from "../../../api/api_calls/data";
import AddPropertyManager from "./AddPropertyManager/AddPropertyManager";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { getDateRange } from "../../../utils/HelperFunctions";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
const headings = [
  { heading: "Name", key: "fullName" },
  { heading: "Email Address", key: "email" },
  { heading: "Country Code", key: "countryCode" },
  { heading: "Mobile Number", key: "mobileNumber" },
  { heading: "Date Added", key: "createdOn", type: "date" },
  { heading: "Job Requests", key: "bookingCount" },
  { heading: "Status", key: "isActive", type: "status" },
  { heading: "Action", key: "icon" },
];
const bookingDates = [
  {
    value: "allTime",
    title: "All Time",
  },
  {
    value: "lastWeek",
    title: "Last Week",
  },
  {
    value: "lastMonth",
    title: "Last Month",
  },
  {
    value: "lastYear",
    title: "Last Year",
  },
  {
    value: "customDateRange",
    title: "Custom Date Range",
  },
];
const status = [
  {
    value: "all",
    title: "Status",
  },
  {
    value: true,
    title: "Active",
  },
  {
    value: false,
    title: "Inactive",
  },
];
const PropertyManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [propertyManagerData, setPropertyManagerData] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchManager, setSearchManager] = useState("");
  const [viewData, setViewData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [bookingTime, setBookingTime] = useState("allTime");
  const [getList, setGetList] = useState({
    startDate: "",
    endDate: "",
  });
  const [customRange, setCustomRange] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  useEffect(() => {
    setCustomRange(false);
    const params = { searchText: searchManager };

    dispatch(loaderActions.toggleLoading(true));
    getPropertyManager(
      (page - 1) * rows,
      rows,
      params,
      getList?.startDate,
      getList?.endDate,
      statusFilter
    )
      .then((res) => {
        setPropertyManagerData(res?.data?.data?.allPropertiesManager);
        const totalData = res?.data?.data?.count?.documentCount;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) =>
        console.error("error in property manager module", error)
      )
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
  }, [rows, page, searchManager, getList, statusFilter]);

  const handleModal = () => {
    setOpen(!open);
    setViewData(null);
    setModalType("");
    setVisibilityModalOpen(false);
    setUpdateModalOpen(false);
  };
  const handleVisibilityModal = (data) => {
    setVisibilityModalOpen(!visibilityModalOpen);
    setViewData(data);
    setModalType("view");
    setOpen(false);
    setUpdateModalOpen(false);
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setVisibilityModalOpen(false);
    setModalType("update");
    setViewData(data);
    setOpen(false);
  };

  const changeModalType = (type) => {
    setModalType("update");
  };

  const handleManagerSearch = (e) => {
    setSearchManager(e.target.value);
  };
  const handleBookingTime = (range) => {
    setBookingTime(range);

    if (range === "customDateRange") {
      setCustomRange(true);
    } else {
      const dateRange = getDateRange(range);
      setCustomRange(false);

      setGetList(dateRange);
    }
  };
  const handleDateRange = (dateRange) => {
    setGetList(dateRange);
  };
  const handleStatus = (e) => {
    setStatusFilter(e);
  };
  const addIconsToUserData = (data) => {
    return data?.map((item) => ({
      ...item,
      icon: (
        <>
          <IconButton
            onClick={() =>
              navigate("/home/propertymanager/jobrequest", {
                state: {
                  id: item._id,
                },
              })
            }
          >
            <WorkIcon />
          </IconButton>
          <IconButton onClick={(e) => handleVisibilityModal(item)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={(e) => handleUpdateModal(item)}>
            <CreateIcon />
          </IconButton>
        </>
      ),
    }));
  };

  return (
    <div
      className="flex-col gap-32 just-sp-bwn pd-24 overflow-auto hidden-scroll bg-white ht-100"
      style={{ width: "96%", position: "relative" }}
    >
      <div className="flex-col gap-32">
        <TableHeader
          title="Property Manager"
          placeholder="Search Manager"
          dropdown
          firstDropdown={bookingDates}
          firstDropdownValue={bookingTime}
          handleFirstDropdown={handleBookingTime}
          secondDropdown={status}
          secondDropdownValue={statusFilter}
          handleSecondDropdown={handleStatus}
          searchValue={searchManager}
          handleSearchInput={handleManagerSearch}
          firsticon={<PersonAddIcon />}
          firstbuttonTitle="Add Property Manager"
          handleFirstButton={handleModal}
        />
        {customRange && (
          <CustomDatePicker getDateRange={(e) => handleDateRange(e)} />
        )}
        <CustomTable
          headers={headings}
          data={addIconsToUserData(propertyManagerData)}
        />
      </div>

      {propertyManagerData.length !== 0 && (
        <div className="flex-row align-center just-sp-bwn">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="table-row-selection">Rows</InputLabel>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                label="Rows"
                value={rows}
                onChange={(e) => setRows(e.target.value)}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <ThemeProvider theme={paginationTheme}>
            {" "}
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      )}
      <GenericModal
        open={open}
        onClose={handleModal}
        title="Add Property Manager"
        caption="Please fill below details to add a new Property Manager."
        content={
          <AddPropertyManager
            title="Property Manager can update their info?"
            caption="Allow Property Manager to update their information"
            onCancel={handleModal}
          />
        }
      />
      {/* action visibility icon modal */}
      <GenericModal
        open={visibilityModalOpen}
        onClose={handleVisibilityModal}
        title={
          modalType === "update"
            ? "Edit Property Manager Info"
            : "View Property Manager Info"
        }
        content={
          <AddPropertyManager
            type={modalType}
            title="Property Manager can update their info?"
            caption="Allow Property Manager to update their information"
            onCancel={handleVisibilityModal}
            data={viewData}
            handleModalType={changeModalType}
          />
        }
      />
      {/* action update icon modal */}
      <GenericModal
        open={updateModalOpen}
        onClose={handleUpdateModal}
        title="Edit Property Manager"
        caption="Allow Property Manager to update their information"
        content={
          <AddPropertyManager
            type={modalType}
            title="Property Manager can update their info?"
            caption="Allow Property Manager to update their information"
            onCancel={handleUpdateModal}
            data={viewData}
          />
        }
      />
    </div>
  );
};

export default PropertyManager;
