import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./NotificationDrawer.module.css";
import { queryNotification } from "../../../pages/SuperAdmin/Chat/ChatBox/firebaseQueries";
import { onSnapshot } from "firebase/firestore";
import moment from "moment";

const NotificationDrawer = ({ open, onClose, read }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (open) {
      const notificationQuery = queryNotification();

      const notificationUnsubscribe = onSnapshot(
        notificationQuery,
        (snapshot) => {
          const allNotifications = [];

          snapshot.forEach((doc) => {
            allNotifications.push({ ...doc.data() });
          });

          setNotifications(allNotifications);
        }
      );

      read();

      return () => notificationUnsubscribe();
    }
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <h1 className={classes.notificationHeading}>Notifications</h1>
      <div className={`flex-col ht-100 overflow-auto hidden-scroll medium-gap ${classes.drawer}`}>
        {notifications.map((notification) => (
          <div
            className={`flex-row medium-gap align-center ${classes.individualNotification}`}
          >
            <div className="flex-col small-gap" style={{width: "80%"}}>
              <h3 style={{color: "#40A59E"}}>{notification?.title}</h3>
              <p>{notification?.body}</p>
            </div>
            <div style={{width: "20%"}}>
              {moment.unix(notification?.createdAt?.seconds).format("lll")}
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
