import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "../../../../components/common/commonComponents/Button/Button";

import { useLocation } from "react-router-dom";

const PaymentService = () => {
  // 2️⃣ Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();

  const postForm = location?.state?.postForm;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://app.nextgenerationclean.com/home/completion?postForm=${JSON.stringify(
          { postForm }
        )}`,
        // return_url: `http://localhost:3001/home/completion?postForm=${JSON.stringify(
        //   { postForm }
        // )}`,
      },
    });

    if (error) {
      setMessage(`Payment failed ${error.message}`);
      setIsProcessing(false);
    } else {
      setMessage(null);
      setIsProcessing(false);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />

        <div
          style={{
            marginTop: "18px",
            marginBottom: "18px",
            display: "flex",
            justifyContent: "center",
          }}>
          <Button
            title={
              isProcessing ? "Processing ... " : `Pay $${postForm?.amount}`
            }
            disabled={isProcessing}
            id="submit"
            variant="contained"
            // type={isProcessing ? "Processing ... " : "Pay now"}
          />
        </div>

        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};

export default PaymentService;
