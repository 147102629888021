import React from "react";
import classes from "./FormField.module.css";

const FormField = ({
  fieldLabel,
  name,
  value,
  handleInputChange,
  placeholder,
  readOnly,
}) => {
  return (
    <div className={`${classes.formField} flex-col small-gap`}>
      <label>{fieldLabel}</label>
      <input
        name={name}
        value={value}
        onChange={handleInputChange}
        readOnly={readOnly}
        placeholder={placeholder}
        maxLength={
          name === "description" ? 60 : name === "fullName" ? 30 : "default"
        }
      />
    </div>
  );
};

export default FormField;
