import React, { useEffect, useState } from "react";
import classes from "./StaffManagement.module.css";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import { getAllServices, getStaffList } from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { toast } from "react-toastify";
import { GenericModal } from "../../../components";
import AddStaff from "./AddStaff/AddStaff";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ConfirmDeleteStaff from "./ConfirmDeleteStaff/ConfirmDeleteStaff";
import StaffManagementTable from "./StaffManagementTable";

const headings = [
  { heading: "Name", key: "fullName" },
  { heading: "Email Address", key: "email" },
  { heading: "Mobile Number", key: "mobileNumber" },
  {
    heading: "Service Category",
    key: "categoryName",
    type: "serviceCategory",
  },
  { heading: "Status", key: "isSignUpCompleted", type: "staffStatus" },
  { heading: "Action", key: "action" },
];

const StaffManagement = () => {
  const dispatch = useDispatch();

  const [staffList, setStaffList] = useState([]);
  const [staffStatus, setStaffStatus] = useState("active");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [staffSearch, setStaffSearch] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState("all");
  const [addStaffModal, setAddStaffModal] = useState(false);
  const [deleteStaffModal, setDeleteStaffModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);

  // --------sorting------------
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    setPage(1);

    getStaffList(0, rows, staffSearch, staffStatus, orderBy, order)
      .then((res) => {
        setStaffList(res?.data?.data?.staff);
        const totalData = res?.data?.data?.countData;

        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) =>
        toast.error(error?.message, {
          toastId: "error in one dependency use effect",
        })
      )
      .finally(() => dispatch(loaderActions.toggleLoading(false)));
  }, [staffStatus, order, orderBy]);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(loaderActions.toggleLoading(true));
      getStaffList((page - 1) * rows, rows, staffSearch, staffStatus, orderBy, order)
        .then((res) => {
          setStaffList(res?.data?.data?.staff);
          const totalData = res?.data?.data?.countData;

          setPageCount(Math.ceil(totalData / rows));
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    }, 1000);

    return () => clearTimeout(getData);
  }, [rows, page, staffSearch, selectedService]);

  const dropdown = (dataset) => {
    if (dataset && dataset[0]?.title !== "All Services") {
      dataset.unshift({ title: "All Services", value: "all" });
    }

    dataset?.map((data) => {
      data.title = data.name || data.title;
      data.value = data._id || data.value;
    });

    return dataset;
  };

  const handleServiceFilter = (service) => {
    setSelectedService(service);
  };

  const handleStaffSearch = (e) => {
    setStaffSearch(e.target.value);
  };

  const addCountryCodeToMobile = (data) => {
    return data?.map((item) => ({
      ...item,
      mobileNumber: `${item?.countryCode} - ${item?.mobileNumber}`,
      action: !item?.isDeleted ? (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}
        >
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => {
              setDeleteStaffModal(true);
              setSelectedStaff(item?._id);
            }}
          >
            <DeleteForeverIcon />
          </div>
        </div>
      ) : (
        <></>
      ),
    }));
  };

  return (
    <div
      className="flex-col gap-32 pd-24 ht-100 medium-gap bg-white overflow-auto"
      style={{ width: "96%" }}
    >
      <div className="wd-100 flex-row  justify-center align-center">
        <div
          className={`pd-16 ${
            staffStatus === "active" ? classes.active : "bg-light"
          } bord-right-gray flex-row pointer justify-center`}
          style={{ width: "300px" }}
          onClick={() => setStaffStatus("active")}
        >
          Active
        </div>
        <div
          className={`pd-16 ${
            staffStatus === "inActive" ? classes.active : "bg-light"
          } flex-row pointer justify-center`}
          style={{ width: "300px" }}
          onClick={() => setStaffStatus("inActive")}
        >
          Inactive
        </div>
      </div>
      <div className="flex-col gap-32">
        <TableHeader
          title="Staff Management"
          placeholder="Search Staff"
          handleSearchInput={handleStaffSearch}
          searchValue={staffSearch}
          firstbuttonTitle="Add Staff"
          firsticon={<PeopleAltIcon />}
          handleFirstButton={() => setAddStaffModal(true)}
          // firstDropdown={dropdown(serviceList)}
          // firstDropdownValue={selectedService}
          // handleFirstDropdown={handleServiceFilter}
        />
        {/* <CustomTable
          headers={headings}
          data={addCountryCodeToMobile(staffList)}
        /> */}
        <StaffManagementTable
          data={addCountryCodeToMobile(staffList)}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          order={order}
          setOrder={setOrder}
        />
      </div>
      {staffList?.length !== 0 && (
        <div className="flex-row align-center just-sp-bwn">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel
                id="table-row-selection"
                sx={{ fontFamily: "Roboto Slab" }}
              >
                Rows
              </InputLabel>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                label="Rows"
                value={rows}
                onChange={(e) => {
                  setRows(e.target.value);
                  setPage(1);
                }}
                sx={{ fontFamily: "Roboto Slab" }}
              >
                <MenuItem value={12} sx={{ fontFamily: "Roboto Slab" }}>
                  12
                </MenuItem>
                <MenuItem value={10} sx={{ fontFamily: "Roboto Slab" }}>
                  10
                </MenuItem>
                <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                  8
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <ThemeProvider theme={paginationTheme}>
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      )}
      <GenericModal
        open={addStaffModal}
        onClose={() => setAddStaffModal(false)}
        title="Add Staff"
        caption="Fill the below form to add Staff"
        content={<AddStaff onClose={() => setAddStaffModal(false)} />}
      />
      <GenericModal
        open={deleteStaffModal}
        onClose={() => {
          setDeleteStaffModal(false);
          setSelectedStaff(null);
        }}
        content={
          <ConfirmDeleteStaff
            staffId={selectedStaff}
            handleCancelFunction={() => {
              setDeleteStaffModal(false);
              setSelectedStaff(null);
            }}
          />
        }
      />
    </div>
  );
};

export default StaffManagement;
