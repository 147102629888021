import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
//import classes from "./GenericModal.module.css";
import "./Modal.style.css";

const GenericModal = ({ open, onClose, title, content, caption, style }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="generic-modal-title"
      sx={{ zIndex: 50, overflow: "scroll" }}>
      {title ? (
        <Box className="modal-container" sx={style}>
          <div
            className="flex-row wd-100"
            style={{ justifyContent: "flex-end" }}>
            <div className="flex-row wd-100 justify-right ">
              {" "}
              {/* Use the class name */}
              <CloseIcon className="pointer" onClick={onClose} />
            </div>
          </div>
          <div
            className="flex-col gap-40 align-center"
            style={{ width: "97%" }}>
            <div className="flex-col align-center">
              <h2 id="generic-modal-title">{title}</h2>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  color: "grey",
                  fontFamily: "Roboto Slab",
                }}>
                {caption}
              </Typography>
            </div>
            {content}
          </div>
        </Box>
      ) : (
        <Box className="modal-container">
          {" "}
          {/* Use the class name */}
          <div className="flex-row wd-100 justify-right ">
            {" "}
            {/* Use the class name */}
            <CloseIcon className="pointer" onClick={onClose} />
          </div>
          {/* <h2 id="generic-modal-title">{title}</h2> */}
          {content}
        </Box>
      )}
    </Modal>
  );
};

export default GenericModal;
