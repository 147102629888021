import axios from "axios";
import API from "./apiConstants";

const fetchClient = () => {
  let instance = axios.create();

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem(API.AUTH_TOKEN);

    if (token) config.headers.authorization = token;

    return config;
  });

  return instance;
};

export default fetchClient();
