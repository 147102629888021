import React, { useState, useEffect } from "react";
import StaffAvatar from "../../../../../components/common/StaffAvatar/StaffAvatar";
import Button from "../../../../../components/common/commonComponents/Button/Button";
import SearchInput from "../../../../../components/common/commonComponents/SearchInput/SearchInput";
import classes from "./AddGroup.module.css";
const ViewGroup = ({
  removedStaff,
  groupMemberDetails,
  onRemovedStaffChange,
}) => {
  const [staffMembers, setStaffMembers] = useState([]);
  const [searchManager, setSearchManager] = useState("");
  const [filteredStaffMembers, setFilteredStaffMembers] = useState([]);

  const handleRemovedStaff = (e) => {
    const staffId = e.target.value;

    if (removedStaff.includes(staffId)) {
      const updatedRemovedStaff = removedStaff.filter((id) => id !== staffId);
      onRemovedStaffChange(updatedRemovedStaff);
    } else {
      const updatedRemovedStaff = [...removedStaff, staffId];
      onRemovedStaffChange(updatedRemovedStaff);
    }
  };
  const handleManagerSearch = (e, staffMembers) => {
    setSearchManager(e.target.value);
    const searchValue = e.target.value.toLowerCase();

    if (searchValue === "") {
      setStaffMembers(filteredStaffMembers);
    } else {
      const filteredStaff = staffMembers.filter((staff) =>
        staff.fullName.toLowerCase().includes(searchValue)
      );
      setStaffMembers(filteredStaff);
    }
  };
  useEffect(() => {
    setStaffMembers(groupMemberDetails);
    setFilteredStaffMembers(groupMemberDetails);
  }, []);

  return (
    <div className={classes.addGroupForm}>
      <div className="flex-col large-gap">
        <SearchInput
          placeholder="Search Staff"
          onChange={(e) => handleManagerSearch(e, staffMembers)}
          value={searchManager}
          disabled={false}
        />
      </div>
      <div className="flex-col large-gap">
        <label>Select Staff Member</label>
        <div className="flex-col medium-gap">
          {Object.values(staffMembers).map((staff, ind) => (
            <div
              className="flex-row just-sp-bwn align-center"
              key={`adding_staff_${ind}`}>
              <StaffAvatar
                img={staff.profilePicture}
                name={staff.fullName}
                email={staff.email}
              />
              <Button
                title={
                  removedStaff.some((person) => person === staff._id)
                    ? "Add"
                    : "Remove"
                }
                background="white"
                color={
                  removedStaff.some((person) => person === staff._id)
                    ? "#40A59E"
                    : "#CBCBCB"
                }
                border={
                  removedStaff.some((person) => person === staff._id)
                    ? "#40A59E"
                    : "#CBCBCB"
                }
                value={staff._id}
                handleFunction={handleRemovedStaff}
              />
            </div>
          ))}
          {staffMembers.length === 0 && (
            <label
              className="flex-row justify-center"
              style={{ color: "#A1A0A3" }}>
              No Staff Members Found
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewGroup;
