import accepted from "../../../assets/serviceStatus/accepted.png";
import cancelled from "../../../assets/serviceStatus/cancelled.png";
import checkedIn from "../../../assets/serviceStatus/checkedIn.png";
import checkedOut from "../../../assets/serviceStatus/checkedOut.png";
import completed from "../../../assets/serviceStatus/completed.png";
import started from "../../../assets/serviceStatus/started.png";

const serviceStatusData = [
  {
    status: accepted,
    title: "Martin Garrix Accepted the job posted job “Gallery Cleaning”.",
    caption: "Accepted 32 min ago",
  },
  {
    status: checkedIn,
    title: "Rick Maybe checked in at 09:00 PM.",
    caption: "Checked-In 20 min ago",
  },
  {
    status: checkedOut,
    title: "Daniel Godfrey checked out at 08:00 PM.",
    caption: "Checked-out 20 min ago",
  },
  {
    status: started,
    title: 'Ryan Zemini started the job of "Sofa cleaning" at 05:00 PM.',
    caption: "Started at 2:25 PM",
  },
  {
    status: completed,
    title: 'Henri Endrew completed the job of "Bathroom cleaning" at 07:00 PM.',
    caption: "Completed at 2:25 PM",
  },
  {
    status: cancelled,
    title: 'Chris Cote has canceled the service " AC repair"',
    caption: "Cancelled at Yesterday 9:25 AM",
  },
];

export default serviceStatusData;
