import Joi from "joi";

export const AddUserValidation = Joi.object({
  fullName: Joi.string()
    .required()
    .max(50)
    .pattern(/^[A-Za-z ]+$/)
    .label("Name")
    .error(Error("Please fill valid name")),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  mobileNumber: Joi.number().integer().required().label("Mobile Number"),
  location: Joi.string().required().label("Location"),
  role: Joi.number()
    .required()
    .label("Role")
    .error(new Error("Please select a role")),
  permissions: Joi.array()
    .min(1)
    .required()
    .label("Accessibility")
    .error(new Error("Please select accessibility")),
  countryCode: Joi.string().required().label("Country Code"),
});
// error({
//     'string.required': Error("Name cannot be empty"),
//     'string.pattern': Error("Please fill valid name"),

// }),

export const emailValidation = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "Please enter an email",
      "string.email": "Please enter a valid email",
    }),
});
