import React, { useEffect, useState } from "react";
import classes from ".././Questionnaire.module.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch } from "react-redux";
import { getQuestions } from "../../../../api/api_calls/data";
import { loaderActions } from "../../../../redux/reducers/loader";
import { deleteQuestion } from "../../../../api/api_calls/deleteData";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Switch,
  styled,
} from "@mui/material";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import CustomTable from "../../../../components/common/commonComponents/CustomTable/CustomTable";
import { CustomSeparator, GenericModal } from "../../../../components";
import AddQuestion from "../AddQuestion/AddQuestion";
import { toast } from "react-toastify";
import { editQuestionStatus } from "../../../../api/api_calls/addData";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { paginationTheme } from "../../../../utils/HelperFuncitons";

const Questions = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const navigate = useNavigate();

  const [questionList, setQuestionList] = useState([]);
  const [questionModalType, setQuestionModalType] = useState("");
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
  const [questionModalData, setQuestionModalData] = useState(null);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuestion, setSearchQuestion] = useState("");

  const headers = [
    { heading: "Questions", key: "text" },
    { heading: "Question Type", key: "type", type: "questionType" },
    { heading: "Status", key: "status" },
    { heading: "Action", key: "icon" },
  ];

  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Questionnaire", url: "/home/questionnaire" },
    { label: "Questions" },
  ];

  useEffect(() => {
    if (!state?.questionnaireId) navigate("/home/questionnaire");
    fetchQuestions();
  }, [searchQuestion, rows, page]);

  const fetchQuestions = () => {
    dispatch(loaderActions.toggleLoading(true));
    getQuestions(
      state?.questionnaireId,
      rows,
      (page - 1) * rows,
      searchQuestion
    )
      .then((res) => {
        setQuestionList(res?.data?.data?.questions);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleAddQuestionModal = (item) => {
    setAddQuestionModalOpen(!addQuestionModalOpen);

    if (item?.text) {
      setQuestionModalType("edit");
      setQuestionModalData(item);
      return;
    }

    setQuestionModalData(null);
    setQuestionModalType("");
  };

  const handleQuestionDelete = (questionId) => {
    dispatch(loaderActions.toggleLoading(true));
    deleteQuestion(questionId)
      .then((res) => fetchQuestions())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "7",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#40a59e",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const modifyMcqOptions = (data) => {
    const mcqOptions = [];

    for (let i = 0; i < data?.options.length; i++) {
      mcqOptions.push({ eng: data.options[i], sp: data.optionsSpanish[i] });
    }

    return { ...data, mcqOptions };
  };

  const addIconsToData = (data) => {
    return data.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}
        >
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => handleAddQuestionModal(item)}
          >
            <EditIcon />
          </div>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => handleQuestionDelete(item._id)}
          >
            <DeleteOutlineIcon />
          </div>
        </div>
      ),
      status: (
        <div>
          <IOSSwitch
            sx={{ m: 1 }}
            checked={item.status}
            // defaultChecked
            onChange={(e) => {
              dispatch(loaderActions.toggleLoading(true));
              editQuestionStatus(item._id, { status: !item.status })
                .then((res) => fetchQuestions())
                .catch((error) => {
                  toast.error(`${error}`, {
                    toastId: "70",
                  });
                })
                .finally((res) => dispatch(loaderActions.toggleLoading(false)));
            }}
          />
        </div>
      ),
    }));
  };
  return (
    <div className={`flex-col gap-28 ht-100 ${classes.main_container}`}>
      <TableHeader
        title="Questionnaire"
        caption={<CustomSeparator items={breadcrumbs} />}
      />
      <div
        className={`bg-white flex-col ht-100 overflow-auto just-sp-bwn hidden-scroll gap-32 pd-24 `}
      >
        <div className="flex-col gap-32">
          <TableHeader
            title={state?.questionnaireName}
            placeholder="Search Question"
            handleSearchInput={(e) => setSearchQuestion(e.target.value)}
            searchValue={searchQuestion}
            firsticon={<AddIcon />}
            firstbuttonTitle="Add Question"
            handleFirstButton={handleAddQuestionModal}
          />
          <CustomTable headers={headers} data={addIconsToData(questionList)} />
        </div>
        {questionList.length !== 0 && (
          <div className="flex-row align-center just-sp-bwn">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="table-row-selection">Rows</InputLabel>
                <Select
                  labelId="table-row-selection"
                  id="table-rows"
                  label="Rows"
                  value={rows}
                  onChange={(e) => setRows(e.target.value)}
                >
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <ThemeProvider theme={paginationTheme}>
              {" "}
              <Pagination
                count={pageCount}
                shape="rounded"
                size="large"
                color="primary"
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            </ThemeProvider>
          </div>
        )}
        <GenericModal
          open={addQuestionModalOpen}
          title={
            questionModalType === "edit" ? "Edit Question" : "Add Question"
          }
          caption={
            questionModalType === "edit"
              ? "Please fill below details to edit the question"
              : "Please fill below details to add a new question."
          }
          onClose={handleAddQuestionModal}
          content={
            <AddQuestion
              onCancel={handleAddQuestionModal}
              type={questionModalType}
              data={modifyMcqOptions(questionModalData)}
              handleFetchQuestions={fetchQuestions}
              categoryId={state?.categoryId}
              serviceId={state?.serviceId}
              questionnaireId={state?.questionnaireId}
            />
          }
        />
      </div>
    </div>
  );
};

export default Questions;
