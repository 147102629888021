import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./SearchInput.module.css";

const SearchInput = (props) => {
  return (
    <div className="flex-row align-center bg-light pd-10 bord-rd-6 color-grey-secondary subtle-border small-gap">
      <SearchIcon sx={{ opacity: "0.4000000059604645" }} />
      <input
        className={`${classes.searchInput} bg-light wd-100`}
        type="text"
        {...props}
      />
    </div>
  );
};

export default SearchInput;
