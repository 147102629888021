import React from "react";
import { useState } from "react";
import classes from "./PropertyManagerDashboard.module.css";
import Activity from "../../../components/common/Activity/Activity";
import BarChart from "../../../components/common/Charts/BarChart/BarChart";
import DoughnutChart from "../../../components/common/Charts/DoughnutChart/DoughnutChart";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import DashboardCard from "../../../components/common/commonComponents/DashboardCard/DashboardCard";
import {
  getPmDashboardActivity,
  getPmDashboardServiceTrends,
  getPmProperties,
  getPmServiceRequest,
  getPmTotalAmountDisbursed,
  getPmUpcomingJobs,
} from "../../../api/api_calls/data";
import { useEffect } from "react";
import UpcomingBookings from "../../../components/common/UpcomingBookings/UpcomingBookings";
import { getDateRange } from "../../../utils/HelperFunctions";
import PropertiesDoughnutPm from "../../../components/common/Charts/DoughnutChart/PropertiesDoughnutPm";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const doughnutOptions = [
  {
    value: "allTime",
    title: "All Time",
  },
  {
    value: "lastWeek",
    title: "Last Week",
  },
  {
    value: "lastMonth",
    title: "Last Month",
  },
  {
    value: "lastYear",
    title: "Last Year",
  },
];

const PropertyManagerDashboard = () => {
  const navigate = useNavigate();

  const [pmDashData, setPmDashData] = useState({
    properties: { data: [], loading: true },
    serviceRequests: {
      upComing: "0",
      onGoing: "0",
      completed: "0",
      totalJobs: "0",
      loading: true,
    },
    serviceTrends: { data: [], loading: true },
    amountDisbursed: { data: "", loading: true },
    activity: { data: [], loading: true, totalCount: "" },
    upcomingJobs: { data: [], loading: true },
  });
  const [serviceRequestTime, setServiceRequestTime] = useState("allTime");
  const [propertiesTime, setPropertiesTime] = useState("allTime");

  const handleServiceRequestTime = async (range) => {
    setServiceRequestTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getPmServiceRequest("", "");
        if (res?.status === 200) {
          setPmDashData((v) => ({
            ...v,
            serviceRequests: {
              ...v?.jobs,
              totalJobs: res?.data?.data?.totalJobs,
              onGoing: res?.data?.data?.Ongoing,
              completed: res?.data?.data?.Completed,
              upComing: res?.data?.data?.Upcoming,
              loading: false,
            },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      try {
        const res = await getPmServiceRequest(
          dateRange?.startDate,
          dateRange?.endDate
        );
        if (res?.status === 200) {
          setPmDashData((v) => ({
            ...v,
            serviceRequests: {
              ...v?.jobs,
              totalJobs: res?.data?.data?.totalJobs,
              onGoing: res?.data?.data?.Ongoing,
              completed: res?.data?.data?.Completed,
              upComing: res?.data?.data?.Upcoming,
              loading: false,
            },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handlePropertiesTime = async (range) => {
    setPropertiesTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getPmProperties("", "");
        if (res?.status === 200) {
          setPmDashData((v) => ({
            ...v,
            properties: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      try {
        const res = await getPmProperties(
          dateRange?.startDate,
          dateRange?.endDate
        );
        if (res?.status === 200) {
          setPmDashData((v) => ({
            ...v,
            properties: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const fetchPmDashData = async () => {
    try {
      const res = await getPmServiceRequest("", "");
      const res2 = await getPmTotalAmountDisbursed();
      const res3 = await getPmDashboardActivity("6");
      const res4 = await getPmProperties();
      const res5 = await getPmUpcomingJobs();
      const res6 = await getPmDashboardServiceTrends();

      if (res?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          serviceRequests: {
            upComing: res?.data?.data?.Upcoming,
            onGoing: res?.data?.data?.Ongoing,
            completed: res?.data?.data?.Completed,
            totalJobs: res?.data?.data?.totalJobs,
            loading: false,
          },
        }));
      }
      if (res2?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          amountDisbursed: { data: res2?.data?.data, loading: false },
        }));
      }
      if (res3?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          activity: {
            data: res3?.data?.data?.notifications,
            totalCount: res3?.data?.data?.countData,
            loading: false,
          },
        }));
      }
      if (res4?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          properties: { data: res4?.data?.data, loading: false },
        }));
      }
      if (res5?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          upcomingJobs: { data: res5?.data?.data, loading: false },
        }));
      }
      if (res6?.status === 200) {
        setPmDashData((v) => ({
          ...v,
          serviceTrends: { data: res6?.data?.data, loading: false },
        }));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchPmDashData();
  }, []);

  return (
    <div className="flex-col gap-28 wd-100">
      <div className={`${classes.top_row} flex-row gap-28`}>
        {/* Properties */}
        <div className={`${classes.left}`}>
          <DashboardCard>
            <TableHeader
              title="Properties & Jobs"
              firstDropdown={doughnutOptions}
              firstDropdownValue={propertiesTime}
              handleFirstDropdown={handlePropertiesTime}
            />
            {pmDashData?.properties?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <PropertiesDoughnutPm
                  pmDataProperties={pmDashData?.properties?.data}
                />
              </Skeleton>
            ) : (
              <PropertiesDoughnutPm
                pmDataProperties={pmDashData?.properties?.data}
              />
            )}
          </DashboardCard>
        </div>

        {/* Total Service Requests */}
        <div className={`${classes.thirty}`}>
          <DashboardCard>
            <TableHeader
              title="Total Service Requests"
              firstDropdown={doughnutOptions}
              firstDropdownValue={serviceRequestTime}
              handleFirstDropdown={handleServiceRequestTime}
            />
            {pmDashData?.serviceRequests?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <DoughnutChart
                  pmDataServiceRequest={pmDashData?.serviceRequests}
                />
              </Skeleton>
            ) : (
              <DoughnutChart
                pmDataServiceRequest={pmDashData?.serviceRequests}
              />
            )}
          </DashboardCard>
        </div>

        {pmDashData?.amountDisbursed?.loading ? (
          <DashboardCard>
            <TableHeader title="Total Amount Disbursed" />
            <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
              <DoughnutChart
                pmDataServiceRequest={pmDashData?.serviceRequests}
              />
            </Skeleton>
          </DashboardCard>
        ) : (
          <div className={`${classes.amount_div_1}`}>
            <div className={`${classes.amount_div_2}`}>
              <div className={`${classes.amount_disbursed}`}>
                <DashboardCard>
                  <TableHeader title="Total Amount Disbursed" />
                  <h1>${pmDashData?.amountDisbursed?.data}</h1>
                </DashboardCard>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`${classes.bottom_row} flex-row gap-28`}>
        <div className={`${classes.left} flex-col gap-28`}>
          {/* Activity */}
          <DashboardCard>
            <TableHeader
              title="Activity"
              firstbuttonTitle="View All"
              firstButtonStyle="grey"
              handleFirstButton={() => navigate("/home/activity")}
            />
            {pmDashData?.activity?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <Activity />
              </Skeleton>
            ) : (
              <Activity activity={pmDashData?.activity?.data || []} />
            )}
          </DashboardCard>

          {/* Upcoming bookings */}
          <DashboardCard>
            <TableHeader
              title="Upcoming bookings"
              firstbuttonTitle="View All"
              firstButtonStyle="grey"
              handleFirstButton={() => navigate("/home/upcomingBookings")}
            />
            {pmDashData?.upcomingJobs?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <UpcomingBookings />
              </Skeleton>
            ) : (
              <UpcomingBookings
                bookings={pmDashData?.upcomingJobs?.data?.jobs}
              />
            )}
          </DashboardCard>
        </div>
        <div className={`${classes.right} flex-col gap-28`}>
          {/* Service Request Trend */}
          <DashboardCard>
            <TableHeader title="Service Request Trend" />
            {pmDashData?.serviceTrends?.loading ? (
              <Skeleton animation="wave">
                <BarChart />
              </Skeleton>
            ) : (
              <BarChart dataset={pmDashData?.serviceTrends?.data} />
            )}
          </DashboardCard>
        </div>
      </div>
    </div>
  );
};

export default PropertyManagerDashboard;
