import React, { useEffect, useState } from "react";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { CustomSeparator, GenericModal } from "../../../../components";
import CustomGroupCard from "../../../../components/CustomCard/CustomGroupCard";
import PixIcon from "@mui/icons-material/Pix";
import { useLocation } from "react-router-dom";
import { getAllGroupsMarket } from "../../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import AddGroup from "./AddGroup/AddGroup";
import { toast } from "react-toastify";
const Groups = () => {
  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Market", url: "/home/scheduler" },
    { label: "Groups" },
  ];
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [open, setOpen] = useState(false);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getAllGroupsMarket(state?.marketDetails?._id)
      .then((res) => setGroups(res?.data?.data?.groupsData))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <div className="flex-col large-gap ht-100" style={{ width: "99%" }}>
      <div className="flex-col gap-32 " style={{ width: "99%" }}>
        <TableHeader
          title="Markets"
          caption={<CustomSeparator items={breadcrumbs} />}
        />
      </div>
      <div
        className="flex-col gap-32 pd-24 bg-white ht-100 overflow-auto"
        style={{ width: "96%" }}
      >
        <TableHeader
          title={state?.marketDetails?.marketName}
          firstbuttonTitle="Add Group"
          firsticon={<PixIcon />}
          handleFirstButton={handleModal}
        />
        <div className="flex-row" style={{ flexWrap: "wrap" }}>
          {groups?.map((data, index) => (
            <CustomGroupCard
              key={`GROUPS_${index}`}
              groupNo={groups.length}
              groupAvatar={data?.groupMemberData}
              groupDetails={data}
              marketId={state?.marketDetails?._id}
            />
          ))}
        </div>
        <GenericModal
          open={open}
          onClose={handleModal}
          title="Create Group"
          caption="Please fill below details to create a new group"
          content={
            <AddGroup
              onCancel={handleModal}
              marketId={state?.marketDetails?._id}
            />
          }
        />
      </div>
    </div>
  );
};

export default Groups;
