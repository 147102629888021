import React from "react";
import { Box, Grid } from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import classes from "./ViewProperty.module.css";
import moment from "moment";

const ViewProperty = ({ data, handleModalType, handleDeleteModal }) => {
  const formData = {
    propertyName: data?.propertyName ? data?.propertyName : "",
    email: data?.email ? data.email : "",
    countryCode: data?.countryCode ? data?.countryCode : "",
    phoneNumber: data?.phoneNumber ? data?.phoneNumber : "",
    propertyType: data?.propertyType ? data?.propertyType : "",
    address: data?.address ? data.address : "",
    city: data?.city ? data.city : "",
    state: data?.state ? data.state : "",
    postalCode: data?.postalCode ? data.postalCode : "",
    country: data?.country ? data.country : "",
    additionalInfo: data?.additionalInfo ? data?.additionalInfo : "",
    createdOn: data?.createdOn ? data?.createdOn : "",
    lastServiced: data?.lastServiced
      ? moment(data?.lastServiced).format("ll")
      : "",
  };

  return (
    <div className="gap-40 flex-col" style={{ width: "94%" }}>
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Property Name</label>
            <p>{formData?.propertyName}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Property Type</label>
            <p>{formData?.propertyType}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Created on</label>
            <p>{moment(formData?.createdOn).format("ll")}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Last Serviced</label>
            <p>{formData?.lastServiced || "N/A"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Contact Detail</label>
            <p>{formData?.email}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Address of property/area</label>
            <p>{`${formData?.address}, ${formData?.city}, ${formData?.state}, ${formData?.country}, ${formData?.postalCode}`}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Additional Info</label>
            <p>{formData?.additionalInfo}</p>
          </div>
        </Grid>
      </Grid>

      <Box
        className="flex-row justify-center medium-gap"
        sx={{ textAlign: "center" }}>
        <Button
          title={"Delete"}
          handleFunction={() => handleDeleteModal(data?._id)}
          background={"#E73F3F"}
        />
        <Button
          title={"Edit Details"}
          handleFunction={() => handleModalType(data)}
        />
      </Box>
    </div>
  );
};

export default ViewProperty;
