import React from "react";
import classes from "./DashboardCard.module.css";

const DashboardCard = ({ children }) => {
  return (
    <div className={classes.dashboardCard} style={{ height: "-webkit-fill-available" }}>
      {children}
    </div>
  );
};

export default DashboardCard;
