import React, { useEffect, useState } from "react";
import classes from "./Chat.module.css";
import ChatBox from "./ChatBox/ChatBox";
import { useSelector } from "react-redux";

import SearchInput from "../../../components/common/commonComponents/SearchInput/SearchInput";
import { Avatar } from "@mui/material";
import { onSnapshot } from "firebase/firestore";
import {
  queryClientRoom,
  queryGroupRoom,
  queryRooms,
} from "./ChatBox/firebaseQueries";
import Button from "../../../components/common/commonComponents/Button/Button";
import AddGroupFrom from "./AddGroupForm/AddGroupForm";
import GroupChatBox from "./GroupChatBox/GroupChatBox";
import moment from "moment";

const Chat = () => {
  const user = useSelector((state) => state?.user);
  const chat = useSelector((state) => state.chat);

  const [search, setSearch] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedListOfPeople, setSelectedListOfPeople] = useState("staff");
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addGroupForm, setAddGroupForm] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [editGroupForm, setGroupForm] = useState(null);

  const currentUser = user?.role === 4 ? "support" : user?.id;
  useEffect(() => {
    if (currentUser) {
      const roomsQuery = queryRooms(currentUser, search);

      const groupsQuery = queryGroupRoom(currentUser, search);

      const clientQuery = queryClientRoom(currentUser, search);

      const unsubscribe = onSnapshot(roomsQuery, (snapshot) => {
        let rooms = [];

        snapshot.forEach((doc) => {
          let roomId = doc.ref?.id;
          rooms.push({ ...doc.data(), uniqueId: roomId });
        });

        setStaffs(rooms);
        setFilteredStaffs(rooms);
        // setSelectedRoom(rooms[0]);
      });

      const groupSubscribe = onSnapshot(groupsQuery, (snapshot) => {
        let fetchedGroups = [];

        snapshot.forEach((doc) => {
          let groupUniqueId = doc.ref?.id;
          fetchedGroups.push({ ...doc.data(), uniqueId: groupUniqueId });
        });

        setGroups(fetchedGroups);
        setFilteredGroups(fetchedGroups);
        // setSelectedGroup(fetchedGroups[0]);
      });

      const clientSubscribe = onSnapshot(clientQuery, (snapshot) => {
        let fetchedClients = [];

        snapshot.forEach((doc) => {
          let clinetUniqueId = doc.ref?.id;
          fetchedClients.push({ ...doc.data(), uniqueId: clinetUniqueId });
        });

        setClients(fetchedClients);
        setFilteredClients(fetchedClients);
      });

      return () => {
        unsubscribe();
        groupSubscribe();
        clientSubscribe();
      };
    }
  }, [user]);

  const handleDeleteGroup = () => {
    setSelectedGroup(null);
    
  }

  useEffect(() => {
    const tempFilteredStaffs = staffs.filter((staff) => {
      let flag = false;
      Object.keys(staff?.userDetails)?.forEach((userId) => {
        if (!flag)
          flag = staff?.userDetails?.[userId]?.fullName
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());
      });

      return flag;
    });

    const tempFilteredClients = clients.filter((client) => {
      let flag = false;
      Object.keys(client?.userDetails)?.forEach((userId) => {
        if (!flag)
          flag = client?.userDetails?.[userId].fullName
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());
      });

      return flag;
    });

    const tempFilteredGroups = groups.filter((group) =>
      group?.groupName?.toLowerCase()?.includes(search?.toLowerCase())
    );

    setFilteredStaffs(tempFilteredStaffs);
    setFilteredClients(tempFilteredClients);
    setFilteredGroups(tempFilteredGroups);
  }, [search]);

  return (
    <div className="flex-row wd-100 ht-100 gap-28 ">
      <div
        className={`${classes.left_container} flex-col gap-24 bg-white vt-pd-24`}
      >
        <div className={`flex-row bg-light side-margin ${classes.list_items}`}>
          <section
            className={
              selectedListOfPeople === "staff" ? classes.activeList : ""
            }
            onClick={() => {
              setSelectedListOfPeople("staff");
              setSearch("");
              setAddGroupForm(false);
            }}
          >
            Staff
            {!!chat.unreadStaffChat && (
              <div className={classes.chatCounter}>{chat.unreadStaffChat}</div>
            )}
          </section>
          <section
            className={
              selectedListOfPeople === "group" ? classes.activeList : ""
            }
            onClick={() => {
              setSelectedListOfPeople("group");
              setSearch("");
              setAddGroupForm(false);
            }}
          >
            Group
            {!!chat.unreadGroupChat && (
              <div className={classes.chatCounter}>{chat.unreadGroupChat}</div>
            )}
          </section>
          <section
            className={
              selectedListOfPeople === "client" ? classes.activeList : ""
            }
            onClick={() => {
              setAddGroupForm(false);
              setSearch("");
              setSelectedListOfPeople("client");
            }}
          >
            Client
            {!!chat.unreadClientChat && (
              <div className={classes.chatCounter}>{chat.unreadClientChat}</div>
            )}
          </section>
        </div>
        <div className="side-margin">
          <SearchInput
            placeholder="Search by username or email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {selectedListOfPeople === "group" && (
          <div className="side-margin">
            <Button
              title="Create group"
              width="100%"
              handleFunction={() => {
                setAddGroupForm(true);
                setEditGroup(false);
                setGroupForm(null);
              }}
            />
          </div>
        )}
        <div className={`${classes.peopleList} hidden-scroll`}>
          {selectedListOfPeople === "staff" && (
            <div>
              {filteredStaffs?.length !== 0 ? (
                filteredStaffs.map((staff) => {
                  const usersId = Object.keys(staff?.userDetails);

                  const userId = usersId.filter((id) => id !== currentUser)[0];

                  const user = staff?.userDetails?.[userId];

                  return (
                    <div
                      key={staff?.roomId}
                      className={`${classes.chat_slot} ${
                        selectedRoom === staff ? classes.activeRoom : ""
                      }`}
                      onClick={() => {
                        setSelectedRoom(staff);
                      }}
                    >
                      <div className="flex-row just-sp-bwn align-center">
                        <div className="flex-row align-center medium-gap">
                          <Avatar src={user?.image} alt={user?.fullName} />
                          <div
                            className={`${classes.staff_heading} flex-col ht-100 small-gap`}
                          >
                            <div className="flex-row align-center small-gap">
                              <h3>{user?.fullName}</h3>
                              {staff?.lastMessage?.sentBy !== currentUser &&
                                !staff?.lastMessage?.read && (
                                  <div className={classes.unread_dot}></div>
                                )}
                            </div>

                            <p
                              style={{
                                maxWidth: "150px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {staff?.lastMessage?.message}
                            </p>
                          </div>
                        </div>

                        <div className="flex-col ">
                          <p className={classes.timestamp}>
                            {moment
                              .unix(staff?.lastMessage?.createdAt?.seconds)
                              .format("lll")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className={classes.no_chat_found}>No Chats found</p>
              )}
            </div>
          )}
          {selectedListOfPeople === "group" && (
            <div>
              {filteredGroups?.length !== 0 ? (
                filteredGroups.map((group) => {
                  return (
                    <div
                      key={group?.uniqueId}
                      className={`${classes.chat_slot} ${
                        selectedGroup === group ? classes.activeRoom : ""
                      }`}
                      onClick={() => {
                        setSelectedGroup(group);
                        setAddGroupForm(false);
                      }}
                    >
                      <div className="flex-row just-sp-bwn align-center">
                        <div className="flex-row align-center medium-gap">
                          <Avatar src={user?.image} alt={user?.fullName} />
                          <div
                            className={`${classes.staff_heading} flex-col ht-100 small-gap`}
                          >
                            <div className="flex-row align-center small-gap">
                              <h3>{group?.groupName}</h3>
                              {!group?.lastMessage?.readBy?.includes(
                                currentUser
                              ) && <div className={classes.unread_dot}></div>}
                            </div>
                            <p>{group?.lastMessage?.message}</p>
                          </div>
                        </div>

                        <div className="flex-col ">
                          <p className={classes.timestamp}>
                            {moment
                              .unix(group?.lastMessage?.createdAt?.seconds)
                              .format("lll")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className={classes.no_chat_found}>No Chats found</p>
              )}
            </div>
          )}
          {selectedListOfPeople === "client" && (
            <div>
              {filteredClients?.length !== 0 ? (
                filteredClients?.map((client) => {
                  const usersId = Object.keys(client?.userDetails);

                  const userId = usersId.filter((id) => id !== currentUser)[0];

                  const user = client?.userDetails?.[userId];

                  return (
                    <div
                      key={client?.roomId}
                      className={`${classes.chat_slot} ${
                        selectedRoom === client ? classes.activeRoom : ""
                      }`}
                      onClick={() => {
                        setSelectedRoom(client);
                      }}
                    >
                      <div className="flex-row just-sp-bwn align-center">
                        <div className="flex-row align-center medium-gap">
                          <Avatar src={user?.image} alt={user?.fullName} />
                          <div
                            className={`${classes.staff_heading} flex-col ht-100 small-gap`}
                          >
                            <div className="flex-row align-center small-gap">
                              <h3>{user?.fullName}</h3>
                              {client?.lastMessage?.sentBy !== currentUser &&
                                !client?.lastMessage?.read && (
                                  <div className={classes.unread_dot}></div>
                                )}
                            </div>
                            <p>{client?.lastMessage?.message}</p>
                          </div>
                        </div>

                        <div className="flex-col ">
                          <p className={classes.timestamp}>
                            {moment
                              .unix(client?.lastMessage?.createdAt?.seconds)
                              .format("lll")}
                          </p>
                        </div>
                      </div>
                      {/* {client?.lastMessage?.by !== currentUser &&
                        !client?.lastMessage?.read && (
                          <div className={classes.unread_dot}></div>
                        )} */}
                      {/* {true && <div className={classes.unread_dot}></div>} */}
                    </div>
                  );
                })
              ) : (
                <p className={classes.no_chat_found}>No Chats found</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${classes.right_container} bg-white overflow-auto hidden-scroll pd-24`}
      >
        {addGroupForm ? (
          <AddGroupFrom
            handleBackButton={setAddGroupForm}
            isEdit={editGroup}
            editGroupForm={editGroupForm}
            createGroup={true}
          />
        ) : selectedListOfPeople === "group" ? (
          <GroupChatBox
            room={selectedGroup}
            setRoom={setSelectedGroup}
            openEditGroup={setAddGroupForm}
            setIsEdit={setEditGroup}
            setGroupForm={setGroupForm}
            handleDelete={handleDeleteGroup}
          />
        ) : (
          <ChatBox room={selectedRoom} />
        )}
      </div>
    </div>
  );
};

export default Chat;
