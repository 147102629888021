import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import {
  FormControl,
  Pagination,
  Select,
  InputLabel,
  ThemeProvider,
  MenuItem,
  Box,
} from "@mui/material";
import { CustomSeparator } from "../../../components";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import { useLocation } from "react-router-dom";
import { getJobRequests } from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { getDateRange } from "../../../utils/HelperFunctions";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { toast } from "react-toastify";
const bookingDates = [
  {
    value: "allTime",
    title: "All Time",
  },
  {
    value: "lastWeek",
    title: "Last Week",
  },
  {
    value: "lastMonth",
    title: "Last Month",
  },
  {
    value: "lastYear",
    title: "Last Year",
  },
  {
    value: "customDateRange",
    title: "Custom Date Range",
  },
];
const status = [
  {
    value: "all",
    title: "Status",
  },
  {
    value: "Ongoing",
    title: "Ongoing",
  },
  {
    value: "Completed",
    title: "Completed",
  },
  {
    value: "Upcoming",
    title: "Upcoming",
  },
  {
    value: "Deleted",
    title: "Deleted"
  }
];
const JobRequest = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [jobRequestData, setJobRequestData] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [bookingStatus, setBookingStatus] = useState("all");
  const [staffSearch, setStaffSearch] = useState("");
  const [customRange, setCustomRange] = useState(false);
  const [bookingTime, setBookingTime] = useState("allTime");
  const [getList, setGetList] = useState({
    startDate: "",
    endDate: "",
  });
  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Property Manager", url: "/home/propertyManager" },
    { label: "Job Request" },
  ];

  const headings = [
    { heading: "Booking ID", key: "bookingId", type: "capital" },
    { heading: "Address", key: "address" },
    {
      heading: "Requested date & time",
      key: "bookingDate",
      type: "serviceTime",
    },
    { heading: "Job date & time", key: "jobDate", type: "serviceTime" },
    { heading: "Additional Notes", key: "task", type: "notes" },
    { heading: "Assigned To", key: "staffDetails", type: "serviceName" },
    { heading: "Status", key: "bookingStatus" },
  ];
  const handleStatus = (e) => {
    setBookingStatus(e);
  };
  const handleManagerSearch = (e) => {
    setStaffSearch(e.target.value);
  };
  const handleDateRange = (dateRange) => {
    setGetList(dateRange);
  };
  const handleBookingTime = (range) => {
    setBookingTime(range);

    if (range === "customDateRange") {
      setCustomRange(true);
    } else {
      const dateRange = getDateRange(range);
      setCustomRange(false);

      setGetList(dateRange);
    }
  };
  const fetchJobRequests = (e) => {
    dispatch(loaderActions.toggleLoading(true));
    getJobRequests(
      state?.id,
      bookingStatus === "all" ? "" : bookingStatus,
      staffSearch,
      (page - 1) * rows,
      rows,
      getList?.startDate,
      getList?.endDate
    )
      .then((res) => {
        setJobRequestData(res?.data?.data?.jobs);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  useEffect(() => {
    setCustomRange(false);
    getJobRequests(
      state?.id,
      bookingStatus === "all" ? "" : bookingStatus,
      staffSearch,
      0,
      rows,
      getList?.startDate,
      getList?.endDate
    )
      .then((res) => {
        setJobRequestData(res?.data?.data?.jobs);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
        setPage(1);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, [bookingStatus, staffSearch, getList]);

  useEffect(() => {
    setCustomRange(false);
    fetchJobRequests();
  }, [rows, page]);

  const addIconsToUserData = (data) => {
    return data.map((item) => ({
      ...item,
      bookingDate: new Date(item?.bookingDate).toLocaleString("en-us", {
        timeZone: item?.timezone,
      }),
      jobDate: new Date(item?.jobDate).toLocaleString("en-us", {
        timeZone: item?.timezone,
      }),
      bookingStatus: item?.isDeleted ? "Deleted" : item?.bookingStatus
    }));
  };

  return (
    <div className="flex-col gap-28 wd-100 ht-100 ">
      <div className="flex-col gap-32" style={{ width: "99%" }}>
        <TableHeader
          title="Job Search"
          caption={<CustomSeparator items={breadcrumbs} />}
        />
      </div>
      <div
        className="flex-col just-sp-bwn gap-32 pd-24 bg-white ht-100 overflow-auto hidden-scroll"
        style={{ width: "96%" }}
      >
        <div className="flex-col gap-32">
          <TableHeader
            title="Job Search"
            placeholder="Search Staff"
            dropdown
            firstDropdown={bookingDates}
            firstDropdownValue={bookingTime}
            handleFirstDropdown={handleBookingTime}
            secondDropdown={status}
            secondDropdownValue={bookingStatus}
            handleSecondDropdown={handleStatus}
            searchValue={staffSearch}
            handleSearchInput={handleManagerSearch}
          />
          {customRange && (
            <CustomDatePicker getDateRange={(e) => handleDateRange(e)} />
          )}
          <CustomTable
            headers={headings}
            data={addIconsToUserData(jobRequestData)}
          />
        </div>
        {jobRequestData.length !== 0 && (
          <div className="flex-row align-center just-sp-bwn">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="table-row-selection">Rows</InputLabel>
                <Select
                  labelId="table-row-selection"
                  id="table-rows"
                  label="Rows"
                  value={rows}
                  onChange={(e) => {
                    setRows(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={paginationTheme}>
              {" "}
              <Pagination
                count={pageCount}
                shape="rounded"
                size="large"
                color="primary"
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobRequest;
