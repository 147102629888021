import Joi from "joi";

export const AddPropertyValidation = Joi.object({
  propertyName: Joi.string().required().label("Property Name").max(30).error(new Error("Please fill valid Property name")),
  email: Joi.string()
    .email({ tlds: { allow: false } }) // Disallow TLDs like .com, .net, etc.
    .required()
    .label("Email"),
  countryCode: Joi.string().required().label("Country Code"),

  phoneNumber: Joi.number().integer().required().label("Mobile Number"),
  propertyType: Joi.string().required().label("Property Type").error(new Error("Please select property type")),
  address: Joi.string().required().label("Address"),
  city: Joi.string()
    // .pattern(/^[A-Za-z]+$/)
    .required()
    .label("City")
    .error(new Error("Please fill valid city")),
  state: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("State")
    .error(new Error("Please fill valid state")),
  postalCode: Joi.number().integer().required().max(9999999999).label("Postal Code"),
  country: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("Country")
    .error(new Error("Please fill valid country")),
  additionalInfo: Joi.string().required().label("Additional Info"),
});
