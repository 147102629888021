import Joi from "joi";

export const addServiceFormValidationSchema = Joi.object({
  name: Joi.string().required().label("Service Name"),
  price: Joi.number()
    .integer()
    .min(1)
    .required()
    .label("Price")
    .error(new Error("Price must be greated than 1$")),
  description: Joi.string().required().label("Description"),
  whatDoesItInclude: Joi.array().items(
    Joi.string().not("").required().error(new Error("What does it include is not allowed to be empty."))
  ).label("What does it include"),
  image: Joi.required(),
});
