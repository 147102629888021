import React, { useState, useEffect } from "react";
import classes from "./SetPassword.module.css";

import LoginRightScreen from "../LoginRightScreen/LoginRightScreen";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Input from "../commonComponents/Input/Input";
import Button from "../commonComponents/Button/Button";

import Error from "../commonComponents/Error/Error";
import {
  createPassword,
  pmCreatePassword,
  pmSetPassword,
  setPassword,
} from "../../../api/api_calls/auth";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import createPasswordValidation from "../../../validations/createPasswordValidation";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [createdPass, setCreatedPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [passSame, setPassSame] = useState("");
  const [passwordSet, setPasswordSet] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = useState(false);
  const url = window.location.href;
  const handlePasswordChange = (e) => {
    setCreatedPass(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPass(e.target.value);
  };
  const passwordNew = () => {
    setShowNewPassword(!showNewPassword);
  };
  const passwordCnfrm = () => {
    setShowCnfrmPassword(!showCnfrmPassword);
  };
  const fetchPmData = async () => {
    try {
      dispatch(loaderActions.toggleLoading(true));
      const response = await pmCreatePassword(id);
      if (response.status === 200) {
        if (!response?.data?.data?.isSignUpCompleted) {
          setEmail(response?.data?.data?.email);
        } else {
          setPasswordSet(true);
          setTimeout(() => {
            navigate("/login");
          }, 5000);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loaderActions.toggleLoading(false));
    }
  };

  const fetchUserData = async () => {
    try {
      dispatch(loaderActions.toggleLoading(true));
      const response = await setPassword(id);
      if (response.status === 200) {
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loaderActions.toggleLoading(false));
    }
  };

  useEffect(() => {
    if (url.includes("pmCreatePassword")) {
      fetchPmData();
    } else {
      fetchUserData();
    }
  }, [url]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPassSame(false);

    const validDate = { createdPass, confirmPass };
    const validatePassword = createPasswordValidation.validate(validDate);
    if (validatePassword?.error?.message) {
      setErrors(validatePassword?.error?.message);
      return;
    } else {
      setErrors(false);
      if (createdPass === confirmPass) {
        if (url.includes("pmCreatePassword")) {
          const formData = {
            email,
            createdPass,
            confirmPass,
          };
          dispatch(loaderActions.toggleLoading(true));

          pmSetPassword(formData)
            .then((res) => {
              if (res.status === 200) {
                navigate("/login");
              }
            })
            .catch((error) => toast.error(error?.message))
            .finally((res) => {
              dispatch(loaderActions.toggleLoading(false));
            });
        } else {
          const formData = {
            password: confirmPass,
          };
          dispatch(loaderActions.toggleLoading(true));

          createPassword(formData, id)
            .then((res) => {
              if (res.status === 200) {
                navigate("/login");
              }
            })
            .catch((error) => toast.error(error?.message))
            .finally((res) => {
              dispatch(loaderActions.toggleLoading(false));
            });
        }
      } else {
        setPassSame(true);
        return;
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <Grid container xs={12} className={classes.root}>
      <Grid xs={12} md={6}>
        <div className={`${classes.login_content} flex-col`}>
          <h3 className="font-24">Create a new password</h3>
          <p className="font-16">
            Enter below details to create a new password
          </p>

          <form className="flex-col" onSubmit={handleSubmit}>
            <div className={`${classes.login_inputs} flex-col`}>
              <div className={`${classes.login_password} flex-col`}>
                <Input
                  name="newPassword"
                  placeholder="New Password"
                  value={createdPass}
                  type={showNewPassword ? "text" : "password"}
                  padding="10px"
                  onChange={handlePasswordChange}
                />
                <div className={classes.password} onClick={passwordNew}>
                  {showNewPassword ? (
                    <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                  )}
                </div>
              </div>
              <div className={`${classes.login_password} flex-col`}>
                <Input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPass}
                  type={showCnfrmPassword ? "text" : "password"}
                  padding="10px"
                  onChange={handleConfirmPasswordChange}
                />
                <div className={classes.password} onClick={passwordCnfrm}>
                  {showCnfrmPassword ? (
                    <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                  )}
                </div>
              </div>
              {errors && (
                <Error title="Password must contain atleast one lowercase letter, one uppercase letter, one number and must be 8 digits long." />
              )}
              {passSame && (
                <Error
                  title={"New password and Confirm password must be same"}
                />
              )}
            </div>
            <Button title="Submit" />
          </form>
          {passwordSet && <p className="font-16">Password already set.</p>}
        </div>
      </Grid>

      <LoginRightScreen />
    </Grid>
  );
};

export default SetPassword;
