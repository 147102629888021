import Joi from "joi";

export const addQuestionValidation = Joi.object({
  text: Joi.string().max(150).required().label("Question"),
  textSpanish: Joi.string().max(150).required().label("Question (Spanish)"),
  type: Joi.string()
    .valid("yesno", "description", "mcq")
    .required()
    .error(new Error("Please choose Question type from the dropdown")),
  options: Joi.array().when("type", {
    is: "mcq",
    then: Joi.array().items(Joi.string().min(1).required().label("Option")),
    otherwise: Joi.array().optional(),
  }),
  optionsSpanish: Joi.array()
    .when("type", {
      is: "mcq",
      then: Joi.array().items(Joi.string().min(1).required().label("Option")),
      otherwise: Joi.array().optional(),
    })
    .error(new Error("Spanish options cannot be empty")),
  categoryId: Joi.string().required(),
  serviceId: Joi.string().required(),
  questionnaireId: Joi.string().required(),
});

export const addGroupValidation = Joi.object({
  groupName: Joi.string()
    .max(30)
    .required()
    .error(new Error("Group name is required")),
  categoryName: Joi.object()
    .required()
    .error(new Error("Please select a service category")),

  groupMember: Joi.array()
    .min(1)
    .required()
    .error(new Error("Please add staff members")),
});
export const updateGroupValidation = Joi.object({
  groupName: Joi.string()
    .max(30)
    .required()
    .error(new Error("Group name is required")),
  groupId: Joi.string(),
  removedGroupMember: Joi.array(),
  groupMember: Joi.array()
    .min(1)
    .required()
    .error(new Error("Please add staff members")),
  groupTagName: Joi.string(),
});
export const addMarketValidation = Joi.object({
  marketName: Joi.string()
    .max(30)
    .required()
    .error(new Error("Market name is required")),
  groupName: Joi.string()
    .max(30)
    .required()
    .error(new Error("Group name is required")),
  categoryName: Joi.object()
    .required()
    .error(new Error("Please select a service category")),
  groupMember: Joi.array()
    .min(1)
    .required()
    .error(new Error("Please add staff members")),
});

export const editMarketValidation = Joi.object({
  marketName: Joi.string()
    .max(30)
    .required()
    .error(new Error("Market name is required")),
  marketId: Joi.string(),
});

export const dateRangeValidation = Joi.object().keys({
  fromDate: Joi.date().required().messages({
    "date.empty": "Please select a Date",
  }),
  toDate: Joi.date()
    .required()
    .greater(Joi.ref("fromDate"))

    .messages({
      "date.empty": "Please select a Date",
      "date.greater": "End Date must be greater than Start Date",
    }),
});
export const editUserValidation = Joi.object({
  fullName: Joi.string()
    .pattern(/^[A-Za-z ]+$/)
    .max(50)
    .label("Name")
    .required()
    .messages({
      "string.empty": "Name cannot be empty",
      "string.pattern.base": "Please fill valid name",
    }),
  // .error(Error("Please fill valid name")),
});

export const editPasswordValidation = Joi.object({
  _id: Joi.string().required(),
  oldPassword: Joi.string()
    .regex(/[ -~]*[a-z][ -~]*/) // at least 1 lower-case
    .regex(/[ -~]*[A-Z][ -~]*/) // at least 1 upper-case
    .regex(/[ -~]*(?=[ -~])[^0-9a-zA-Z][ -~]*/) // basically: [ -~] && [^0-9a-zA-Z], at least 1 special character
    .regex(/[ -~]*[0-9][ -~]*/) // at least 1 number
    .min(8)
    .required(),
  newPassword: Joi.string()
    .regex(/[ -~]*[a-z][ -~]*/) // at least 1 lower-case
    .regex(/[ -~]*[A-Z][ -~]*/) // at least 1 upper-case
    .regex(/[ -~]*(?=[ -~])[^0-9a-zA-Z][ -~]*/) // basically: [ -~] && [^0-9a-zA-Z], at least 1 special character
    .regex(/[ -~]*[0-9][ -~]*/) // at least 1 number
    .min(8)
    .required(),
});

export const questionnaireValidation = Joi.object({
  questionnaireName: Joi.string()
    .required()
    .error(new Error("Questionnaire name cannot be empty")),

  questionnaireNameSpanish: Joi.string()
    .required()
    .error(new Error("Questionnaire name cannot be empty")),
  categoryId: Joi.string()
    .required()
    .error(new Error("Please choose a category")),
  serviceId: Joi.string()
    .required()
    .error(new Error("Please choose a Service")),
});

export const addGroupFormValidation = Joi.object({
  isAdmin: Joi.boolean().required(),
  groupName: Joi.string()
    .required()
    .error(new Error("Group name cannot be empty")),
  userType: Joi.number().required(),
  market: Joi.when("userType", {
    is: 3,
    then: Joi.object()
      .required()
      .min(1)
      .label("Market")
      .error(new Error("Please select a Market")),
    otherwise: Joi.object().optional(),
  }),
  group: Joi.when("userType", {
    is: 3,
    then: Joi.object()
      .required()
      .min(1)
      .label("Group")
      .error(new Error("Please select a Group")),
    otherwise: Joi.object().optional(),
  }),
  users: Joi.array()
    .min(2)
    .required()
    .error(new Error("Select atleast one member")),
  userDetails: Joi.object(),
  roomImage: Joi.optional(),
});

export const assignStaffValidation = Joi.object({
  marketId: Joi.string().required().error(new Error("Market is required")),
  groupId: Joi.string().required().error(new Error("Group is required")),
  staffId: Joi.string().required().error(new Error("Staff is required")),
  jobId: Joi.string().required(),
  task: Joi.string().min(0).optional(),
});

export const addStaffValidation = Joi.object({
  fullName: Joi.string()
    .max(30)
    .pattern(/^[A-Za-z ]+$/)
    .required()
    .label("Property Manager name")
    .messages({
      "string.empty": "Staff's name is not allowed to be empty",
      "string.pattern.base":
        "Please enter a valid name for the property manager",
    }),
  countryCode: Joi.string().required().label("Country Code"),
  mobileNumber: Joi.number()
    .integer()
    .required()
    .label("Mobile Number")
    .error(new Error("Mobile number cannot be empty")),
});
