import React, { useEffect, useState } from "react";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { GenericModal, CustomCard } from "../../../components";
import { Pagination, ThemeProvider } from "@mui/material";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { getAllMarkets } from "../../../api/api_calls/data";
import AddMarket from "./AddMarket/AddMarket";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import moment from "moment";
import { toast } from "react-toastify";

const Scheduler = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchManager, setSearchManager] = useState("");
  const [MarketModalOpen, setMarketModalOpen] = useState(false);

  const [availableMarkets, setAvailableMarkets] = useState([]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getAllMarkets(6, (page - 1) * 6, searchManager)
      .then((res) => {
        setAvailableMarkets(res?.data?.data?.market);
        const totalCount = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalCount / 6));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, [page, searchManager]);

  const handleManagerSearch = (e) => {
    setSearchManager(e.target.value);
  };
  const handleMarketModal = () => {
    setMarketModalOpen(!MarketModalOpen);
  };

  return (
    <div
      className="flex-col gap-32 ht-100 overflow-auto just-sp-bwn pd-24 bg-white"
      style={{ width: "96%" }}>
      <div className="flex-col gap-32">
        <TableHeader
          title="Markets"
          placeholder="Search Market"
          searchValue={searchManager}
          handleSearchInput={handleManagerSearch}
          secondicon={<StorefrontIcon />}
          secondbuttonTitle="Add Market"
          handleSecondButton={handleMarketModal}
        />
        {availableMarkets.length > 0 ? (
          <div className="flex-row" style={{ flexWrap: "wrap" }}>
            {availableMarkets.map((data, index) => (
              <CustomCard
                key={`MARKET_${index}`}
                marketId={data?._id}
                marketDetails={data}
                marketName={data?.marketName}
                createdOn={moment(data?.createdAt).format("ll")}
                groupDetails={data?.groupData}
                buttonText="Schedule Now"
              />
            ))}
          </div>
        ) : (
          <div className="flex-row justify-center">
            <p
              style={{
                color: "#5f5f61",
                fontSize: "16px",
                fontWeight: "400",
              }}>
              No Markets Found
            </p>
          </div>
        )}
      </div>
      <GenericModal
        open={MarketModalOpen}
        onClose={handleMarketModal}
        title="Add Market"
        caption="Please fill below details to add a new market."
        content={<AddMarket onCancel={handleMarketModal} />}
      />

      <div className="flex-row justify-right">
        <ThemeProvider theme={paginationTheme}>
          {availableMarkets.length > 0 && (
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Scheduler;
