import jwt from "jwt-decode";

export const getToken = () => localStorage.getItem("nextGenToken");
export const setToken = (token) => localStorage.setItem("nextGenToken", token);
export const removeToken = () => localStorage.removeItem("nextGenToken");
export const isAuth = () => Boolean(getToken());

export const decodeJwt = (tk) => {
  return jwt(tk);
};

export const convertDate = (date) => {
  if (date) {
    const inputDate = new Date(date);
    return inputDate.toISOString();
  }
};

export const preventBack = () => {
  return window.history.forward();
};

export const getDateRange = (range) => {
  let endDate = new Date();
  let startDate;
  switch (range) {
    case "lastWeek":
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
      break;

    case "lastMonth":
      startDate = new Date(endDate);
      startDate.setMonth(endDate.getMonth() - 1);
      break;

    case "lastYear":
      startDate = new Date(endDate);
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      startDate = null;
      break;
  }

  return { startDate: startDate?.toISOString() || "", endDate: endDate?.toISOString() };
};

export const convertToUTC = (date) => {
  if (typeof date === "string") {
    return new Date(date).toUTCString()
  }

  return date.toUTCString()
}
