import React from "react";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import Home from "../components/SuperAdmin/Home/Home";
import MyProfile from "../components/MyProfile/MyProfile";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/common/commonComponents/Loader/Loader";
import Completion from "../pages/PropertyManager/BookingManagement/BookService/Completion";
import BookingManagement from "../pages/PropertyManager/BookingManagement/BookingManagement";
import PropertyManagement from "../pages/PropertyManager/PropertyManagement/PropertyManagement";
import PaymentElement from "../pages/PropertyManager/BookingManagement/BookService/PaymentElement";
import PropertyManagerDashboard from "../pages/PropertyManager/PropertyManagerDashboard/PropertyManagerDashboard";
import AllActivities from "../pages/SuperAdmin/AllAcitvities/AllActivities";
import AllUpcomingBookings from "../pages/PropertyManager/PropertyManagerDashboard/UpcomingBookings/AllUpcomingBookings";

const PropertyManagerRoutes = ({ props }) => {
  const { loader } = props;

  return (
    <div className="main_container">
      {loader && <Loader />}
      <Routes>
        <Route
          path="/home"
          exact
          element={
            <PrivateRoute>
              <Navigate replace to="/home/dashboard" />
            </PrivateRoute>
          }
        />

        <Route
          path="/home"
          exact
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        >
          {/* <Route path='/home' exact element={<Navigate replace to='/home/dashboard' />} /> */}
          <Route path="activity" exact element={<AllActivities />} />
          <Route
            path="upcomingBookings"
            exact
            element={<AllUpcomingBookings />}
          />

          <Route path="profile" exact element={<MyProfile />} />
          <Route
            path="dashboard"
            exact
            element={<PropertyManagerDashboard />}
          />
          <Route
            path="bookingManagement"
            exact
            element={<BookingManagement />}
          />
          <Route
            path="propertyManagement"
            exact
            element={<PropertyManagement />}
          />
          <Route path="payment" exact element={<PaymentElement />} />
          <Route path="completion" exact element={<Completion />} />

          {/* default path for auth routes */}
          <Route path="*" exact element={<Navigate to="dashboard" replace />} />
        </Route>

        {/* default redirection - without auth no pvt should hit... */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navigate replace to="/home/dashboard" />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default PropertyManagerRoutes;
