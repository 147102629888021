import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import HouseIcon from "@mui/icons-material/House";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import { GenericModal } from "../../../components";
import classes from "../../SuperAdmin/CategoryManagement/CategoryManagement.module.css";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import {
  getPropertyList,
  getSingleProperty,
} from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import AddProperty from "./AddProperty/AddProperty";
import ViewProperty from "./ViewProperty/ViewProperty";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { toast } from "react-toastify";
import DeleteProperty from "./DeleteProperty/DeleteProperty";
const headings = [
  { heading: "Property Name", key: "propertyName" },
  { heading: "Address of property/area", key: "address" },
  { heading: "Contact Details", key: "phoneNumber" },
  { heading: "Created On", key: "createdOn", type: "date" },
  { heading: "Action", key: "icon" },
];

const PropertyManagement = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchProperty, setSearchProperty] = useState("");
  const [viewData, setViewData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  useEffect(() => {
    const params = searchProperty;
    dispatch(loaderActions.toggleLoading(true));
    getPropertyList((page - 1) * rows, rows, params)
      .then((res) => {
        if (res.status === 200) {
          setPropertyData(res?.data?.data?.properties);
          const totalData = res?.data?.data?.countData;
          setPageCount(Math.ceil(totalData / rows));
        }
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
  }, [rows, page, searchProperty]);
  const handleDeleteModal = (data) => {
    setDeleteModal(!deleteModal);

    setVisibilityModalOpen(false);
    setPropertyId(data);
    setOpen(false);
  };
  const handleModal = () => {
    setOpen(!open);
    setViewData(null);
    setModalType("");
    setVisibilityModalOpen(false);
    setUpdateModalOpen(false);
  };
  const handleVisibilityModal = (data) => {
    setVisibilityModalOpen(!visibilityModalOpen);
    setViewData(data);
    setOpen(false);
    setUpdateModalOpen(false);
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setVisibilityModalOpen(false);
    setModalType("update");
    setViewData(data);
    setOpen(false);
  };

  const handlePropertySearch = (e) => {
    setSearchProperty(e.target.value);
  };

  const fetchSinglePropertyDetails = async (_id) => {
    try {
      const res = await getSingleProperty(_id);
      if (res?.status === 200) {
        handleUpdateModal(res?.data?.data?.propertyData);
      }
    } catch (error) {}
  };
  const viewSinglePropertyDetails = async (_id) => {
    try {
      const res = await getSingleProperty(_id);
      if (res?.status === 200) {
        handleVisibilityModal(res?.data?.data?.propertyData);
      }
    } catch (error) {}
  };

  const addCountryCodeToMobile = (data) => {
    return data?.map((item) => ({
      ...item,
      phoneNumber: `${item?.countryCode} - ${item?.phoneNumber}`,
    }));
  };

  const addIconsToUserData = (data) => {
    return data?.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={(e) => viewSinglePropertyDetails(item?._id)}>
            <VisibilityIcon />
          </div>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => fetchSinglePropertyDetails(item?._id)}>
            <CreateIcon />
          </div>
        </div>
      ),
    }));
  };
  return (
    <div
      className="flex-col gap-32 pd-24 ht-100 overflow-auto hidden-scroll bg-white just-sp-bwn"
      style={{ width: "96%" }}>
      <div className="flex-col gap-32">
        <TableHeader
          title="Property Management"
          placeholder="Search Property"
          dropdown
          searchValue={searchProperty}
          handleSearchInput={handlePropertySearch}
          firsticon={<HouseIcon />}
          firstbuttonTitle="Add Property"
          handleFirstButton={handleModal}
        />
        <CustomTable
          headers={headings}
          data={addIconsToUserData(addCountryCodeToMobile(propertyData))}
        />
      </div>
      <div className="flex-row align-center just-sp-bwn">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="table-row-selection">Rows</InputLabel>
            <Select
              labelId="table-row-selection"
              id="table-rows"
              label="Rows"
              value={rows}
              onChange={(e) => setRows(e.target.value)}>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={8}>8</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Add Property Manager modal */}
        <GenericModal
          open={open}
          onClose={handleModal}
          title="Add Property"
          caption="Please fill below details to add a new Property."
          content={<AddProperty type={modalType} onCancel={handleModal} />}
        />

        {/* action visibility icon modal */}

        <GenericModal
          open={visibilityModalOpen}
          onClose={handleVisibilityModal}
          title="View Property Info"
          content={
            <ViewProperty
              data={viewData}
              handleModalType={handleUpdateModal}
              handleDeleteModal={handleDeleteModal}
            />
          }
        />

        {/*  update icon modal */}
        <GenericModal
          open={updateModalOpen}
          onClose={handleUpdateModal}
          title="Edit Property"
          content={
            <AddProperty
              type={modalType}
              onCancel={handleUpdateModal}
              data={viewData}
            />
          }
        />
        {/*  delete icon  */}

        <GenericModal
          open={deleteModal}
          onClose={handleDeleteModal}
          title="Delete Property"
          content={
            <DeleteProperty
              propertyId={propertyId}
              handleCancelFunction={handleDeleteModal}
            />
          }
        />
        <ThemeProvider theme={paginationTheme}>
          <Pagination
            count={pageCount}
            shape="rounded"
            size="large"
            color="primary"
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default PropertyManagement;
