import React, { useState } from "react";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import API from "../../../../api/api_setting/apiConstants";
const downloadOptions = [
  {
    label: "Single PDF with all photos",
    value: "singlePDF",
  },
  {
    label: "Multiple PDFs individually for each photo",
    value: "separatePDF",
  },
  {
    label: "Download as attachments",
    value: "imagesSeparately",
  },
];
const DownloadImages = ({ onClose, jobId }) => {
  const [type, setType] = useState("");

  const handleDownloadImage = (e) => {
    setType("");
    const apiUrl = `${API.DOWNLOAD_IMAGES}/${jobId}/media?downloadType=${type}`;
    const link = document.createElement("a");
    link.href = apiUrl;
    link.download = "images.pdf";
    link.click();
    onClose();
  };
  return (
    <div className="flex-col wd-100 gap-40 top-bottom-padding">
      <div className="flex-col gap-24">
        <label>Select Download Type</label>

        <RadioGroup>
          {downloadOptions.map((item) => (
            <FormControlLabel
              className="information-color"
              sx={{
                ".MuiFormControlLabel-label": {
                  fontFamily: "Roboto Slab !important",
                },
              }}
              value={item?.value}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#40A59E",
                    },
                    color: "#DBDBDB",
                    fontFamily: "Roboto Slab !important",
                  }}
                />
              }
              label={item?.label}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          ))}
        </RadioGroup>
      </div>
      <div className="flex-row justify-center medium-gap">
        <Button theme="gray" title="Cancel" handleFunction={onClose} />
        <Button
          title="Download"
          disabled={type ? false : true}
          handleFunction={handleDownloadImage}
        />
      </div>
    </div>
  );
};

export default DownloadImages;
