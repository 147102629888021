import React, { useEffect, useState } from "react";
import classes from "./AddMarket.module.css";
import Input from "../../../../components/common/commonComponents/Input/Input";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { addMarketValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { addMarket } from "../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import Select from "react-select";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";
import { Grid } from "@mui/material";
import {
  getAllUnassignedStaff,
  getCategory,
} from "../../../../api/api_calls/data";
import SearchInput from "../../../../components/common/commonComponents/SearchInput/SearchInput";
import StaffAvatar from "../../../../components/common/StaffAvatar/StaffAvatar";
const AddMarket = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    marketName: "",
    groupName: "",
    categoryName: "",
    groupMember: [],
  });
  const [category, setCategory] = useState([]);
  // const [service, setService] = useState([]);
  const [error, setError] = useState("");
  const [staffMembers, setStaffMembers] = useState([]);
  const [addedStaff, setAddedStaff] = useState([]);
  const [searchManager, setSearchManager] = useState("");

  const fetchAllServiceCategory = async () => {
    try {
      const res = await getCategory();
      if (res?.status === 200) {
        setCategory(res?.data?.data?.categories);
      }
    } catch (error) {}
  };

  const fetchStaff = async (categoryId, text) => {
    try {
      const res = await getAllUnassignedStaff(categoryId, text);
      if (res?.status === 200) {
        setStaffMembers(res?.data?.data);
      }
    } catch (error) {}
  };

  const handleCategorySelect = (e) => {
    setSearchManager("");
    fetchStaff(e?._id, searchManager);
  };

  const handleAddingStaff = (e) => {
    let updatedAddedStaff;

    const staffIndex = addedStaff.findIndex((person) => person === e);

    if (staffIndex !== -1) {
      updatedAddedStaff = [...addedStaff];
      updatedAddedStaff.splice(staffIndex, 1);
      setAddedStaff(updatedAddedStaff);
    } else {
      updatedAddedStaff = [...addedStaff, e];
      setAddedStaff(updatedAddedStaff);
    }
    return updatedAddedStaff;
  };
  const handleManagerSearch = (e) => {
    setSearchManager(e.target.value);
    fetchStaff(formData?.categoryName?._id, e.target.value);
  };
  const handleFormChange = (name, value) => {
    if (name === "groupMember") {
      const updatedValue = handleAddingStaff(value);
      value = updatedValue;
    }
    setFormData((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const postForm = {
      marketName: formData?.marketName,
      groupName: formData?.groupName,
      categoryId: formData?.categoryName?._id,
      // serviceId: formData?.serviceName?._id,
      groupMember: formData?.groupMember,
      groupTagName: formData?.categoryName?.categoryName,
    };
    const formValidation = addMarketValidation.validate(formData);
    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }
    dispatch(loaderActions.toggleLoading(true));

    addMarket(postForm)
      .then((res) => window.location.reload())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "30",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  useEffect(() => {
    fetchAllServiceCategory();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return (
    <>
      <form className="gap-40 flex-col" style={{ width: "94%" }}>
        <Grid container rowSpacing={5} columnSpacing={3}>
          <Grid item xs={12}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Market Name</label>
              <Input
                name="marketName"
                placeholder="Enter market name"
                value={formData?.marketName}
                onChange={(e) =>
                  handleFormChange(e.target.name, e.target.value)
                }
                maxLength={30}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <h4 className="mg-0">Create New Group</h4>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Group Name</label>
              <Input
                name="groupName"
                placeholder="Enter group name"
                value={formData?.groupName}
                onChange={(e) =>
                  handleFormChange(e.target.name, e.target.value)
                }
                maxLength={30}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Service Category</label>
              <Select
                name="categoryName"
                placeholder="Select Category"
                //  className="basic-single"
                styles={customStylesReactSelect}
                options={category}
                getOptionLabel={(option) => option.categoryName}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleCategorySelect(selectedOption);
                  handleFormChange("categoryName", selectedOption);
                  setAddedStaff([]);
                  setStaffMembers([]);
                }}
              />
            </div>
          </Grid>
          {/* <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Service</label>
              <Select
                name="serviceName"
                placeholder="Select Service"
                //  className="basic-single"
                styles={customStylesReactSelect}
                options={service}
                value={formData?.serviceName}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleServiceSelect(selectedOption);
                  handleFormChange("serviceName", selectedOption);
                  setAddedStaff([]);
                  setStaffMembers([]);
                }}
              />
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div className="flex-col large-gap">
              <div className={`${classes.formField} flex-col small-gap`}>
                <h4 className="mg-0">Add staff members to the Group</h4>
                <label>Please add staff members to this group</label>
              </div>
              <SearchInput
                placeholder="Search Staff"
                onChange={handleManagerSearch}
                value={searchManager}
                disabled={formData?.categoryName === "" ? true : false}
              />
              <Grid item xs={12}>
                <div className={`${classes.formField} flex-col small-gap`}>
                  <label>Select Staff Member</label>
                  {staffMembers.length !== 0 ? (
                    <div
                      className={`flex-col medium-gap icon-padding ${classes.staffDiv}`}>
                      {staffMembers.map((staff, ind) => (
                        <div
                          className="flex-row just-sp-bwn align-center"
                          key={`adding_staff_${ind}`}>
                          <StaffAvatar
                            img={staff.profilePicture}
                            name={staff.fullName}
                            email={staff.email}
                          />
                          <Button
                            type="button"
                            title={
                              addedStaff.some((person) => person === staff._id)
                                ? "Remove"
                                : "Add"
                            }
                            background="white"
                            color={
                              addedStaff.some((person) => person === staff._id)
                                ? "#CBCBCB"
                                : "#40A59E"
                            }
                            border={
                              addedStaff.some((person) => person === staff._id)
                                ? "#CBCBCB"
                                : "#40A59E"
                            }
                            value={staff._id}
                            handleFunction={(e) =>
                              handleFormChange("groupMember", e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Input placeholder="No Staff Found" readOnly={true} />
                  )}
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {error && <Error title={error} />}
        <div className="flex-row wd-100 justify-center medium-gap bottom-pd">
          <Button
            type="button"
            title="Cancel"
            theme="gray"
            handleFunction={onCancel}
          />
          <Button title="Create" type="button" handleFunction={handleSubmit} />
        </div>
      </form>
    </>
  );
};

export default AddMarket;
