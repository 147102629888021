import React from "react";
import SearchInput from "../SearchInput/SearchInput";
import Button from "../Button/Button";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const TableHeader = ({
  title,
  caption,
  placeholder,
  firstDropdown,
  firstDropdownValue,
  handleFirstDropdown,
  handleSearchInput,
  searchValue,
  secondDropdown,
  secondDropdownValue,
  handleSecondDropdown,
  firstbuttonTitle,
  firstButtonStyle,
  secondbuttonTitle,
  backIcon,
  firsticon = "",
  secondicon = "",
  handleFirstButton,
  handleSecondButton,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex-row just-sp-bwn align-center"
      style={{ marginBottom: "15px" }}
    >
      <div className="flex-row align-center medium-gap">
        {backIcon && (
          <ArrowBackIcon className="pointer" onClick={() => navigate(-1)} />
        )}
        <div className="flex-col small-gap">
          <h2 className="secondary-heading">{title}</h2>
          {caption && (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{ color: "grey", padding: "0px" }}
            >
              {caption}
            </Typography>
          )}
        </div>
      </div>
      <div className="flex-row align-center medium-gap">
        {firstDropdown && (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                value={firstDropdownValue}
                onChange={(e) => {
                  handleFirstDropdown(e.target.value);
                }}
                sx={{ height: 40, fontFamily: "Roboto Slab" }}
              >
                {firstDropdown?.map((val) => {
                  return (
                    <MenuItem
                      value={val?.value}
                      key={`${val.value}_${val.title}`}
                      sx={{ fontFamily: "Roboto Slab" }}
                    >
                      {val?.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        {secondDropdown && (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                value={secondDropdownValue}
                onChange={(e) => handleSecondDropdown(e.target.value)}
                sx={{ height: 40, fontFamily: "Roboto Slab" }}
              >
                {secondDropdown?.map((val) => {
                  return (
                    <MenuItem
                      value={val?.value}
                      key={`${val.value}_${val.title}`}
                      sx={{ fontFamily: "Roboto Slab" }}
                    >
                      {val?.title || "All"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        {placeholder && (
          <SearchInput
            placeholder={placeholder}
            onChange={handleSearchInput}
            value={searchValue}
          />
        )}
        {(firstbuttonTitle || firsticon) && (
          <Button
            handleFunction={handleFirstButton}
            icon={firsticon}
            title={firstbuttonTitle}
            theme={firstButtonStyle}
          />
        )}
        {secondbuttonTitle && (
          <Button
            handleFunction={handleSecondButton}
            icon={secondicon}
            title={secondbuttonTitle}
          />
        )}
      </div>
    </div>
  );
};

export default TableHeader;
