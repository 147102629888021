import React from "react";
import classes from "./ViewService.module.css";
import { Avatar, Grid } from "@mui/material";
import moment from "moment";
import CustomTag from "../../../../../components/common/commonComponents/CustomTag/CustomTag";

const ViewService = (props) => {
  return (
    <div
      className="gap-40 flex-col"
      style={{ width: "94%", paddingBottom: "20px" }}>
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Booking Date</label>
            <p>{moment(new Date(props?.data?.createdAt)).format("ll") || "N/A"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Name of the property</label>
            <p>{props?.data?.propertyData?.propertyName || "N/A"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service category</label>
            <p>{props?.data?.categoryData?.categoryName || "N/A"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Name of the service</label>
            <p>
              {props?.data?.serviceData?.name.toUpperCase().charAt(0) +
                props?.data?.serviceData?.name.slice(1) || "N/A"}
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service date and time</label>
            <p>{`${moment(new Date(props?.data?.jobTiming?.startTime).toLocaleString("en-us", {timeZone: props?.data?.timezone})).format(
              "LLL"
            )}`}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Amount paid</label>
            <p>${props?.data?.amount || "N/A"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Assigned to</label>
            <div className="flex-row align-center medium-gap">
              {props?.data?.assignedStaffMembersDetails?._id ? (
                <>
                  <Avatar
                    src={
                      props?.data?.assignedStaffMembersDetails?.profilePicture
                    }
                  />
                  <div className={` flex-col ht-100 small-gap`}>
                    <label className="" style={{ color: "#40A59E" }}>
                      {props?.data?.assignedStaffMembersDetails?.fullName}
                    </label>
                    <p>{props?.data?.assignedStaffMembersDetails?.email}</p>
                  </div>
                </>
              ) : (
                <p>No staff assigned.</p>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Address of property area</label>
            <p>{`${props?.data?.address}, ${props?.data?.city}, ${props?.data?.state}, ${props?.data?.country}, ${props?.data?.postalCode}.`}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service status</label>
            <p>
              <CustomTag title={props?.data?.bookingStatus} />
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewService;
