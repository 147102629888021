import React from "react";
import groupTags from "../grouptag";

const CustomTag = ({ type, title, background, color, customName }) => {
  const tag = groupTags.find((val) => val.name === title);

  return (
    <button
      type={type}
      value={title || customName}
      style={{
        background: background ? background : tag.background,
        color: color ? color : tag.color,
        padding: "4px 12px",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "600",
        border: "none",
        height: "fit-content",
        cursor: "default",
      }}
    >
      {customName !== "" ? customName : ""}
      {title}
    </button>
  );
};

export default CustomTag;
