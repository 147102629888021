import React from "react";
import classes from "./UpcomingBookings.module.css";
import serviceStatusData from "./mock";
import { Avatar } from "@mui/material";
import moment from "moment";
import upcomingjobs from "../../../assets/serviceStatus/upcomingJobs.png";
import cancelled from "../../../assets/serviceStatus/cancelled.png";
import Button from "../../common/commonComponents/Button/Button";
import CustomTag from "../commonComponents/CustomTag/CustomTag";

const UpcomingBookings = ({ bookings, viewAll = false }) => {
  return (
    <div className={`flex-col gap-36 ${classes.activity_container}`}>
      {bookings ? (
        bookings.slice(0, viewAll ? 10 : 3)?.map((el, i) => {
          return (
            <div
              className="flex-row align-center"
              key={`${el + i}`}
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex-row large-gap wd-80 align-center">
                <Avatar src={upcomingjobs} sx={{ height: 45, width: 45 }} />
                <div className="flex-col wd-100 normal-gap">
                  <h3>{el?.city}</h3>

                  <p className="text-ellipsis">
                    {el?.address}
                    {` (${el?.serviceData?.name})`}
                  </p>
                  {/* <p>{moment(el?.time).format("ll")}</p> */}
                </div>
              </div>
              {/* <Button
                title={moment(el?.jobTiming?.startTime).format("ll")}
                theme={"grey"}
              /> */}
              <CustomTag title={moment(el?.jobTiming?.startTime).format("ll") }background="#FFF5E5" color="#E49614" />
            </div>
          );
        })
      ) : (
        <div className="flex-row large-gap align-center">
          <Avatar src={cancelled} sx={{ height: 45, width: 45 }} />
          <div className="flex-col normal-gap">
            <h3>{`No upcoming bookings.`}</h3>
            {/* <p>{service.caption}</p> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingBookings;
