// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFCMToken } from "../../../api/api_calls/addData";
import { toast } from "react-toastify";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BE2Aa9q4xIjO71rogMz9Kmwbp-cXfFVQ5fWhimgxu9yKZ1FZLnqxjwZzUgRaafDR-rYHUmTKuNedysyoRBgASH8",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("FIREBASE_TOKEN", currentToken);
            updateFCMToken({ firebaseToken: currentToken })
              .then((res) => {
                return;
              })
              .catch((error) => {
                return;
              });
          } else {
            console.log("Can not get token");
          }
        })
        .catch((error) => console.log(error, "error in get token"));
    } else {
      console.log("Do not have permission!");
    }
  });
}

const newSw = await navigator.serviceWorker.register(
  "/firebase-messaging-sw.js"
);

export const onMessageListener = (callback) => {
  return new Promise((resolve) => {
      const unsubscribe = onMessage(messaging, (payload) => {
          resolve(payload);
          if (typeof callback === "function") {
              callback(payload);
          }
      });
      return unsubscribe;
  });
};

// requestPermission();

// Initialize Firebase
export const db = getFirestore(app);
