import React, { useEffect, useState } from "react";
import classes from "./PropertiesDoughnut.module.css";
import { Doughnut } from "react-chartjs-2";
import { getCategory } from "../../../../api/api_calls/data";
import { toast } from "react-toastify";

const PropertiesDoughnutPm = ({ pmDataProperties }) => {
  let arr_2 = [];

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategory()
      .then((res) => {
        const tempArr = [];
        res.data.data?.categories.forEach((category) => {
          tempArr.push(category?.categoryName);
        });
        setCategories(tempArr);
      })
      .catch((error) =>
        toast.error(error?.message, { toastId: "get_category" })
      );
  }, []);

  const setLabels = () => {
    if (pmDataProperties !== undefined) {
      const tempArr = [];
      if (pmDataProperties?.jobData) {
        arr_2 = setLabelArr(pmDataProperties);
        let arr = [];
        arr_2?.map((el) => {
          return arr.push(el?.categoryName);
        });
        // return arr;
        pmDataProperties?.jobData?.forEach((job) => {
          tempArr?.push(job?.categoryName);
        });
        // return categories
        return tempArr;
      } else {
        let arr = [];
        arr_2 = [
          {
            _id: "",
            totalJobs: 0,
            categoryName: "--",
          },
          {
            _id: "",
            totalJobs: 0,
            categoryName: "--",
          },
          {
            _id: "",
            totalJobs: 0,
            categoryName: "--",
          },
          {
            _id: "",
            totalJobs: 0,
            categoryName: "--",
          },
          {
            _id: "",
            totalJobs: 0,
            categoryName: "--",
          },
        ];
        // arr = ["Cleaning", "Plumbing"];
        arr = categories;
        return arr;
      }
    }
  };

  const setLabelArr = (arr) => {
    // const demoArr = ["Cleaning", "Plumbing"];
    const demoArr = categories;
    let z = [];

    if (arr.jobData.length === 5) return [...arr?.jobData];
    if (arr.jobData?.length > 5) return [...arr?.jobData].slice(0, 5);
    if (arr.jobData.length < 5) {
      const diff = 5 - arr.jobData.length;
      if (diff > 1) {
        for (let i = 0; i < diff; i++) {
          z.push({
            _id: "",
            totalJobs: 0,
            categoryName: demoArr[demoArr.length - 1 - i],
          });
        }
      } else if (diff === 1) {
        z.push({
          _id: "",
          totalJobs: 0,
          categoryName: demoArr[4],
        });
      }
    }
    // return [...arr?.jobData, ...z];
    return arr?.jobData;
  };

  const chartColors = [
    "#E45858",
    "#FF5A1F",
    "#40A59E",
    "#8FA3FA",
    "#5BC6D6",
    "#F07DB7",
  ];

  const statusColors = () => {
    if (pmDataProperties !== undefined) {
      return ["#E45858", "#FF5A1F", "#40A59E", "#8FA3FA", "#5BC6D6", "#F07DB7"];
    }
  };

  const setDoghnutArray = () => {
    if (pmDataProperties?.jobData !== undefined) {
      return pmDataProperties?.jobData.length > 0
        ? // ? [pmDataProperties?.jobData?.[0]?.totalJobs, pmDataProperties?.jobData?.[1]?.totalJobs, 0, 0, 0]
          arr_2.map((el) => el.totalJobs)
        : [0, 0, 0, 0, 0];
    }
  };

  const data = {
    labels: setLabels(),
    datasets: [
      {
        borderColor: "white",
        backgroundColor: statusColors(),
        data: setDoghnutArray(),
      },
    ],
  };

  // const totalJobs = data?.datasets[0]?.data?.reduce((curr, acc) => acc + curr, 0);

  const options = {
    cutout: 60,
    boxWidth: 100,
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={classes.chart_container}>
      <div className={classes.doughnut_container}>
        <div className={classes.canvas_properties}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={`flex-col align-center  ${classes.chart_details}`}>
          <h3>{pmDataProperties?.propertyCount || "0"}</h3>
          <p style={{ fontSize: "13px" }}>Total Properties</p>
        </div>
      </div>
      <div style={{width: "-webkit-fill-available"}}>
        {/* <div className={classes.chart_numbers_properties}>
          {arr_2 &&
            arr_2.map((el, i) => {
              return <p>{el?.totalJobs}</p>;
            })}
        </div> */}
        <div className="flex-col" style={{ justifyContent: "space-evenly" }}>
          {categories.map((category, ind) => {
            const totalJobs =
              arr_2.find((data) => data?.categoryName === category)
                ?.totalJobs || 0;

            return (
              <div className="flex-row just-sp-bwn align-center">
                <div className="flex-row align-center medium-gap">
                  <div
                    style={{
                      width: "40px",
                      height: "10px",
                      background: chartColors[ind],
                    }}
                  ></div>
                  <p
                    style={{
                      color: "#5f5f5f",
                      fontWeight: "400",
                      fontSize: "smaller",
                    }}
                  >
                    {category}
                  </p>
                </div>
                <p
                  style={{ fontS: "14px", fontWeight: "600", color: "#5f5f61" }}
                >
                  {totalJobs}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PropertiesDoughnutPm;
