import React, { useState, useEffect } from "react";
import classes from "./CustomDatePicker.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Button from "../common/commonComponents/Button/Button";
import { convertDate } from "../../utils/HelperFunctions";
import { Tooltip } from "@mui/material";
import { dateRangeValidation } from "../../validations/addDataValidation";
import Error from "../common/commonComponents/Error/Error";
const CustomDatePicker = ({ getDateRange = () => {} }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errors, setErrors] = useState(false);
  const handleDateRange = () => {
    const fromDate = convertDate(startDate);
    const toDate = convertDate(endDate);
    const validData = dateRangeValidation.validate({
      fromDate,
      toDate,
    });
    if (validData?.error?.message) {
      setErrors(validData?.error?.message);
      return;
    }
    getDateRange({ startDate: fromDate, endDate: toDate });
  };
  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <Tooltip
      placement="right-start"
      className={`flex-col pd-10 medium-gap ${classes.container} `}
      arrow
    >
      <div className={`${classes.date_picker}`}>
        <p>From:</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(e) => {
              const date = new Date(e);
              date.setHours(0,0,0,0)
              setStartDate(date);
            }}
            disableFuture
            format="DD/MM/YYYY"
            value={dayjs(startDate)}
          />
        </LocalizationProvider>
      </div>
      <div className={`${classes.date_picker}`}>
        <p>To:</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(e) => {
              const date = new Date(e)
              date.setHours(23, 59, 59, 0);
              setEndDate(date)}}
            disableFuture
            format="DD/MM/YYYY"
            value={dayjs(endDate)}
          />
        </LocalizationProvider>
      </div>
      {errors && <Error title={errors} />}
      <div>
        <Button title="Apply" handleFunction={handleDateRange} />
      </div>
    </Tooltip>
  );
};

export default CustomDatePicker;
