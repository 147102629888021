import React, { useEffect, useState } from "react";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import GroupTags from "../../../components/GroupTags/GroupTags";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";

import { getCategory, getService } from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { toast } from "react-toastify";

const headings = [
  { heading: "Image", key: "image" },
  { heading: "Name", key: "name" },
  { heading: "Ratings", key: "averageRating", type: "rating" },
];

const Ratings = () => {
  const [serviceData, setServiceData] = useState([]);
  const [active, setActive] = useState("");
  const [categories, setCategories] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getCategory()
      .then((res) => {
        setCategories(res?.data?.data?.categories);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  useEffect(() => {
    if (categories.length !== 0) {
      if (!active) {
        setActive(categories[0].categoryName);
      } else {
        const categoryId = categories.filter(
          (category) => category.categoryName === active
        )[0]._id;

        setActive(active);

        if (categoryId) {
          dispatch(loaderActions.toggleLoading(true));

          getService(categoryId, (page - 1) * rows, rows)
            .then((res) => {
              setServiceData(res?.data?.data?.services);
            })
            .catch((error) => toast.error(error?.message))
            .finally((res) => dispatch(loaderActions.toggleLoading(false)));
        }
      }
    }
  }, [active, rows, page, categories]);

  const handleServiceTable = (service) => {
    setActive(service);
  };

  return (
    <div
      className="flex-col just-sp-bwn pd-24 ht-100 bg-white overflow-auto"
      style={{ width: "95%" }}>
      <div className="flex-col ht-100 just-sp-bwn gap-32">
        <div className="flex-col gap-32">
          <TableHeader title="Category Management" />
          <GroupTags
            active={active}
            categoryList={categories}
            handleServices={handleServiceTable}
            addCategory={false}
          />
          <TableHeader title="Rating" />
          <CustomTable headers={headings} data={serviceData} />
        </div>

        {serviceData.length !== 0 && (
          <div className="flex-row align-center just-sp-bwn bottom-pd">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="table-row-selection">Rows</InputLabel>
                <Select
                  labelId="table-row-selection"
                  id="table-rows"
                  label="Rows"
                  value={rows}
                  onChange={(e) => setRows(e.target.value)}>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <ThemeProvider theme={paginationTheme}>
              <Pagination
                count={pageCount}
                shape="rounded"
                size="large"
                color="primary"
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ratings;
