import { collection, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";

export const queryRooms = (userId) =>
  query(
    collection(db, "rooms"),
    where("users", "array-contains", userId),
    where("type", "==", "direct"),
    orderBy("lastMessage.createdAt", "desc")
  );

export const queryGroupRoom = (userId) =>
  query(
    collection(db, "groups"),
    where("users", "array-contains", userId),
    orderBy("lastMessage.createdAt", "desc")
  );

export const queryClientRoom = (userId) =>
  query(
    collection(db, "rooms"),
    where("users", "array-contains", userId),
    where("type", "==", "direct-client"),
    orderBy("lastMessage.createdAt", "desc")
  );

export const queryMessages = (roomId) => {
  const messagesRef = query(
    collection(db, `rooms/${roomId}/messages`),
    orderBy("createdAt", "asc")
  );

  return messagesRef;
};

export const queryGroupMessages = (roomId) => {
  const messagesRef = query(
    collection(db, `groups/${roomId}/messages`),
    orderBy("createdAt", "asc")
  );

  return messagesRef;
};

export const queryNotificationDetails = () => {
  const notificationRef = query(collection(db, "notifications"));

  return notificationRef;
};

export const queryNotification = () => {
  const notificationRef = query(
    collection(db, "notifications/rootnotifications/notificationTexts"),
    orderBy("createdAt", "desc")
  );

  return notificationRef;
};
