import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import Error from "../../../../components/common/commonComponents/Error/Error";
import Select from "react-select";
import classes from "./AddProperty.module.css";
import { useSelector } from "react-redux";
import { AddPropertyValidation } from "../../../../validations/addPropertyValidation";
import { countryCode } from "../../../../utils/countryCode";
import { addProperty, editProperty } from "../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import {
  capitalize,
  customStylesReactSelect,
} from "../../../../utils/HelperFuncitons";
import PhoneNumberInput from "../../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
import zipcode from "zipcodes";

const types = [
  {
    value: "corporate",
    label: "Corporate",
  },
  {
    value: "household",
    label: "Household",
  },
];

const AddProperty = ({ onCancel, data, type }) => {
  const [formData, setFormData] = useState({
    propertyName: data?.propertyName ? data?.propertyName : "",
    email: data?.email ? data.email : "",
    countryCode: data?.countryCode ? data?.countryCode : "",
    phoneNumber: data?.phoneNumber ? data?.phoneNumber : "",
    propertyType: data?.propertyType ? data?.propertyType : "",
    address: data?.address ? data.address : "",
    city: data?.city ? data.city : "",
    state: data?.state ? data.state : "",
    postalCode: data?.postalCode ? data.postalCode : "",
    country: data?.country ? data.country : "",
    additionalInfo: data?.additionalInfo ? data?.additionalInfo : "",
  });
  const propertyManagerId = useSelector((state) => state.user?.id);
  const [errors, setErrors] = useState("");

  const autoFill = (val) => {
    if (val.target.value !== "") {
      const z = zipcode.lookup(val.target.value);

      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: z?.city || "",
        state: z?.state || "",
        country: z?.country || "",
      }));
    } else {
      setFormData((v) => ({
        ...v,
        [val.target.name]: val.target.value,
        city: "",
        state: "",
        country: "",
      }));
    }
  };

  const handleFormChange = (e) => {
    let name, value;
    if (e.target == undefined) {
      if (Number(e.value)) {
        name = "countryCode";
        value = e?.value;
      } else {
        name = "propertyType";
        value = e?.label;
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "postalCode") {
      return autoFill(e);
    }
    setFormData((currentState) => {
      return { ...currentState, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    const validData = AddPropertyValidation.validate(formData);
    if (validData?.error?.message) {
      setErrors(validData.error.message);
      return;
    }
    const postForm = {
      propertyName: capitalize(formData?.propertyName),
      email: formData?.email,
      propertyType: formData?.propertyType,
      phoneNumber: formData?.phoneNumber,
      countryCode: formData?.countryCode,
      address: formData?.address,
      city: formData?.city,
      state: formData?.state,
      postalCode: formData?.postalCode,
      country: formData?.country,
      additionalInfo: formData?.additionalInfo,
      propertyManagerId: propertyManagerId,
    };

    if (type === "update") {
      postForm._id = data?._id;
      editProperty(postForm)
        .then((res) => {
          window.location.reload();
        })

        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "customId",
          });
        });
    } else {
      addProperty(postForm)
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "customId",
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="gap-40 flex-col"
        style={{ width: "94%" }}
      >
        <Grid container rowSpacing={5} columnSpacing={3}>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Property Name</label>
              <input
                name="propertyName"
                placeholder="Enter Property name"
                value={formData.propertyName}
                onChange={handleFormChange}
                maxLength={30}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Email Address</label>
              <input
                name="email"
                placeholder="Enter Email"
                onChange={handleFormChange}
                value={formData.email}
                readOnly={type === "update" ? true : false}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Mobile Number</label>
              <PhoneNumberInput
                props={{ setFormData }}
                modalType="property"
                value={formData?.countryCode + formData?.phoneNumber}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Property Type</label>
              <Select
                name="propertyType"
                placeholder="Select type"
                options={types}
                styles={customStylesReactSelect}
                onChange={handleFormChange}
                defaultValue={
                  type === ""
                    ? ""
                    : {
                        label: formData?.propertyType,
                        value: formData?.propertyType,
                      }
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Address</label>
              <input
                name="address"
                onChange={handleFormChange}
                placeholder="Enter Address"
                value={formData.address}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Zip Code</label>
              <input
                name="postalCode"
                onChange={handleFormChange}
                placeholder="Enter Zip Code"
                value={formData.postalCode}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>City</label>
              <input
                name="city"
                onChange={handleFormChange}
                placeholder="Enter City"
                value={formData.city}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>State</label>
              <input
                name="state"
                onChange={handleFormChange}
                placeholder="Enter State"
                value={formData.state}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Country</label>
              <input
                name="country"
                onChange={handleFormChange}
                placeholder="Enter Country"
                value={formData.country}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Additional Info</label>
              <textarea
                name="additionalInfo"
                onChange={handleFormChange}
                placeholder="Add Info"
                value={formData.additionalInfo}
                maxLength={300}
              />
            </div>
          </Grid>
        </Grid>
        {errors && <Error title={errors} />}

        <Box
          className="flex-row justify-center medium-gap"
          sx={{ textAlign: "center" }}
        >
          <Button
            type="button"
            title={"Cancel"}
            handleFunction={onCancel}
            theme={"grey"}
          />
          <Button
            title={type === "update" ? "Update" : "Save"}
            type={type === "" || type === "update" ? "submit" : ""}
          />
        </Box>
      </form>
    </>
  );
};

export default AddProperty;
