import { createSlice } from "@reduxjs/toolkit";
import API from "../../api/api_setting/apiConstants";

const token = localStorage.getItem(API.AUTH_TOKEN);

const initialState = {
  loggedIn: token ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.loggedIn = true;

      return state;
    },

    logout(state) {
      state.loggedIn = false;

      return state;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
