import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentService from "./PaymentService";
import { useEffect } from "react";
import { paymentIntentMethod } from "../../../../api/api_calls/paymentApi";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { useLocation } from "react-router-dom";
import classes from "./BookService.module.css";
import moment from "moment";

const PaymentElement = ({}) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const location = useLocation();

  const formData = location?.state;

  // getting public key from server .
  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
    // setStripePromise(loadStripe("pk_test_51NfFmeJqCIPGKX2xmctsNkdlLCjyW1XUSSFg4lmDMw5ncm01eZtZmrGd7PHkXo1kd8SMdxkcJmzEkfB1VoeCNeF900TwO9Wm7F"));
  }, []);

  // getting the payment intent info
  useEffect(() => {
    let data = {
      price: formData?.formData?.amount || "",
      description: "Payment",
    };
    if (data?.price !== "") {
      paymentIntentMethod(data).then(async (res) => {
        let clientSecret = res?.data?.paymentIntent;
        if (clientSecret) {
          setClientSecret(clientSecret);
        }
      });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card
          color="neutral"
          invertedColors
          orientation="vertical"
          variant="soft"
          sx={{ p: 2 }}
        >
          <TableHeader title="Booking Details" />

          <>
            <div
              className="gap-40 flex-col"
              style={{ width: "94%", marginTop: "1rem" }}
            >
              <Grid container rowSpacing={5} columnSpacing={3}>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    <b>Property Name</b>
                    <p>{formData?.formData?.propertyName?.propertyName}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    <b>Category Name</b>
                    <p>{formData?.formData?.categoryName?.categoryName}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    {/* <label>Postal Code</label> */}
                    <b>Address</b>
                    <p>{`${formData?.formData?.address}, ${formData?.formData?.city}, ${formData?.formData?.state}, ${formData?.formData?.country}, ${formData?.formData?.postalCode}`}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    <b>Date</b>
                    <p>{moment(formData?.formData?.jobDate).format("LL")}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    <b>Start Time</b>
                    <p>{`${moment(formData?.formData?.startTime).format(
                      "LT"
                    )}`}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={`${classes.formField} flex-col small-gap`}>
                    <b>End Time</b>
                    <p>{`${moment(formData?.formData?.endTime).format(
                      "LT"
                    )}`}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card
          color="neutral"
          invertedColors
          orientation="vertical"
          variant="soft"
          sx={{ p: 2 }}
        >
          <TableHeader title="Payment Details" />
          <div style={{ marginTop: "1rem" }}>
            {stripePromise && clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentService />
              </Elements>
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentElement;
