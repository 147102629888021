import React from "react";
import classes from "./Activity.module.css";
import serviceStatusData from "./mock";
import { Avatar } from "@mui/material";
import moment from "moment";
import accepted from "../../../assets/serviceStatus/accepted.png";
import started from "../../../assets/serviceStatus/started.png";
import cancelled from "../../../assets/serviceStatus/cancelled.png";
import checkedIn from "../../../assets/serviceStatus/checkedIn.png";
import completed from "../../../assets/serviceStatus/completed.png";
import { useSelector } from "react-redux";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
const Activity = ({ activity }) => {
  const role = useSelector((state) => state?.user?.role);

  const iconStatus = (status) => {
    switch (status) {
      case "Accepted":
        return <Avatar src={accepted} sx={{ height: 45, width: 45 }} />;

      case "Ongoing":
        return <Avatar src={started} sx={{ height: 45, width: 45 }} />;

      case "Rejected":
        return <Avatar src={cancelled} sx={{ height: 45, width: 45 }} />;

      case "Completed":
        return <Avatar src={completed} sx={{ height: 45, width: 45 }} />;

      case "Upcoming":
        return <Avatar src={checkedIn} sx={{ height: 45, width: 45 }} />;

      case "Booked":
        return <Avatar src={completed} sx={{ height: 45, width: 45 }} />;

      case null:
        return <Avatar src={completed} sx={{ height: 45, width: 45 }} />;

      default:
        return <Avatar src={completed} sx={{ height: 45, width: 45 }} />;
    }
  };
  return (
    <div className={`flex-col gap-36 ${classes.activity_container}`}>
      {activity?.length === 0 && (
        <div className="flex-col align-center">
          <FormatListBulletedIcon
            style={{ color: "#d0f2f0", width: "200px", height: "200px" }}
          />

          <p
            style={{
              fontSize: "16px",
              fontFamily: "Roboto Slab",
            }}>
            No Activity Yet
          </p>
        </div>
      )}
      {activity
        ? activity?.map((el, i) => {
            if (role === 1 && el?.forAdmin === false) {
              return (
                <div
                  className="flex-row large-gap align-center"
                  key={`${el + i}`}>
                  {/* <Avatar src={serviceStatusData?.[i]?.status} sx={{ height: 45, width: 45 }} /> */}
                  {iconStatus(el?.bookingStatus)}
                  <div className="flex-col normal-gap">
                    {el?.bookingStatus === "Ongoing" ? (
                      <h3>
                        {el?.userName}
                        {el?.message} {el?.serviceName} {"on "}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Accepted" ? (
                      <h3>
                        {el?.message}
                        {el?.userName} {`for `}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Completed" ? (
                      <h3>
                        {el?.userName} {el?.message} {moment(el?.createdAt).format("LT")} on {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Started" ? (
                      <h3>
                        {el?.userName} {el?.message}{" "}
                        {moment(el?.createdAt).format("LT")} on{" "}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Upcoming" ? (
                      <h3>
                        {el?.serviceName}
                        {el?.message}
                        {el?.userName} {`on `}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Assigned" ? (
                      <h3>
                        {el?.message}
                        {el?.userName} {`on `}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === null && el?.isPayment === true ? (
                      <h3>
                        {el?.message?.trimStart().charAt(0).toUpperCase() +
                          el?.message?.trimStart().slice(1)}
                      </h3>
                    ) : (
                      ""
                    )}
                    <p>{moment(el?.createdAt).format("LLL")}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="flex-row large-gap align-center"
                  key={`${el + i}`}>
                  {/* <Avatar src={serviceStatusData?.[i]?.status} sx={{ height: 45, width: 45 }} /> */}
                  {iconStatus(el?.bookingStatus)}
                  <div className="flex-col normal-gap">
                    {el?.bookingStatus === "Ongoing" ? (
                      <h3>
                        {el?.userName}
                        {el?.message} {el?.serviceName} {"on "}
                        {moment(el?.createdAt).format("ll")}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Accepted" ? (
                      <h3>
                        {el?.message}
                        {el?.userName}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Completed" ? (
                      <h3>
                        {el?.message} {moment(el?.createdAt).format("LT")} on{" "}
                        {moment(el?.createdAt).format("ll")} requested by{" "}
                        {el?.userName}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Started" ? (
                      <h3>
                        {el?.message} {moment(el?.createdAt).format("LT")} on{" "}
                        {moment(el?.createdAt).format("ll")} requested by{" "}
                        {el?.userName}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === "Upcoming" ? (
                      <h3>{el?.userName} {el?.message} {el?.serviceName}</h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === null && el?.isUpdates === true ? (
                      <h3>
                        {el?.message?.trimStart().charAt(0).toUpperCase() +
                          el?.message?.trimStart().slice(1)}
                      </h3>
                    ) : (
                      ""
                    )}

                    {el?.bookingStatus === null && el?.isPayment === true ? (
                      <h3>
                        {el?.message} {el?.serviceName} requested by{" "}
                        {el?.userName}
                      </h3>
                    ) : (
                      ""
                    )}
                    {el?.bookingStatus === null &&
                    el?.isQuestionnaire === true ? (
                      <h3>
                        {el?.message} {el?.serviceName}
                        {el?.userName}
                      </h3>
                    ) : (
                      ""
                    )}
                    <p>{moment(el?.createdAt).format("ll")} </p>
                  </div>
                </div>
              );
            }
          })
        : serviceStatusData.map((service) => (
            <div
              className="flex-row large-gap align-center"
              key={service?.title}>
              <Avatar src={service.status} sx={{ height: 45, width: 45 }} />
              <div className="flex-col normal-gap">
                <h3>{service.title}</h3>
                <p>{service.caption}</p>
              </div>
            </div>
          ))}
    </div>
  );
};

export default Activity;
