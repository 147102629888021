import React, { useEffect } from "react";
import classes from "./Home.module.css";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  queryClientRoom,
  queryGroupRoom,
  queryRooms,
} from "../../../pages/SuperAdmin/Chat/ChatBox/firebaseQueries";
import { onSnapshot } from "firebase/firestore";
import { chatActions } from "../../../redux/reducers/chat";

const Home = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const currentUser = user?.role === 4 ? "support" : user?.id;
  useEffect(() => {
    if (currentUser) {
      const roomsQuery = queryRooms(currentUser);

      const groupsQuery = queryGroupRoom(currentUser);

      const clientQuery = queryClientRoom(currentUser);

      const unsubscribe = onSnapshot(roomsQuery, (snapshot) => {
        let rooms = [];

        let unreadStaffChat = 0;

        snapshot.forEach((doc) => {
          const roomData = doc.data();

          if (
            !roomData?.lastMessage?.read &&
            roomData?.lastMessage?.sentBy !== currentUser
          )
            unreadStaffChat++;
        });

        dispatch(chatActions.storeStaffChat(unreadStaffChat));
      });

      const groupSubscribe = onSnapshot(groupsQuery, (snapshot) => {
        let unreadGroupChat = 0;

        snapshot.forEach((doc) => {
          const roomData = doc.data();

          if (
            !roomData?.lastMessage?.readByAll &&
            roomData?.lastMessage?.sentBy !== currentUser &&
            !roomData?.lastMessage?.readBy?.includes(currentUser)
          )
            unreadGroupChat++;
        });

        dispatch(chatActions.storeGroupChat(unreadGroupChat));
      });

      const clientSubscribe = onSnapshot(clientQuery, (snapshot) => {
        let unreadClientChat = 0;

        snapshot.forEach((doc) => {
          const roomData = doc.data();
          if (
            !roomData?.lastMessage?.read &&
            roomData?.lastMessage?.sentBy !== currentUser
          )
            unreadClientChat++;
        });

        dispatch(chatActions.storeClientChat(unreadClientChat));
      });

      return () => {
        unsubscribe();
        clientSubscribe();
      };
    }
  }, [user]);

  return (
    <div className={`flex-row ${classes.main_container}`}>
      <Sidebar />
      <div className={`flex-col ${classes.right_content}`}>
        <Navbar />
        <div className="ht-100 bg-base flex-col align-center pd-28 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
