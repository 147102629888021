const STAGING_URL = "https://app.nextgenerationclean.com/api";

const API = {
  AUTH_TOKEN: "nextGenToken",

  //   ------------------PATH URLS------------------
  GET_NOTIFICATIONS: STAGING_URL + "/admin/notifications",

  LOGIN_URL: STAGING_URL + "/admin/login",
  LOGOUT_URL: STAGING_URL + "/admin/logout",
  CHANGE_PASSWORD: STAGING_URL + "/admin/changePassword",
  EDIT_PROFILE: STAGING_URL + "/admin/editAdminProfile",
  PROFILE_DETAIL: STAGING_URL + "/admin/getMyProfileDetail",
  STAFF_LIST: STAGING_URL + "/admin/staff",
  CREATE_STAFF: STAGING_URL + "/admin/createStaff",
  GET_CATEGORY: STAGING_URL + "/admin/category",
  REORDER_CATEGORY: STAGING_URL + "/admin/category/order/wise",
  GET_SERVICE: STAGING_URL + "/admin/service/categoryId",
  GET_ALL_SERVICE: STAGING_URL + "/admin/service",
  GET_PROPERTY_MANAGER: STAGING_URL + "/admin/getPropertyManager",
  GET_PROPERTY_MANAGER_SCHEDULER:
    STAGING_URL + "/admin/getPropertyManager/scheduler",
  ADD_PROPERTY_MANAGER: STAGING_URL + "/admin/addPropertyManager",
  EDIT_PROPERTY_MANAGER: STAGING_URL + "/admin/editPropertyManager",
  GET_JOB_REQUESTS: STAGING_URL + "/admin/jobRquestOfPM",
  GET_JOB_REQUESTS_FOR_SERVICE: STAGING_URL + "/admin/service/jobs-requests",
  ASSIGN_STAFF_TO_A_JOB: STAGING_URL + "/admin/service/assign/jobs-requests",
  GET_QUESTIONS: STAGING_URL + "/admin/question",
  UPDATE_QUESTION_STATUS: STAGING_URL + "/admin/question/status",
  GET_ALL_MARKETS: STAGING_URL + "/admin/getAllMarket",
  GET_ALL_MARKET_STAFF: STAGING_URL + "/admin/getAllMarketStaff",
  CREATE_MARKET_GROUP: STAGING_URL + "/admin/createGroup",
  GET_ALL_MARKET_GROUPS: STAGING_URL + "/admin/getAllGroup",
  CREATE_MARKET: STAGING_URL + "/admin/createMarket",
  GET_PROPERTIES_OF_PM: STAGING_URL + "/admin/property",
  PM_CREATE_PASS_URL: STAGING_URL + "/propertyManager/onboarding",
  PM_RESET_PASSWORD: STAGING_URL + "/propertyManager/resetpassword",
  PROPERTY_MANAGER_LOGIN_URL: STAGING_URL + "/propertyManager/login",
  FORGOT_PASSWORD_URL: STAGING_URL + "/admin/forgotPassword",
  RESET_PASSWORD: STAGING_URL + "/admin/resetPassword",
  PROPERTY_MANAGER_DETAIL: STAGING_URL + "/propertyManager/getMyProfileDetail",
  CHANGE_PROPERTY_MANAGER_PASSWORD:
    STAGING_URL + "/propertyManager/changepassword",
  EDIT_PROPERTY_MANAGER_DETAILS: STAGING_URL + "/propertyManager/updateprofile",
  GET_PROPERTY: STAGING_URL + "/propertyManager/property",
  BOOKING_SERVICE: STAGING_URL + "/propertyManager/booking",
  FILTER_STAFF: STAGING_URL + "/admin/staff/market",
  UPLOAD_MEDIA: STAGING_URL + "/admin/upload/media",
  UPDATES: STAGING_URL + "/admin/update",
  GET_JOB_DETAILS: STAGING_URL + "/admin/getJobDetails",
  GET_AVAILABLE_STAFF_MEMBERS: STAGING_URL + "/admin/staff/free/market",

  ACCEPT_JOB: STAGING_URL + "/admin/acceptJob",
  REJECT_JOB: STAGING_URL + "/admin/rejectJob",

  CREATE_UNREGISTERED_USER_JOB: STAGING_URL + "/admin/createJobWithOutUser",

  // ------------QUESTIONNAIRE MODULE---------
  GET_ALL_QUESTIONNAIRES: STAGING_URL + "/admin/questionnaire",

  // ------------Admin Dashboard--------------
  GET_ADMIN_JOB_DATA: STAGING_URL + "/admin/dashboard/jobData",
  GET_ADMIN_USER_DATA: STAGING_URL + "/admin/dashboard/userData",
  GET_ADMIN_ACTIVITY_DATA: STAGING_URL + "/admin/activity",
  GET_ADMIN_REVENUE_DATA: STAGING_URL + "/admin/dashboard/revenueData",

  //-------------Property Manager Dashboard-----------------
  GET_PM_SERVICE_REQUEST:
    STAGING_URL + "/propertyManager/dashboard/total/servicerequests",
  GET_PM_SERVICE_TRENDS:
    STAGING_URL + "/propertyManager/dashboard/serviceTrend",
  GET_PM_TOTAL_AMOUNT_DISBURSED:
    STAGING_URL + "/propertyManager/dashboard/totalAmount",
  GET_PM_DASHBOARD_ACTIVITY: STAGING_URL + "/propertyManager/dashboard/activity",
  GET_PM_PROPERTIES: STAGING_URL + "/propertyManager/dashboard/propertyData",
  GET_PM_UPCOMING_JOBS: STAGING_URL + "/propertyManager/dashboard/upcomingJobs",

  //  -------------------FOR PAYMENT------------------------
  PAYMENT_INTENT_URL: STAGING_URL + "/stripe/create/payment/intent",

  ADD_USER: STAGING_URL + "/admin/createUser",
  GET_ALL_USER: STAGING_URL + "/admin/list/adminUsers",
  ACCEPT_INVITE: STAGING_URL + "/admin/accept/invite",
  CREATE_PASSWORD: STAGING_URL + "/admin/create/password",
  UPDATE_USER: STAGING_URL + "/admin/updateUser",
  GET_SINGLE_USER: STAGING_URL + "/admin/adminUser",

  //Scheduler Apis
  GET_ALL_GROUPS_OF_MARKET: STAGING_URL + "/admin/getMarketsGroups",
  UNASSIGNED_STAFF: STAGING_URL + "/admin/staff/unassigned",
  DELETE_GROUP: STAGING_URL + "/admin/deleteGroup/market",
  DELETE_MARKET: STAGING_URL + "/admin/deleteMarket",
  UPDATE_GROUP: STAGING_URL + "/admin/updateGroup",
  UPDATE_MARKET: STAGING_URL + "/admin/updateMarket",
  CREATE_JOB: STAGING_URL + "/admin/createJob",
  EDIT_JOB: STAGING_URL + "/admin/editJobDetail",
  GET_ALL_TEMPLATES: STAGING_URL + "/admin/getAllTemplate",
  GET_ALL_GROUP_JOBS: STAGING_URL + "/admin/group/jobs",
  DOWNLOAD_IMAGES: STAGING_URL + "/admin/service/job",
  DELETE_JOB: STAGING_URL + "/admin/deleteJob",
  // Chat group api...
  GET_STAFF_MEMBERS_FOR_CHAT_GROUP: STAGING_URL + "/admin/users/role-based",

  // -------------Client Management module----------------
  GET_CLIENTS: STAGING_URL + "/admin/user",
  // -----------------------------------------------------

  // ----------------Firebase apis------------------------
  UPDATE_FCM_TOKEN: STAGING_URL + "/admin/updateFCM",
  SEND_NOTIFICATION: STAGING_URL + "/admin/sendNotification"
  // ------------------------------------------------------
};

export default API;
