import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../../../../components/common/commonComponents/Button/Button";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../../redux/reducers/loader";
import { deleteGroup } from "../../../../../api/api_calls/deleteData";
import { toast } from "react-toastify";

const DeleteGroup = ({ groupId, marketId, handleCancelFunction }) => {
  const dispatch = useDispatch();

  const handleDeleteGroup = () => {
    dispatch(loaderActions.toggleLoading(true));
    deleteGroup(marketId, groupId)
      .then((res) => window.location.reload())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "9",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  return (
    <div
      className="flex-col text-center justify-center align-center wd-100 large-gap "
      style={{ paddingBottom: "20px", marginTop: "-20px" }}>
      <div className="large-square circular-div flex-row align-center justify-center light-pink-bg-color">
        <div className="pink-icon flex-row align-center justify-center font-40 big-icon-square circular-div">
          <DeleteForeverIcon fontSize="inherit" />
        </div>
      </div>
      <div className="flex-col small-gap">
        <h3>Are you sure you want to delete?</h3>
        <p className="information-color">
          Are you sure you want to delete this Group from the market?
        </p>
      </div>
      <div className="flex-row medium-gap">
        <Button
          theme="gray"
          title="No, Cancel"
          handleFunction={handleCancelFunction}
        />
        <Button title="Yes" handleFunction={handleDeleteGroup} />
      </div>
    </div>
  );
};

export default DeleteGroup;
