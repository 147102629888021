import React, { useEffect, useState } from "react";
import classes from "./Updates.module.css";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
import CollectionsIcon from "@mui/icons-material/Collections";
import GifBoxIcon from "@mui/icons-material/GifBox";
import EditIcon from "@mui/icons-material/Edit";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { loaderActions } from "../../../redux/reducers/loader";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdate,
  editUpdate,
  uploadMedia,
} from "../../../api/api_calls/addData";
import FormData from "form-data";
import { bgImageUrls } from ".";
import { getUpdates } from "../../../api/api_calls/data";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import { GenericModal } from "../../../components";
import SendUpdate from "./SendUpdate/SendUpdate";
import { Avatar } from "@mui/material";
import moment from "moment";
const Updates = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const [backgroundType, setBackgroundType] = useState("image");
  const [solidColor, setSolidColor] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [updates, setUpdates] = useState([]);
  const [sendUpdateModal, setSendUpdateModal] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [editPostDetails, setEditPostDetails] = useState(null);

  useEffect(() => {
    fetchPosts();
    document.getElementById("editor").focus();
  }, []);

  useEffect(() => {
    if (selectedGroups?.length !== 0 && !editPost) {
      handleCreatePost();
    }
  }, [selectedGroups]);

  const handleSolidColorChange = (color) => {
    setSolidColor(color.hex);

    const editorElement = document.getElementById("editor");
    editorElement.style.background = color.hex;
    setBgImage("");
  };

  const fetchPosts = () => {
    dispatch(loaderActions.toggleLoading(true));
    getUpdates()
      .then((res) => setUpdates(res?.data?.data?.updates))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleEditPostIcon = (updateDetails) => {
    if (updateDetails?.backgroundImage || updateDetails?.colorCode) {
      setEditPost(true);
      setEditPostDetails(updateDetails);

      const editorElement = document.getElementById("editor");
      editorElement.innerHTML = updateDetails?.html;
      if (updateDetails?.backgroundImage) {
        editorElement.style.backgroundImage = `url(${updateDetails?.backgroundImage})`;
        editorElement.style.backgroundSize = "cover";
        editorElement.style.backgroundRepeat = "no-repeat";
        setBgImage(updateDetails?.backgroundImage);
      } else {
        editorElement.style.background = updateDetails?.colorCode;
        setSolidColor(updateDetails?.colorCode);
      }
    }
  };

  const handleCreatePost = async () => {
    if (!bgImage && !solidColor) {
      toast.error("Create a post first to update", {
        toastId: "14",
      });
      return;
    }

    const htmlToSend = document.getElementById("editor").innerHTML;
    const htmlToConvert = document.getElementById("editor");

    const options = {
      useCORS: true,
      allowTaint: true,
      logging: true,
      imageSmoothingEnabled: true,
    };

    dispatch(loaderActions.toggleLoading(true));
    html2canvas(htmlToConvert, options)
      .then((dataUrl) => dataUrl.toDataURL("image/png"))
      .then((url) => fetch(url))
      .then((r) => r.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append("media", blob);
        return uploadMedia(formData);
      })
      .then((thumbnailUrl) => {
        const postData = {
          backgroundImage: bgImage,
          html: htmlToSend,
          colorCode: solidColor,
          thumbnail: thumbnailUrl?.data?.data?.url,
          groupIds: selectedGroups,
        };
        return addUpdate(postData);
      })
      .then((res) => {
        setBgImage("");
        setSolidColor("");
        const editor = document.getElementById("editor");
        editor.innerHTML = "";
        editor.style.background = "";
        setSelectedGroups([]);
        fetchPosts();
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => dispatch(loaderActions.toggleLoading(false)));
  };

  const setFocus = () => {
    const el = document.getElementById("editor");
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  };

  const handleImageUpload = async (e) => {
    const uploadedImages = [];
    if (e?.target?.files && e?.target?.files.length !== 0) {
      for (let key in Object.keys(e.target.files)) {
        const formData = new FormData();
        formData.append("media", e.target.files[key]);
        try {
          dispatch(loaderActions.toggleLoading(true));
          const imageUrl = await uploadMedia(formData);
          dispatch(loaderActions.toggleLoading(false));
          uploadedImages.push(imageUrl?.data?.data?.url);
        } catch (error) {
          toast.error("Could not upload image");
        }
      }
      // input field so if you removed it you can re-add the same file
      e.target.value = "";
      // for appending all these images in my dom
      uploadedImages.map((image) => {
        const imgDiv = document.createElement("div");
        imgDiv.style.width = "100%";
        const imgTag = document.createElement("img");
        imgTag.setAttribute("src", image);
        imgTag.setAttribute("crossorigin", true);
        imgTag.style.objectFit = "cover";
        imgTag.style.width = "55%";
        imgDiv.appendChild(imgTag);
        document.getElementById("editor").appendChild(imgDiv);
        document.getElementById("editor").focus();
        setFocus();
      });
    }
  };

  const handleCancelButton = () => {
    setEditPost(false);
    setEditPostDetails(null);
    setSolidColor("");
    setBgImage("");
    const editorElement = document.getElementById("editor");
    editorElement.style.background = "";
    editorElement.style.backgroundImage = "";
    editorElement.innerHTML = "";
  };

  const editOldPost = () => {
    if (!bgImage && !solidColor) {
      toast.error("You cannot post empty updates", {
        toastId: "14",
      });
      return;
    }

    const htmlToSend = document.getElementById("editor").innerHTML;
    const htmlToConvert = document.getElementById("editor");

    const options = {
      useCORS: true,
      allowTaint: true,
      logging: true,
      imageSmoothingEnabled: true,
    };

    html2canvas(htmlToConvert, options)
      .then((dataUrl) => dataUrl.toDataURL("image/png"))
      .then((url) => fetch(url))
      .then((r) => r.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append("media", blob);
        return uploadMedia(formData);
      })
      .then((thumbnailUrl) => {
        const postData = {
          backgroundImage: bgImage,
          html: htmlToSend,
          colorCode: solidColor,
          thumbnail: thumbnailUrl?.data?.data?.url,
        };
        return editUpdate(editPostDetails?._id, postData);
      })
      .then((res) => {
        setBgImage("");
        setSolidColor("");
        const editor = document.getElementById("editor");
        editor.innerHTML = "";
        editor.style.background = "";
        setSelectedGroups([]);
        fetchPosts();
        setEditPost(false);
        setEditPostDetails(null);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleCreateOrEditButton = () => {
    if (!editPost) {
      setSendUpdateModal(true);
    } else {
      editOldPost();
    }
  };

  const handleVideoUpload = async (e) => {
    const uploadedVideos = [];

    if (e?.target?.files && e.target.files.length !== 0) {
      for (let key in Object.keys(e.target.files)) {
        try {
          const formData = new FormData();
          formData.append("media", e.target.files[key]);
          dispatch(loaderActions.toggleLoading(true));
          const videoUrl = await uploadMedia(formData);
          dispatch(loaderActions.toggleLoading(false));
          uploadedVideos.push(videoUrl?.data?.data?.url);
        } catch (error) {
          dispatch(loaderActions.toggleLoading(false));
          toast.error("Could not upload video");
        }
      }

      // ---------to empty the input for triggering on change again------
      e.target.value = "";

      uploadedVideos.map((video) => {
        document.getElementById("editor").focus();

        setFocus();

        // --------------&nbsp is there so it can store the cursor selection if we only upload one video with no text-----------------
        document.execCommand(
          "insertHTML",
          false,
          `&nbsp;<video width="320" height="240" crossorigin=true controls>
          <source src=${video}  type="video/mp4"/>
        </video>`
        );
      });
    }
  };

  return (
    <div className="flex-row ht-100 gap-28 wd-100 overflow-hidden">
      <div
        className={`flex-col gap-32 bg-white pd-24 ${classes.left_side}`}
        style={{ width: "40%", overflow: "auto" }}
      >
        <TableHeader
          title="Create an Update"
          firstbuttonTitle={editPost ? "Save" : "Create & Post"}
          handleFirstButton={handleCreateOrEditButton}
          secondbuttonTitle={editPost ? "Cancel" : ""}
          handleSecondButton={handleCancelButton}
        />
        <hr />
        <div style={{ width: "100%" }}>
          <div
            id="editor"
            placeholder="Write your update..."
            contentEditable="true"
            crossorigin="true"
            style={{
              minHeight: "400px",
              width: "-webkit-fill-available",
              fontSize: "18px",
              fontWeight: "700",
              color: "white",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              outline: "2px solid #40a59e",
              borderRadius: "6px",
              padding: "16px 12px",
              // background: "red",
              textAlign: "center",
            }}
          >
            {/* <p>Create your post</p>
            <img src={company_logo} alt="Company Logo" /> */}
          </div>
        </div>
        <hr />
        <div className="flex-row medium-gap">
          <label
            htmlFor="editor-image-upload"
            style={{
              padding: "10px 16px",
              background: "#F8F8F8",
              color: "#5F5F61",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "6px",
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ImageIcon />
            Images
          </label>
          <input
            type="file"
            // accept="image/png, image/jpeg"
            multiple
            id="editor-image-upload"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <label
            htmlFor="editor-video-upload"
            style={{
              padding: "10px 16px",
              background: "#F8F8F8",
              color: "#5F5F61",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "6px",
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <VideocamIcon />
            Video
          </label>
          <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            multiple
            id="editor-video-upload"
            style={{ display: "none" }}
            onChange={handleVideoUpload}
          />
          <label
            htmlFor="editor-gif-upload"
            style={{
              padding: "10px 16px",
              background: "#F8F8F8",
              color: "#5F5F61",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "6px",
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <GifBoxIcon />
            GIF
          </label>
          <input
            type="file"
            accept="image/gif"
            multiple
            id="editor-gif-upload"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          {/* <p>Gif</p> */}
        </div>
        <hr />
        <div className="flex-row wd-100">
          <div
            className={`bg-base flex-row small-gap ${
              backgroundType === "image" ? classes.active_background : ""
            } bord-right-gray pointer justify-center align-center text-center pd-16`}
            style={{ width: "50%" }}
            onClick={(e) => {
              setBackgroundType("image");
            }}
          >
            <CollectionsIcon />
            Background
          </div>
          <div
            className={`bg-base flex-row small-gap ${
              backgroundType === "color" ? classes.active_background : ""
            } pointer justify-center align-center text-center pd-16`}
            style={{ width: "50%" }}
            onClick={(e) => setBackgroundType("color")}
          >
            <FormatColorFillIcon />
            Color
          </div>
        </div>
        {backgroundType === "image" ? (
          <div className="flex-wrap medium-gap">
            {bgImageUrls.map((image) => (
              <img
                key={image}
                src={image}
                className="pointer"
                style={{
                  height: "100px",
                  width: "100px",
                  objectFit: "cover",
                  borderRadius: "6px",
                }}
                onClick={(e) => {
                  const editorElement = document.getElementById("editor");
                  editorElement.style.backgroundImage = `url(${e?.target?.src})`;
                  editorElement.style.backgroundSize = "cover";
                  editorElement.style.backgroundRepeat = "no-repeat";

                  setSolidColor("");
                  setBgImage(e?.target?.src);
                }}
              />
            ))}
          </div>
        ) : (
          <SketchPicker
            color={solidColor}
            onChange={(e) => handleSolidColorChange(e)}
            width="100"
            disableAlpha
          />
        )}
      </div>
      <div
        className={`bg-white ht-100 pd-24 overflow-auto flex-col ${classes.right_side}`}
        style={{ width: "60%", gap: "40px" }}
      >
        {updates.length === 0 && (
          <div className="flex-col align-center justify-center ht-100">
            <DynamicFeedIcon style={{ fontSize: "175px", color: "#d0f2f0" }} />
            <p style={{ color: "#a1a0a3" }}>
              No updates have been added. Try posting one from the side panel.
            </p>
          </div>
        )}
        {updates?.map((update, ind) => (
          <div className={`flex-col large-gap wd-100 ${classes.createdUpdate}`}>
            <div className="flex-row just-sp-bwn">
              <div className="flex-row medium-gap align-center">
                <Avatar src={update?.createdByDetail?.profileImage} />
                <div className="flex-col">
                <h3 className={classes.update_name}>
                  {update?.createdByDetail?.fullName}
                </h3>
                <p className={classes.update_date}>{moment(update?.createdAt).format("lll")}</p></div>
              </div>
              {update?.createdByDetail?._id === user?.id && (
                <div
                  className="pointer"
                  onClick={() => handleEditPostIcon(update)}
                >
                  <EditIcon />
                </div>
              )}
            </div>
            <div
              // id={`udpate-${ind}`}
              style={{
                background: update.backgroundImage
                  ? `url(${update.backgroundImage})`
                  : update?.colorCode,
                display: "flex",
                flexDirection: "column",
                minHeight: "500px",
                borderRadius: "8px",
                alignItems: "center",
                textAlign: "center",
                padding: "24px",
                color: "white",
                fontSize: "18px",
                fontWeight: "700",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              dangerouslySetInnerHTML={{ __html: update?.html }}
            ></div>
          </div>
        ))}
      </div>
      <GenericModal
        title="Send your Updates"
        caption="Please select the groups to send out the updates"
        content={
          <SendUpdate
            onCancel={() => setSendUpdateModal(false)}
            handleSelectedGroups={(groups) => setSelectedGroups(groups)}
          />
        }
        open={sendUpdateModal}
        onClose={() => setSendUpdateModal(false)}
      ></GenericModal>
    </div>
  );
};

export default Updates;
