import Joi from "joi";

export const PMformValidationSchema = Joi.object({
  fullName: Joi.string()
    .max(30)
    .pattern(/^[A-Za-z ]+$/)
    .required()
    .label("Property Manager name")
    .messages({
      "string.empty": "Property Manager name is not allowed to be empty",
      "string.pattern.base":
        "Please enter a valid name for the property manager",
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } }) // Disallow TLDs like .com, .net, etc.
    .required()
    .label("Email"),
  mobileNumber: Joi.number()
    .integer()
    .required()
    .label("Mobile Number")
    .error(new Error("Mobile number cannot be empty")),
  address: Joi.string().required().label("Address"),
  city: Joi.string()
    // .pattern(/^[A-Za-z]+$/)
    .required()
    .label("City")
    .error(new Error("Please fill valid city")),
  state: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("State")
    .error(new Error("Please fill valid state")),
  postalCode: Joi.number()
    .integer()
    .required()
    .max(9999999999)
    .label("Postal Code"),
  country: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("Country")
    .error(new Error("Please fill valid country")),
  countryCode: Joi.string().required().label("Country Code"),
  description: Joi.string().required().max(60).label("Description"),
  isEditable: Joi.required(),
  isDiscountGiven: Joi.boolean().required(),
  discounts: Joi.array()
    .items({
      serviceId: Joi.string().required().error(new Error("Please select a service")),
      discount: Joi.number().min(1).required().error(new Error("Please enter a discount amount")),
    })
    .empty(Joi.array().length(0)),
});
