import React from "react";
import classes from "./LoginRightScreen.module.css";
import company_logo from "../../../assets/Next_Generation_Logo.png";
import model from "../../../assets/cleaning_model.png";
import { Grid } from "@mui/material";

const LoginRightScreen = () => {
  return (
    <Grid xs={12} md={6} className="bg-green">
      <div className={`flex-col color-white ${classes.right_content}`}>
        <div className={`flex-col ${classes.company_slogan}`}>
          <img src={company_logo} alt="Company Logo" />
          <h1 className="font-40">
            Our cleaning service keeps your
            <br />
            space looking its best.
          </h1>
        </div>
        <div className={classes.cleaning_model}>
          <img src={model} alt="Cleaning Model" />
        </div>
      </div>
    </Grid>
  );
};

export default LoginRightScreen;
