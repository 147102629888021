import API from "./apiConstants";
import instance from "./axiosConfig";

export async function wrappedFetch(url, method, data, headers) {
  let config = {
    url,
    method,
    headers: {},
    data,
  };
  if (headers) config.headers = headers;
  try {
    let x = await instance(config);
    return x;
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.removeItem(API.AUTH_TOKEN);
      window.location.reload();
    }
    throw new Error(err?.response?.data?.message);
  }
}
export async function wrappedGet(url, method, params) {
  let config = {
    url,
    method,
    headers: {},
    params,
  };

  try {
    return await instance(config);
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.removeItem(API.AUTH_TOKEN);
      window.location.reload();
    }
    throw new Error(err?.response?.data?.message);
  }
}
