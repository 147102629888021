import { Box } from "@mui/material";
import React, { useState } from "react";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { useEffect } from "react";
import { getAllMarketTemplates } from "../../../../api/api_calls/data";
import moment from "moment";
import { toast } from "react-toastify";
import SearchInput from "../../../../components/common/commonComponents/SearchInput/SearchInput";

const AddTemplate = ({
  onCancel,
  handleTemplate = () => {},
  handleModal,
  groupId,
  marketId,
}) => {
  const [jobDetails, setJobDetails] = useState([]);
  const [search, setSearch] = useState("");

  const handleModalOpen = () => {
    handleModal();
    onCancel();
  };
  const handleModalUpdate = (data) => {
    onCancel();
    handleTemplate(data);
  };
  useEffect(() => {
    getAllMarketTemplates(marketId, groupId, search)
      .then((res) => {
        setJobDetails(res?.data?.data);
      })
      .catch((error) => toast.error(error?.message));
  }, [search]);
  return (
    <div className="gap-40 flex-col" style={{ width: "94%" }}>
      <div className="flex-row align-center wd-100 just-sp-bwn">
        <label>Select Template</label>
        <SearchInput
          placeholder="Search Template"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {jobDetails.length > 0 ? (
        <div className="flex-col  medium-gap">
          {jobDetails?.map((data, index) => (
            <Box
              key={`Job_${index}`}
              className="flex-row icon-padding just-sp-bwn bord-rd-6 light-border "
            >
              <div key={`jobId_${index}`} className="flex-col  justify-center ">
                <p
                  style={{
                    color: " #5F5F61",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                >
                  {`${moment(
                    new Date(data?.jobTiming?.startTime).toLocaleString(
                      "en-us",
                      { timeZone: data?.timezone }
                    )
                  ).format("hh:mm A")} - ${moment(
                    new Date(data?.jobTiming?.endTime).toLocaleString("en-us", {
                      timeZone: data?.timezone,
                    })
                  ).format("hh:mm A")}`}
                </p>
                <p
                  style={{
                    color: " #5F5F61",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                  }}
                >
                  {`${data?.serviceData?.name}`}
                </p>
              </div>
              <Button
                title={"Select"}
                background="white"
                color={"#40A59E"}
                border={"#40A59E"}
                handleFunction={(e) => handleModalUpdate(data)}
              />
            </Box>
          ))}
        </div>
      ) : (
        <div>
          <p className="flex-row justify-center subtle-label">
            No Templates Found For This Group{" "}
          </p>
        </div>
      )}
      <div className="flex-row gap-40 justify-center">
        <Button title={"Add New"} handleFunction={handleModalOpen} />
      </div>
    </div>
  );
};

export default AddTemplate;
