const groupTags = [
  {
    name: "Cleaning",
    background: "#F7F6FF",
    color: "#9162FA",
  },
  {
    name: "Plumbing",
    background: "#FCF1F7",
    color: "#E64593",
  },
  {
    name: "Accepted",
    background: "#E8F6ED",
    color: "#1DA94A",
  },
  {
    name: "Rejected",
    background: "#FFF5E5",
    color: "#E49614",
  },
  {
    name: "Pending",
    background: "#FFF5E5",
    color: "#E49614",
  },
  {
    name: "Active",
    background: "#E8F6ED",
    color: "#1DA94A",
  },
  {
    name: "Inactive",
    background: "#FCEAEA",
    color: "#E73F3F",
  },
  {
    name: "Dashboard",
    background: "#F8F8F8",
    color: "#5F5F61",
  },
  {
    name: "Upcoming",
    background: "#FFF5E5",
    color: "#F4A118",
  },
  {
    name: "Ongoing",
    background: "#F8F8F8",
    color: "#5F5F61",
  },
  {
    name: "Completed",
    background: "#E8F6ED",
    color: "#1DA94A",
  },
  { name: "Deleted", background: "#FCEAEA", color: "#E73F3F" },
  {
    name: true,
    background: "#FCEAEA",
    color: "#E73F3F",
  },
];

export default groupTags;
