import { createTheme } from "@mui/material";
export const customStylesReactSelect = {
  control: (base, state) => ({
    ...base,
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
    background: "#f8f8f8",
    borderColor: state.isFocused ? "#d4d4d4" : "#d4d4d4",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "black" : "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#40a59e" : null,
    color: state.isFocused ? "white" : null,
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      display: "none",
    },
    "::-webkit-scrollbar-track": {
      display: "none",
    },
    "::-webkit-scrollbar-thumb": {
      display: "none",
    },
    "::-webkit-scrollbar-thumb:hover": {
      display: "none",
    },
  }),
};

export const customStylesReactAsyncPaginate = {
  control: (base, state) => ({
    ...base,
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
    background: "#f8f8f8",
    height: "38px",
    borderColor: state.isFocused ? "#d4d4d4" : "#d4d4d4",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "black" : "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#40a59e" : null,
    color: state.isFocused ? "white" : null,
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      display: "none",
    },
    "::-webkit-scrollbar-track": {
      display: "none",
    },
    "::-webkit-scrollbar-thumb": {
      display: "none",
    },
    "::-webkit-scrollbar-thumb:hover": {
      display: "none",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginBottom: "13px",
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    height: "20px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    marginBottom: "13px",
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    marginBottom: "13px",
    fontWeight: state.isFocused ? "400" : "400",
    fontSize: state.isFocused ? "14px" : "14px",
  }),
};
export const paginationTheme = createTheme({
  palette: {
    primary: {
      main: "#40A59E",
      contrastText: "#fff",
    },
  },
});

export const capitalize = (words) => {
  if (!words) return "";

  const wordsArray = words?.split(" ");
  const updatedArray = wordsArray.map(
    (word) => word && word?.[0]?.toUpperCase() + word?.substring(1)
  );

  const validWords = updatedArray.filter((word) => Boolean(word));

  return validWords.join(" ");
};
