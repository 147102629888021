import React from "react";
import classes from "./Button.module.css";

const Button = ({
  title,
  type,
  color,
  background,
  height,
  handleFunction,
  icon,
  value,
  border,
  theme,
  disabled,
  width,
}) => {
  return (
    <button
      type={type}
      value={value}
      onClick={handleFunction}
      style={{
        background: theme ? "#F8F8F8" : background,
        color: theme ? "#5F5F61" : color,
        borderRadius: "6px",
        height,
        width,
        border: `1px solid ${border}`,
      }}
      className={
        border
          ? `${classes.hover_grey} ${classes.customButton}`
          : disabled
          ? classes.disable
          : classes.customButton
      }
      disabled={disabled}
    >
      {icon && icon}
      {title && title}
    </button>
  );
};

export default Button;
