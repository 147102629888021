import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import dayjs from "dayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import classes from "../../../../components/common/commonComponents/FormField/FormField.module.css";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import zipcode from "zipcodes";
import { convertDate, convertToUTC } from "../../../../utils/HelperFunctions";
import { toast } from "react-toastify";
import {
  getPropertiesOfPM,
  getPropertyManager,
  getService,
} from "../../../../api/api_calls/data";
import {
  customStylesReactSelect,
  customStylesReactAsyncPaginate,
} from "../../../../utils/HelperFuncitons";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { AddJobValidation } from "../../../../validations/addJobValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { addJob, editJob } from "../../../../api/api_calls/addData";
import moment from "moment";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro";
import PhoneNumberInput from "../../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
import { createJobForUnregistered } from "../../../../api/api_calls/schedule";
import { error } from "jquery";

export const AddJob = ({
  modalType,
  type,
  onCancel,
  tempData,
  marketId,
  groupDetails,
  handleGetAllGroupJobs,
}) => {
  let offset = new Date().getTimezoneOffset() / 60;

  const timeZone = {
    CST: 6,
    PST: 8,
    EST: 5,
  };

  const currDate = dayjs(tempData?.startDate || tempData?.bookingDate || "");
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const [formData, setFormData] = useState({
    timezone: tempData?.timezone || "EST",
    forRegisteredUser:
      tempData?.forRegisteredUser !== undefined
        ? tempData?.forRegisteredUser
        : true,
    //jobName: "",
    date: tempData?.jobTiming
      ? new Date(tempData?.jobTiming?.startTime).toLocaleString("en-us", {
          timeZone: tempData?.timezone,
        })
      : new Date(currDate),
    startTime: tempData?.jobTiming
      ? new Date(tempData?.jobTiming?.startTime).toLocaleString("en-us", {
          timeZone: tempData?.timezone,
        })
      : new Date(new Date(currDate["$d"]).setHours(8, 0, 0, 0)),
    endTime: tempData?.jobTiming
      ? new Date(tempData?.jobTiming?.endTime).toLocaleString("en-us", {
          timeZone: tempData?.timezone,
        })
      : new Date(new Date(currDate["$d"]).setHours(17, 0, 0, 0)),
    serviceCategory: groupDetails?.categoryData
      ? groupDetails?.categoryData
      : "",
    service: tempData?.serviceData ? tempData?.serviceData : "",
    propertyManager: tempData?.requestedByDetail
      ? tempData?.requestedByDetail
      : null,
    property: tempData ? tempData?.propertyDetail : null,
    groupName: groupDetails ? groupDetails : "",
    staffMember: tempData?.assignedStaffMembersDetail
      ? tempData?.assignedStaffMembersDetail
      : tempData?.assignedStaff
      ? tempData?.assignedStaff
      : "",
    address: tempData ? tempData?.address : "",
    city: tempData ? tempData?.city : "",
    state: tempData ? tempData?.state : "",
    country: tempData ? tempData?.country : "",
    zipCode: tempData ? tempData?.postalCode : "",
    addTask: tempData ? tempData?.task : "",
    isTemplate: false,
    amount: tempData ? tempData?.serviceData?.price : "",

    // for unregistered user
    fullName: tempData?.fullName || "",
    countryCode: tempData?.countryCode || "",
    phoneNumber: tempData?.mobileNumber || "",
  });

  const [errors, setErrors] = useState("");
  const [properties, setProperties] = useState([]);
  const [jobs, setJobs] = useState([]);

  let showPropertyManagerFields = formData?.forRegisteredUser;

  if (tempData?.requestedByDetail && tempData?.requestedByDetail?.role !== 1) {
    showPropertyManagerFields = false;
  }

  const staffs = groupDetails?.groupMemberData
    ? groupDetails?.groupMemberData
    : [];
  const serviceValues = (data) => {
    return data?.map((item) => {
      return {
        _id: item?._id,
        name: `${item?.name} ($${item?.price})`,
        price: item?.price,
      };
    });
  };

  const clientOptions = [
    { label: "Registered", value: true },
    { label: "Unregistered", value: false },
  ];

  const timeZoneOptions = [
    { label: "EST", value: "EST" },
    { label: "CST", value: "CST" },
    { label: "PST", value: "PST" },
  ];

  // function convertToUTC(date, timeZone) {
  //   if (typeof date === "string") {
  //     return new Date(
  //       new Date(date).toLocaleString("en-US", {
  //         timeZone,
  //       })
  //     );
  //   }

  //   return new Date(
  //     date.toLocaleString("en-US", {
  //       timeZone,
  //     })
  //   );
  // }

  const fetchPropertManager = async (searchQuery, loadOptions, { page }) => {
    try {
      const res = await getPropertyManager((page - 1) * 10);
      return {
        options: res?.data?.data?.allPropertiesManager,
        hasMore: res?.data?.data?.allPropertiesManager.length >= 1,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {}
  };
  const autoFill = (val) => {
    setFormData((currentState) => {
      return { ...currentState, [val.target.name]: val.target.value };
    });
    if (val.target.value !== "") {
      const z = zipcode.lookup(val.target.value);
      setFormData((v) => ({
        ...v,
        city: z?.city,
        state: z?.state,
        country: z?.country,
      }));
    } else {
      setFormData((v) => ({
        ...v,
        city: "",
        state: "",
        country: "",
      }));
    }
  };
  const handleFormChange = (name, value, e) => {
    if (e?.target?.name === "zipCode") {
      return autoFill(e);
    } else {
      setFormData((currentState) => ({
        ...currentState,
        [name]: value,
      }));
    }
  };

  const updateJob = () => {
    let jobDate = new Date(formData?.date);
    jobDate.setTime(
      jobDate.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    const dateMoment = moment(formData?.date);
    const startTimeMoment = moment(formData?.startTime);
    const combinedDateTime = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: startTimeMoment.second(),
      millisecond: startTimeMoment.millisecond(),
    });
    const endTimeMoment = moment(formData?.endTime);
    const endTimeForJob = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond(),
    });

    let startTime = new Date(combinedDateTime);

    startTime.setTime(
      startTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    let endTime = new Date(endTimeForJob);
    endTime.setTime(
      endTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    const postForm = {
      timezone: formData?.timezone,
      jobId: tempData?.jobId,
      requestedBy: formData?.propertyManager?._id
        ? formData?.propertyManager?._id
        : formData?.propertyManager,
      propertyId: formData?.property?._id
        ? formData?.property?._id
        : formData?.property,
      //jobName: formData?.jobName,
      // jobDate: convertToUTC(formData?.date),
      jobDate: startTime,
      jobTiming: {
        // startTime: convertToUTC(combinedDateTime.toISOString()),
        // endTime: convertToUTC(endTimeForJob.toISOString()),
        startTime,
        endTime,
      },
      categoryId: formData?.serviceCategory?._id,
      serviceId: formData?.service?._id
        ? formData?.service?._id
        : formData?.service,
      amount: formData?.amount,
      address: formData?.address,
      city: formData?.city,
      state: formData?.state,
      country: formData?.country,
      postalCode: formData?.zipCode,
      groupTagID: formData?.groupName?._id,
      assignedStaffMembers: formData?.staffMember?._id
      ? [formData?.staffMember?._id]
      : formData?.staffMember
      ? [formData.staffMember]
      : [tempData?.assignedStaff],
      isTemplate: formData?.isTemplate,
      task: formData?.addTask,
      fullName: formData?.fullName,
      countryCode: formData?.countryCode,
      mobileNumber: formData?.phoneNumber,
    };

    if (!formData?.forRegisteredUser) {
    }

    delete postForm?.fullName;
    delete postForm?.countryCode;
    delete postForm?.mobileNumber;

    dispatch(loaderActions.toggleLoading(true));
    editJob(postForm)
      .then((res) => {
        toast.success(res?.data?.message, {
          toastId: "job updated in scheduler",
        });
        onCancel();
        handleGetAllGroupJobs(groupDetails?._id);
      })
      .catch((error) =>
        toast.error(error?.message, {
          toastId: "job failed to update in scheduler",
        })
      )
      .finally(() => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (showPropertyManagerFields) {
      if (!formData?.propertyManager || !formData?.property) {
        setErrors("Please select Property Manager and Property");
        return;
      }
    } else {
      if (!formData?.fullName || !formData?.phoneNumber) {
        setErrors("Please enter Full Name and Mobile Number");
        return;
      }
    }

    const validData = AddJobValidation.validate(formData);

    if (validData?.error?.message) {
      setErrors(validData?.error?.message);
      return;
    }

    if (modalType === "edit") {
      updateJob();
      return;
    }

    let jobDate = new Date(formData?.date);
    jobDate.setTime(
      jobDate.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    const dateMoment = moment(formData?.date);
    const startTimeMoment = moment(formData?.startTime);
    const combinedDateTime = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: startTimeMoment.second(),
      millisecond: startTimeMoment.millisecond(),
    });
    const endTimeMoment = moment(formData?.endTime);
    const endTimeForJob = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond(),
    });

    let startTime = new Date(combinedDateTime);

    startTime.setTime(
      startTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    let endTime = new Date(endTimeForJob);
    endTime.setTime(
      endTime.getTime() -
        (offset - timeZone?.[formData.timezone]) * 60 * 60 * 1000
    );

    const postForm = {
      timezone: formData?.timezone,
      localJobDate: moment(combinedDateTime).format("L"),
      localStartTime: moment(combinedDateTime).format("LT"),
      marketId: marketId,
      requestedBy: formData?.propertyManager?._id
        ? formData?.propertyManager?._id
        : formData?.propertyManager,
      propertyId: formData?.property?._id
        ? formData?.property?._id
        : formData?.property,
      //jobName: formData?.jobName,
      // jobDate: convertToUTC(formData?.date),
      jobDate: startTime,
      jobTiming: {
        // startTime: convertToUTC(combinedDateTime.toISOString()),
        // endTime: convertToUTC(endTimeForJob.toISOString()),
        startTime,
        endTime,
      },
      categoryId: formData?.serviceCategory?._id,
      serviceId: formData?.service?._id
        ? formData?.service?._id
        : formData?.service,
      amount: formData?.amount,
      address: formData?.address,
      city: formData?.city,
      state: formData?.state,
      country: formData?.country,
      postalCode: formData?.zipCode,
      groupTagID: formData?.groupName?._id,
      assignedStaffMembers: formData?.staffMember?._id
        ? [formData?.staffMember?._id]
        : formData?.staffMember
        ? [formData.staffMember]
        : [tempData?.assignedStaff],
      isTemplate: formData?.isTemplate,
      task: formData?.addTask,
      adminRole: role,
      fullName: formData?.fullName,
      countryCode: formData?.countryCode,
      mobileNumber: formData?.phoneNumber,
    };

    if (!formData?.forRegisteredUser) {
      delete postForm?.requestedBy;
      delete postForm?.propertyId;
      dispatch(loaderActions.toggleLoading(true));
      createJobForUnregistered(postForm)
        .then((res) => {
          // window.location.reload();
          onCancel();
          handleGetAllGroupJobs(groupDetails?._id || formData?.groupName?._id);
        })
        .catch((error) =>
          toast.error(error?.message, {
            toastId: "error in creating job for unregistered user",
          })
        )
        .finally(() => dispatch(loaderActions.toggleLoading(false)));
      return;
    }

    delete postForm?.fullName;
    delete postForm?.countryCode;
    delete postForm?.mobileNumber;

    dispatch(loaderActions.toggleLoading(true));
    addJob(postForm)
      .then((res) => {
        // window.location.reload();
        onCancel();
        handleGetAllGroupJobs(groupDetails?._id || formData?.groupName?._id);
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "3500",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const fetchPropertiesOfPM = (PMId) => {
    dispatch(loaderActions.toggleLoading(true));
    getPropertiesOfPM(PMId)
      .then((res) => {
        setProperties(res?.data?.data?.properties);
        setFormData((currentState) => ({
          ...currentState,
          property: "",
        }));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  useEffect(() => {
    getService(groupDetails?.categoryData?._id)
      .then((res) => {
        setJobs(res?.data?.data?.services);
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "pogfi",
        });
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);

  return (
    <form
      onSubmit={handleSubmit}
      className="gap-40 flex-col"
      style={{ width: "94%" }}
    >
      <Grid container rowSpacing={5} columnSpacing={3}>
        {/* <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Job Name</label>
            <input
              className={`${classes.formFieldTwo} flex-col small-gap`}
              name="jobName"
              value={formData?.jobName}
              placeholder="Enter Job Name"
              maxLength={30}
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.value)
              }
            />
          </div>
        </Grid> */}
        {/* group name */}
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Timezone</label>
            <Select
              name="timezone"
              styles={customStylesReactSelect}
              options={timeZoneOptions}
              value={timeZoneOptions.filter(
                (timeZone) => timeZone?.value === formData?.timezone
              )}
              onChange={(e) =>
                setFormData((currState) => {
                  return { ...currState, timezone: e?.value };
                })
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Client Type</label>
            <Select
              name="clientType"
              styles={customStylesReactSelect}
              // defaultValue={{ label: "Registered", value: "registered" }}
              // defaultValue={formData?.clientType}
              isDisabled={
                tempData?.forRegisteredUser !== undefined ? true : false
              }
              value={
                clientOptions.filter(
                  (option) => option?.value === formData?.forRegisteredUser
                )[0]
              }
              options={clientOptions}
              onChange={(e) => {
                setFormData((currState) => {
                  return { ...currState, forRegisteredUser: e?.value };
                });
                if (!e?.value)
                  setFormData((currState) => {
                    return { ...currState, propertyManager: "", property: "" };
                  });
                else
                  setFormData((currState) => {
                    return {
                      ...currState,
                      fullName: "",
                      countryCode: "",
                      phoneNumber: "",
                    };
                  });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Group Name</label>
            <Select
              name="groupName"
              styles={customStylesReactSelect}
              defaultValue={{
                value: formData?.groupName?._id,
                label: formData?.groupName?.groupName,
              }}
              isDisabled
            />
          </div>
        </Grid>
        {/* service category */}
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Service Category</label>
            <Select
              name="serviceCategory"
              placeholder="Select Category"
              styles={customStylesReactSelect}
              defaultValue={{
                value: formData?.serviceCategory?._id,
                label: formData?.serviceCategory?.categoryName,
              }}
              isDisabled
            />
          </div>
        </Grid>

        {/* service */}
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Job Name</label>
            <Select
              name="service"
              placeholder="Select Job"
              styles={customStylesReactSelect}
              defaultValue={
                tempData?.serviceData
                  ? {
                      _id: formData?.service?._id,
                      name: `${formData?.service?.name} $${formData?.service?.price}`,
                    }
                  : ""
              }
              options={serviceValues(jobs)}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option._id}
              onChange={(e) =>
                setFormData((currState) => {
                  return { ...currState, service: e?._id, amount: e?.price };
                })
              }

              // isDisabled
            />
          </div>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: 2, marginTop: 0 }}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Select Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={classes.formFieldTwo}
                label=""
                defaultValue={currDate}
                disablePast
                onChange={(newValue) => {
                  if (!newValue) return;
                  setFormData((currState) => {
                    return {
                      ...currState,
                      date: newValue["$d"],
                      startTime: new Date(
                        new Date(newValue["$d"]).setHours(8, 0, 0, 0)
                      ),
                      endTime: new Date(
                        new Date(newValue["$d"]).setHours(17, 0, 0, 0)
                      ),
                    };
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid>

        {/*Start time */}
        <Grid item xs={6} sx={{ paddingLeft: 2, marginTop: 0 }}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Select Time</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SingleInputTimeRangeField
                value={[
                  dayjs(formData?.startTime),
                  dayjs(formData?.endTime) || "",
                ]}
                onChange={(e) => {
                  setFormData((currState) => {
                    return {
                      ...currState,
                      startTime: moment(e[0]?.["$d"])
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                      endTime: moment(e[1]?.["$d"])
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    };
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid>
        {/*End time */}
        {/* <Grid item xs={6} sx={{ paddingLeft: 2, marginTop: 0 }}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>End Time</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker

                // defaultValue={tempData ? formData?.endTime : ""}
                onChange={(e) => {
                  setFormData((currState) => {
                    return {
                      ...currState,

                      endTime: e?.["$d"],
                    };
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid> */}

        {/* staff member */}
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Staff Member</label>
            <Select
              name="staffMember"
              styles={customStylesReactSelect}
              placeholder="Select Staff"
              // isDisabled={tempData?.assignedStaff ? true : false}
              options={staffs}
              defaultValue={
                // tempData
                //   ? {
                //       _id: formData?.staffMember?._id,
                //       fullName: formData?.staffMember?.fullName,
                //     }
                //   : ""
                staffs.filter(
                  (staff) =>
                    staff?._id === tempData?.assignedStaff ||
                    staff?._id === tempData?.assignedStaffMembers?.[0]
                )?.[0] || ""
              }
              getOptionLabel={(option) => option.fullName}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setFormData((currState) => {
                  return { ...currState, staffMember: e?._id };
                });
              }}
            />
          </div>
        </Grid>
        {/* property manager */}
        {showPropertyManagerFields ? (
          <>
            <Grid item xs={6}>
              <div className={`${classes.formField} flex-col small-gap`}>
                <label>Property Manager</label>

                <AsyncPaginate
                  isSearchable={false}
                  styles={customStylesReactAsyncPaginate}
                  debounceTimeout={500}
                  name="propertyManager"
                  placeholder="Type or Select"
                  loadOptions={fetchPropertManager}
                  defaultValue={
                    formData?.propertyManager
                      ? {
                          _id: formData?.propertyManager?._id,
                          fullName: formData?.propertyManager?.fullName,
                        }
                      : ""
                  }
                  getOptionValue={(option) => option._id}
                  getOptionLabel={(option) => option.fullName}
                  onChange={(e) => {
                    setFormData((currState) => {
                      return {
                        ...currState,
                        propertyManager: e?._id,
                        property: "",
                      };
                    });
                    fetchPropertiesOfPM(e?._id);
                    setProperties([]);
                  }}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
            </Grid>

            {/* properties of property manager */}

            <Grid item xs={6}>
              <div className={`${classes.formField} flex-col small-gap`}>
                <label>Property</label>
                <Select
                  key={properties && formData?.propertyManager}
                  name="property"
                  placeholder="Select Property"
                  styles={customStylesReactSelect}
                  options={properties}
                  defaultValue={
                    formData?.property
                      ? {
                          _id: formData?.property?._id,
                          propertyName: formData?.property?.propertyName,
                        }
                      : ""
                  }
                  getOptionLabel={(option) => option.propertyName}
                  getOptionValue={(option) => option._id}
                  onChange={(e) =>
                    setFormData((currState) => {
                      return { ...currState, property: e?._id };
                    })
                  }
                />
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <div className={`${classes.formField} flex-col small-gap`}>
                <label>Full Name</label>
                <input
                  name="fullName"
                  className={`${classes.formFieldTwo} flex-col small-gap`}
                  value={formData?.fullName}
                  disabled={
                    tempData?.requestedByDetail &&
                    tempData?.requestedByDetail?.role !== 1
                  }
                  placeholder="Enter Full name"
                  onChange={(e) =>
                    handleFormChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={`${classes.formField} flex-col small-gap`}>
                <label>Mobile Number</label>
                <PhoneNumberInput
                  disabled={
                    tempData?.requestedByDetail &&
                    tempData?.requestedByDetail?.role !== 1
                  }
                  value={formData?.countryCode + formData?.phoneNumber}
                  props={{ setFormData }}
                  modalType="property"
                />
              </div>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Address</label>
            <input
              name="address"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.address}
              placeholder="Enter Address"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Zip Code</label>
            <input
              name="zipCode"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.zipCode}
              placeholder="Enter Zip Code"
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.value, e)
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>City</label>
            <input
              name="city"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.city}
              placeholder="Enter City"
              onChange={(e) => {
                handleFormChange(e?.target?.name, e?.target?.value);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>State</label>
            <input
              name="state"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.state}
              placeholder="Enter State"
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.value)
              }
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Country</label>
            <input
              name="country"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.country}
              placeholder="Enter Country"
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.value)
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Add Task</label>
            <textarea
              name="addTask"
              className={`${classes.formFieldTwo} flex-col small-gap`}
              value={formData?.addTask}
              placeholder="Enter Task"
              maxLength={300}
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.value)
              }
            />
          </div>
        </Grid>

        {formData?.forRegisteredUser && (
          <div className={`${classes.formField} flex-col pd-24`}>
            <FormControlLabel
              name="isTemplate"
              //className=""
              sx={{
                ".MuiFormControlLabel-label": {
                  fontFamily: "Roboto Slab !important",
                },
              }}
              // value={item?.value}
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#40A59E",
                    },
                    color: "#DBDBDB",
                  }}
                />
              }
              label="Save it as a Template"
              onChange={(e) =>
                handleFormChange(e?.target?.name, e?.target?.checked)
              }
            />
          </div>
        )}
      </Grid>
      {errors && <Error title={errors} />}
      <Box
        className="flex-row justify-center medium-gap"
        sx={{ textAlign: "center" }}
      >
        <Button title="Cancel" theme="grey" handleFunction={onCancel} />
        <Button
          title={modalType === "edit" ? "Save" : "Add Job"}
          type={"submit"}
        />
      </Box>
    </form>
  );
};
