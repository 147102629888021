import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { GenericModal } from "../../../components";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import classes from "../../SuperAdmin/CategoryManagement/CategoryManagement.module.css";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import BookService from "./BookService/BookService";
import { getServiceList } from "../../../api/api_calls/data";
import { loaderActions } from "../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { ThemeProvider } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewService from "./BookService/ViewService/ViewService";
import { getDateRange } from "../../../utils/HelperFunctions";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import BookingSuccessfull from "./BookService/BookingSuccessfull/BookingSuccessfull";
import { toast } from "react-toastify";
const headings = [
  { heading: "Property Name", key: "propertyName" },
  { heading: "Service Category", key: "categoryData", type: "serviceCategory" },
  { heading: "Name of the service", key: "serviceName" },
  {
    heading: "Service date and time",
    key: "jobTiming",
    type: "serviceTimings",
  },
  { heading: "Address of property/area", key: "address" },
  { heading: "Service status", key: "bookingStatus", key2: "jobDiscarded" },
  { heading: "Action", key: "icon" },
];
const bookingDates = [
  {
    value: "allTime",
    title: "All Time",
  },
  {
    value: "lastWeek",
    title: "Last Week",
  },
  {
    value: "lastMonth",
    title: "Last Month",
  },
  {
    value: "lastYear",
    title: "Last Year",
  },
  {
    value: "customDateRange",
    title: "Custom Date Range",
  },
];
const status = [
  {
    value: "All",
    title: "All Status",
  },
  {
    value: "Upcoming",
    title: "Upcoming",
  },
  {
    value: "Completed",
    title: "Completed",
  },
  {
    value: "Ongoing",
    title: "Ongoing",
  },
];

const BookingManagement = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [bookingTime, setBookingTime] = useState("allTime");
  const [bookingList, setBookingList] = useState({ data: [] });
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [bookingListParams, setBookingListParams] = useState({
    status: "",
    startDate: "",
    endDate: "",
    skip: (page - 1) * rows,
    limit: rows,
  });
  const [statusFilter, setStatusFilter] = useState("All");
  const [open, setOpen] = useState(false);
  const [completionModal, setCompletionModal] = useState(
    state?.modalOpen || false
  );

  const [modalType, setModalType] = useState(null);
  const [customRange, setCustomRange] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    navigate(".", { replace: true });
  }, [navigate]);

  const fetchData = () => {
    setCustomRange(false);

    getServiceList(
      bookingListParams?.startDate,
      bookingListParams?.endDate,
      bookingListParams?.status,
      bookingListParams?.skip,
      bookingListParams?.limit
    )
      .then((res) => {
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
        setBookingList((v) => ({
          ...v,
          data: res?.data?.data?.bookings,
        }));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => {
        dispatch(loaderActions.toggleLoading(false));
      });
  };

  const handleModal = () => {
    setOpen(!open);
    setModalType("");
  };
  const handleBookingTime = (range) => {
    setBookingTime(range);

    if (range === "customDateRange") {
      setCustomRange(true);
    } else {
      const dateRange = getDateRange(range);
      setCustomRange(false);
      setBookingListParams((v) => ({
        ...v,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      }));
    }
  };
  const handleDateRange = (range) => {
    setBookingListParams((v) => ({
      ...v,
      startDate: range?.startDate,
      endDate: range?.endDate,
    }));
  };
  const handleStatus = (range) => {
    if (range === "All") {
      setBookingListParams((v) => ({ ...v, status: "" }));
    } else {
      setBookingListParams((v) => ({ ...v, status: range }));
    }
    setStatusFilter(range);
  };
  const handleVisibilityModal = (data) => {
    setVisibilityModalOpen(!visibilityModalOpen);
    setViewData(data);
    setModalType("view");
    setOpen(false);
    // setUpdateModalOpen(false);
  };
  const addIconsToUserData = (data) => {
    return data?.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={(e) => handleVisibilityModal(item)}>
            <VisibilityIcon />
          </div>
        </div>
      ),
      jobTiming: {
        startTime: new Date(item?.jobTiming?.startTime).toLocaleString("en-us", {timeZone: item?.timezone}),
        endTime: new Date(item?.jobTiming?.endTime).toLocaleString("en-us", {timeZone: item?.timezone})
      }
    }));
  };

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    fetchData();
  }, [bookingListParams, rows, page]);

  return (
    <div
      className="flex-col gap-32 pd-24 ht-100 overflow-auto  bg-white just-sp-bwn"
      style={{ width: "96%" }}>
      <div className="flex-col gap-32">
        <TableHeader
          title="Booking Management"
          firstDropdown={bookingDates}
          firstDropdownValue={bookingTime}
          handleFirstDropdown={handleBookingTime}
          secondDropdown={status}
          secondDropdownValue={statusFilter}
          handleSecondDropdown={handleStatus}
          firsticon={<EventNoteIcon />}
          firstbuttonTitle="Book Service"
          handleFirstButton={handleModal}
        />
        {customRange && (
          <CustomDatePicker getDateRange={(e) => handleDateRange(e)} />
        )}
        <CustomTable
          headers={headings}
          data={addIconsToUserData(bookingList?.data)}
        />
      </div>

      <div className="flex-row align-center just-sp-bwn">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="table-row-selection">Rows</InputLabel>
            <Select
              labelId="table-row-selection"
              id="table-rows"
              label="Rows"
              value={rows}
              onChange={(e) => {
                setRows(e.target.value);

                setPage(1);
                setBookingListParams((v) => ({
                  ...v,
                  limit: e.target.value,
                  skip: 0,
                }));
              }}>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={8}>8</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Add Property Manager modal */}
        <GenericModal
          open={open}
          onClose={handleModal}
          title="Book Service"
          caption="Please fill below details to book a service."
          content={<BookService type={modalType} onCancel={handleModal} />}
        />

        {/* action visibility icon modal */}

        <GenericModal
          open={visibilityModalOpen}
          onClose={handleVisibilityModal}
          title="Booking Details"
          content={<ViewService data={viewData} />}
        />

        <GenericModal
          title={""}
          content={
            <BookingSuccessfull
              handleCompletionModal={() => setCompletionModal(!completionModal)}
            />
          }
          open={completionModal}
          onClose={() => setCompletionModal(!completionModal)}
        />

        {/* action update icon modal */}
        <ThemeProvider theme={paginationTheme}>
          {" "}
          <Pagination
            count={pageCount}
            shape="rounded"
            size="large"
            color="primary"
            page={page}
            onChange={(e, page) => {
              setPage(page);
              setBookingListParams((v) => ({
                ...v,
                skip: (page - 1) * rows,
              }));
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default BookingManagement;
