import React, { memo, useEffect, useState } from "react";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { CustomSeparator, GenericModal } from "../../../../components";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";
import {
  getAllGroupsMarket,
  getGroupJobs,
} from "../../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import CustomCalendar from "../../CustomCalendar/CustomCalendar";
import { AddJob } from "../../CustomCalendar/AddJob/AddJob";
import { toast } from "react-toastify";
import AddTemplate from "../../CustomCalendar/AddTemplate/AddTemplate";
import { Menu, MenuItem } from "@mui/material";
import moment from "moment";
const Schedule = () => {
  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Market", url: "/home/scheduler" },
    { label: "Schedule" },
  ];
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [addJobModalType, setAddJobModalType] = useState("");

  const [groups, setGroups] = useState([]);

  const [groupData, setGroupData] = useState([]);
  const groupArray = groupData?.valueOf().map((item) => {
    return { value: item._id, title: item.groupName };
  });
  const [selectedGroup, setSelectedGroup] = useState("");
  const [templateOpen, setTemplateOpen] = useState(false);
  const [templateData, setTemplateData] = useState({});

  const [staffMember, setStaffMember] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentView, setCurrentView] = useState("Month");

  const handleAddJobAnchor = (e) => {
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (selectedGroup) getAllGroupJobs(selectedGroup);
  }, [currentView]);

  const addJobMenuOpen = Boolean(anchorEl);

  const handleAddJobMenuClose = () => {
    setAnchorEl(null);
  };
  const handleGroupSelect = (value) => {
    setSelectedGroup(value);
    const filteredGroup = groupData?.find((group) => group._id === value);
    setStaffMember(filteredGroup?.groupMemberData);
    setGroups(filteredGroup);
  };

  const handleModal = () => {
    // if (open) setAddJobModalType("");
    setOpen(!open);
    setUpdateModalOpen(false);
  };
  const handleTemplateModal = () => {
    setTemplateOpen(!templateOpen);
  };
  const handleSetData = (data) => {
    setTemplateData(data);
    handleUpdateModal();
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setOpen(false);
  };
  const getAllGroups = (id) => {
    getAllGroupsMarket(id)
      .then((res) => {
        setGroups(res?.data?.data?.groupsData[0]);
        setGroupData(res?.data?.data?.groupsData);
        setStaffMember(res?.data?.data?.groupsData?.[0]?.groupMemberData);
        setSelectedGroup(res?.data?.data?.groupsData?.[0]?._id);
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const transformJobsData = (jobData) => {
    const transformedData = [];
    Object.values(jobData).forEach((jobs) => {
      jobs.forEach((job) => {
        if (job?.assignedStaffMembersDetail?.fullName === "Hammad Bhat") {
        }
        transformedData.push({
          timezone: job?.timezone,
          text: `${moment(
            new Date(job?.jobTiming?.startTime).toLocaleString("en-us", {
              timeZone: job?.timezone,
            })
          ).format("LT")} - ${moment(
            new Date(job?.jobTiming?.endTime).toLocaleString("en-us", {
              timeZone: job?.timezone,
            })
          ).format("LT")}`,
          address: job?.address,
          assignedStaff: job?.assignedStaffMembers?.[0],
          isStrikethroughed: job?.jobRejected || job?.jobRejectedByStaff,
          city: job?.city,
          jobName: job?.jobName,
          forRegisteredUser: job?.forRegisteredUser,
          jobDate: new Date(job?.jobDate).toLocaleString("en-us", {
            timezone: job?.timezone,
          }),
          endDate:
            currentView === "Week"
              ? new Date(
                  new Date(job?.jobTiming?.endTime).toLocaleString("en-us", {
                    timeZone: job?.timezone,
                  })
                ).setHours(24, 0, 0, 0)
              : new Date(job?.jobTiming?.endTime).toLocaleString("en-us", {
                  timeZone: job?.timezone,
                }),
          groupName: selectedGroup,
          startDate:
            currentView === "Week"
              ? new Date(
                  new Date(job?.jobTiming?.startTime).toLocaleString("en-us", {
                    timeZone: job?.timezone,
                  })
                ).setHours(0, 0, 0, 0)
              : new Date(job?.jobTiming?.startTime).toLocaleString("en-us", {
                  timeZone: job?.timezone,
                }),
          jobTiming: job?.jobTiming,
          jobId: job?._id,
          bookingId: job?.bookingId.toUpperCase(),
          state: job?.state,
          postalCode: job?.postalCode,
          requestedBy: job?.requestedBy,
          propertyDetail: job?.propertyDetail,
          requestedByDetail: job?.requestedByDetail,
          fullName: job?.requestedByDetail?.fullName,
          mobileNumber: job?.requestedByDetail?.mobileNumber,
          countryCode: job?.requestedByDetail?.countryCode,
          assignedStaffMembersDetail: job?.assignedStaffMembersDetail,
          userDetails:
            job?.requestedByDetail?.role === 2 ? job?.requestedByDetail : {},
          property: job?.propertyDetail,
          task: job?.task,
          serviceCategory: job?.categoryId,
          service: {
            name: job?.serviceData?.name,
            _id: job?.serviceData?._id,
            price: job?.serviceData?.price,
          },
          serviceData: job?.serviceData,
          isTemplate: job?.isTemplate,
          role: job?.requestedByDetail?.role,
          country: job?.country,
        });
      });
    });

    setDataSource(transformedData);
  };
  const getAllGroupJobs = (groupId) => {
    getGroupJobs(groupId)
      .then((res) => transformJobsData(res?.data?.data))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  useEffect(() => {
    if (selectedGroup) getAllGroupJobs(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getAllGroups(state?.marketDetails?._id);
  }, []);

  return (
    <div className="flex-col ht-100 large-gap">
      <div className="flex-col gap-32 " style={{ width: "99%" }}>
        <TableHeader
          title="Markets"
          caption={<CustomSeparator items={breadcrumbs} />}
        />
      </div>
      <div
        className="flex-col gap-32 pd-24 bg-white ht-100 overflow-auto hidden-scroll "
        style={{ width: "81vw" }}
      >
        <TableHeader
          title={state?.marketDetails?.marketName}
          dropDown
          firstDropdown={groupArray}
          firstDropdownValue={selectedGroup}
          handleFirstDropdown={handleGroupSelect}
          firstbuttonTitle="Add Job"
          firsticon={<AddIcon />}
          // handleFirstButton={handleModal}
          handleFirstButton={handleModal}
          secondbuttonTitle="Select Template"
          secondicon={<AddIcon />}
          handleSecondButton={handleTemplateModal}
        />

        <CustomCalendar
          resources={staffMember}
          // addJobModal={handleModal}
          dataSource={dataSource}
          market={state?.marketDetails?._id}
          groupDetails={groups}
          handleGetAllGroupJobs={getAllGroupJobs}
          currentView={currentView}
          setCurrentView={setCurrentView}
          handleClearingAllJobs={setDataSource}
        />
        <GenericModal
          open={open}
          onClose={handleModal}
          title="Add Job"
          caption=""
          content={
            <AddJob
              type={addJobModalType}
              onCancel={handleModal}
              marketId={state?.marketDetails?._id}
              groupDetails={groups}
              handleGetAllGroupJobs={getAllGroupJobs}
            />
          }
        />
        <GenericModal
          open={templateOpen}
          onClose={handleTemplateModal}
          title="Select Template"
          caption="Select a saved template from below options."
          content={
            <AddTemplate
              onCancel={handleTemplateModal}
              handleTemplate={handleSetData}
              handleModal={handleModal}
              marketId={state?.marketDetails?._id}
              groupId={groups?._id}
            />
          }
        />
        <GenericModal
          open={updateModalOpen}
          onClose={handleUpdateModal}
          title="Add Job"
          caption=""
          content={
            <AddJob
              onCancel={handleUpdateModal}
              tempData={templateData}
              marketId={state?.marketDetails?._id}
              groupDetails={groups}
              handleGetAllGroupJobs={getAllGroupJobs}
            />
          }
        />
      </div>
      <Menu
        id="add-job-meu"
        anchorEl={anchorEl}
        open={addJobMenuOpen}
        onClose={handleAddJobMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setAddJobModalType("");
            handleModal();
            handleAddJobMenuClose();
          }}
        >
          Registered Client
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddJobModalType("unregistered");
            handleModal();
            handleAddJobMenuClose();
          }}
        >
          Unregistered Client
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Schedule;
