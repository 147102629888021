import React, { useEffect, useState } from "react";
import {
  getAdminDashboardActivityData,
  getAdminDashboardJobData,
  getAdminDashboardRevenueData,
  getAdminDashboardUserData,
  getCategory,
  getPropertyManagerDashboard,
} from "../../../api/api_calls/data";
import { IconButton, Skeleton } from "@mui/material";
import classes from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getDateRange } from "../../../utils/HelperFunctions";
import { loaderActions } from "../../../redux/reducers/loader";
import Activity from "../../../components/common/Activity/Activity";
import LineChart from "../../../components/common/Charts/LineChart/LineChart";
import DoughnutChart from "../../../components/common/Charts/DoughnutChart/DoughnutChart";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import DashboardCard from "../../../components/common/commonComponents/DashboardCard/DashboardCard";
import { GenericModal } from "../../../components";
import CreateIcon from "@mui/icons-material/Create";
import AddPropertyManager from "../PropertyManager/AddPropertyManager/AddPropertyManager";
import { toast } from "react-toastify";

const doughnutOptions = [
  {
    value: "allTime",
    title: "All Time",
  },
  {
    value: "lastWeek",
    title: "Last Week",
  },
  {
    value: "lastMonth",
    title: "Last Month",
  },
  {
    value: "lastYear",
    title: "Last Year",
  },
];

const userOptions = [
  {
    value: "client",
    title: "Client",
  },
  {
    value: "staff",
    title: "Staff",
  },
];

const lineChartLabels = ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"];

const headings = [
  { heading: "Name", key: "fullName" },
  { heading: "Email Address", key: "email" },
  { heading: "Country Code", key: "countryCode" },
  { heading: "Mobile Number", key: "mobileNumber" },
  { heading: "Date Added", key: "createdOn", type: "date" },
  { heading: "Job Requests", key: "bookingCount" },
  { heading: "Status", key: "isActive", type: "propertyManagerStatus" },
  { heading: "Action", key: "icon" },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.user?.role);
  const permission = useSelector((state) => state.user?.permissions);
  const [user, setUser] = useState("client");
  const [viewData, setViewData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [usersTime, setUsersTime] = useState("allTime");
  const [tableTime, setTableTime] = useState("allTime");
  const [revenueTime, setRevenueTime] = useState("allTime");
  const [doughnutTime, setDoughnutTime] = useState("allTime");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [service, setService] = useState("allCategories");
  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false);
  const [adminDashData, setAdminDashData] = useState({
    jobs: {
      upComing: "0",
      onGoing: "0",
      completed: "0",
      totalJobs: "0",
      loading: true,
    },
    users: { data: [], loading: true },
    activity: { data: [], loading: true },
    revenue: { data: [], loading: true },
  });
  const [categories, setCategories] = useState([]);
  const [pmParams, setPmParams] = useState({ startDate: "", endDate: "" });
  const [userParams, setUserParams] = useState({
    role: 2,
    startDate: "",
    endDate: "",
  });
  const [dashboardTableData, setDashboardTableData] = useState({
    data: [],
    loading: true,
  });
  const [revenueParams, setRevenueParams] = useState({
    startDate: "",
    endDate: "",
    categoryId: "",
  });

  const attatchActionIcons = (dataset) => {
    return dataset?.map((data) => ({
      ...data,
      icon: (
        <div className="flex-row medium-gap">
          <IconButton onClick={(e) => handleVisibilityModal(data)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={(e) => handleUpdateModal(data)}>
            <CreateIcon />
          </IconButton>
        </div>
      ),
    }));
  };

  const handleDoughnutTime = async (range) => {
    setDoughnutTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getAdminDashboardJobData("", "");
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            jobs: {
              ...v?.jobs,
              totalJobs: res?.data?.data?.totalJobs,
              onGoing: res?.data?.data?.Ongoing,
              completed: res?.data?.data?.Completed,
              upComing: res?.data?.data?.Upcoming,
              loading: false,
            },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      try {
        const res = await getAdminDashboardJobData(
          dateRange?.startDate,
          dateRange?.endDate
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            jobs: {
              ...v?.jobs,
              totalJobs: res?.data?.data?.totalJobs,
              onGoing: res?.data?.data?.Ongoing,
              completed: res?.data?.data?.Completed,
              upComing: res?.data?.data?.Upcoming,
              loading: false,
            },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleUser = async (role) => {
    if (role == "client") {
      setUser("client");
      setUserParams((v) => ({
        ...v,
        role: 2,
      }));

      try {
        const res = await getAdminDashboardUserData(
          userParams?.startDate,
          userParams?.endDate,
          2
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            users: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      setUser("staff");
      setUserParams((v) => ({
        ...v,
        role: 3,
      }));
      try {
        const res = await getAdminDashboardUserData(
          userParams?.startDate,
          userParams?.endDate,
          3
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            users: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleUserTime = async (range) => {
    setUsersTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getAdminDashboardUserData("", "", userParams?.role);
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            users: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      setUserParams((v) => ({
        ...v,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      }));
      try {
        const res = await getAdminDashboardUserData(
          dateRange?.startDate,
          dateRange?.endDate,
          userParams?.role
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            users: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleRevenueService = async (id) => {
    setService(id);
    setRevenueParams((v) => ({
      ...v,
      categoryId: id,
    }));
    try {
      const categoryId = id === "allCategories" ? "" : id;
      const res = await getAdminDashboardRevenueData(
        revenueParams?.startDate,
        revenueParams?.endDate,
        categoryId
      );
      if (res?.status === 200) {
        setAdminDashData((v) => ({
          ...v,
          revenue: { data: res?.data?.data, loading: false },
        }));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleRevenueTime = async (range) => {
    setRevenueTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getAdminDashboardRevenueData(
          "",
          "",
          revenueParams?.categoryId
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            revenue: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      setRevenueParams((v) => ({
        ...v,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      }));
      try {
        const res = await getAdminDashboardRevenueData(
          dateRange?.startDate,
          dateRange?.endDate,
          revenueParams?.categoryId
        );
        if (res?.status === 200) {
          setAdminDashData((v) => ({
            ...v,
            revenue: { data: res?.data?.data, loading: false },
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleTableTime = async (range) => {
    setTableTime(range);
    const dateRange = getDateRange(range);
    if (range === "allTime") {
      try {
        const res = await getPropertyManagerDashboard("", "");
        if (res?.status === 200) {
          setDashboardTableData((v) => ({
            ...v,
            data: res?.data?.data?.allPropertiesManager,
            loading: false,
          }));
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      setPmParams({
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      });
      const res = await getPropertyManagerDashboard(
        dateRange?.startDate,
        dateRange?.endDate
      );
      if (res?.status === 200) {
        setDashboardTableData((v) => ({
          ...v,
          data: res?.data?.data?.allPropertiesManager,
          loading: false,
        }));
      }
    }
  };

  const handleTableViewMore = () => {
    navigate("/home/propertyManager");
  };

  const getAllDashData = async () => {
    try {
      const res = await getAdminDashboardJobData("", "");

      const res2 = await getAdminDashboardUserData("", "", userParams?.role);
      const res3 = await getAdminDashboardActivityData("6");
      const res4 = await getAdminDashboardRevenueData(
        "",
        "",
        revenueParams?.categoryId
      );
      const resCat = await getCategory();

      if (res?.status === 200) {
        if (!res?.data?.data?.totalJobs) {
          setAdminDashData((v) => ({
            ...v,
            jobs: {
              upComing: "0",
              onGoing: "0",
              completed: "0",
              totalJobs: "0",
              loading: false,
            },
          }));
        } else {
          setAdminDashData((v) => ({
            ...v,
            jobs: {
              ...v?.jobs,
              totalJobs: res?.data?.data?.totalJobs,
              onGoing: res?.data?.data?.Ongoing,
              completed: res?.data?.data?.Completed,
              upComing: res?.data?.data?.Upcoming,
              loading: false,
            },
          }));
        }
      }
      if (res2?.status === 200) {
        setAdminDashData((v) => ({
          ...v,
          users: { ...v, data: res2?.data?.data, loading: false },
        }));
      }
      if (res3?.status === 200) {
        setAdminDashData((v) => ({
          ...v,
          activity: { ...v, data: res3?.data?.data?.activity, loading: false },
        }));
      }
      if (res4?.status === 200) {
        setAdminDashData((v) => ({
          ...v,
          revenue: { ...v, data: res4?.data?.data, loading: false },
        }));
      }
      if (resCat?.status === 200) {
        setCategories(resCat?.data?.data?.categories);
      }
      dispatch(loaderActions.toggleLoading(false));
    } catch (error) {
      toast.error(error?.message);
      dispatch(loaderActions.toggleLoading(false));
    }
  };

  const fetchPropertyManagers = () => {
    if (role && role !== 1) {
      dispatch(loaderActions.toggleLoading(true));
      getPropertyManagerDashboard(pmParams?.startDate, pmParams?.endDate)
        .then((res) => {
          setDashboardTableData((v) => ({
            ...v,
            data: res?.data?.data?.allPropertiesManager,
            loading: false,
          }));
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    }
  };

  const handleVisibilityModal = (data) => {
    setVisibilityModalOpen(!visibilityModalOpen);
    setViewData(data);
    setModalType("view");
    setUpdateModalOpen(false);
  };

  const handleUpdateModal = (data) => {
    setUpdateModalOpen(!updateModalOpen);
    setVisibilityModalOpen(false);
    setModalType("update");
    setViewData(data);
  };

  const changeModalType = (type) => {
    setModalType("update");
  };

  useEffect(() => {
    if (permission.includes("All") || permission.includes("Property Manager")) {
      fetchPropertyManagers();
    }
  }, [role]);

  useEffect(() => {
    getAllDashData();
  }, []);

  const categoriesOptions = [
    {
      value: "allCategories",
      title: "All Categories",
    },
  ];

  // const categoriesOptions = categories?.map((el, i) => {
  //   const container = {};
  //   container["value"] = el._id;
  //   container["title"] = el.categoryName;
  //   return container;
  // });

  categories.forEach((category) => {
    categoriesOptions.push({
      value: category?._id,
      title: category?.categoryName,
    });
  });

  return (
    <div className="flex-col gap-28 wd-100">
      <div className="flex-row gap-28">
        <div className={`${classes.left} flex-col gap-28`}>
          {/* jobs */}
          <DashboardCard>
            <TableHeader
              title="Total Jobs"
              firstDropdown={doughnutOptions}
              firstDropdownValue={doughnutTime}
              handleFirstDropdown={handleDoughnutTime}
            />
            {adminDashData?.jobs?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <DoughnutChart props={{ jobs: adminDashData?.jobs }} />
              </Skeleton>
            ) : (
              <DoughnutChart jobs={adminDashData?.jobs} />
            )}
          </DashboardCard>

          {/* activity */}
          <DashboardCard>
            <TableHeader
              title="Activity"
              firstbuttonTitle="View All"
              firstButtonStyle="grey"
              handleFirstButton={() => navigate("/home/activity")}
            />
            {adminDashData?.activity?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <Activity />
              </Skeleton>
            ) : (
              <Activity activity={adminDashData?.activity?.data || []} />
            )}
          </DashboardCard>
        </div>
        <div className={`${classes.right} flex-col gap-28`}>
          {/* users */}
          <DashboardCard>
            <TableHeader
              title="Total number of Users"
              firstDropdown={userOptions}
              firstDropdownValue={user}
              handleFirstDropdown={handleUser}
              secondDropdown={doughnutOptions}
              secondDropdownValue={usersTime}
              handleSecondDropdown={handleUserTime}
            />
            {adminDashData?.users?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <div className={`${classes.chart_wrapper}`}>
                  <LineChart />
                </div>
              </Skeleton>
            ) : (
              // <div>
              <div className={`${classes.chart_wrapper}`}>
                <LineChart
                  timeRange={usersTime}
                  label="Users"
                  dataset={adminDashData?.users?.data}
                  labels={lineChartLabels}
                  backgroundColor="#DDF5F4"
                  borderColor="#40A59E"
                />
              </div>
              // </div>
              // ""
            )}
          </DashboardCard>

          {/* revenue */}
          <DashboardCard>
            <TableHeader
              title="Total Revenue Generated"
              firstDropdown={categoriesOptions}
              firstDropdownValue={service}
              handleFirstDropdown={handleRevenueService}
              secondDropdown={doughnutOptions}
              secondDropdownValue={revenueTime}
              handleSecondDropdown={handleRevenueTime}
            />
            {adminDashData?.revenue?.loading ? (
              <Skeleton sx={{ maxWidth: "none !important" }} animation="wave">
                <div className={`${classes.chart_wrapper}`}>
                  <LineChart />
                </div>
              </Skeleton>
            ) : (
              // <div>
              <div className={`${classes.chart_wrapper}`}>
                <LineChart
                timeRange={revenueTime}
                  label="Revenue"
                  dataset={adminDashData?.revenue?.data}
                  labels={lineChartLabels}
                  backgroundColor="#FCF1F7"
                  borderColor="#E64593"
                />
              </div>
              // </div>
              // ""
            )}
          </DashboardCard>
        </div>
      </div>
      {permission.includes("All") || permission.includes("Property Manager") ? (
        <div className={`flex-col bg-white pd-24`}>
          <DashboardCard>
            <TableHeader
              title="Property Managers"
              firstDropdown={doughnutOptions}
              firstDropdownValue={tableTime}
              handleFirstDropdown={handleTableTime}
              firstbuttonTitle="View All"
              firstButtonStyle="grey"
              handleFirstButton={handleTableViewMore}
            />
            {dashboardTableData?.loading ? (
              <Skeleton
                sx={{ maxWidth: "none !important", minHeight: "100px" }}
                animation="wave"
              >
                {/* <CustomTable headers={headings} data={attatchActionIcons(dashboardTableData?.data)} /> */}
              </Skeleton>
            ) : (
              <CustomTable
                headers={headings}
                data={attatchActionIcons(dashboardTableData?.data)}
              />
            )}
          </DashboardCard>
        </div>
      ) : (
        ""
      )}

      {/* action visibility icon modal */}
      <GenericModal
        open={visibilityModalOpen}
        onClose={handleVisibilityModal}
        title={
          modalType === "update"
            ? "Edit Property Manager Info"
            : "View Property Manager Info"
        }
        content={
          <AddPropertyManager
            type={modalType}
            title="Property Manager can update their info?"
            caption="Allow Property Manager to update their information"
            onCancel={handleVisibilityModal}
            data={viewData}
            handleModalType={changeModalType}
          />
        }
      />
      {/* action update icon modal */}
      <GenericModal
        open={updateModalOpen}
        onClose={handleUpdateModal}
        title="Edit Property Manager"
        caption="Allow Property Manager to update their information"
        content={
          <AddPropertyManager
            type={modalType}
            title="Property Manager can update their info?"
            caption="Allow Property Manager to update their information"
            onCancel={handleUpdateModal}
            data={viewData}
          />
        }
      />
    </div>
  );
};

export default Dashboard;
