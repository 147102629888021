import React, { useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Flip, ToastContainer } from "react-toastify";
import Chat from "../pages/SuperAdmin/Chat/Chat";
import Users from "../pages/SuperAdmin/Users/Users";
import Home from "../components/SuperAdmin/Home/Home";
import Updates from "../pages/SuperAdmin/Updates/Updates";
import Ratings from "../pages/SuperAdmin/Ratings/Ratings";
import MyProfile from "../components/MyProfile/MyProfile";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/SuperAdmin/Dashboard/Dashboard";
import Scheduler from "../pages/SuperAdmin/Scheduler/Scheduler";
import Schedule from "../pages/SuperAdmin/Scheduler/Schedule/Schedule";
import JobRequest from "../pages/SuperAdmin/PropertyManager/JobRequest";
import Loader from "../components/common/commonComponents/Loader/Loader";
import Action from "../pages/SuperAdmin/CategoryManagement/Action/Action";
import Questionnaire from "../pages/SuperAdmin/Questionnaire/Questionnaire";
import AllActivities from "../pages/SuperAdmin/AllAcitvities/AllActivities";
import PropertyManager from "../pages/SuperAdmin/PropertyManager/PropertyManager";
import StaffManagement from "../pages/SuperAdmin/StaffManagement/StaffManagement";
import CategoryManagement from "../pages/SuperAdmin/CategoryManagement/CategoryManagement";
import ServiceRequests from "../pages/SuperAdmin/CategoryManagement/ServiceRequests/ServiceRequests";
import CategoryManagementRoutes from "../pages/SuperAdmin/CategoryManagement/CategoryManagementRoutes";
import Groups from "../pages/SuperAdmin/Scheduler/Groups/Groups";
import QuestionnaireRoutes from "../pages/SuperAdmin/Questionnaire/QuestionnaireRoutes";
import Questions from "../pages/SuperAdmin/Questionnaire/Questions/Questions";
import ClientManagement from "../pages/SuperAdmin/ClientManagement/ClientManagement";
import { requestPermission } from "../pages/SuperAdmin/Chat/firebase";

const MainRouter = ({ props }) => {
  const { loader } = props;
  let permissions = useSelector((state) => state.user?.permissions);
  const isLoggedIn = useSelector((state) => state.auth)

  if (!permissions) permissions = [""];

  useEffect(() => {
    if (isLoggedIn.loggedIn) requestPermission()
  }, [isLoggedIn])

  return (
    <div className="main_container">
      {loader && <Loader />}
      <Routes>
        <Route
          path="/home"
          exact
          element={
            // only Home outlet is wrapped in private routing, no need of checking again and again...
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        >
          <Route path="activity" exact element={<AllActivities />} />
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Dashboard")) && (
              <Route path="dashboard" exact element={<Dashboard />} />
            )}
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Scheduler")) && (
              <>
                <Route path="scheduler" exact element={<Scheduler />} />
                <Route path="scheduler/schedule" exact element={<Schedule />} />
                <Route path="scheduler/groups" exact element={<Groups />} />
              </>
            )}
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Chat")) && (
              <Route path="chat" exact element={<Chat />} />
            )}
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Updates")) && (
              <Route path="updates" exact element={<Updates />} />
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("Property Manager")) && (
              <>
                <Route
                  path="propertyManager"
                  exact
                  element={<PropertyManager />}
                />
                <Route
                  path="propertymanager/jobrequest"
                  exact
                  element={<JobRequest />}
                />
              </>
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("Category Management")) && (
              <Route
                path="categoryManagement"
                exact
                element={<CategoryManagementRoutes />}
              >
                <Route path="" exact element={<CategoryManagement />} />
                <Route
                  path="serviceRequests"
                  exact
                  element={<ServiceRequests />}
                />
                <Route path="action" exact element={<Action />} />
              </Route>
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("Staff Management")) && (
              <Route path="staff" exact element={<StaffManagement />} />
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("Questionnaire")) && (
              <Route
                path="questionnaire"
                exact
                element={<QuestionnaireRoutes />}
              >
                <Route path="" exact element={<Questionnaire />} />
                <Route path="questions" exact element={<Questions />} />
              </Route>
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("User and Access")) && (
              <Route path="users" exact element={<Users />} />
            )}
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Ratings")) && (
              <Route path="ratings" exact element={<Ratings />} />
            )}
          {permissions &&
            (permissions[0] === "All" ||
              permissions.includes("Client Management")) && (
              <Route path="client" exact element={<ClientManagement />} />
            )}
          {permissions &&
            (permissions[0] === "All" || permissions.includes("Dashboard")) && (
              <Route
                path="/home"
                exact
                element={<Navigate replace to="/home/dashboard" />}
              />
            )}

          <Route path="profile" exact element={<MyProfile />} />

          {/* default path for auth routes... */}
          <Route path="*" exact element={<Navigate to="dashboard" replace />} />
        </Route>
        {/* )} */}

        {/* default redirection - without auth no pvt should hit... */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navigate replace to="/home/dashboard" />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer position="top-right" transition={Flip} newestOnTop />
    </div>
  );
};

export default MainRouter;
