import Joi from "joi";

export const serviceformValidation = Joi.object({
  timezone: Joi.string().required(),
  propertyName: Joi.object().required().label("Property Name").error(new Error("Please select a Property Name")),
  categoryName: Joi.object().required().label("Service Category").error(new Error("Please select a Service Category ")),
  serviceName: Joi.object().required().label("Service").error(new Error("Please select a Service")),
  address: Joi.string().required().label("Address"),
  city: Joi.string()
    // .pattern(/^[A-Za-z]+$/)
    .required()
    .label("City")
    .error(new Error("Please fill valid city")),
  state: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("State")
    .error(new Error("Please fill valid state")),
  postalCode: Joi.number().integer().required().max(9999999999).label("Postal Code").error(new Error("Please fill valid Postal Code")),
  country: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required()
    .label("Country")
    .error(new Error("Please fill valid country")),
  jobDate: Joi.date().required().label("Select Date").error(new Error("Please select a Date")),
  startTime: Joi.string().required().label("Select Start Time").error(new Error("Please select Start Time")),
  endTime: Joi.string().required().label("Select End Time").error(new Error("Please select End Time")),
  amount: Joi.string(),
});
