import React, { useState } from "react";
import classes from "./Card.module.css";
import { useNavigate } from "react-router-dom";
import { Typography, Menu, MenuItem } from "@mui/material";
import CustomTag from "../common/commonComponents/CustomTag/CustomTag";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomButton from "./Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LetterAvatar } from "../LetterAvatar";
import DeleteMarket from "../../pages/SuperAdmin/Scheduler/AddMarket/DeleteMarket";
import { GenericModal } from "../GenericModal";
import moment from "moment";
import EditMarket from "../../pages/SuperAdmin/Scheduler/AddMarket/EditMarket";
const CustomCard = ({
  marketDetails,
  marketId,
  marketName,
  createdOn,
  tagName,
  buttonText,
  groupDetails,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [deleteMarketModal, setDeleteMarketModal] = useState(false);
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleEditModal = () => {
    setMenuAnchorEl(null);
    setOpen(!open);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleDeleteModal = () => {
    setMenuAnchorEl(null);
    setDeleteMarketModal(!deleteMarketModal);
  };
  return (
    <>
      <div className={`flex-row ${classes.cardDiv}`}>
        <div className={`flex-row gap-32 pd-38 bg-white ${classes.styleddiv}`}>
          <div className="flex-row large-gap wd-100">
            <LetterAvatar names={[marketDetails?.marketName]} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "290px",
              }}>
              <div
                className="pointer"
                onClick={() =>
                  navigate("/home/scheduler/groups", {
                    state: { marketDetails, groupDetails },
                  })
                }>
                <h2 className="secondary-heading">
                  {marketDetails?.marketName}
                </h2>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "grey" }}>
                  Created on {moment(marketDetails?.createdAt).format("ll")}
                </Typography>
              </div>
              <div>{tagName && <CustomTag title={tagName} />}</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <div>
              <CustomButton
                bgColor="#D0F2F0"
                borderColor="#D0F2F0"
                textColor="#40A59E"
                fontWeight="bold"
                text={buttonText}
                icon={<CalendarMonthIcon />}
                onClick={() =>
                  navigate("/home/scheduler/schedule", {
                    state: { marketDetails, groupDetails },
                  })
                }
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MoreVertIcon className="pointer" onClick={handleMenuOpen} />
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}>
                <MenuItem onClick={handleEditModal}>Edit Name</MenuItem>
                <MenuItem onClick={handleDeleteModal}>Delete Market</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <GenericModal
          open={deleteMarketModal}
          onClose={handleDeleteModal}
          title="Delete Market"
          content={
            <DeleteMarket
              marketId={marketDetails?._id}
              handleCancelFunction={handleDeleteModal}
            />
          }
        />
        <GenericModal
          open={open}
          onClose={handleEditModal}
          title="Edit Market"
          content={
            <EditMarket onCancel={handleEditModal} data={marketDetails} />
          }
        />
      </div>
    </>
  );
};

export default CustomCard;
