import { wrappedFetch } from "../api_setting/urlFuncs";
import API from "../api_setting/apiConstants";

export const addCategory = (data) => {
  return wrappedFetch(API.GET_CATEGORY, "post", data);
};

export const editCategory = (data) => {
  return wrappedFetch(API.GET_CATEGORY, "patch", data);
};

export const addPropertyManager = (data) => {
  return wrappedFetch(API.ADD_PROPERTY_MANAGER, "post", data);
};

export const editPropertyManager = (data) => {
  return wrappedFetch(API.EDIT_PROPERTY_MANAGER, "put", data);
};

export const addService = (data) => {
  return wrappedFetch(API.GET_ALL_SERVICE, "post", data);
};

export const editService = (id, data) => {
  return wrappedFetch(`${API.GET_ALL_SERVICE}/${id}`, "patch", data);
};

export const addQuestion = (data) => {
  return wrappedFetch(API.GET_QUESTIONS, "post", data);
};

export const editQuestion = (questionId, data) => {
  return wrappedFetch(`${API.GET_QUESTIONS}/${questionId}`, "patch", data);
};

export const editQuestionStatus = (questionId, data) => {
  return wrappedFetch(
    `${API.UPDATE_QUESTION_STATUS}/${questionId}`,
    "patch",
    data
  );
};

export const addMarketGroup = (data) => {
  return wrappedFetch(API.CREATE_MARKET_GROUP, "post", data);
};

export const addMarket = (data) => {
  return wrappedFetch(API.CREATE_MARKET, "post", data);
};
export const bookService = (data) => {
  return wrappedFetch(API.BOOKING_SERVICE, "post", data);
};

export const addProperty = (data) => {
  return wrappedFetch(API.GET_PROPERTY, "post", data);
};
export const editProperty = (data) => {
  return wrappedFetch(API.GET_PROPERTY, "put", data);
};

export const addUser = (data) => {
  return wrappedFetch(API.ADD_USER, "post", data);
};

export const uploadMedia = (data) => {
  return wrappedFetch(API.UPLOAD_MEDIA, "post", data);
};

export const addUpdate = (data) => {
  return wrappedFetch(API.UPDATES, "post", data);
};

export const editUpdate = (updateId, data) => {
  return wrappedFetch(`${API.UPDATES}/${updateId}`, "patch", data);
};

export const updateUser = (id, data) => {
  return wrappedFetch(`${API.UPDATE_USER}/${id}`, "patch", data);
};

export const updateGroup = (data) => {
  return wrappedFetch(API.UPDATE_GROUP, "patch", data);
};
export const updateMarket = (data) => {
  return wrappedFetch(API.UPDATE_MARKET, "patch", data);
};

export const addJob = (data) => {
  return wrappedFetch(API.CREATE_JOB, "post", data);
};

export const editJob = (data) => {
  return wrappedFetch(API.EDIT_JOB, "put", data);
};
// --------------QUESTIONNAIRE MODULE------------
export const addQuestionnaire = (data) => {
  return wrappedFetch(API.GET_ALL_QUESTIONNAIRES, "post", data);
};

export const addStaff = (data) => {
  return wrappedFetch(API.CREATE_STAFF, "post", data);
};

// ----------------Category module------------------
export const reorderCategories = (data) => {
  return wrappedFetch(API.REORDER_CATEGORY, "patch", data)
}

// -----------------Firebase APIs-------------------
export const updateFCMToken = (data) => {
  return wrappedFetch(API.UPDATE_FCM_TOKEN, "post", data)
}
// -------------------------------------------------
