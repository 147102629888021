import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import classes from "./PhoneNumberInput.module.css";
import "./PhoneNumberInput.css";

const PhoneNumberInput = (props) => {
  const { setFormData } = props?.props;
  const handleCustomePhoneNumberChange = (e, data) => {
    if (props?.modalType === "property") {
      setFormData((currState) => {
        return {
          ...currState,
          countryCode: "+" + data?.dialCode,
          phoneNumber: e.replace(/[^0-9]+/g, "").slice(data.dialCode.length),
        };
      });
      return;
    }

    setFormData((formData) => {
      return {
        ...formData,
        countryCode: "+" + data?.dialCode,
        mobileNumber: e.replace(/[^0-9]+/g, "").slice(data.dialCode.length),
      };
    });
  };

  return (
    <>
      <div className={""}>
        <PhoneInput
          country={"us"}
          //  inputStyle={{classes.formField}}
          countryCodeEditable={true}
          value={props?.value}
          onChange={(e, data) => handleCustomePhoneNumberChange(e, data)}
          disabled={props?.disabled}
        />
      </div>
    </>
  );
};

export default PhoneNumberInput;
