import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookService } from "../../../../api/api_calls/addData";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { preventBack } from "../../../../utils/HelperFunctions";
import { loaderActions } from "../../../../redux/reducers/loader";
import BookingSuccessfull from "./BookingSuccessfull/BookingSuccessfull";
import { GenericModal } from "../../../../components";
import { toast } from "react-toastify";

const Completion = () => {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [parms] = useSearchParams();

  const data = parms.get("postForm");
  const redirect_status = parms.get("redirect_status");
  const paymentIntent = parms.get("payment_intent");

  const finalForm = JSON.parse(data)?.postForm;

  const handleModal = () => {
    setOpenModal(!openModal);
    navigate("/home/bookingManagement");
  };

  useEffect(() => {
    finalForm.transactionId = paymentIntent;
    if (redirect_status === "succeeded") {
      bookService(finalForm)
        .then((res) => {
          if (res.status === 200) {
            navigate("/home/bookingManagement", { state: { modalOpen: true } });
          }
        })
        .catch((error) => {
          toast.error(error?.message);
        })
        .finally((res) => {
          dispatch(loaderActions.toggleLoading(false));
        });
    }
    // }
    preventBack();
  }, []);

  return (
    <>
      {/* <h1>Thanks</h1> */}
      <GenericModal
        title={""}
        content={<BookingSuccessfull />}
        open={openModal}
        onClose={handleModal}
      />
      {/* <BookingSuccessfull />  */}
    </>
  );
};

export default Completion;
