import React, { useEffect, useState } from "react";
import classes from "./AddService.module.css";
import Input from "../../../../components/common/commonComponents/Input/Input";
import Button from "../../../../components/common/commonComponents/Button/Button";
import FilterIcon from "@mui/icons-material/Filter";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import Select from "react-select";
import FormData from "form-data";
import { addService, editService } from "../../../../api/api_calls/addData";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { addServiceFormValidationSchema } from "../../../../validations/addServiceFormValidation";
import { toast } from "react-toastify";
import {
  capitalize,
  customStylesReactSelect,
} from "../../../../utils/HelperFuncitons";
import { Add, Remove } from "@mui/icons-material";
import { cities } from "./index";

const AddService = ({
  handleCancelFunction,
  data,
  type,
  categoryId,
  handleFetchServices,
}) => {
  const dispatch = useDispatch();

  const [formContent, setFormContent] = useState({
    name: data?.name ? data.name : "",
    description: data?.description ? data.description : "",
    price: data?.price ? data.price : "",
    image: data?.image ? data.image : [],
    whatDoesItInclude: data?.whatDoesItInclude ? data?.whatDoesItInclude : [""],
  });
  const [serviceImages, setServiceImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState(
    data?.image ? [...data?.image] : []
  );
  const [isDefaultImageAdded, setIsDefaultImageAdded] = useState(
    data?.isDefaultImageAdded || false
  );
  const [error, setError] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const [customPrices, setCustomPrices] = useState(data?.prices || []);
  const [regionOptions, setRegionOptions] = useState([]);

  const imageUploadClicked = () => {
    document.getElementById("add-service-image-upload").click();
  };

  const cityOptions = [];
  Object.keys(cities).map((city) =>
    cityOptions.push({ label: city, value: city })
  );

  const handleAddedData = (e, index) => {
    const updatedData = [...formContent.whatDoesItInclude];
    updatedData[index] = e.target.value;
    setFormContent((state) => {
      return { ...state, whatDoesItInclude: updatedData };
    });
  };

  const handleAddIcon = (e, index) => {
    if (formContent?.whatDoesItInclude.length < 15) {
      const updatedData = [...formContent.whatDoesItInclude];
      if (index === 0) {
        updatedData.push("");
      } else if (formContent.whatDoesItInclude[index] !== "") {
        updatedData.push("");
      } else if (formContent.whatDoesItInclude[index] === "") {
        updatedData.splice(index, 1);

        index--;
      }
      setFormContent((state) => {
        return { ...state, whatDoesItInclude: updatedData };
      });
    }
  };
  const handleImageUpload = (e) => {
    if (e?.target?.files && e?.target?.files.length !== 0) {
      const imagesToShow = [];
      const imagesToSend = [];
      for (let i in Object.keys(e.target.files)) {
        const file = e.target.files[i];
        if (file.size <= 5 * 1024 * 1024) {
          setImageSize(false);
          imagesToShow.push(URL.createObjectURL(file));
          imagesToSend.push(file);
        } else {
          setImageSize(true);
        }
      }

      e.target.value = "";

      setImagesPreview((currState) => {
        return [...currState, ...imagesToShow];
      });
      setServiceImages((currState) => {
        return [...currState, ...imagesToSend];
      });

      setError("");
    }
  };

  const handleCustomPriceAdd = () => {
    const temp = {
      state: "",
      city: "",
      country: "",
      price: "",
    };

    setCustomPrices((customPrices) => {
      return [...customPrices, temp];
    });
  };

  const handleCustomPriceRemove = () => {
    const correctArray = customPrices.slice();
    correctArray.pop();

    setCustomPrices([...correctArray]);
  };

  const handleCitySelection = (selectedOption, ind) => {
    const tempCustomPrice = customPrices[ind];

    tempCustomPrice.city = selectedOption?.value;
    tempCustomPrice.country = cities[selectedOption?.value]?.country;
    tempCustomPrice.state = cities[selectedOption?.value]?.state;

    const correctArray = customPrices.slice();
    correctArray.splice(ind, 1, tempCustomPrice);

    setCustomPrices([...correctArray]);
  };

  const handleCustomPriceInput = (e, ind) => {
    const tempCustomPrice = customPrices[ind];
    tempCustomPrice.price = e.target.value;

    const correctArray = customPrices.slice();
    correctArray.splice(ind, 1, tempCustomPrice);

    setCustomPrices([...correctArray]);
  };

  const removeUploadedImage = async (index, image) => {
    if (!image.startsWith("blob")) {
      const imageIndex = formContent.image.indexOf(image);
      formContent.image.splice(imageIndex, 1);
      setFormContent((curr) => {
        return { ...curr, image: [...formContent.image] };
      });
    } else {
      let blob = await fetch(image).then((r) => r.blob());
      const imageIndex = serviceImages.indexOf(blob);
      serviceImages.splice(imageIndex, 1);
      setServiceImages([...serviceImages]);
    }

    imagesPreview.splice(index, 1);
    setImagesPreview([...imagesPreview]);
  };

  const handleFormData = (e) => {
    setFormContent((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  const handleDefaultImageCheckbox = (e) => {
    setIsDefaultImageAdded(e.target.checked);

    if (!e.target.checked && type === "edit") {
      const defaultImageIndex = formContent?.image.indexOf(
        "https://nextgen-resourcifi.s3.amazonaws.com/Uploads/1704889748844/defaultImage.png"
      );
      const previewImageIndex = imagesPreview.indexOf(
        "https://nextgen-resourcifi.s3.amazonaws.com/Uploads/1704889748844/defaultImage.png"
      );

      if (defaultImageIndex !== -1) {
        const updatedImageArray = [...formContent?.image];
        updatedImageArray.splice(defaultImageIndex, 1);

        setFormContent((currState) => {
          return { ...currState, image: updatedImageArray };
        });
      }

      if (previewImageIndex !== -1) {
        const updatedImageArray = [...imagesPreview];
        updatedImageArray.splice(previewImageIndex, 1);

        setImagesPreview([...updatedImageArray]);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let postData = new FormData();
    postData.append("name", capitalize(formContent?.name));
    postData.append("price", formContent?.price);
    postData.append("description", formContent?.description);
    postData.append("image", JSON.stringify(formContent.image));

    postData.append("categoryId", categoryId);
    serviceImages.forEach((obj) => {
      postData.append("media", obj);
    });
    postData.append("isDefaultImageAdded", isDefaultImageAdded);
    postData.append("prices", JSON.stringify(customPrices));

    // error handling for checking if the person has selected images for their service

    // formContent?.whatDoesItInclude.map((include) =>
    //   postData.append("whatDoesItInclude", include)
    // );
    postData.append(
      "whatDoesItInclude",
      JSON.stringify(formContent?.whatDoesItInclude)
    );

    const formValidation = addServiceFormValidationSchema.validate(formContent);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }

    if (
      formContent?.image.length === 0 &&
      serviceImages.length === 0 &&
      !isDefaultImageAdded
    ) {
      setError(
        "Either choose images or check the default image selected checkbox"
      );
      return;
    }

    if (type === "edit") {
      dispatch(loaderActions.toggleLoading(true));
      editService(data._id, postData)
        .then((res) => {
          handleFetchServices(categoryId);
          handleCancelFunction();
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "10",
          });
        })
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    addService(postData)
      .then((res) => {
        handleFetchServices(categoryId);
        handleCancelFunction();
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "11",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return (
    <div className={`flex-col gap-40 ${classes.main_container}`}>
      {type ? (
        <div className="flex-col align-center small-gap">
          <h2>Edit services</h2>
          <p className={`color-tertiary-grey`}>
            Please edit below details to modify service.
          </p>
        </div>
      ) : (
        <div className="flex-col align-center small-gap">
          <h2>Add services</h2>
          <p className={`color-tertiary-grey`}>
            Please fill the below details to add a new service.
          </p>
        </div>
      )}
      <form className="flex-col gap-40" onSubmit={handleSubmit}>
        <div className="flex-row gap-24">
          <div className="flex-col wd-50 small-gap">
            <label>Service Name</label>
            <Input
              placeholder="Enter service name"
              name="name"
              maxLength={30}
              value={formContent.name}
              onChange={handleFormData}
            />
          </div>
          <div className="flex-col wd-50 small-gap">
            <label>Default Price</label>
            <Input
              placeholder="Enter price"
              type="number"
              name="price"
              min="1"
              value={formContent.price}
              onChange={handleFormData}
            />
          </div>
        </div>
        <div className="flex-col wd-100 small-gap">
          <div className="flex-row align-center just-sp-bwn">
            <label>Set Custom pricing</label>
            <div className="flex-row align-center small-gap">
              <Add
                className="pointer"
                sx={{ color: "#40a59e" }}
                onClick={handleCustomPriceAdd}
              />
              <Remove
                className="pointer"
                sx={{ color: "#40a59e" }}
                onClick={handleCustomPriceRemove}
              />
            </div>
          </div>
          <div className="flex-col medium-gap wd-100">
            {customPrices.map((customPrice, ind) => (
              <div className={classes.customPrice}>
                <div className="flex-row gap-24 wd-100 align-center">
                <div className="wd-50">
                    <Select
                      name="city"
                      placeholder="Select City/Region"
                      //  className="basic-single"
                      styles={customStylesReactSelect}
                      options={cityOptions}
                      value={cityOptions.filter((city) => city?.value === customPrice?.city)[0]}
                      // getOptionLabel={(option) => option.categoryName}
                      // getOptionValue={(option) => option._id}
                      // onChange={(selectedOption) => {
                      //   handleCategorySelect(selectedOption);
                      //   handleFormChange("categoryName", selectedOption);
                      //   setAddedStaff([]);
                      //   setStaffMembers([]);
                      // }}
                      onChange={(selectedOption) =>
                        handleCitySelection(selectedOption, ind)
                      }
                    />
                  </div>
                  
                  <div className="wd-50">
                    <Input
                      value={customPrice?.state}
                      placeholder="State"
                      readOnly="true"
                      style={{ width: "-webkit-fill-available" }}
                    />
                  </div>
                </div>
                <div className="flex-row gap-24 wd-100 align-center">
                <div className="wd-50">
                    <Input
                      value={customPrice?.country}
                      readOnly="true"
                      placeholder="Country"
                      style={{ width: "-webkit-fill-available" }}
                    />
                  </div>
                  <div className="wd-50">
                    <Input
                      value={customPrice?.price}
                      placeholder="Enter custom price"
                      onChange={(e) => handleCustomPriceInput(e, ind)}
                      style={{ width: "-webkit-fill-available" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-col small-gap">
          <label>Description</label>

          <textarea
            placeholder="Describe your services"
            name="description"
            value={formContent.description}
            onChange={handleFormData}
            maxLength={300}
          />
        </div>
        <div className="flex-col small-gap">
          <label>What does it include?</label>
          {formContent?.whatDoesItInclude.map((item, index) => (
            <div
              key={index}
              className="flex-col"
              style={{ position: "relative" }}
            >
              <Input
                placeholder="What does it include?"
                name="whatDoesItInclude"
                value={item}
                onChange={(e) => handleAddedData(e, index)}
                maxLength={100}
                style={{ paddingRight: "30px" }}
              />
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: "12px",
                  top: "8px",
                }}
                onClick={(e) => handleAddIcon(e, index)}
              >
                {index === 0 ? (
                  <Add sx={{ color: "#a1a0a3" }} />
                ) : formContent?.whatDoesItInclude[index] === "" ? (
                  <Remove sx={{ color: "#a1a0a3" }} />
                ) : (
                  <Add sx={{ color: "#a1a0a3" }} />
                )}
              </div>
            </div>
          ))}
        </div>
        {imagesPreview.length !== 0 && (
          <div className="flex-row medium-gap">
            {imagesPreview.map((image, ind) => (
              <div key={image} className={`${classes.image_div}`}>
                <Avatar
                  src={image}
                  variant="rounded"
                  sx={{ height: "100px", width: "100px" }}
                />
                <div
                  className={`${classes.image_cross} miniscule-square bord-rd-6 information-color pointer bg-base`}
                  onClick={() => removeUploadedImage(ind, image)}
                >
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
        )}
        <div>
          <div
            className={`flex-col pd-28 small-gap align-center bord-rd-8 pointer ${classes.image_upload}`}
            onClick={imageUploadClicked}
          >
            <div className={`color-green`}>
              <FilterIcon />
            </div>
            {/* <h3>Drop files here to upload.</h3> */}
            <p>Upload up to 5MB</p>
          </div>
          <Input
            type="file"
            id="add-service-image-upload"
            style={{ display: "none" }}
            onChange={handleImageUpload}
            accept=".jpg, .jpeg, .png"
            multiple={true}
          />
        </div>

        {imageSize && <Error title={"File size must be smaller than 2 MB."} />}

        <div className={`flex-row small-gap align-center`}>
          <input
            type="checkbox"
            checked={isDefaultImageAdded}
            onChange={handleDefaultImageCheckbox}
          />
          <p className="color-tertiary-grey">Add a default image</p>
        </div>

        {error && <Error title={error} />}

        <div className="flex-row wd-100 justify-center large-gap">
          <Button
            type="button"
            background="#f8f8f8"
            color="#5F5F61"
            title="Cancel"
            handleFunction={handleCancelFunction}
          />
          <Button title={type ? "Save" : "Add"} />
        </div>
      </form>
    </div>
  );
};

export default AddService;
