import React, { useEffect, useState } from "react";
import classes from "./Action.module.css";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { CustomSeparator, GenericModal } from "../../../../components";
import LabelData from "../../../../components/common/LabelData/LabelData";
import Avatar from "@mui/material/Avatar";
import Button from "../../../../components/common/commonComponents/Button/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { getJobDetails } from "../../../../api/api_calls/data";
import { toast } from "react-toastify";
import DownloadImages from "./DownloadImages";
import QuestionImage from "./QuestionImage/QuestionImage";

const Action = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [downloadModal, setDownloadModal] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [questionImage, setQuestionImage] = useState(false);
  const [questionImageModal, setQuestionImageModal] = useState(false);

  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Category Management", url: "/home/categoryManagement" },
    {
      label: "Service request",
      url: "/home/categoryManagement/serviceRequests",
      data: { _id: state?.serviceId },
    },
  ];

  const dataFields = [
    { header: "Booking ID", key: "bookingId", type: "capital" },
    {
      header: "Service Name",
      key: "serviceId",
      secondKey: "name",
      depth: true,
    },
    {
      header: "Booked by",
      key: "requestedByData",
      secondKey: "fullName",
      depth: true,
    },
    { header: "Booking Date", key: "createdAt" },
    { header: "Service/Job date and time", key: "jobDate" },
    {
      header: "Assigned to",
      key: "assignedStaffMembersDetail",
      secondKey: "fullName",
      depth: true,
    },
    { header: "Service/Job Status", key: "bookingStatus" },
    { header: "Amount Paid", key: "amount", type: "price" },
    {
      header: "Service Category",
      key: "categoryId",
      secondKey: "categoryName",
      depth: true,
    },
    {
      header: "Market Name",
      key: "marketData",
      secondKey: "marketName",
      depth: true,
    },
    { header: "Address of property/area", key: "address" },
  ];
  const handleDownloadModal = () => {
    setDownloadModal(!downloadModal);
  };
  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getJobDetails(state?._id)
      .then((res) => setJobDetails(res?.data?.data[0]))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  return (
    <div className="flex-col pd-24 gap-28 " style={{ width: "95%" }}>
      <TableHeader
        title="Service requests"
        backIcon={true}
        caption={<CustomSeparator items={breadcrumbs} />}
      />
      <div className="flex-col bg-white pd-24 gap-32">
        <div className={`flex-wrap  ${classes.data_container}`}>
          {jobDetails &&
            dataFields.map((val) => {
              let dataValue;

              if (val.depth) {
                dataValue = jobDetails?.[val.key]?.[val.secondKey];
              } else {
                dataValue = jobDetails?.[val.key];
                
                if (val?.type === "capital") {
                  dataValue = dataValue.toUpperCase();
                } else if (val?.type === "price") dataValue = "$ " + dataValue;
                if (val.key === "jobDate")
                  dataValue = moment(new Date(dataValue)?.toLocaleString("en-us", {timeZone: jobDetails?.timezone
                })).format("lll");
                else if (val?.key === "createdAt") {
                  dataValue = moment(new Date(dataValue)).format("ll");
                }
              }
              return <LabelData label={val.header} data={dataValue || "N/A"} />;
            })}
        </div>
        <div className="flex-row just-sp-bwn align-center">
          <div className="flex-col gap-32 wd-100">
            <div className="flex-row just-sp-bwn align-center wd-100">
              <h3 className="subtle-heading">
                Photos added by the staff
                {jobDetails?.photos.length === 0 && (
                  <p className={classes.imageClass}>N/A</p>
                )}
              </h3>

              <Button
                title="Download"
                height="44px"
                disabled={jobDetails?.photos.length === 0 ? true : false}
                handleFunction={handleDownloadModal}
                icon={<FileDownloadIcon />}
              />
            </div>
            <div className="flex-row medium-gap">
              {jobDetails?.photos.map((photo) => (
                <Avatar
                  src={photo}
                  variant="rounded"
                  sx={{ height: "92px", width: "92px" }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex-col small-gap">
          <label>Tasks or Instructions</label>
          <p className={classes.instructions}>{jobDetails?.task || "N/A"}</p>
        </div>
        <div className="flex-col medium-gap">
          <label>Questionnnaire</label>
          <div className="flex-col normal-gap">
            {jobDetails?.responsesOfQuestions.map((question) =>
              question?.questionData?.text ? (
                <div className="flex-col medium-gap bt-margin">
                  <div className="flex-col normal-gap icon-padding light-border bord-rd-8 wd-80">
                    <h3 className={classes.question}>
                      Q: {question?.questionData?.text}
                    </h3>
                    <p className={classes.instructions}>
                      A: {question?.answer}
                    </p>
                  </div>
                  <div className="flex-row align-center medium-gap">
                    {question?.images.map((image) => (
                      <Avatar
                        className="pointer"
                        variant="rounded"
                        key={image}
                        src={image}
                        sx={{ height: "50px", width: "50px" }}
                        onClick={(e) => {
                          setQuestionImageModal(true);
                          setQuestionImage(e.target.src);
                        }}
                      />
                    ))}
                  </div>
                  <hr />
                </div>
              ) : (
                <p className={classes.imageClass}>N/A</p>
              )
            )}
          </div>
        </div>
      </div>
      <GenericModal
        open={downloadModal}
        onClose={handleDownloadModal}
        content={
          <DownloadImages
            onClose={handleDownloadModal}
            jobId={jobDetails?._id}
          />
        }
      />
      <QuestionImage
        open={questionImageModal}
        image={questionImage}
        onClose={() => setQuestionImageModal(false)}
      />
    </div>
  );
};

export default Action;
