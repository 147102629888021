import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({
  timeRange,
  dataset,
  backgroundColor,
  borderColor,
  label,
}) => {
  let yearsAndMonths = [];
  // let graphData = Array(12).fill(0);
  let graphData = [];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  if (dataset !== undefined && dataset.length > 0) {
    if (timeRange === "lastWeek") {
      dataset.forEach((data) => {
        const dayOfWeek = new Date(data?.date)?.getDay();
        yearsAndMonths.push(dayNames?.[dayOfWeek]);
        graphData.push(data?.count || data?.totalRevenue);
      });
      
    } else if (timeRange === "lastMonth") {
      for (let i = 0; i < 5; i++) {
        yearsAndMonths.push(`Week ${i + 1}`);
        const filtered = dataset.filter(
          (el) => el?.week === `Week ${i + 1}`
        )?.[0];

        if (filtered) {
          graphData.push(filtered.totalRevenue);
        } else {
          graphData.push(0);
        }
      }
      // dataset.forEach((el, i) => {
      //   return (
      //     yearsAndMonths.push(el?.week) &&
      //     graphData.push(el?.count || el?.totalRevenue)
      //   );
      // });
    } else {
      dataset.forEach((el, i) => {
        return (
          yearsAndMonths.push(`${el?.month} ${el?.year}`) &&
          graphData.push(el?.count || el?.totalRevenue)
        );
      });
    }
  }

  const borderWidth = 2;
  const lineWidth = 2;

  const data = {
    labels: yearsAndMonths,
    datasets: [
      {
        label: label,
        data: graphData,
        fill: true,
        backgroundColor,
        borderColor,
        borderWidth,
        lineWidth,
      },
    ],
  };

  const options = {
    responsive: true,
    // boxWidth: 100,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <Line
      className="line-chart"
      data={data}
      options={options}
      // style={{
      //   width: "100%",
      //   height: 300,
      // }}
    />
  );
};

export default LineChart;
