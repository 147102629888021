import Joi from "joi";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } }) // Disallow TLDs like .com, .net, etc.
    .regex(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    .required()
    .label("Email"),
  password: Joi.string()
    .regex(/[ -~]*[a-z][ -~]*/) // at least 1 lower-case
    .regex(/[ -~]*[A-Z][ -~]*/) // at least 1 upper-case
    .regex(/[ -~]*(?=[ -~])[^0-9a-zA-Z][ -~]*/) // basically: [ -~] && [^0-9a-zA-Z], at least 1 special character
    .regex(/[ -~]*[0-9][ -~]*/) // at least 1 number
    .min(8)
    .required()
    .error(
      new Error(
        "Password must contain 1 lower case, 1 upper case , 1 special character and atleast 1 number"
      )
    ),
  role: Joi.number().integer().required(),
});

export default schema;
