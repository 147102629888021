import React from "react";
import calendar from "../../../../../assets/Calendar.svg";
import Button from "../../../../../components/common/commonComponents/Button/Button";
import classes from "./BookingSuccessfull.module.css";
const BookingSuccessfull = ({ handleCompletionModal }) => {
  return (
    <div className="flex-col gap-40 align-center">
      <div>
        <img src={calendar} alt="Calendar" />
      </div>
      <div className={`flex-col gap-4 align-center ${classes.details}`}>
        <label>Booking Confirmed!</label>
        <p>Your booking has been confirmed.</p>
        <p>Check your email for more details</p>
      </div>
      <div className="bottom-pd">
        {" "}
        <Button title="View Bookings" handleFunction={handleCompletionModal} />
      </div>
    </div>
  );
};

export default BookingSuccessfull;
