import React from "react";
import PublicRoute from "./PublicRoute";
import Login from "../components/common/Login/Login";
import { Navigate, Route, Routes } from "react-router";
import SetPassword from "../components/common/SetPassword/SetPassword";
import Loader from "../components/common/commonComponents/Loader/Loader";
import ResetPassword from "../components/common/ResetPassword/ResetPassword";
import ForgotPassword from "../components/common/ForgotPassword/ForgotPassword";
import { ToastContainer } from "react-toastify";

const PublicallyAccessibleRoutes = ({ props }) => {
  const { loader } = props;

  return (
    <div className="main_container">
      {loader && <Loader />}
      <Routes>
        {/* Public Routes... */}
        <Route
          path="/"
          exact
          element={
            <PublicRoute>
              <Navigate replace to="/login" />
            </PublicRoute>
          }
        />
        <Route
          path="login"
          exact
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="resetPassword/:id/:value"
          exact
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="pmCreatePassword/:id"
          exact
          element={
            <PublicRoute>
              <SetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="createPassword/:id"
          exact
          element={
            <PublicRoute>
              <SetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="forgotPassword"
          exact
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />

        {/* default redirection - without auth no pvt should hit... */}
        <Route
          path="*"
          element={
            <PublicRoute>
              <Navigate replace to="/login" />
            </PublicRoute>
          }
        />
      </Routes>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default PublicallyAccessibleRoutes;
