import React from "react";
import classes from './ActionButton.module.css'

const ActionButton = ({ title, handleFunction, background, color, width }) => {
  return (
    <button
    className={classes.actionButton}
      onClick={handleFunction}
      style={{ background: background, color: color, width: width }}
    >
      {title}
    </button>
  );
};

export default ActionButton;
