import React from "react";
import "./App.css";
import { useEffect, useState } from "react";
import MainRouter from "./MainRouter/MainRouter";
import { userActions } from "./redux/reducers/user";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "./redux/reducers/loader";
import { decodeJwt, isAuth } from "./utils/HelperFunctions";
import PropertyManagerRoutes from "./MainRouter/PropertyManagerRoutes";
import PublicallyAccessibleRoutes from "./MainRouter/PublicallyAccessibleRoutes";
import {
  getPropertyManagerDetails,
  getUserDetails,
} from "./api/api_calls/user";
import { toast } from "react-toastify";
import {
  messaging,
  onMessageListener,
  requestPermission,
} from "./pages/SuperAdmin/Chat/firebase";

// master branch
function App() {
  const isLoggedIn = useSelector((state) => state.auth);
  const loader = useSelector((state) => state.loader.isLoading);
  const [getRole, setGetRole] = useState("");
  const dispatch = useDispatch();

  const role = useSelector((state) => state.user.role);

  const token_ = localStorage.getItem("nextGenToken");

  const fetchPropertyManagerDetails = () => {
    try {
      dispatch(loaderActions.toggleLoading(true));
      getPropertyManagerDetails()
        .then((res) => {
          dispatch(userActions.storeUser(res?.data?.data));
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    } catch (error) {}
  };

  useEffect(() => {
    const unsubscribe = onMessageListener((payload) => {
        toast.info(`${payload.notification.title}: ${payload.notification.body}`)
    });
    return () => {
        unsubscribe();
    };
}, []);

  const fetchUserDetails = () => {
    try {
      dispatch(loaderActions.toggleLoading(true));
      getUserDetails()
        .then((res) => {
          dispatch(userActions.storeUser(res?.data?.data));
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    } catch (error) {}
  };

  useEffect(() => {
    let tkValue = "";

    if (token_ !== null) {
      tkValue = decodeJwt(token_ !== "" ? token_ : null);
      setGetRole(tkValue?.role);
    }

    if (tkValue?.role !== "" && token_ !== null) {
      if (tkValue?.role == 1) {
        fetchPropertyManagerDetails();
      } else {
        fetchUserDetails();
      }
    }
  }, [token_]);

  const routerProps = { loader, isLoggedIn, role, getRole };

  const roleArr = [4, 5, 6, 7, 8];

  return (
    <>
      {isLoggedIn.loggedIn ? (
        Boolean(getRole !== "" && getRole === 1) ? (
          <PropertyManagerRoutes props={routerProps} />
        ) : (
          Boolean(getRole !== "" && roleArr.includes(getRole)) && (
            <MainRouter props={routerProps} />
          )
        )
      ) : (
        Boolean(getRole === "" && token_ === null) && (
          <PublicallyAccessibleRoutes props={routerProps} />
        )
      )}
    </>
  );
}

export default App;
