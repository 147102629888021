import React, { useEffect, useState } from "react";
import classes from "./Questionnaire.module.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import { GenericModal } from "../../../components";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { getQuestionnaires } from "../../../api/api_calls/data";
import { deleteQuestionnaire } from "../../../api/api_calls/deleteData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddQuestionnaire from "./AddQuestionnaire/AddQuestionnaire";
import { paginationTheme } from "../../../utils/HelperFuncitons";

const Questionnaire = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [addQuestionnaireModal, setAddQuestionnaireModal] = useState(false);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const headers = [
    { heading: "Name of Questionnaire", key: "questionnaireName" },
    { heading: "Service Name", key: "serviceData", type: "serviceName" },
    { heading: "No. of Questions", key: "questions", type: "totalNumber" },
    { heading: "Action", key: "icon" },
  ];

  useEffect(() => {
    fetchQuestionnaires(search);
  }, [search, rows, page]);

  const fetchQuestionnaires = (searchQuestionnaire) => {
    dispatch(loaderActions.toggleLoading(true));
    getQuestionnaires(searchQuestionnaire, rows, (page - 1) * rows)
      .then((res) => {
        setQuestionnaireList(res?.data?.data?.questionnaire);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleAddQuestionaireModal = (item) => {
    setAddQuestionnaireModal(!addQuestionnaireModal);
  };

  const handleQuestionnaireDelete = (questionnaireId) => {
    dispatch(loaderActions.toggleLoading(true));
    deleteQuestionnaire(questionnaireId)
      .then((res) => fetchQuestionnaires())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "7",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const addIconsToData = (data) => {
    return data.map((item) => ({
      ...item,
      icon: (
        <div
          className="flex-row align-center medium-gap"
          style={{ height: "44px" }}>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() =>
              navigate("/home/questionnaire/questions", {
                state: {
                  categoryId: item?.categoryId,
                  serviceId: item?.serviceId,
                  questionnaireId: item?._id,
                  questionnaireName: item?.questionnaireName,
                },
              })
            }>
            <EditIcon />
          </div>
          <div
            className={`bg-light ht-100 flex-row align-center justify-center pd-12 bord-rd-6 pointer ${classes.icons}`}
            onClick={() => handleQuestionnaireDelete(item._id)}>
            <DeleteOutlineIcon />
          </div>
        </div>
      ),
    }));
  };
  return (
    <div
      className={`bg-white overflow-auto hidden-scroll just-sp-bwn ht-100 flex-col gap-32 pd-24 ${classes.main_container}`}>
      <div className="flex-col gap-32">
        <TableHeader
          title="Questionnaire"
          placeholder="Search Questionnaire"
          searchValue={search}
          handleSearchInput={(e) => setSearch(e.target.value)}
          firsticon={<AddIcon />}
          firstbuttonTitle="Add Questionnaire"
          handleFirstButton={handleAddQuestionaireModal}
        />
        <CustomTable
          headers={headers}
          data={addIconsToData(questionnaireList)}
        />
      </div>
      {questionnaireList.length !== 0 && (
        <div className="flex-row align-center just-sp-bwn">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="table-row-selection">Rows</InputLabel>
              <Select
                labelId="table-row-selection"
                id="table-rows"
                label="Rows"
                value={rows}
                onChange={(e) => setRows(e.target.value)}>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <ThemeProvider theme={paginationTheme}>
            {" "}
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      )}
      <GenericModal
        open={addQuestionnaireModal}
        title="Add Questionnaire"
        caption={"Please fill below details to add a new questionnaire."}
        onClose={handleAddQuestionaireModal}
        content={
          <AddQuestionnaire
            onCancel={handleAddQuestionaireModal}
            handleFetchQuestionnaire={fetchQuestionnaires}
          />
        }
      />
    </div>
  );
};

export default Questionnaire;
