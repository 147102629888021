import API from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const login = (data) => {
  return wrappedFetch(API.LOGIN_URL, "post", data);
};

export const changeUserPassword = (data) => {
  return wrappedFetch(API.CHANGE_PASSWORD, "post", data);
};

export const changePropertyManagerPassword = (data) => {
  return wrappedFetch(API.CHANGE_PROPERTY_MANAGER_PASSWORD, "put", data);
};

export const pmCreatePassword = (id) => {
  return wrappedGet(`${API.PM_CREATE_PASS_URL}/${id}`, "get");
};

export const pmSetPassword = (data) => {
  return wrappedFetch(API.PM_RESET_PASSWORD, "put", data);
};

export const propertyManagerLogin = (data) => {
  return wrappedFetch(API.PROPERTY_MANAGER_LOGIN_URL, "post", data);
};

export const forgotPassword = (data) => {
  return wrappedFetch(API.FORGOT_PASSWORD_URL, "post", data);
};

export const resetPassword = (data) => {
  return wrappedFetch(API.RESET_PASSWORD, "post", data);
};

export const setPassword = (id) => {
  return wrappedGet(`${API.ACCEPT_INVITE}/${id}`, "get");
};
export const createPassword = (data, id) => {
  return wrappedFetch(`${API.CREATE_PASSWORD}/${id}`, "put", data);
};

export const logout = (data) => {
  return wrappedFetch(API.LOGOUT_URL, "post", data);
};
