import API from "../api_setting/apiConstants";
import { wrappedFetch } from "../api_setting/urlFuncs";

export const assignStaff = (data) => {
  return wrappedFetch(API.ASSIGN_STAFF_TO_A_JOB, "post", data);
};

export const acceptJob = (data) => {
  return wrappedFetch(API.ACCEPT_JOB, "post", data);
};

export const rejectJob = (data) => {
  return wrappedFetch(API.REJECT_JOB, "post", data);
};

export const createJobForUnregistered = (data) => {
  return wrappedFetch(API.CREATE_UNREGISTERED_USER_JOB, "post", data)
}
