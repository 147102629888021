import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { CustomSeparator } from "../../../../components";
import { loaderActions } from "../../../../redux/reducers/loader";
import { getPmUpcomingJobs } from "../../../../api/api_calls/data";
import UpcomingBookings from "../../../../components/common/UpcomingBookings/UpcomingBookings";
import { ThemeProvider } from "@emotion/react";
import { Pagination } from "@mui/material";
import { paginationTheme } from "../../../../utils/HelperFuncitons";
import { toast } from "react-toastify";

const AllUpcomingBookings = () => {
  const [bookingArray, setBookingArray] = useState({
    data: [],
    totalCount: "",
    loading: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const breadcrumbs = [
    { label: "Home", url: "/home/dashboard" },
    { label: "Upcoming Bookings" },
  ];

  const dispatch = useDispatch();

  const fetchUpcomingBookings = async () => {
    dispatch(loaderActions.toggleLoading(true));
    try {
      const res = await getPmUpcomingJobs((page - 1) * 10, 10);
      if (res?.status === 200) {
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / 10));
        setBookingArray((v) => ({
          ...v,
          data: res?.data?.data,
          totalCount: res?.data?.data?.length,
          loading: false,
        }));

        dispatch(loaderActions.toggleLoading(false));
      }
    } catch (error) {
      dispatch(loaderActions.toggleLoading(false));
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchUpcomingBookings();
  }, [page]);

  return (
    <div className="flex-col ht-100 gap-28 side-pd-70" style={{ width: "90%" }}>
      <TableHeader
        title="Upcoming Bookings"
        caption={<CustomSeparator items={breadcrumbs} />}
        backIcon={true}
      />
      <div className="flex-col gap-32 pd-24 ht-100 overflow-auto  bg-white just-sp-bwn">
        <UpcomingBookings
          bookings={bookingArray?.data?.jobs || []}
          viewAll={true}
        />
        <div className="flex-row justify-right mt-40">
          <ThemeProvider theme={paginationTheme}>
            <Pagination
              count={pageCount}
              shape="rounded"
              size="large"
              color="primary"
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default AllUpcomingBookings;
