import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteProperty } from "../../../../api/api_calls/deleteData";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { loaderActions } from "../../../../redux/reducers/loader";

const DeleteProperty = ({ propertyId, handleCancelFunction }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(loaderActions.toggleLoading(true));
    deleteProperty(propertyId)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          return;
        }
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "14",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  return (
    <div
      className="flex-col text-center justify-center align-center wd-100 large-gap "
      style={{ paddingBottom: "20px", marginTop: "-20px" }}>
      <div className="large-square circular-div flex-row align-center justify-center light-pink-bg-color">
        <div className="pink-icon flex-row align-center justify-center font-40 big-icon-square circular-div">
          <DeleteForeverIcon fontSize="inherit" />
        </div>
      </div>
      <div className="flex-col small-gap">
        <h3>Are you sure you want to delete?</h3>
        <p className="information-color">
          Are you sure you want to delete this property?
        </p>
      </div>
      <div className="flex-row medium-gap">
        <Button
          theme="gray"
          title="No, Cancel"
          handleFunction={handleCancelFunction}
        />
        <Button title="Yes" handleFunction={handleDelete} />
      </div>
    </div>
  );
};

export default DeleteProperty;
