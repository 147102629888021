import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import {
  Avatar,
  Badge,
  Divider,
  Drawer,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/reducers/auth";
import API from "../../../api/api_setting/apiConstants";
import { loaderActions } from "../../../redux/reducers/loader";
import { logout } from "../../../api/api_calls/auth";
import { userActions } from "../../../redux/reducers/user";
import { queryNotificationDetails } from "../../../pages/SuperAdmin/Chat/ChatBox/firebaseQueries";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import NotificationDrawer from "../NotificationDrawer/NotificationDrawer";
import { db } from "../../../pages/SuperAdmin/Chat/firebase";

const Navbar = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  useEffect(() => {
    const notificationDetailQuery = queryNotificationDetails();

    const notificationDetailUnsubscribe = onSnapshot(
      notificationDetailQuery,
      (snapshot) => {
        snapshot.forEach((doc) => setNotificationCount(doc.data().count));
      }
    );

    return () => notificationDetailUnsubscribe();
  }, []);

  const handleLogOut = () => {
    dispatch(loaderActions.toggleLoading(true));
    localStorage.removeItem(API.AUTH_TOKEN);
    dispatch(authActions.logout());

    const firebaseToken = localStorage.getItem("FIREBASE_TOKEN");
    localStorage.removeItem("FIREBASE_TOKEN");

    logout({ firebaseToken, id: userData?.id })
      .then((res) => {
        return;
      })
      .catch((error) => console.log(error));

    dispatch(userActions.removeUser());

    navigate("/login");
    window.location.reload();

    dispatch(loaderActions.toggleLoading(false));
  };

  const readNotification = async () => {
    try {
      let notificationRef = doc(db, "notifications", "rootnotifications");

      await updateDoc(notificationRef, {
        isRead: true,
        count: 0,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`flex-row ${classes.navbar}`}>
      <h2 className="font-20 color-brand-secondary mg-0">Dashboards</h2>
      <div className={`flex-row ${classes.actions}`}>
        {userData?.permissions?.some(
          (permission) => permission === "All" || permission === "Notifications"
        ) && (
          <>
            <Badge
              color="secondary"
              badgeContent={notificationCount}
              className="pointer"
              onClick={toggleDrawer(true)}
            >
              <NotificationsIcon />
            </Badge>
            <NotificationDrawer
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              read={readNotification}
            />
          </>
        )}

        <Tooltip title="Account Settings">
          <Avatar
            src={userData.profileImg}
            variant="rounded"
            onClick={handleClick}
            className="pointer"
          />
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose} style={{ background: "white" }}>
            <ListItemIcon>
              <div className={`${classes.profile} flex-row`}>
                <Avatar src={userData.profileImg} variant="rounded" />
                <div>
                  <p className={classes.name}>{userData.name}</p>
                  <p>{userData.email}</p>
                </div>
              </div>
            </ListItemIcon>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => navigate("/home/profile")}
            className="pointer"
          >
            My Account
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleLogOut();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
