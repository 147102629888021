import {
  Avatar,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import groupTags from "../grouptag";
import CustomTag from "../CustomTag/CustomTag";
import moment from "moment";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { styled } from "@mui/material";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#F4A118",
  },
});

const CustomTable = ({ headers, data }) => {
  const matchesGroupTag = (item) => {
    return groupTags.some((tag) => tag.name === item);
  };
  const showRatings = (data) => {
    if (data === 0) {
      return (
        <p
          style={{
            color: "#1F2327",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Roboto Slab",
            paddingLeft: "2px",
          }}
        >
          No Ratings Added
        </p>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <StyledRating
            name="read-only"
            value={data}
            precision={0.1}
            readOnly
            emptyIcon={<StarBorderRoundedIcon />}
            icon={<StarRoundedIcon />}
          />
          <p
            style={{
              color: "#1F2327",
              fontSize: "14px",
              fontWeight: 600,
              fontFamily: "Roboto Slab",
            }}
          >
            {`${data.toFixed(2)}`}
          </p>
        </div>
      );
    }
  };
  const permissionsGiven = (data) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          maxWidth: "500px",
          flexWrap: "wrap",
        }}
      >
        {data?.map((item) => (
          <button
            style={{
              background: "#f8f8f8",
              color: "black",
              padding: "4px 12px",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "400",
              border: "1px solid #e0e0e0",
              cursor: "default"
            }}
          >
            {item}
          </button>
        ))}
      </div>
    );
  };
  const questionType = (data) => {
    if (data === "mcq") return "MCQ";
    else if (data === "yesno") return "Yes / No";
    else return "Description";
  };
  const getColumnData = (type, data) => {
    switch (type) {
      case "capital":
        return data?.toUpperCase()
      case "date":
        return moment(data).local(true).format("ll");

      case "serviceTimings":
        return moment(data?.startTime).format("lll");

      case "serviceTime":
        return moment(data).format("lll");

      case "serviceName":
        return data?.name || data?.fullName || "N/A";

      case "serviceCategory":
        return data?.categoryName || "";

      case "rating":
        return showRatings(data);

      case "status":
        return <CustomTag title={data === true ? "Active" : "Inactive"} />;

      case "permission":
        return permissionsGiven(data);

      case "totalNumber":
        return data?.length;

      case "propertyManagerStatus":
        return <CustomTag title={data === true ? "Active" : "Inactive"} />;

      case "price":
        return `$ ${data}`;

      case "questionType":
        return questionType(data);

      case "staffStatus":
        return data ? (
          <CustomTag title="Registered" background="#E8F6ED" color="#1DA94A" />
        ) : (
          <CustomTag
            title="Unregistered"
            background="#FCEAEA"
            color="#E73F3F"
          />
        );

      default:
        return data;
    }
  };

  return (
    <TableContainer className="overflow-auto hidden-scroll">
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((val) => (
              <TableCell
                sx={{
                  color: "#40A59E",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Roboto Slab",
                }}
                key={val?.heading}
              >
                {val.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((val, ind) => (
              <TableRow key={ind}>
                {data.length !== 0 &&
                  headers.map((header, ind) => {
                    if (header.key === "image") {
                      return (
                        <TableCell key={`${header.key}_${ind}`}>
                          <Avatar src={val.image?.[0]} />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={`${header.key}_${ind}`}
                        sx={{ fontFamily: "Roboto Slab" }}
                      >
                        {header?.type ? (
                          getColumnData(header.type, val[header.key]) || "NA"
                        ) : Boolean(
                            matchesGroupTag(val[header.key]) ||
                              val[header?.key2] === true
                          ) ? (
                          val[header?.key2] === true ? (
                            <CustomTag
                              title={val[header.key2]}
                              customName={
                                val[header?.key2] === true ? "Discarded" : ""
                              }
                            />
                          ) : (
                            <CustomTag title={val[header.key]} /> || "NA"
                          )
                        ) : (
                          val[header.key] || "NA"
                        )}
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {data == false || data == undefined ? (
        <p
          style={{
            display: "flex",
            fontWeight: "400",
            marginTop: "0.75rem",
            color: "#5F5F61",
            justifyContent: "center",
          }}
        >
          No record found
        </p>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export default CustomTable;
