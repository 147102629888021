import React, { useState } from "react";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { getCategoryIcon } from "../../../../utils/icons";
import { GenericModal } from "../../../../components";
import ConfirmDeleteCategory from "./ConfirmDeleteCategory/ConfirmDeleteCategory";

const EditCategory = ({ categories, handleCategoryModalType }) => {
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleDeleteCategoryModal = () => {
    setDeleteCategoryModal(!deleteCategoryModal);
  };
  return (
    <div
      className="flex-col large-gap"
      style={{ width: "85%", paddingBottom: "20px" }}
    >
      <p>All Categories</p>
      <div className="flex-col large-gap">
        {categories.map((category) => (
          <div
            className="flex-row wd-100 just-sp-bwn align-center"
            key={category._id}
          >
            <div className="flex-row align-center medium-gap information-color">
              <div className="action-square circular-div flex-row align-center justify-center bg-base">
                <div style={{ height: "24px", width: "24px", fill: "#a1a0a3" }}>
                  {getCategoryIcon(category.categoryIcon)}
                </div>
              </div>
              <p className="color-grey-secondary">{category.categoryName}</p>
            </div>
            <div className="flex-row medium-gap align-center">
              <p
                className="pointer error-color"
                onClick={() => {
                  setDeleteCategoryModal(true);
                  setSelectedCategory(category._id);
                }}
              >
                Delete
              </p>
              <Button
                title="Edit"
                handleFunction={() => handleCategoryModalType(category)}
              />
            </div>
          </div>
        ))}
      </div>
      <GenericModal
        open={deleteCategoryModal}
        onClose={handleDeleteCategoryModal}
        content={
          <ConfirmDeleteCategory
            categoryId={selectedCategory}
            handleCancelFunction={handleDeleteCategoryModal}
          />
        }
      />
    </div>
  );
};

export default EditCategory;
