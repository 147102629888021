import React, { useEffect, useState } from "react";
import classes from "../StaffManagement/StaffManagement.module.css";
import CustomTable from "../../../components/common/commonComponents/CustomTable/CustomTable";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TableHeader from "../../../components/common/commonComponents/TableHeader/TableHeader";
import {
  getAllServices,
  getClients,
  getStaffList,
} from "../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { paginationTheme } from "../../../utils/HelperFuncitons";
import { toast } from "react-toastify";
import { GenericModal } from "../../../components";
// import AddStaff from "./AddStaff/AddStaff";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import ConfirmDeleteStaff from "./ConfirmDeleteStaff/ConfirmDeleteStaff";
// import StaffManagementTable from "./StaffManagementTable";

const headings = [
  { heading: "Name", key: "fullName" },
  { heading: "Email Address", key: "email" },
  { heading: "Mobile Number", key: "mobileNumber" },
  { heading: "City", key: "city" },
  { heading: "Number of Bookings", key: "bookingCount" },
];

const StaffManagement = () => {
  const dispatch = useDispatch();

  const [clientList, setClientList] = useState([]);
  const [clientStatus, setClientStatus] = useState("active");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [clientSearch, setClientSearch] = useState("");

  //   useEffect(() => {
  //     dispatch(loaderActions.toggleLoading(true));
  //     setPage(1);

  //     getStaffList(0, rows, staffSearch, clientStatus, orderBy, order)
  //       .then((res) => {
  //         setStaffList(res?.data?.data?.staff);
  //         const totalData = res?.data?.data?.countData;

  //         setPageCount(Math.ceil(totalData / rows));
  //       })
  //       .catch((error) =>
  //         toast.error(error?.message, {
  //           toastId: "error in one dependency use effect",
  //         })
  //       )
  //       .finally(() => dispatch(loaderActions.toggleLoading(false)));
  //   }, [clientStatus, order, orderBy]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getClients(rows, (page - 1) * rows, clientSearch, clientStatus)
      .then((res) => {
        setClientList(res?.data?.data?.clients);
        const totalData = res?.data?.data?.countData;

        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  }, [clientStatus]);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(loaderActions.toggleLoading(true));
      getClients(rows, (page - 1) * rows, clientSearch, clientStatus)
        .then((res) => {
          setClientList(res?.data?.data?.clients);
          const totalData = res?.data?.data?.countData;

          setPageCount(Math.ceil(totalData / rows));
        })
        .catch((error) => toast.error(error?.message))
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));
    }, 1000);

    return () => clearTimeout(getData);
  }, [rows, page, clientSearch]);

  useEffect(() => {
    dispatch(loaderActions.toggleLoading(true));
    getClients(rows, (page - 1) * rows, clientSearch, clientStatus)
      .then((res) => {
        setClientList(res?.data?.data?.clients);
        const totalData = res?.data?.data?.countData;

        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) =>
        toast.error(error?.message, { toastId: "error in getting clients" })
      )
      .finally(() => dispatch(loaderActions.toggleLoading(false)));
  }, []);

  const dropdown = (dataset) => {
    if (dataset && dataset[0]?.title !== "All Services") {
      dataset.unshift({ title: "All Services", value: "all" });
    }

    dataset?.map((data) => {
      data.title = data.name || data.title;
      data.value = data._id || data.value;
    });

    return dataset;
  };

  //   const handleServiceFilter = (service) => {
  //     setSelectedService(service);
  //   };

  const addCountryCodeToMobile = (data) => {
    return data?.map((item) => ({
      ...item,
      mobileNumber: `${item?.countryCode} - ${item?.mobileNumber}`,
    }));
  };

  return (
    <div
      className="flex-col gap-32 pd-24 ht-100 medium-gap bg-white overflow-auto"
      style={{ width: "96%" }}
    >
      <div className="wd-100 flex-row  justify-center align-center">
        <div
          className={`pd-16 ${
            clientStatus === "active" ? classes.active : "bg-light"
          } bord-right-gray flex-row pointer justify-center`}
          style={{ width: "300px" }}
          onClick={() => setClientStatus("active")}
        >
          Active
        </div>
        <div
          className={`pd-16 ${
            clientStatus === "inActive" ? classes.active : "bg-light"
          } flex-row pointer justify-center`}
          style={{ width: "300px" }}
          onClick={() => setClientStatus("inActive")}
        >
          Inactive
        </div>
      </div>
      <div className="flex-col gap-32">
        <TableHeader
          title="Client Management"
          placeholder="Search Client"
          handleSearchInput={(e) => setClientSearch(e.target.value)}
          searchValue={clientSearch}
        />
      </div>
      <div className="flex-col just-sp-bwn gap-32 ht-100">
        <CustomTable
          headers={headings}
          data={addCountryCodeToMobile(clientList)}
        />
        {clientList?.length !== 0 && (
          <div className="flex-row align-center just-sp-bwn">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel
                  id="table-row-selection"
                  sx={{ fontFamily: "Roboto Slab" }}
                >
                  Rows
                </InputLabel>
                <Select
                  labelId="table-row-selection"
                  id="table-rows"
                  label="Rows"
                  value={rows}
                  onChange={(e) => {
                    setRows(e.target.value);
                    setPage(1);
                  }}
                  sx={{ fontFamily: "Roboto Slab" }}
                >
                  <MenuItem value={12} sx={{ fontFamily: "Roboto Slab" }}>
                    12
                  </MenuItem>
                  <MenuItem value={10} sx={{ fontFamily: "Roboto Slab" }}>
                    10
                  </MenuItem>
                  <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                    8
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <ThemeProvider theme={paginationTheme}>
              <Pagination
                count={pageCount}
                shape="rounded"
                size="large"
                color="primary"
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
      {/* <GenericModal
        open={addStaffModal}
        onClose={() => setAddStaffModal(false)}
        title="Add Staff"
        caption="Fill the below form to add Staff"
        content={<AddStaff onClose={() => setAddStaffModal(false)} />}
      />
      <GenericModal
        open={deleteStaffModal}
        onClose={() => {
          setDeleteStaffModal(false);
          setSelectedStaff(null);
        }}
        content={
          <ConfirmDeleteStaff
            staffId={selectedStaff}
            handleCancelFunction={() => {
              setDeleteStaffModal(false);
              setSelectedStaff(null);
            }}
          />
        }
      /> */}
    </div>
  );
};

export default StaffManagement;
