import React, { useEffect, useState } from "react";
import classes from "./AddGroup.module.css";
import Input from "../../../../../components/common/commonComponents/Input/Input";
import SearchInput from "../../../../../components/common/commonComponents/SearchInput/SearchInput";
import {
  getAllUnassignedStaff,
  getCategory,
} from "../../../../../api/api_calls/data";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../../redux/reducers/loader";
import StaffAvatar from "../../../../../components/common/StaffAvatar/StaffAvatar";
import Button from "../../../../../components/common/commonComponents/Button/Button";
import {
  addGroupValidation,
  updateGroupValidation,
} from "../../../../../validations/addDataValidation";
import Error from "../../../../../components/common/commonComponents/Error/Error";
import {
  addMarketGroup,
  updateGroup,
} from "../../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import Select from "react-select";
import { customStylesReactSelect } from "../../../../../utils/HelperFuncitons";
import ViewGroup from "./ViewGroup";
import { Grid } from "@mui/material";
import GenericDrawer from "../../../../../components/modal/GenericDrawer";
const AddGroup = ({ onCancel, type = "", marketId, data }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    groupName: data?.groupName ? data?.groupName : "",
    categoryName: data?.categoryData ? data?.categoryData : "",
    groupMember: data?.groupMemberData ? data?.groupMemberData : [],
  });
  const previousMember = data?.groupMemberData ? data?.groupMemberData : [];
  const [open, setOpen] = useState(false);
  const [staffMembers, setStaffMembers] = useState([]);
  const [addedStaff, setAddedStaff] = useState([]);
  const [error, setError] = useState("");
  const [category, setCategory] = useState([]);

  // const [service, setService] = useState([]);
  const [removedStaff, setRemovedStaff] = useState([]);
  const [searchManager, setSearchManager] = useState("");

  const defaultCategory = {
    value: formData?.categoryName?._id,
    label: formData?.categoryName?.categoryName,
  };
  const fetchAllServiceCategory = async () => {
    try {
      dispatch(loaderActions.toggleLoading(true));

      const res = await getCategory();
      if (res?.status === 200) {
        setCategory(res?.data?.data?.categories);
      }
    } catch (error) {}
    dispatch(loaderActions.toggleLoading(false));
  };

  // const fetchServices = async (id) => {
  //   try {
  //     dispatch(loaderActions.toggleLoading(true));

  //     const res = await getService(id);
  //     if (res?.status === 200) {
  //       setService(res?.data?.data?.services);
  //     }
  //   } catch (error) {}
  //   dispatch(loaderActions.toggleLoading(false));
  // };
  const fetchStaff = async (categoryId, text) => {
    try {
      const res = await getAllUnassignedStaff(categoryId, text);
      if (res?.status === 200) {
        setStaffMembers(res?.data?.data);
      }
    } catch (error) {}
  };
  const handleServiceCategorySelect = (e) => {
    setSearchManager("");
    fetchStaff(e?._id, searchManager);
  };

  const handleManagerSearch = (e) => {
    setSearchManager(e.target.value);
    fetchStaff(formData?.categoryName?._id, e.target.value);
  };
  const handleAddingStaff = (e) => {
    const staffIndex = addedStaff.findIndex((person) => person === e);
    let updatedAddedStaff;

    if (staffIndex !== -1) {
      updatedAddedStaff = [...addedStaff];
      updatedAddedStaff.splice(staffIndex, 1);
      setAddedStaff(updatedAddedStaff);
    } else {
      updatedAddedStaff = [...addedStaff, e];
      setAddedStaff(updatedAddedStaff);
    }

    return updatedAddedStaff;
  };

  const handleFormChange = (name, value) => {
    if (name === "groupMember") {
      const updatedValue = handleAddingStaff(value);
      value = updatedValue;
    }
    setFormData((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };
  const handleStaffChange = (data) => {
    setRemovedStaff(data);
  };
  const handleModal = () => {
    setOpen(!open);
  };
  const updateGroupFunction = () => {
    let updatedGroupMembers, members;
    const groupArray = previousMember.map((item) => item._id);
    if (removedStaff.length === 0) {
      members = formData?.groupMember.some(
        (item) => typeof item === "object" && item !== null
      )
        ? formData?.groupMember.map((item) => item._id)
        : formData?.groupMember;

      const unionSet = new Set([...groupArray, ...members]);
      updatedGroupMembers = Array.from(unionSet);
    } else if (
      formData?.groupMember.some(
        (item) => typeof item === "object" && item !== null
      )
    ) {
      const previousMember = formData?.groupMember.map((item) => item._id);
      updatedGroupMembers = previousMember.filter(
        (id) => !removedStaff.includes(id)
      );
    } else {
      members = previousMember.map((item) => item._id);
      const remainingMembers = members.filter(
        (item) => !members.includes(item)
      );
      const unionSet = new Set([...remainingMembers, ...formData?.groupMember]);
      updatedGroupMembers = Array.from(unionSet);
    }

    const postForm = {
      groupId: data?._id,
      groupName: formData?.groupName,
      groupMember: updatedGroupMembers,
      removedGroupMember: removedStaff,
      groupTagName: formData?.categoryName?.categoryName,
    };
    const formValidation = updateGroupValidation.validate(postForm);
    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }
    dispatch(loaderActions.toggleLoading(true));
    updateGroup(postForm)
      .then((res) => window.location.reload())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "3000",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  const addGroupFunction = () => {
    const postForm = {
      marketId: marketId,
      groupName: formData?.groupName,
      categoryId: formData?.categoryName?._id,
      // serviceId: [formData?.serviceName?._id],
      groupMember: formData?.groupMember,
      groupTagName: formData?.categoryName?.categoryName,
    };

    const formValidation = addGroupValidation.validate(formData);

    if (formValidation?.error?.message) {
      setFormData((v) => ({ ...v, categoryName: "" }));

      setError(formValidation.error.message);
      return;
    }
    dispatch(loaderActions.toggleLoading(true));
    addMarketGroup(postForm)
      .then((res) => window.location.reload())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "3000",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === "update") {
      updateGroupFunction();
    } else {
      addGroupFunction();
    }
  };
  useEffect(() => {
    if (type !== "") {
      fetchStaff(formData?.categoryName?._id, searchManager);
    }
  }, [type]);
  useEffect(() => {
    if (type === "") {
      fetchAllServiceCategory();
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return (
    <div className={classes.addGroupForm}>
      <div className="flex-row gap-24 ">
        <div className={`${classes.groupField} wd-100 flex-col small-gap`}>
          <label>Group Name</label>
          <Input
            name="groupName"
            placeholder="Enter group name"
            value={formData?.groupName}
            onChange={(e) => handleFormChange(e.target.name, e.target.value)}
            maxLength={30}
          />
        </div>

        <div className={`${classes.groupField} flex-col small-gap`}>
          <label>Service Category</label>
          <Select
            name="serviceCategory"
            placeholder="Select Category"
            styles={customStylesReactSelect}
            defaultValue={data ? defaultCategory : formData?.categoryName}
            options={data ? [] : category}
            getOptionLabel={(option) =>
              data ? option.label : option.categoryName
            }
            getOptionValue={(option) => (data ? option.value : option._id)}
            isDisabled={data ? true : false}
            onChange={(selectedOption) => {
              handleServiceCategorySelect(selectedOption);
              handleFormChange("categoryName", selectedOption);
              setAddedStaff([]);
              setStaffMembers([]);
            }}
          />
        </div>
        {/* <div className={`${classes.formField} flex-col small-gap`}>
          <label>Service</label>
          <Select
            name="service"
            placeholder="Select Service"
            styles={customStylesReactSelect}
            value={data ? defaultService : formData?.serviceName}
            options={data ? [] : service}
            getOptionLabel={(option) => (data ? option.label : option.name)}
            getOptionValue={(option) => (data ? option.value : option._id)}
            isDisabled={data ? true : false}
            onChange={(selectedOption) => {
              handleServiceSelect(selectedOption);
              handleFormChange("serviceName", selectedOption);
              setAddedStaff([]);
              setStaffMembers([]);
            }}
          />
        </div> */}
      </div>
      <div className="flex-col large-gap">
        <div className="flex-col small-gap">
          <h3>Add staff members to the Group</h3>
          <p>Please add staff members to this group</p>
        </div>
        <SearchInput
          placeholder="Search Staff"
          onChange={handleManagerSearch}
          value={searchManager}
          disabled={formData?.categoryName === "" ? true : false}
        />
      </div>
      <Grid item xs={12}>
        <div className={`flex-col small-gap`}>
          <label>Select Staff Member</label>
          {staffMembers.length !== 0 ? (
            <div
              className={`flex-col medium-gap icon-padding ${classes.staffDiv}`}>
              {staffMembers.map((staff, ind) => (
                <div
                  className="flex-row just-sp-bwn align-center"
                  key={`adding_staff_${ind}`}>
                  <StaffAvatar
                    img={staff.profilePicture}
                    name={staff.fullName}
                    email={staff.email}
                  />
                  <Button
                    type="button"
                    title={
                      addedStaff.some((person) => person === staff._id)
                        ? "Remove"
                        : "Add"
                    }
                    background="white"
                    color={
                      addedStaff.some((person) => person === staff._id)
                        ? "#CBCBCB"
                        : "#40A59E"
                    }
                    border={
                      addedStaff.some((person) => person === staff._id)
                        ? "#CBCBCB"
                        : "#40A59E"
                    }
                    value={staff._id}
                    handleFunction={(e) =>
                      handleFormChange("groupMember", e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <Input placeholder="No Staff Found" readOnly={true} />
          )}
        </div>
        {type === "update" ? (
          <div style={{ paddingTop: "18px" }}>
            <Button
              title="View Added Staff Members"
              background={"#D0F2F0"}
              color={"#40A59E"}
              handleFunction={handleModal}
            />
          </div>
        ) : (
          ""
        )}
      </Grid>
      {error && <Error title={error} />}
      <div className="flex-row wd-100 justify-center medium-gap bottom-pd">
        <GenericDrawer
          open={open}
          onClose={handleModal}
          title="All Staff Members"
          content={
            <ViewGroup
              onCancel={handleModal}
              groupMemberDetails={previousMember}
              removedStaff={removedStaff}
              onRemovedStaffChange={handleStaffChange}
            />
          }
        />

        <Button
          type="button"
          title="Cancel"
          theme="gray"
          handleFunction={onCancel}
        />
        <Button
          title={type === "update" ? "Save" : "Create"}
          type={"submit"}
          handleFunction={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddGroup;
