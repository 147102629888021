import React, { useEffect, useState } from "react";
import TableHeader from "../../../../components/common/commonComponents/TableHeader/TableHeader";
import { CustomSeparator, GenericModal } from "../../../../components";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AssignStaff } from "../AssignStaff/AssignStaff";
import { getServiceJobRequests } from "../../../../api/api_calls/data";
import moment from "moment";
import { paginationTheme } from "../../../../utils/HelperFuncitons";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { rejectJob } from "../../../../api/api_calls/schedule";
import { toast } from "react-toastify";
import CustomTag from "../../../../components/common/commonComponents/CustomTag/CustomTag";
import ActionButton from "./ActionButton.jsx/ActionButton";

const ServiceRequests = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [showAssign, setShowAssign] = useState(false);
  const [serviceRequestsData, setServiceRequestsData] = useState([]);
  const [assignJobData, setAssignJobData] = useState(null);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    fetchServiceJobRequests(state?._id);
  }, [page, rows]);

  const fetchServiceJobRequests = (serviceId) => {
    getServiceJobRequests(serviceId, rows, (page - 1) * rows)
      .then((res) => {
        setServiceRequestsData(res?.data?.data?.services);
        const totalData = res?.data?.data?.countData;
        setPageCount(Math.ceil(totalData / rows));
      })
      .catch((error) => toast.error(error?.message));
  };

  const handleAssignModal = (data) => {
    setShowAssign(!showAssign);
    if (data) setAssignJobData(data);
  };

  const breadcrumbs = [
    { label: "Dashboard", url: "/home/dashboard" },
    { label: "Category Management", url: "/home/categoryManagement" },
    { label: "Service request" },
  ];

  const headers = [
    "Booking ID",
    "Booked by",
    "Booking date",
    "Status",
    "Assigned to",
    "Service/Job date",
    "Address",
    "Action",
  ];

  const handleRejectJob = (jobId) => {
    dispatch(loaderActions.toggleLoading(true));
    const postData = {
      jobId: jobId,
    };
    rejectJob(postData)
      .then((res) => fetchServiceJobRequests(state?._id))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const getJobStatus = (jobData) => {
    if (jobData?.jobDiscarded)
      return (
        <CustomTag title="Discarded" background="#FCEAEA" color="#E73F3F" />
      );

    if (jobData?.bookingStatus === "Completed")
      return <CustomTag title="Completed" />;
    if (jobData?.jobRejected)
      return (
        <CustomTag title="Rejected" background="#FCEAEA" color="#E73F3F" />
      );

    if (jobData?.jobRejectedByStaff)
      return (
        <CustomTag
          background="#FCEAEA"
          color="#E73F3F"
          title="Rejected by Staff"
        />
      );

    if (jobData?.jobAcceptedByStaff) return <CustomTag title="Accepted" />;

    if (
      !jobData?.jobRejected &&
      !jobData?.jobRejectedByStaff &&
      !jobData?.jobAcceptedByStaff
    ) {
      if (jobData?.staffData?._id)
        return (
          <CustomTag
            background="#FFF5E5"
            color="#E49614"
            title="Pending by Staff"
          />
        );
      else
        return (
          <CustomTag
            background="#FFF5E5"
            color="#E49614"
            title="Pending Approval"
          />
        );
    }
  };

  const actionNeeded = (jobData) => {
    if (
      jobData?.bookingStatus !== "Completed" &&
      !jobData?.jobRejected &&
      !jobData?.jobDiscarded &&
      (!jobData?.staffData?._id || jobData?.jobRejectedByStaff)
    )
      return true;
    else return false;
  };

  return (
    <div className="flex-col pd-24 gap-32 ht-100" style={{ width: "95%" }}>
      <TableHeader
        title="Service requests"
        caption={<CustomSeparator items={breadcrumbs} />}
      />
      <div className="flex-col bg-white pd-24 just-sp-bwn gap-32 ht-100 overflow-auto">
        <div>
          <TableHeader title={state?.name} />
          <TableContainer className="overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((val, ind) => (
                    <TableCell
                      key={`service_request_header_${ind}`}
                      sx={{
                        color: "#40a59e",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "Roboto Slab",
                      }}>
                      {val}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {serviceRequestsData.map((data, ind) => (
                  <TableRow key={`service_request_row_${ind}`}>
                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {data.bookingId.toUpperCase() || "1234"}
                    </TableCell>

                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {data?.requestedByDetail?.fullName || ""}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {data?.createdAt
                        ? moment(new Date(data?.createdAt)).format("ll")
                        : ""}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {getJobStatus(data)}
                    </TableCell>
                    {data?.staffData?.fullName ? (
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Roboto Slab",
                        }}>
                        {data.staffData.fullName}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          color: "#a1a0a3",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Roboto Slab",
                        }}>
                        Unassigned
                      </TableCell>
                    )}
                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {data?.jobTiming
                        ? moment(new Date(data?.jobTiming?.startTime).toLocaleString("en-us", {timeZone: "PST"})).format("lll")
                        : "N/A"}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Roboto Slab" }}>
                      {`${data?.address}, ${data?.postalCode}`}
                    </TableCell>

                    <TableCell>
                      {actionNeeded(data) ? (
                        <div className="flex-row align-center normal-gap">
                          <ActionButton
                            title="Accept"
                            handleFunction={() => handleAssignModal(data)}
                            width="70px"
                          />
                          <ActionButton
                            title="Reject"
                            background="red"
                            handleFunction={() => handleRejectJob(data?._id)}
                            width="70px"
                          />
                        </div>
                      ) : (
                        <div className="flex-row align-center normal-gap">
                          <ActionButton
                            title="View"
                            handleFunction={() =>
                              navigate("/home/categoryManagement/action", {
                                state: data,
                              })
                            }
                            width="70px"
                          />
                          {(!data?.jobRejected && data?.bookingStatus !== "Completed") && <ActionButton
                            title="Edit"
                            handleFunction={() => handleAssignModal(data)}
                            width="70px"
                          />}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {serviceRequestsData?.length === 0 ? (
              <p
                style={{
                  display: "flex",
                  fontWeight: "400",
                  marginTop: "0.75rem",
                  color: "#5F5F61",
                  justifyContent: "center",
                }}>
                Data not found
              </p>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
        <GenericModal
          open={showAssign}
          t
          onClose={handleAssignModal}
          content={
            <AssignStaff
              handleCancelFunction={handleAssignModal}
              serviceData={state}
              jobData={assignJobData}
              onCancel={handleAssignModal}
              handleFetchServiceJobRequests={fetchServiceJobRequests}
            />
          }
        />
        {serviceRequestsData.length !== 0 && (
          <div className="flex-row align-center just-sp-bwn ">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="table-row-selection">Rows</InputLabel>
                <Select
                  labelId="table-row-selection"
                  id="table-rows"
                  label="Rows"
                  value={rows}
                  onChange={(e) => {
                    setPage(1);
                    setRows(e.target.value);
                  }}>
                  <MenuItem value={12} sx={{ fontFamily: "Roboto Slab" }}>
                    12
                  </MenuItem>
                  <MenuItem value={10} sx={{ fontFamily: "Roboto Slab" }}>
                    10
                  </MenuItem>
                  <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                    8
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <ThemeProvider theme={paginationTheme}>
              {" "}
              <Pagination
                count={pageCount}
                shape="rounded"
                size="large"
                color="primary"
                onChange={(e, currPage) => setPage(currPage)}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceRequests;
