import React, { useState, useRef, useEffect } from "react";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import classes from "./GroupChatBox.module.css";
import Button from "../../../../components/common/commonComponents/Button/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../firebase";
import { queryGroupMessages } from "../ChatBox/firebaseQueries";
import { useDispatch, useSelector } from "react-redux";
import { sendNotification } from "../../../../api/api_calls/user";
import FormData from "form-data";
import { uploadMedia } from "../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import { loaderActions } from "../../../../redux/reducers/loader";

const GroupChatBox = ({
  room,
  setRoom,
  openEditGroup,
  setIsEdit,
  setGroupForm,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [chatMedia, setChatMedia] = useState("");
  const [mediaPreview, setMediaPreview] = useState("");
  const [mediaType, setMediaType] = useState("");

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  let currentUser = user?.role === 4 ? "support" : user?.id;

  const roomUid = room?.uniqueId || "";

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const markMessageRead = async () => {
    if (
      room &&
      !room?.lastMessage?.readBy?.includes(currentUser) &&
      room?.lastMessage?.sentBy !== currentUser
    ) {
      try {
        let chatRoomRef = doc(db, "groups", room?.uniqueId);

        await updateDoc(chatRoomRef, {
          lastMessage: {
            sentBy: room?.lastMessage?.sentBy,
            message: room?.lastMessage?.message,
            createdAt: room?.lastMessage?.createdAt,
            readBy: [...room?.lastMessage?.readBy, currentUser],
            readByAll: false,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (option, roomId) => {
    if (option === "Delete Group") {
      const groupDocRef = doc(db, "groups", roomId);
      await deleteDoc(groupDocRef);
      openEditGroup(false);
      setAnchorEl(null);
      setRoom(null);
      setChatMessages([]);
    } else {
      setAnchorEl(null);
      openEditGroup(true);
      setIsEdit(true);
      setGroupForm(room);
    }
  };

  const getUserDetails = (userId) => {
    return room?.userDetails?.[userId];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputMessage.trim() && !chatMedia) return;

    const _inputMessage = inputMessage;
    setInputMessage("");
    const _mediaPreview = mediaPreview;
    setMediaPreview("");

    const groupDocRef = doc(db, "groups", room?.uniqueId);
    try {
      if (room?.type === "group") {
        const rmId = `${room?.uniqueId}`;
        const msgVal = doc(db, "groups", rmId);
        const collectionVal = collection(msgVal, "messages");
        await addDoc(collectionVal, {
          message: _inputMessage,
          media: {
            name: _mediaPreview,
            type: mediaType.includes("image")
              ? "image"
              : mediaType.includes("video")
              ? "video"
              : "",
            url: chatMedia?.url || "",
            thumbnail: chatMedia?.thumbnail || "",
          },
          messageType: mediaType.includes("image")
            ? "image"
            : mediaType.includes("video")
            ? "video"
            : "text",
          sentBy: user?.role !== 4 ? user?.id : "support",
          //   sentTo: otherUser?.id,
          createdAt: serverTimestamp(),
        });

        await updateDoc(groupDocRef, {
          lastMessage: {
            sentBy: user?.role !== 4 ? user?.id : "support",
            createdAt: serverTimestamp(),
            message: _inputMessage
              ? _inputMessage
              : `${
                  currentUser === "support" ? "Support Center" : user?.name
                } uploaded a new ${mediaType}`,
            // read: editGroupForm?.lastMessage?.read || false
            readBy: [currentUser],
            readByAll: false,
          },
        });

        getDoc(groupDocRef)
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              const staffIds = [];
              const adminIds = [];

              Object.values(data?.userDetails).forEach((user) => {
                if (user?.id !== currentUser) {
                  if (user?.role === 3) staffIds.push(user?.id);
                  else adminIds.push(user?.id);
                }
              });

              const postData = {
                title: room?.groupName,
                body: _inputMessage,
                isGroup: true,
                staffIds,
                adminIds,
                roomId: room?.uniqueId,
              };

              sendNotification(postData)
                .then((res) => {
                  return;
                })
                .catch((error) => console.log(error));
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.error("Error getting document:", error);
          });

        setMediaType("");
        setChatMedia("");
      }
    } catch (error) {}
  };

  const options = ["Edit Group", "Delete Group"];
  const ITEM_HEIGHT = 48;

  useEffect(() => {
    // for finding the other person in direct chat
    if (room?.type === "group") {
      const usersArr = Object.keys(room?.users);
    }
    if (roomUid !== "") {
      let messagesRef = queryGroupMessages(roomUid);

      const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
        let messages = [];

        snapshot.forEach((doc) => {
          messages.push({ ...doc.data() });
        });

        setChatMessages(messages);
      });

      return () => unsubscribe();
    }
  }, [room]);

  useEffect(() => {
    scrollToBottom();
    markMessageRead();
  }, [chatMessages]);

  return (
    <div className={`flex-col ht-100 just-sp-bwn ${classes.right_container}`}>
      <div className={classes.top_half}>
        {room && (
          <div
            className={`flex-row align-center wd-100 just-sp-bwn ${classes.heading_container}`}
          >
            <div
              className={`flex-row normal-gap align-center ${classes.chat_heading}`}
            >
              <Avatar src={room?.roomImage} alt={room?.groupName} />
              <div className="flex-col">
                <h3>{room?.groupName}</h3>
                <p>{room?.users?.length} Members</p>
              </div>
            </div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => handleClose(option, room?.uniqueId)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
            {/* <div className={classes.job_due_date}>Nov 9 2020, 12:32PM</div> */}
          </div>
        )}
        {chatMessages?.length !== 0 ? (
          <div
            className={`${classes.chat_component} overflow-auto hidden-scroll`}
          >
            {chatMessages.map((message) => {
              const userInfo = getUserDetails(message?.sentBy);

              if (message?.media?.url) {
                if (message?.media?.type === "image") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`flex-col normal-gap ${classes.chat_message} ${
                        message?.sentBy === currentUser ? "align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "flex-reverse-row"
                            : "flex-row"
                        } normal-gap align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`medium-gap flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                      >
                        <div
                          className={`flex-col medium-gap ${
                            message?.sentBy === currentUser
                              ? "flex-reverse-row"
                              : "flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <img src={message?.media?.url} width={200} />
                          {message?.message}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {moment
                            .unix(message?.createdAt?.seconds)
                            .format("lll")}
                        </p>
                      </div>
                    </div>
                  );
                } else if (message?.media?.type === "video") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`flex-col normal-gap ${classes.chat_message} ${
                        message?.sentBy === currentUser ? "align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "flex-reverse-row"
                            : "flex-row"
                        } normal-gap align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`medium-gap flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                      >
                        <div
                          className={`flex-col medium-gap ${
                            message?.sentBy === currentUser
                              ? "flex-reverse-row"
                              : "flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <video width="320" height="240" controls>
                            <source
                              src={message?.media?.url}
                              type="video/mp4"
                            />
                          </video>
                          {message?.message}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {moment
                            .unix(message?.createdAt?.seconds)
                            .format("lll")}
                        </p>
                      </div>
                    </div>
                  );
                }
              }

              return (
                <div ref={messagesEndRef}>
                  <div
                    className={`flex-col normal-gap ${classes.chat_message} ${
                      message?.sentBy === currentUser ? "align-right" : ""
                    }`}
                  >
                    <div
                      className={`${
                        message?.sentBy === currentUser
                          ? "flex-reverse-row"
                          : "flex-row"
                      } normal-gap align-center`}
                    >
                      <Avatar
                        src={
                          userInfo?.profilePicture ||
                          userInfo?.image ||
                          user?.profileImg
                        }
                        alt={userInfo?.fullName}
                        sx={{ height: "30px", width: "30px" }}
                      />
                      <h3>
                        {userInfo?.name || userInfo?.fullName || user?.name}
                      </h3>
                    </div>
                    <div
                      className={`${
                        message?.sentBy === currentUser
                          ? classes.sending_message
                          : classes.incoming_message
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "flex-reverse-row"
                            : "flex-row"
                        }`}
                        style={{
                          textAlign:
                            message?.sentBy === currentUser ? "right" : "left",
                        }}
                      >
                        {message?.message}
                      </div>
                      <p
                        style={{
                          textAlign:
                            message?.sentBy === currentUser ? "right" : "left",
                        }}
                      >
                        {moment.unix(message?.createdAt?.seconds).format("lll")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="flex-row align-center justify-center ht-100"
            style={{ fontSize: "150px", color: "#d0f2f0" }}
          >
            <ForumIcon fontSize="inherit" />
          </div>
        )}
      </div>
      {room && (
        <>
          {mediaPreview && (
            <div
              style={{
                position: "relative",
                width: "min-content",
              }}
            >
              <p className={`subtle-label ${classes.mediaPreview}`}>
                {mediaPreview}
              </p>
              <div
                className={classes.media_cross}
                onClick={() => {
                  setMediaPreview("");
                  setChatMedia("");
                  setMediaType("");
                }}
              >
                X
              </div>
            </div>
          )}
          <form
            className={`wd-100 flex-row align-center ${classes.input_form}`}
            onSubmit={handleSubmit}
          >
            <div className="flex-row align-center wd-100 normal-gap">
              <div
                style={{ color: "#a1a0a3" }}
                className="pointer"
                onClick={() => {
                  document.getElementById("chat_media").click();
                }}
              >
                <PermMediaIcon />
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                id="chat_media"
                onChange={(e) => {
                  setMediaPreview(e.target.files["0"]?.name);
                  setMediaType(e.target.files["0"].type);
                  dispatch(loaderActions.toggleLoading(true));
                  const formData = new FormData();
                  formData.append("media", e.target.files["0"]);
                  uploadMedia(formData)
                    .then((res) => setChatMedia(res?.data?.data))
                    .catch((error) => {
                      toast.error(error?.message, {
                        toastId: "chat message media upload",
                      });
                      setChatMedia("");
                      setMediaPreview("");
                      setMediaType("");
                    })
                    .finally(() =>
                      dispatch(loaderActions.toggleLoading(false))
                    );
                  e.target.value = "";
                }}
              />
              <input
                placeholder="Type something..."
                className={classes.chat_input}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              <Button title="Send" type="submit" />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default GroupChatBox;
