import React, { useState, useEffect } from "react";
import classes from "./AddQuestion.module.css";
import Input from "../../../../components/common/commonComponents/Input/Input";
import { MenuItem, Select } from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { addQuestion, editQuestion } from "../../../../api/api_calls/addData";
import { addQuestionValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { toast } from "react-toastify";
import { LocalGasStation } from "@mui/icons-material";

const AddQuestion = ({
  onCancel,
  type,
  data,
  handleFetchQuestions,
  categoryId,
  serviceId,
  questionnaireId,
}) => {
  const dispatch = useDispatch();

  const [questionType, setQuestionType] = useState(
    type === "edit" ? data.type : "placeholder"
  );
  const [questionName, setQuestionName] = useState(
    type === "edit" ? data.text : ""
  );
  const [questionNameSpanish, setQuestionNameSpanish] = useState(
    type === "edit" ? data?.textSpanish : ""
  );

  const [mcqOptions, setMcqOptions] = useState(
    type === "edit"
      ? data.mcqOptions
      : [
          { eng: "", sp: "" },
          { eng: "", sp: "" },
        ]
  );
  // const [spanishMcqOptions, setSpanishMcqOptions] = useState(
  //   type === "edit" ? data?.spanishOptions : ["", ""]
  // );
  const [error, setError] = useState("");

  const handleMcqInputChange = (e, ind) => {
    setMcqOptions((currState) => {
      const latestState = [...currState];

      latestState[ind][e.target.name] = e.target.value;
      return latestState;
    });
  };

  const handleAddOption = () => {
    if (mcqOptions.length === 5) return;
    setMcqOptions((currState) => {
      return [...currState, { eng: "", sp: "" }];
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = [];
    const optionsSpanish = [];

    mcqOptions.forEach((option) => {
      options.push(option.eng);
      optionsSpanish.push(option.sp);
    });

    const postData = {
      text: questionName,
      textSpanish: questionNameSpanish,
      type: questionType,
      options: questionType === "mcq" ? options : [],
      optionsSpanish: questionType === "mcq" ? optionsSpanish : [],
      categoryId,
      serviceId,
      questionnaireId,
    };

    const formValidation = addQuestionValidation.validate(postData);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }

    if (type === "edit") {
      dispatch(loaderActions.toggleLoading(true));
      editQuestion(data._id, postData)
        .then((res) => {
          handleFetchQuestions();
          onCancel();
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "5",
          });
        })
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));

      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    addQuestion(postData)
      .then((res) => {
        handleFetchQuestions();
        onCancel();
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "50",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);
  return (
    <form
      className={`${classes.main_container} flex-col gap-36`}
      onSubmit={handleSubmit}
    >
      <div className="flex-col gap-6">
        <label>Question</label>
        <Input
          placeholder="Enter Question"
          type="text"
          value={questionName}
          maxlength="150"
          onChange={(e) => setQuestionName(e.target.value)}
        />
      </div>
      <div className="flex-col gap-6">
        <label>Question (Spanish)</label>
        <Input
          placeholder="Enter Question in Spanish"
          type="text"
          value={questionNameSpanish}
          maxlength="150"
          onChange={(e) => setQuestionNameSpanish(e.target.value)}
        />
      </div>
      <div className="flex-col gap-6">
        <label>Question Type</label>
        <Select
          value={questionType}
          onChange={(e) => setQuestionType(e.target.value)}
          sx={{
            height: "38px",
            background: "#F8F8F8",
            fontFamily: "Roboto Slab",
            fontSize: "14px",
          }}
        >
          <MenuItem
            disabled
            value="placeholder"
            sx={{ fontFamily: "Roboto Slab" }}
          >
            Select Question type
          </MenuItem>
          <MenuItem value="yesno" sx={{ fontFamily: "Roboto Slab" }}>
            Yes/No
          </MenuItem>
          <MenuItem value="description" sx={{ fontFamily: "Roboto Slab" }}>
            Description
          </MenuItem>
          <MenuItem value="mcq" sx={{ fontFamily: "Roboto Slab" }}>
            MCQ
          </MenuItem>
        </Select>
      </div>

      {questionType === "mcq" && (
        <div className="flex-col gap-36">
          {mcqOptions.map((option, ind) => (
            <div className="flex-col gap-6" key={`mcqOption_${ind}`}>
              <label>Option {ind + 1}</label>
              <Input
                placeholder="Enter Option"
                name={"eng"}
                value={option?.eng}
                onChange={(e) => handleMcqInputChange(e, ind)}
              />
              <label>Option {ind + 1} (Spanish)</label>
              <Input
                placeholder="Enter Option in spanish"
                name={"sp"}
                value={option?.sp}
                onChange={(e) => handleMcqInputChange(e, ind)}
              />
            </div>
          ))}

          {mcqOptions.length < 5 && (
            <p
              className={`${classes.add_option} pointer`}
              onClick={handleAddOption}
            >
              + Add Option
            </p>
          )}
        </div>
      )}
      {error && <Error title={error} />}
      <div className="flex-row justify-center medium-gap">
        <Button
          type="button"
          title="Cancel"
          theme="grey"
          handleFunction={onCancel}
        />
        <Button title={type === "edit" ? "Save" : "Add"} type="submit" />
      </div>
    </form>
  );
};

export default AddQuestion;
