import React from "react";
import classes from "./DoughnutChart.module.css";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ jobs, pmDataServiceRequest }) => {
  const setLabels = () => {
    if (jobs === undefined) {
      return ["Completed", "Ongoing", "Upcoming"];
    }
    if (pmDataServiceRequest === undefined) {
      return ["Completed", "Ongoing", "Upcoming"];
    }
  };

  const statusColors = () => {
    if (jobs === undefined) {
      return ["#E45858", "#FF5A1F", "#40A59E"];
    }
    if (pmDataServiceRequest === undefined) {
      return ["#52C6BE", "#E45858", "#F4A118"];
    }
  };

  const setDoghnutArray = () => {
    if (pmDataServiceRequest === undefined && jobs !== undefined) {
      return [jobs?.completed, jobs?.onGoing, jobs?.upComing];
    }
    if (pmDataServiceRequest !== undefined && jobs === undefined) {
      return pmDataServiceRequestValues(pmDataServiceRequest)
        ? [0, 0, 0]
        : [
            pmDataServiceRequest?.completed,
            pmDataServiceRequest?.onGoing,
            pmDataServiceRequest?.upComing,
          ];
    }
  };

  const pmDataServiceRequestValues = (obj) => {
    return Object.keys(obj).every(
      (key) => obj[key] === 0 || obj[key] === false
    );
  };

  const data = {
    labels: setLabels(),
    datasets: [
      {
        borderColor: "white",
        backgroundColor: statusColors(),
        data: setDoghnutArray(),
      },
    ],
  };

  const options = {
    cutout: 60,
    boxWidth: 100,
    // responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 50,
          padding: 25,
          // paddingBottom: 20,
        },
      },
    },
  };

  return (
    <div className={classes.chart_container}>
      <div className={classes.doughnut_container}>
        <div className={classes.canvas}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={`flex-col align-center  ${classes.chart_details}`}>
          {/* <h3>{totalJobs}</h3> */}
          <h3>{jobs?.totalJobs || pmDataServiceRequest?.totalJobs}</h3>
          <p>Total Jobs</p>
          {/* <p>Total Service Requests</p> */}
        </div>
      </div>
      <div>
        <div className={classes.chart_numbers}>
          <p>{jobs?.completed || pmDataServiceRequest?.completed || "0"}</p>
          <p>{jobs?.onGoing || pmDataServiceRequest?.onGoing || "0"}</p>
          <p>{jobs?.upComing || pmDataServiceRequest?.upComing || "0"}</p>
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
