import React from "react";
import classes from "./LabelData.module.css";

const LabelData = ({ label, data }) => {
  return (
    <div className={`flex-col small-gap ${classes.container}`}>
      <h3 className="color-primary-grey">{label}</h3>
      <p className="color-tertiary-grey">{data || ""}</p>
    </div>
  );
};

export default LabelData;
