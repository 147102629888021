import React, { useEffect, useState } from "react";
import Input from "../../../../components/common/commonComponents/Input/Input";
import classes from "../AddQuestion/AddQuestion.module.css";

import Button from "../../../../components/common/commonComponents/Button/Button";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { getCategory, getService } from "../../../../api/api_calls/data";
import { toast } from "react-toastify";
import Select from "react-select";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";

import { addQuestionnaire } from "../../../../api/api_calls/addData";
import { questionnaireValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";

const AddQuestionnaire = ({ onCancel, handleFetchQuestionnaire }) => {
  const dispatch = useDispatch();

  const [categoryList, setCategoryList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [formData, setFormData] = useState({
    questionnaireName: "",
    questionnaireNameSpanish: "",
    categoryId: "",
    serviceId: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    dispatch(loaderActions.toggleLoading(true));
    getCategory()
      .then((res) => setCategoryList(res?.data?.data?.categories))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const fetchServices = (id) => {
    dispatch(loaderActions.toggleLoading(true));
    getService(id)
      .then((res) => setServicesList(res?.data?.data?.services))
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  const handleServiceCategorySelect = (e) => {
    fetchServices(e?._id);
    setFormData((currState) => {
      return { ...currState, categoryId: e?._id };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formValidation = questionnaireValidation.validate(formData);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    addQuestionnaire(formData)
      .then((res) => {
        handleFetchQuestionnaire();
        onCancel();
      })
      .catch((error) => toast.error(error?.message))
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  return (
    <form
      className={`${classes.main_container} flex-col gap-36`}
      onSubmit={handleFormSubmit}
    >
      <div className="flex-col gap-6">
        <label>Questionnaire Name</label>
        <Input
          placeholder="Enter Questionnaire Name"
          value={formData?.questionnaireName}
          onChange={(e) =>
            setFormData((currState) => {
              return { ...currState, questionnaireName: e.target.value };
            })
          }
        />
      </div>
      <div className="flex-col gap-6">
        <label>Questionnaire Name (Spanish)</label>
        <Input
          placeholder="Enter Questionnaire Name in Spanish"
          value={formData?.questionnaireNameSpanish}
          onChange={(e) =>
            setFormData((currState) => {
              return { ...currState, questionnaireNameSpanish: e.target.value };
            })
          }
        />
      </div>
      <div className="flex-col gap-6">
        <label>Select Category</label>
        <Select
          name="categoryName"
          placeholder="Select Category"
          //  className="basic-single"
          styles={customStylesReactSelect}
          options={categoryList}
          getOptionLabel={(option) => option.categoryName}
          getOptionValue={(option) => option._id}
          onChange={(selectedOption) => {
            handleServiceCategorySelect(selectedOption);
          }}
        />
      </div>
      <div className="flex-col gap-6">
        <label>Select Service</label>
        <Select
          name="serviceName"
          placeholder="Select Service"
          //  className="basic-single"
          styles={customStylesReactSelect}
          options={servicesList}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          onChange={(selectedOption) => {
            setFormData((currState) => {
              return { ...currState, serviceId: selectedOption?._id };
            });
          }}
        />
      </div>
      {error && <Error title={error} />}
      <div className="flex-row justify-center medium-gap">
        <Button
          type="button"
          title="Cancel"
          theme="grey"
          handleFunction={onCancel}
        />
        <Button title="Add" type="submit" />
      </div>
    </form>
  );
};

export default AddQuestionnaire;
