import React, { useCallback, useState } from "react";
import classes from "./AddGroupFrom.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Input from "../../../../components/common/commonComponents/Input/Input";
import { customStylesReactSelect } from "../../../../utils/HelperFuncitons";
import Button from "../../../../components/common/commonComponents/Button/Button";
import {
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import {
  getAllGroupsMarket,
  getAllMarkets,
  getAllRoleBasesMembersForChatGroup,
} from "../../../../api/api_calls/data";
import { useEffect } from "react";
import { Avatar, MenuItem, Select as MSelect } from "@mui/material";
import Select from "react-select";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { uploadMedia } from "../../../../api/api_calls/addData";
import { addGroupFormValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { toast } from "react-toastify";

const AddGroupForm = ({
  handleBackButton,
  isEdit,
  editGroupForm,
  createGroup,
}) => {
  const [role, setRole] = useState(isEdit ? editGroupForm?.userType : 5);
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [members, setMembers] = useState({ data: [], totalCount: "" });
  const [imagePreview, setImagePreview] = useState(
    editGroupForm?.roomImage || ""
  );
  const [error, setError] = useState("");
  const supportDetails = useSelector((state) => state?.user);

  const dispatch = useDispatch();

  const currentUser =
    supportDetails?.role !== 4 ? supportDetails?.id : "support";

  const [formData, setFormData] = useState({
    isAdmin: Boolean(isEdit === true) ? false : true,
    groupName: editGroupForm?.groupName || "",
    userType: role !== "" ? role : "",
    market: editGroupForm?.market || {},
    group: editGroupForm?.group || {},
    users: editGroupForm?.users ? editGroupForm?.users : [currentUser],
    roomImage: editGroupForm?.roomImage || "",
    userDetails: editGroupForm?.userDetails || {},
  });

  const groupReference = collection(db, `groups`);

  const handleChangeProfilePicture = async (e) => {
    // const uploadedImages = [];

    if (e?.target?.files && e?.target?.files.length !== 0) {
      for (let key in Object.keys(e.target.files)) {
        const formData = new FormData();
        formData.append("media", e.target.files[key]);
        try {
          dispatch(loaderActions.toggleLoading(true));
          const imageUrl = await uploadMedia(formData);

          if (imageUrl?.status === 200) {
            setFormData((v) => ({
              ...v,
              roomImage: imageUrl?.data?.data?.url,
            }));
          }
          dispatch(loaderActions.toggleLoading(false));
          setImagePreview(URL.createObjectURL(e.target.files[0]));
          // uploadedImages.push(imageUrl?.data?.data?.url);
        } catch (error) {
          toast.error("Could not upload image");
        }
      }
      // input field so if you removed it you can re-add the same file
      e.target.value = "";
    }
  };

  const createGroupForm = async () => {
    const formValidation = addGroupFormValidation.validate(formData);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }

    // e.preventDefault();
    await addDoc(groupReference, {
      type: "group",
      group: formData?.group,
      groupName: formData?.groupName,
      userType: formData?.userType,
      market: formData?.market,
      users: formData?.users,
      roomImage: formData?.roomImage,
      createdAt: serverTimestamp(),
      userDetails: formData?.userDetails,
      lastMessage: {
        sentBy: currentUser,
        createdAt: serverTimestamp(),
        message: "Welcome to the chat",
        readBy: [currentUser],
        readByAll: true,
      },
    });

    setFormData({
      isAdmin: true,
      groupName: "",
      userType: role !== "" ? role : "",
      market: {},
      group: {},
      users: [currentUser],
      roomImage: "",
    });

    handleBackButton();
  };

  const editGroupFormSubmit = async () => {
    const formValidation = addGroupFormValidation.validate(formData);

    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }
    const groupDocRef = doc(db, "groups", editGroupForm?.uniqueId);

    await updateDoc(groupDocRef, {
      type: "group",
      group: formData?.group,
      groupName: formData?.groupName,
      userType: formData?.userType,
      market: formData?.market,
      users: formData?.users,
      roomImage: formData?.roomImage,
      createdAt: editGroupForm?.lastMessage?.createdAt,
      userDetails: formData?.userDetails,
      lastMessage: {
        sentBy: editGroupForm?.lastMessage?.sentBy || "",
        createdAt: editGroupForm?.lastMessage?.createdAt || "",
        message: editGroupForm?.lastMessage?.message,
        readBy: editGroupForm?.lastMessage?.readBy,
        readByAll: editGroupForm?.lastMessage?.readByAll,
      },
    });

    setFormData({
      isAdmin: true,
      groupName: "",
      userType: role !== "" ? role : "",
      market: {},
      group: {},
      users: [currentUser],
      roomImage: "",
    });

    handleBackButton();
  };

  const handleFormChange = (e) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleRoleChange = async (e) => {
    setRole(e.target.value);
    if (e.target.value === 3) {
      setFormData((v) => ({
        ...v,
        userType: e.target.value,
        isAdmin: false,
      }));
    } else {
      setFormData((v) => ({
        ...v,
        userType: e.target.value,
        isAdmin: true,
      }));
    }
  };

  const handleMarketChange = async (mkt) => {
    setFormData((v) => ({
      ...v,
      market: { marketId: mkt?._id, marketName: mkt?.marketName },
    }));
    if (availableMarkets?.length > 0 && mkt?._id !== "") {
      // setAvailableGroups(mkt?.groupData);
      const res = await getAllGroupsMarket(mkt?._id);

      if (res?.status === 200) {
        setAvailableGroups(res?.data?.data?.groupsData);
      }
    }
  };

  const handleGroupChange = (grp) => {
    setFormData((v) => ({
      ...v,
      group: { groupId: grp?._id, groupName: grp?.groupName },
    }));
  };

  const handleMemberChange = (member) => {
    const tempUserDetails = {
      [currentUser]: {
        email: supportDetails?.email,
        fullName:
          supportDetails?.role === 4 ? "Support Center" : supportDetails?.name,
        image:
          supportDetails?.role === 4
            ? "https://nextgen-resourcifi.s3.amazonaws.com/Uploads/1698920312171/nextGen1698920311515.jpg"
            : supportDetails?.profileImg || "",
        id: currentUser,
        role: supportDetails?.role,
      },
    };

    const tempUsers = [currentUser];

    member?.forEach((el, i) => {
      tempUserDetails[el?.value] = {
        email: el?.email,
        fullName: el?.label,
        id: el?.value,
        image: el?.image || "",
        role: el?.role,
      };

      tempUsers.push(el?.value);
    });

    setFormData((v) => {
      return { ...v, userDetails: tempUserDetails, users: tempUsers };
    });
  };

  const fetchMarket = async () => {
    try {
      const res = await getAllMarkets();
      if (res?.status === 200) {
        setAvailableMarkets(res?.data?.data?.market);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const fetchAllMembers = async () => {
    try {
      const res = await getAllRoleBasesMembersForChatGroup(
        formData?.isAdmin,
        formData?.userType,
        formData?.market?.marketId,
        formData?.group?.groupId,
        ""
      );
      if (res?.status === 200) {
        setMembers({
          data: res?.data?.data?.users,
          totalCount: res?.data?.data?.countData,
        });
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No members found.</span>
      </components.NoOptionsMessage>
    );
  };

  const handleMemberSearch = async (search) => {
    try {
      const res = await getAllRoleBasesMembersForChatGroup(
        formData?.isAdmin,
        formData?.userType,
        formData?.market?.marketId,
        formData?.group?.groupId,
        search
      );
      if (res?.status === 200) {
        const rd = res?.data?.data?.users.map((e) => {
          return {
            label: e.fullName,
            value: e._id,
          };
        });
        return rd;
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const fetchMemebersArray = useCallback(
    debounce((inputText, callback) => {
      handleMemberSearch(inputText).then((options) => callback(options));
    }, 300),
    [formData]
  );

  const membersOptions = members?.data.map((el, i) => {
    const container = {};

    container["value"] = el._id;
    container["label"] = el.fullName;
    container["image"] = el.profilePicture;
    container["email"] = el?.email;
    container["role"] = el?.role;

    return container;
  });

  const editMemberDefaultArray = (members) => {
    const membersCopy = { ...members };
    delete membersCopy[currentUser];

    // delete members.support;
    const container = [];

    Object.values(membersCopy).forEach((member) => {
      container.push({
        value: member?.id,
        label: member?.fullName,
        image: member?.image,
        email: member?.email,
        role: member?.role,
      });
    });

    return container;
  };

  useEffect(() => {
    fetchMarket();
  }, []);

  useEffect(() => {
    if (
      formData?.userType === 3 &&
      formData?.market?.marketId &&
      formData?.group?.groupId
    )
      fetchAllMembers();
    else if (formData?.userType === 7 || formData?.userType === 8)
      fetchAllMembers();
    else setMembers({ data: [], totalCount: "" })
  }, [formData]);

  useEffect(() => {
    if (editGroupForm === null || isEdit === false) {
      setRole(5);
      setFormData(() => ({
        isAdmin: false,
        groupName: "",
        userType: role !== "" ? role : "",
        market: {},
        group: {},
        users: [currentUser],
        roomImage: "",
        userDetails: {},
      }));
      setImagePreview("");
    }
  }, [editGroupForm, isEdit]);
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return isEdit === true ? (
    // Edit Group...
    <div className="flex-col align-center gap-32">
      <div
        className={`flex-row gap-32 align-center wd-100 ${classes.back_button}`}
      >
        <div onClick={() => handleBackButton(false)} className="pointer">
          <ArrowBackIcon />
        </div>
        <div className="flex-col">
          <h3>Edit Group</h3>
          <p>Edit the group details below</p>
        </div>
      </div>
      <form className={`flex-col gap-32 ${classes.add_group_form}`}>
        <div className="flex-col medium-gap wd-100 align-center">
          {imagePreview !== "" ? (
            <Avatar
              src={imagePreview}
              variant="rounded"
              sx={{ height: 130, width: 130, borderRadius: "50%" }}
            />
          ) : (
            <div
              className={`circular-div flex-row align-center justify-center ${classes.camera_icon}`}
              onClick={() => {
                document.getElementById("change-profile-picture").click();
              }}
            >
              <CameraAltIcon fontSize="inherit" />
            </div>
          )}
          <p>Add Group Image</p>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            id="change-profile-picture"
            style={{ display: "none" }}
            onChange={handleChangeProfilePicture}
          />
        </div>
        <div className="flex-row wd-100 gap-24">
          <div className="flex-col wd-50 gap-6">
            <label>Group Name</label>
            <Input
              placeholder="Enter Group name"
              name="groupName"
              value={formData?.groupName}
              onChange={handleFormChange}
              maxLength={30}
            />
          </div>
          <div className="flex-col wd-50 gap-6">
            <label>User type</label>
            <MSelect
              name="userType"
              placeholder="Select User Type"
              value={role}
              onChange={handleRoleChange}
              styles={customStylesReactSelect}
              sx={{
                height: "38px",
                background: "#F8F8F8",
                fontFamily: "Roboto Slab",
                fontSize: "14px",
              }}
            >
              <MenuItem value={3} sx={{ fontFamily: "Roboto Slab" }}>
                Staff
              </MenuItem>
              <MenuItem value={5} sx={{ fontFamily: "Roboto Slab" }}>
                Admin
              </MenuItem>
              {/* <MenuItem value={1} sx={{ fontFamily: "Roboto Slab" }}>
                Property Manager
              </MenuItem> */}
              <MenuItem value={6} sx={{ fontFamily: "Roboto Slab" }}>
                Sub-Admin
              </MenuItem>
              <MenuItem value={7} sx={{ fontFamily: "Roboto Slab" }}>
                Manager
              </MenuItem>
              <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                Q A
              </MenuItem>
            </MSelect>
          </div>
        </div>

        {Boolean(role === 3) ? (
          <div className="flex-row wd-100 gap-24">
            <div className="flex-col wd-50 gap-6">
              <label>Select Market</label>
              <Select
                name="marketId"
                placeholder="Select Market"
                // className='basic-single'
                styles={customStylesReactSelect}
                defaultValue={
                  editGroupForm?.market
                    ? {
                        _id: editGroupForm?.market?.marketId,
                        marketName: editGroupForm?.market?.marketName,
                      }
                    : ""
                }
                options={availableMarkets}
                sx={{
                  height: "38px",
                  background: "#F8F8F8",
                  fontFamily: "Roboto Slab",
                  fontSize: "14px",
                }}
                getOptionLabel={(option) => option.marketName}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleMarketChange(selectedOption);
                }}
                onFocus={() => setAvailableGroups([])}
              />
            </div>
            <div className="flex-col wd-50 gap-6">
              <label>Select Group</label>
              <Select
                name="groupId"
                placeholder="Select Group"
                //  className="basic-single"
                isSearchable={true}
                defaultValue={
                  editGroupForm?.group
                    ? {
                        _id: editGroupForm?.group?.groupId,
                        groupName: editGroupForm?.group.groupName,
                      }
                    : ""
                }
                options={availableGroups}
                styles={customStylesReactSelect}
                sx={{
                  height: "38px",
                  background: "#F8F8F8",
                  fontFamily: "Roboto Slab",
                  fontSize: "14px",
                }}
                getOptionLabel={(option) => option.groupName}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleGroupChange(selectedOption);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex-col">
          <label style={{ marginBottom: "8px" }}>Select Members</label>
          <AsyncSelect
            defaultOptions={membersOptions}
            loadOptions={fetchMemebersArray}
            styles={customStylesReactSelect}
            components={{ NoOptionsMessage }}
            defaultValue={editMemberDefaultArray(editGroupForm?.userDetails)}
            value={
              isEdit
                ? editMemberDefaultArray(formData?.userDetails)
                : editMemberDefaultArray(editGroupForm?.userDetails)
            }
            // handleChange={(e) => handleMultiSelectMembers(e)}
            // defaultInputValue={editMemberDefaultArray(editGroupForm?.userDetails)}
            isSearchable
            isMulti
            name="members"
            onChange={handleMemberChange}
          />
        </div>
        {error && <Error title={error} />}

        <div className="flex-row wd-100 justify-center medium-gap">
          <Button
            type="button"
            title="Cancel"
            theme="gray"
            handleFunction={() => handleBackButton(false)}
          />
          <Button
            title="Save"
            type="button"
            handleFunction={editGroupFormSubmit}
          />
        </div>
      </form>
    </div>
  ) : (
    // Create Group Form...
    <div className="flex-col align-center gap-32">
      <div
        className={`flex-row gap-32 align-center wd-100 ${classes.back_button}`}
      >
        <div onClick={() => handleBackButton(false)} className="pointer">
          <ArrowBackIcon />
        </div>
        <div className="flex-col">
          <h3>Create Group</h3>
          <p>Please fill below details to add a group</p>
        </div>
      </div>
      <form className={`flex-col gap-32 ${classes.add_group_form}`}>
        <div className="flex-col medium-gap wd-100 align-center">
          {imagePreview !== "" ? (
            <Avatar
              src={imagePreview}
              variant="rounded"
              sx={{ height: 130, width: 130, borderRadius: "50%" }}
            />
          ) : (
            <div
              className={`circular-div flex-row align-center justify-center ${classes.camera_icon}`}
              onClick={() => {
                document.getElementById("change-profile-picture").click();
              }}
            >
              <CameraAltIcon fontSize="inherit" />
            </div>
          )}
          <p>Add Group Image</p>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            id="change-profile-picture"
            style={{ display: "none" }}
            onChange={handleChangeProfilePicture}
          />
        </div>
        <div className="flex-row wd-100 gap-24">
          <div className="flex-col wd-50 gap-6">
            <label>Group Name</label>
            <Input
              placeholder="Enter Group name"
              name="groupName"
              value={formData?.groupName}
              onChange={handleFormChange}
              maxLength={30}
            />
          </div>
          <div className="flex-col wd-50 gap-6">
            <label>User type</label>
            <MSelect
              name="userType"
              placeholder="Select User Type"
              value={role}
              onChange={handleRoleChange}
              styles={customStylesReactSelect}
              sx={{
                height: "38px",
                background: "#F8F8F8",
                fontFamily: "Roboto Slab",
                fontSize: "14px",
              }}
            >
              <MenuItem value={3} sx={{ fontFamily: "Roboto Slab" }}>
                Staff
              </MenuItem>
              <MenuItem value={5} sx={{ fontFamily: "Roboto Slab" }}>
                Super Admin
              </MenuItem>
              {/* <MenuItem value={1} sx={{ fontFamily: "Roboto Slab" }}>
                Property Manager
              </MenuItem> */}
              <MenuItem value={6} sx={{ fontFamily: "Roboto Slab" }}>
                Sub-Admin
              </MenuItem>
              <MenuItem value={7} sx={{ fontFamily: "Roboto Slab" }}>
                Manager
              </MenuItem>
              <MenuItem value={8} sx={{ fontFamily: "Roboto Slab" }}>
                Q A
              </MenuItem>
            </MSelect>
          </div>
        </div>

        {Boolean(role === 3) ? (
          <div className="flex-row wd-100 gap-24">
            <div className="flex-col wd-50 gap-6">
              <label>Select Market</label>
              <Select
                name="marketId"
                placeholder="Select Market"
                // className='basic-single'
                styles={customStylesReactSelect}
                defaultValue={""}
                options={availableMarkets}
                sx={{
                  height: "38px",
                  background: "#F8F8F8",
                  fontFamily: "Roboto Slab",
                  fontSize: "14px",
                }}
                getOptionLabel={(option) => option.marketName}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleMarketChange(selectedOption);
                }}
                onFocus={() => setAvailableGroups([])}
              />
            </div>
            <div className="flex-col wd-50 gap-6">
              <label>Select Group</label>
              <Select
                name="groupId"
                placeholder="Select Group"
                //  className="basic-single"
                isSearchable={true}
                defaultValue=""
                options={availableGroups}
                styles={customStylesReactSelect}
                sx={{
                  height: "38px",
                  background: "#F8F8F8",
                  fontFamily: "Roboto Slab",
                  fontSize: "14px",
                }}
                getOptionLabel={(option) => option.groupName}
                getOptionValue={(option) => option._id}
                onChange={(selectedOption) => {
                  handleGroupChange(selectedOption);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="flex-col">
          <label style={{ marginBottom: "8px" }}>Select Members</label>
          <AsyncSelect
            defaultOptions={membersOptions}
            loadOptions={fetchMemebersArray}
            styles={customStylesReactSelect}
            value={editMemberDefaultArray(formData?.userDetails)}
            components={{ NoOptionsMessage }}
            isSearchable
            isMulti
            name="members"
            onChange={handleMemberChange}
          />
        </div>
        {error && <Error title={error} />}

        <div className="flex-row wd-100 justify-center medium-gap">
          <Button
            type="button"
            title="Cancel"
            theme="gray"
            handleFunction={() => handleBackButton(false)}
          />
          <Button
            title="Create"
            type="button"
            handleFunction={createGroupForm}
          />
        </div>
      </form>
    </div>
  );
};

export default AddGroupForm;
