import API from "../api_setting/apiConstants";
import { wrappedGet } from "../api_setting/urlFuncs";

export const getNotifications = (limit = 10, skip = 0) => {
  return wrappedGet(`${API.GET_NOTIFICATIONS}?limit=${limit}&skip=${skip}`);
};

export const getStaffList = (
  skip = 0,
  limit = 10,
  text = "",
  staffStatus,
  orderBy,
  order
) => {
  return wrappedGet(
    `${
      API.STAFF_LIST
    }?limit=${limit}&skip=${skip}&text=${text}&sortKey=${orderBy}&sortDirection=${order}&isDeleted=${
      staffStatus !== "active"
    }`,
    "get"
  );
};

export const getCategory = () => {
  return wrappedGet(API.GET_CATEGORY, "get");
};

export const getCategoryData = (categoryId) => {
  return wrappedGet(API.GET_CATEGORY + "/" + categoryId, "get");
};

export const getService = (categoryId = "", skip = 0, limit = 10) => {
  return wrappedGet(
    `${API.GET_SERVICE}/${categoryId}?skip=${skip}&limit=${limit}`,
    "get"
  );
};

export const getAllServices = () => {
  return wrappedGet(API.GET_ALL_SERVICE, "get");
};

export const getPropertyManager = (
  skip = 0,
  limit = 10,
  params,
  startDate = "",
  endDate = "",
  isActive = ""
) => {
  return wrappedGet(
    `${API.GET_PROPERTY_MANAGER}?skip=${skip}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&isActive=${isActive}`,
    "get",
    params
  );
};
export const getPropertyManagerScheduler = () => {
  return wrappedGet(API.GET_PROPERTY_MANAGER_SCHEDULER, "get");
};

export const getPropertyManagerDashboard = (
  startDate,
  endDate,
  skip = 0,
  limit = 10,
  isActive = "all"
) => {
  return wrappedGet(
    `${API.GET_PROPERTY_MANAGER}?startDate=${startDate}&endDate=${endDate}&skip=${skip}&limit=${limit}&isActive=${isActive}`,
    "get"
  );
};

export const getJobRequests = (
  id,
  status = "",
  search = "",
  skip = 0,
  limit = 10,
  startDate,
  endDate
) => {
  return wrappedGet(
    `${
      API.GET_JOB_REQUESTS
    }?skip=${skip}&limit=${limit}&pmId=${id}&bookingStatus=${
      status === "Deleted" ? "" : status
    }&staffName=${search}&startDate=${startDate}&endDate=${endDate}&isDeleted=${
      status === "" ? "" : status === "Deleted"
    }`,
    "get"
  );
};

export const getJobDetails = (id) => {
  return wrappedGet(`${API.GET_JOB_DETAILS}/${id}`, "get");
};

export const getServiceJobRequests = (id, limit = 10, skip = 0) => {
  return wrappedGet(
    `${API.GET_JOB_REQUESTS_FOR_SERVICE}/${id}/?limit=${limit}&skip=${skip}`,
    "get"
  );
};

export const getQuestions = (questionnaireId, limit = 10, skip = 0, search) => {
  return wrappedGet(
    `${API.GET_QUESTIONS}?questionnaireId=${questionnaireId}&limit=${limit}&skip=${skip}&text=${search}`,
    "get"
  );
};

export const getAllMarkets = (limit = "", skip = 0, text = "") => {
  return wrappedGet(
    `${API.GET_ALL_MARKETS}?limit=${limit}&skip=${skip}&text=${text}`,
    "get"
  );
};

export const getAllMarketTemplates = (marketId, groupId, search) => {
  return wrappedGet(
    `${API.GET_ALL_TEMPLATES}/${marketId}/${groupId}?searchText=${search}`,
    "get"
  );
};

export const getAllMarketStaff = (marketId) => {
  return wrappedGet(`${API.GET_ALL_MARKET_STAFF}/${marketId}`, "get");
};

export const getAllMarketGroups = () => {
  return wrappedGet(API.GET_ALL_MARKET_GROUPS, "get");
};

export const getPropertiesOfPM = (PMId) => {
  return wrappedGet(`${API.GET_PROPERTIES_OF_PM}/${PMId}`, "get");
};
export const getProperty = () => {
  return wrappedGet(`${API.GET_PROPERTY}`, "get");
};
export const getPropertyList = (skip = 0, limit = 10, params) => {
  return wrappedGet(
    `${API.GET_PROPERTY}?text=${params}&skip=${skip}&limit=${limit}`,
    "get"
  );
};

export const getSingleProperty = (id) => {
  return wrappedGet(`${API.GET_PROPERTY}/${id}`, "get");
};

export const getServiceList = (
  startDate,
  endDate,
  bookingStatus,
  skip = 0,
  limit = 10
) => {
  return wrappedGet(
    `${API.BOOKING_SERVICE}?startDate=${startDate}&endDate=${endDate}&bookingStatus=${bookingStatus}&skip=${skip}&limit=${limit}`,
    "get"
  );
};

export const getFilteredStaff = (marketId, serviceId, categoryId) => {
  return wrappedGet(
    `${API.FILTER_STAFF}/${marketId}/service/${serviceId}/category/${categoryId}`,
    "get"
  );
};

export const getAvailableStaffs = () => {};

export const getUserList = (limit = 10, skip = 0, params = "", status) => {
  return wrappedGet(
    `${API.GET_ALL_USER}?text=${params}&limit=${limit}&skip=${skip}&isActive=${
      status === "active"
    }`,
    "get"
  );
};

export const getUpdates = () => {
  return wrappedGet(API.UPDATES, "get");
};
export const getSingleUser = (id) => {
  return wrappedGet(`${API.GET_SINGLE_USER}/${id}`, "get");
};

export const getQuestionnaires = (search = "", limit = 10, skip = 0) => {
  return wrappedGet(
    `${API.GET_ALL_QUESTIONNAIRES}?text=${search}&limit=${limit}&skip=${skip}`,
    "get"
  );
};

//Super Admin Dashboard api methods...
export const getAdminDashboardJobData = (startDate, endDate) => {
  return wrappedGet(
    `${API.GET_ADMIN_JOB_DATA}/?startDate=${startDate}&endDate=${endDate}`,
    "get"
  );
};

export const getAdminDashboardUserData = (startDate, endDate, role) => {
  return wrappedGet(
    `${API.GET_ADMIN_USER_DATA}/?startDate=${startDate}&endDate=${endDate}&role=${role}`,
    "get"
  );
};

export const getAdminDashboardActivityData = (limit = 10, skip = 0) => {
  return wrappedGet(
    `${API.GET_ADMIN_ACTIVITY_DATA}/?limit=${limit}&skip=${skip}`,
    "get"
  );
};

export const getAdminDashboardRevenueData = (
  startDate,
  endDate,
  categoryId
) => {
  return wrappedGet(
    `${API.GET_ADMIN_REVENUE_DATA}/?startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}`,
    "get"
  );
};

// PM Dashboard api methods...
export const getPmServiceRequest = (startDate, endDate) => {
  return wrappedGet(
    `${API.GET_PM_SERVICE_REQUEST}/?startDate=${startDate}&endDate=${endDate}`,
    "get"
  );
};

export const getPmDashboardServiceTrends = () => {
  return wrappedGet(`${API.GET_PM_SERVICE_TRENDS}`, "get");
};

export const getPmTotalAmountDisbursed = () => {
  return wrappedGet(`${API.GET_PM_TOTAL_AMOUNT_DISBURSED}`, "get");
};

export const getPmDashboardActivity = (limit = 10, skip = 0) => {
  return wrappedGet(
    `${API.GET_PM_DASHBOARD_ACTIVITY}/?limit=${limit}&skip=${skip}`,
    "get"
  );
};

export const getPmProperties = (startDate, endDate) => {
  return wrappedGet(
    `${API.GET_PM_PROPERTIES}/?startDate=${startDate}&endDate=${endDate}`,
    "get"
  );
};

export const getPmUpcomingJobs = (skip = 0, limit = 10) => {
  return wrappedGet(
    `${API.GET_PM_UPCOMING_JOBS}/?limit=${limit}&skip=${skip}`,
    "get"
  );
};

// ---------------------------------------------------------------------------------------------------

export const getAllGroupsMarket = (id) => {
  return wrappedGet(`${API.GET_ALL_GROUPS_OF_MARKET}/${id}`, "get");
};

export const getAllUnassignedStaff = (categoryId = "", text = "") => {
  return wrappedGet(
    `${API.UNASSIGNED_STAFF}/category/${categoryId}?text=${text}`,
    "get"
  );
};

export const getGroupJobs = (groupId) => {
  return wrappedGet(`${API.GET_ALL_GROUP_JOBS}/${groupId}`, "get");
};

// --------------------Chat Create Group Staff Members--------------------------
export const getAllRoleBasesMembersForChatGroup = (
  boolAdmin,
  role,
  marketId,
  groupId,
  search
) => {
  return wrappedGet(
    `${API.GET_STAFF_MEMBERS_FOR_CHAT_GROUP}/?isAdmin=${boolAdmin}&role=${
      role || ""
    }&groupId=${groupId || ""}&marketId=${marketId || ""}&text=${
      search || ""
    }&limit=10&skip=0`
  );
};

export const getClients = (limit, skip, text, clientStatus) => {
  return wrappedGet(
    `${API.GET_CLIENTS}?limit=${limit}&skip=${skip}&text=${text}&isDeleted=${
      clientStatus !== "active"
    }`
  );
};
