import React, { useState, useEffect } from "react";
import classes from "./AddCategory.module.css";

import Input from "../../../../components/common/commonComponents/Input/Input";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { addCategory, editCategory } from "../../../../api/api_calls/addData";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import { toast } from "react-toastify";
import { capitalize } from "../../../../utils/HelperFuncitons";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { categoryIcons, categorySVGs } from "../indes";

const AddCategory = ({ type, data, handleCancelFunction }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(false);
  const [categoryName, setCategoryName] = useState(
    data ? data.categoryName : ""
  );
  const [categoryIcon, setCategoryIcon] = useState(
    data ? data.categoryIcon : ""
  );
  const [active, setActive] = useState(categoryIcon);

  const handleCategoryName = (e) => {
    setCategoryName(e.target.value);
  };
  const handleCategoryIcon = (e) => {
    setActive(e);
    setCategoryIcon(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    const formData = { categoryName, categoryIcon };
    if (!categoryName) {
      setErrors(true);
      return;
    }
    if (type === "edit") {
      const postData = {
        categoryName: capitalize(categoryName),
        categoryId: data._id,
        categoryIcon: categoryIcon,
      };

      dispatch(loaderActions.toggleLoading(true));
      editCategory(postData)
        .then((res) => window.location.reload())
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "11",
          });
        })
        .finally((res) => dispatch(loaderActions.toggleLoading(false)));

      return;
    }

    dispatch(loaderActions.toggleLoading(true));
    addCategory(formData)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "12",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };
  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <div className={`flex-col gap-40 ${classes.main_container}`}>
      {type === "edit" ? (
        <div className="flex-col align-center small-gap">
          <h2>Edit category</h2>
          <p className={`color-tertiary-grey`}>
            Please edit below details to modify category
          </p>
        </div>
      ) : (
        <div className="flex-col align-center small-gap">
          <h2>Add category</h2>
          <p className={`color-tertiary-grey`}>
            Please fill the below details to add a new category.
          </p>
        </div>
      )}
      <form className="flex-col gap-40" onSubmit={handleSubmit}>
        <div className="flex-col medium-gap">
          <label>Category Name</label>
          <Input
            placeholder="Enter category name"
            value={categoryName}
            onChange={handleCategoryName}
            maxLength={30}
          />
        </div>
        <div className="flex-col large-gap">
          <p>Select Icon as per category</p>
          <div className="flex-row large-gap overflow-auto hidden-scroll">
            {/* <div
              className={`flex-row align-center justify-center square-75 circular-div pointer bg-light ${
                active === "Cleaning"
                  ? classes.category_icon_active
                  : classes.category_icon
              }`}
              onClick={() => {
                handleCategoryIcon("Cleaning");
              }}
            >
              <CleaningServicesIcon />
            </div>
            <div
              className={`flex-row align-center justify-center square-75 circular-div pointer bg-light ${
                active === "Plumbing"
                  ? classes.category_icon_active
                  : classes.category_icon
              }`}
              onClick={() => {
                handleCategoryIcon("Plumbing");
              }}
            >
              <PlumbingIcon />
            </div> */}
            {categorySVGs.map((icon) => {
              return (
                <div
                  className={`flex-row align-center justify-center  square-75 circular-div pointer bg-light ${
                    active === icon?.name
                      ? classes.category_icon_active
                      : classes.category_icon
                  } `}
                  style={{
                    minWidth: "75px",
                    minHeight: "75px",
                  }}
                  onClick={() => handleCategoryIcon(icon?.name)}
                >
                  <div style={{ width: "40px", height: "40px" }}>
                    {icon?.icon}
                  </div>
                </div>
              );
            })}
          </div>
          {errors && <Error title={"Category Name cannot be empty"} />}
        </div>
        <div className="flex-row wd-100 justify-center large-gap">
          <Button
            type="button"
            background="#f8f8f8"
            color="#5F5F61"
            title="Cancel"
            handleFunction={handleCancelFunction}
          />
          <Button title={type === "edit" ? "Edit" : "Add"} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default AddCategory;
