import React from "react";
import classes from "./Loader.module.css";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className={classes.backdrop}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loader;
