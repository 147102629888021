import React, { useEffect, useState } from "react";
import classes from "./MyProfile.module.css";
import Button from "../common/commonComponents/Button/Button";
import Input from "../common/commonComponents/Input/Input";

import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import FormData from "form-data";

import API from "../../api/api_setting/apiConstants";
import { decodeToken } from "../../utils/decode";
import {
  changePropertyManagerPassword,
  changeUserPassword,
} from "../../api/api_calls/auth";
import {
  editPropertyManagerDetails,
  editUserProfile,
} from "../../api/api_calls/user";
import Error from "../common/commonComponents/Error/Error";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  editPasswordValidation,
  editUserValidation,
} from "../../validations/addDataValidation";
import { loaderActions } from "../../redux/reducers/loader";
import { toast } from "react-toastify";

const MyProfile = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const role = useSelector((state) => state.user.role);
  const isEditable = useSelector((state) => state.user.isEditable);

  const [changeProfilePicture, setChangeProfilePicture] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [changeFullName, setChangeFullName] = useState(userState?.name || "");

  const [changeEmail, setChangeEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = useState(false);

  const handleEditProfile = () => {
    setChangeEmail(!changeEmail);
    setImagePreview(userState.profileImg);
    setChangeProfilePicture(null);
  };

  const handleChangeProfilePicture = (e) => {
    if (e?.target?.value) {
      setChangeProfilePicture(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleFullNameChange = (e) => {
    setChangeFullName(e.target.value);
  };

  const handleUpdatePassword = () => {
    setChangePassword(!changePassword);
  };

  const handlePasswordFormChange = (e) => {
    setPasswordForm((currState) => {
      return { ...currState, [e.target.name]: e.target.value };
    });
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const passwordNew = () => {
    setShowNewPassword(!showNewPassword);
  };
  const passwordCnfrm = () => {
    setShowCnfrmPassword(!showCnfrmPassword);
  };
  const handleEditProfileSubmit = (e) => {
    e.preventDefault();

    let data = new FormData();

    if (changeFullName.trim().length !== 0)
      data.append("fullName", changeFullName);

    data.append("media", changeProfilePicture);

    const validateData = editUserValidation.validate({
      fullName: changeFullName,
    });

    if (validateData?.error?.message) {
      setError(validateData.error.message);
      return;
    }

    if (role === 1) {
      editPropertyManagerDetails(data)
        .then((res) => window.location.reload())
        .catch((error) => console.error(error));
    } else {
      editUserProfile(data)
        .then((res) => window.location.reload())
        .catch((error) => toast.error(error?.message));
    }
  };

  const handlePasswordFormSubmit = (e) => {
    e.preventDefault();

    if (
      passwordForm.oldPassword.trim().length !== 0 &&
      passwordForm.newPassword.trim().length !== 0 &&
      passwordForm.confirmPassword.trim().length !== 0
    ) {
      if (passwordForm.newPassword === passwordForm.confirmPassword) {
        const token = localStorage.getItem(API.AUTH_TOKEN);
        const decodedToken = decodeToken(token);

        const formData = {
          _id: decodedToken.id,
          oldPassword: passwordForm.oldPassword,
          newPassword: passwordForm.newPassword,
        };

        const validateData = editPasswordValidation.validate(formData);

        if (validateData?.error?.message) {
          setError(validateData.error.message);
          return;
        }

        if (role === 1) {
          dispatch(loaderActions.toggleLoading(true));
          changePropertyManagerPassword({
            currentPassword: formData?.oldPassword,
            newPassword: formData?.newPassword,
            confirmPassword: formData?.newPassword,
          })
            .then((res) => {
              if (res?.status === 400) {
                setError(res?.data?.message);
                return;
              } else {
                toast.success("Password changed successfully");
                setPasswordForm({
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
              }
            })
            .catch((error) => {
              toast.error(error?.message);
            })
            .finally((res) => dispatch(loaderActions.toggleLoading(false)));
        } else {
          dispatch(loaderActions.toggleLoading(true));
          changeUserPassword(formData)
            .then((res) => {
              if (res?.status === 400) {
                // setError(res?.data?.message);
                return;
              } else {
                toast.success("Password changed successfully!");
                setPasswordForm({
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
              }
            })
            .catch((error) => {
              toast.error(error?.message);
            })
            .finally((res) => dispatch(loaderActions.toggleLoading(false)));
        }
      } else {
        setError("New Password and Confirm Password do not match");
      }
    } else {
      setError("Password cannot be empty");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);

  return (
    <div className={`${classes.container} flex-col`}>
      <div className={`${classes.top} bg-white`}>
        <div
          className={`${classes.profile_details} flex-row just-sp-bwn align-center`}
        >
          <h2 className="title-color">Profile Details</h2>
          {!changeEmail && (
            <Button
              title="Edit Profile"
              icon={<EditIcon />}
              handleFunction={handleEditProfile}
              disabled={isEditable === false ? true : false}
            />
          )}
        </div>
        <div className={classes.profile_image}>
          <div className={classes.image_container}>
            <Avatar
              src={imagePreview || userState?.profileImg}
              variant="rounded"
              sx={{ height: 135, width: 135 }}
            />
            {changeEmail && (
              <>
                <div
                  className={`${classes.edit_profile} miniscule-square pointer flex-row align-center justify-center bord-rd-6`}
                  onClick={() => {
                    document.getElementById("change-profile-picture").click();
                  }}
                >
                  <EditIcon fontSize="small" />
                </div>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  id="change-profile-picture"
                  style={{ display: "none" }}
                  onChange={handleChangeProfilePicture}
                />
              </>
            )}
          </div>
        </div>
        {!changeEmail ? (
          <div
            className={`${classes.profile_information} flex-row just-sp-bwn align-center`}
          >
            <div className={`${classes.profile_name} flex-col`}>
              <h3 className="title-color">Full Name</h3>
              <p className="information-color">{userState.name}</p>
            </div>
            <div className={`${classes.profile_email} flex-col`}>
              <h3 className="title-color">Email Address</h3>
              <p className="information-color">{userState.email}</p>
            </div>
          </div>
        ) : (
          <div className={`flex-col ${classes.change_email}`}>
            <div
              className={`${classes.profile_information} flex-row just-sp-bwn align-center`}
            >
              <div className={`${classes.profile_name} flex-col`}>
                <h3 className="title-color">Full Name</h3>
                <Input
                  type="text"
                  placeholder="Enter a name"
                  onChange={handleFullNameChange}
                  value={changeFullName}
                />
              </div>
              <div className={`${classes.profile_email} flex-col`}>
                <h3 className="title-color">Email Address</h3>
                <Input
                  type="text"
                  placeholder={userState.email}
                  readonly="true"
                />
              </div>
            </div>
            <div className={`flex-row ${classes.action_buttons}`}>
              <Button
                background="#f8f8f8"
                type="button"
                color="#5F5F61"
                title="Cancel"
                handleFunction={handleEditProfile}
              />
              <Button title="Save" handleFunction={handleEditProfileSubmit} />
            </div>
          </div>
        )}
      </div>

      <div className={`${classes.down} flex-col bg-white`}>
        <div
          className={`${classes.account_settings} flex-row just-sp-bwn align-center`}
        >
          <h2 className="title-color">Account Settings</h2>
          {!changePassword && (
            <Button
              icon={<LockIcon />}
              handleFunction={handleUpdatePassword}
              title="Update Password"
            />
          )}
        </div>
        {!changePassword ? (
          ""
        ) : (
          <form onSubmit={handlePasswordFormSubmit}>
            <div className={`${classes.password_inputs} just-sp-bwn flex-row`}>
              <div className={`flex-col ${classes.field}`}>
                <label>Current Password *</label>
                <div className={`${classes.class_password} flex-col`}>
                  <Input
                    name="oldPassword"
                    placeholder="Enter Old Password"
                    type={showPassword ? "text" : "password"}
                    value={passwordForm.oldPassword}
                    onChange={handlePasswordFormChange}
                  />
                  <div
                    className={classes.password}
                    onClick={passwordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                    )}
                  </div>
                </div>
              </div>

              <div className={`flex-col ${classes.field}`}>
                <label>New Password *</label>
                <div className={`${classes.class_password} flex-col`}>
                  <Input
                    name="newPassword"
                    placeholder="Enter New Password"
                    type={showNewPassword ? "text" : "password"}
                    value={passwordForm.newPassword}
                    onChange={handlePasswordFormChange}
                  />
                  <div className={classes.password} onClick={passwordNew}>
                    {showNewPassword ? (
                      <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                    )}
                  </div>
                </div>
              </div>

              <div className={`flex-col ${classes.field}`}>
                <label>Confirm New Password *</label>
                <div className={`${classes.class_password} flex-col`}>
                  <Input
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    type={showCnfrmPassword ? "text" : "password"}
                    value={passwordForm.confirmPassword}
                    onChange={handlePasswordFormChange}
                  />
                  <div className={classes.password} onClick={passwordCnfrm}>
                    {showCnfrmPassword ? (
                      <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.action_buttons} flex-row`}>
              <Button
                type="button"
                title="Cancel"
                background="#f8f8f8"
                color="#5F5F61"
                handleFunction={handleUpdatePassword}
              />
              <Button title="Update Password" type="submit" />
            </div>
          </form>
        )}
      </div>
      {error && <Error title={error} />}
    </div>
  );
};

export default MyProfile;
