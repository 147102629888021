import React, { useEffect, useState } from "react";
import classes from "./AddMarket.module.css";
import Input from "../../../../components/common/commonComponents/Input/Input";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../redux/reducers/loader";
import Button from "../../../../components/common/commonComponents/Button/Button";
import { editMarketValidation } from "../../../../validations/addDataValidation";
import Error from "../../../../components/common/commonComponents/Error/Error";
import { updateMarket } from "../../../../api/api_calls/addData";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import CustomTag from "../../../../components/common/commonComponents/CustomTag/CustomTag";

const EditMarket = ({ onCancel, data }) => {
  const dispatch = useDispatch();
  const [marketName, setMarketName] = useState(data?.marketName);
  const [error, setError] = useState("");
  const groupData = data?.groupData;
  const handleSubmit = (e) => {
    e.preventDefault();
    const postForm = {
      marketName,
      marketId: data?._id,
    };
    const formValidation = editMarketValidation.validate(postForm);
    if (formValidation?.error?.message) {
      setError(formValidation.error.message);
      return;
    }
    dispatch(loaderActions.toggleLoading(true));

    updateMarket(postForm)
      .then((res) => window.location.reload())
      .catch((error) => {
        toast.error(`${error}`, {
          toastId: "30",
        });
      })
      .finally((res) => dispatch(loaderActions.toggleLoading(false)));
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
    clearTimeout();
  }, [error]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="gap-40 flex-col"
        style={{ width: "94%" }}>
        <Grid container rowSpacing={5} columnSpacing={3}>
          <Grid item xs={12}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <label>Market Name</label>
              <Input
                name="marketName"
                placeholder="Enter market name"
                value={marketName}
                onChange={(e) => setMarketName(e.target.value)}
                maxLength={30}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${classes.formField} flex-col small-gap`}>
              <h4 className="mg-0">Associated Group</h4>
            </div>
            <div
              className={`flex-col mt-20 medium-gap icon-padding ${classes.staffDiv}`}>
              {groupData.map((group, ind) => (
                <div
                  className={`${classes.formField} flex-row just-sp-bwn align-center `}
                  key={`group_${ind}`}>
                  <label>{group?.groupName}</label>
                  <CustomTag
                    type={"button"}
                    title={group?.groupTagName}
                    background={"#FCF1F7"}
                    color={"#E64593"}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        {error && <Error title={error} />}
        <div className="flex-row wd-100 justify-center medium-gap bottom-pd">
          <Button
            type="button"
            title="Cancel"
            theme="gray"
            handleFunction={onCancel}
          />
          <Button title="Save" type={"submit"} />
        </div>
      </form>
    </>
  );
};

export default EditMarket;
