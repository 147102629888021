import React, { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import Button from "../../../../components/common/commonComponents/Button/Button";
import Error from "../../../../components/common/commonComponents/Error/Error";
import classes from "./AddUser.module.css";
import { addUser, updateUser } from "../../../../api/api_calls/addData";
import { AddUserValidation } from "../../../../validations/addUserValidation";
import { toast } from "react-toastify";
import PhoneNumberInput from "../../../../components/common/commonComponents/PhoneNumberInput/PhoneNumberInput";
const userRoles = [
  {
    label: "Admin",
    value: 5,
  },
  {
    label: "Sub-Admin",
    value: 6,
  },
  {
    label: "Manager",
    value: 7,
  },
  {
    label: "QA",
    value: 8,
  },
];
const accessibility = [
  {
    label: "Dashboard",
    value: "Dashboard",
    checked: true,
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Scheduler",
    value: "Scheduler",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Chat",
    value: "Chat",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Updates",
    value: "Updates",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Property Manager",
    value: "Property Manager",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Category Management",
    value: "Category Management",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Staff Management",
    value: "Staff Management",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "Ratings",
    value: "Ratings",
    allowed: [5, 6, 7, 8],
  },
  {
    label: "User and Access",
    value: "User and Access",
    allowed: [5, 6],
  },
  {
    label: "Client Management",
    value: "Client Management",
    allowed: [5, 6, 7, 8],
  },
  { label: "Notifications", value: "Notifications", allowed: [5, 6, 7, 8] },
];

const AddUser = ({ onCancel, data, type, handleFetchUsers }) => {
  const [formData, setFormData] = useState({
    fullName: data?.fullName ? data?.fullName : "",
    email: data?.email ? data.email : "",
    mobileNumber: data?.mobileNumber ? data?.mobileNumber : "",
    location: data?.location ? data.location : "",
    role: data?.role ? data.role : "",
    permissions: data?.permissions ? data.permissions : ["Dashboard"],
    countryCode: data?.countryCode ? data.countryCode : "",
  });

  const [errors, setErrors] = useState("");

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    if (name === "role" && (value === "7" || value === "8")) {
      const userPermissionCheck =
        formData?.permissions.includes("User and Access");
      if (userPermissionCheck) {
        const updatedPermissions = [...formData?.permissions];
        updatedPermissions.splice(
          updatedPermissions.indexOf("User and Access"),
          1
        );
        setFormData((curr) => {
          return { ...curr, permissions: [...updatedPermissions] };
        });
      }
    }

    if (name === "permissions") {
      const updatedPermissions = [...formData.permissions];

      if (updatedPermissions.includes(value)) {
        updatedPermissions.splice(updatedPermissions.indexOf(value), 1);
      } else {
        updatedPermissions.push(value);
      }
      value = updatedPermissions;
    }
    if (name === "role") {
      value = Number(value);
    }

    setFormData((currentState) => {
      return { ...currentState, [name]: value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validData = AddUserValidation.validate(formData);

    if (validData?.error?.message) {
      setErrors(validData.error.message);

      setErrors(validData.error.message);
      return;
    }
    if (type === "update") {
      const id = data._id;
      delete formData.email;
      updateUser(id, formData)
        .then((res) => {
          onCancel();
          handleFetchUsers(10, 1);
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "99",
          });
        });
    } else {
      addUser(formData)
        .then((res) => {
          onCancel();
          handleFetchUsers(10, 1);
        })
        .catch((error) => {
          toast.error(`${error}`, {
            toastId: "2",
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 5000);
    clearTimeout();
  }, [errors]);
  return (
    <form
      onSubmit={handleSubmit}
      className="gap-40 flex-col"
      style={{ width: "94%" }}
    >
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Full Name</label>
            <input
              name="fullName"
              placeholder="Enter name"
              value={formData.fullName}
              onChange={handleFormChange}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Email Address</label>
            <input
              name="email"
              placeholder="Enter your Email Address"
              onChange={handleFormChange}
              value={formData.email}
              readOnly={type === "update" ? true : false}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Mobile Number</label>
            <PhoneNumberInput
              props={{ setFormData }}
              modalType="addUser"
              value={formData?.countryCode + formData?.mobileNumber}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label>Location</label>
            <input
              name="location"
              onChange={handleFormChange}
              placeholder="Enter location"
              value={formData.location}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label className={classes.heading}>User Role</label>
            <RadioGroup
              name="role"
              value={formData?.role}
              sx={{ display: "flex", flexDirection: "row", gap: "30px" }}
            >
              {userRoles.map((item) => (
                <FormControlLabel
                  className={classes.radioCheckbox}
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontFamily: "Roboto Slab !important",
                    },
                  }}
                  value={item?.value}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#40A59E",
                        },
                        color: "#DBDBDB",
                      }}
                    />
                  }
                  label={item?.label}
                  onChange={handleFormChange}
                />
              ))}
            </RadioGroup>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.formField} flex-col small-gap`}>
            <label className={classes.heading}>Accessibility</label>
            <div>
              {accessibility.map((item) => (
                <FormControlLabel
                  name="permissions"
                  className={classes.radioCheckbox}
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontFamily: "Roboto Slab !important",
                    },
                    ".Mui-disabled": {
                      cursor: "not-allowed",
                    },
                  }}
                  value={item?.value}
                  control={
                    <Checkbox
                      disabled={
                        (item?.allowed && item?.allowed.includes(formData?.role)
                          ? false
                          : true) || item?.checked
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: "#40A59E",
                        },
                        color: "#DBDBDB",
                      }}
                      checked={
                        formData?.permissions.includes(item?.value) ||
                        item?.checked
                      }
                    />
                  }
                  label={item?.label}
                  onChange={handleFormChange}
                  checked={
                    formData?.permissions.includes(item?.value) || item?.checked
                  }
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      {errors && <Error title={errors} />}

      <Box
        className="flex-row justify-center medium-gap"
        sx={{ textAlign: "center" }}
      >
        <Button
          type="button"
          title={"Cancel"}
          handleFunction={onCancel}
          theme={"grey"}
        />
        <Button
          title={type === "update" ? "Save" : "Send Invite"}
          type={"submit"}
        />
      </Box>
    </form>
  );
};

export default AddUser;
